<div class="legal-page-container px-3">
  <div class="bar-container">
    <div class="logo-container" *ngIf="('design' | env).legalLogoText"><img class="logo-image" [src]="('design' | env).legalLogo"><span>{{ ('design' | env).legalLogoText }}</span></div>
    <div class="logo-container" *ngIf="!('design' | env).legalLogoText"><img class="logo-only-image" [class.wide]="('design' | env).wideLegalLogo" [src]="('design' | env).legalLogo"></div>
    <a routerLink="/login" class="back-link text-info mr-3"></a>
  </div>

  <div class="legal-page-content">
    <div class="legal-page-title">Privacy Policy</div>
  
    <p class="mt-4">This Privacy Statement (the "Privacy Statement") is provided by:</p>
    <p class="mb-4">{{('design' | env).legalAppName | uppercase }}. ("{{('design' | env).legalName }}"/"we")</p>

    <div class="legal-page-title-secondary">Introduction</div>
    <p>This policy describes what information we collect when you use {{('design' | env).legalName }}’s sites, services, mobile applications, products, and content (“Services”). It also provides information about how we store, transfer, use, and delete that information, and what choices you have with respect to the information.</p>
    <p>This policy applies to {{('design' | env).legalName }}’s online collaboration tool, including the website and mobile and wearable applications, and other {{('design' | env).legalName }} websites (collectively “the Websites”), as well as other interaction (e.g. customer support conversations, user surveys and interviews etc.) you may have with {{('design' | env).legalName }}.</p>
    <p>This policy applies where we are acting as a Data Controller with respect to the personal data of users of our Services; in other words, where we determine the purposes and means of the processing of that personal data. For content and data that you upload to or make available through the Service (“User Content”), you are responsible for ensuring this content is in accordance with our Terms of Service, and that the content is not violating other users’ privacy.</p>

    <div class="legal-page-title-secondary">How we collect, process and store information</div>
    <p>We in {{('design' | env).legalName }} are committed to safeguarding the privacy of our users. Our business model is to provide a paid service to business users, and does not rely on widespread collection of general user data. We will only collect information that we need to deliver the service to you, and continue to maintain and develop the service.</p>
    <p>{{('design' | env).legalName }} may collect, store and process various kinds of data, with different legal grounds, as listed below. For the categories of data that require your consent, we will actively ask you for consent before collecting any data. You can give and revoke your consents at any time in your Settings page in https://{{('design' | env).legalName }}.</p>
    <p>The following is a list of data we collect, process or store, with the purpose and legal ground listed for each item or group of items having the same purpose and legal ground:</p>
    <ul>
      <li>
        <p><strong>User account information.</strong><span> Users that choose to register in {{('design' | env).legalName }}, will have to provide a valid corporate email address or phone number. The user can also choose to enter a display name and/or add a profile picture that will be used to represent them in conversations.</span></p>
      </li>
      <li>
        <p><strong>Room information.</strong><span> To create a room in {{('design' | env).legalName }}, you as a user will have to select a room name. This name will be publicly visible, and will be used by other users accessing meetings in that room. You as a user is responsible for the content you enter into a room name, and the content has to be compliant with our guidelines for Prohibited Content in Terms of Services.</span></p>
        <p><i>The information may be used for the purposes of operating our website, providing our services, ensuring the security of our website and services, maintaining back-ups of our databases and communicating with you. This is required to deliver the Service to you as user, by taking steps, at your request, to enter into such a contract (Terms of Service) cf. GDPR art. 6 (1) b.</i></p>
      </li>
      <li>
        <p><strong>Transaction information.</strong><span> Customers that choose to purchase paid version of the Services provide {{('design' | env).legalName }} (and our payment processors) with billing details such as credit card information, billing email, banking information, location at the time of transaction and/or a billing address.</span></p>
        <p><i>The transaction data may be processed for the purpose of supplying the purchased services and keeping proper records of those transactions. This data may be used for the purpose of delivering the Services to you. Collecting this information is required for performing the contract we entered into with you, at your request (our Terms of Service) cf. GDPR art. 6 (1) b.Additionally, this information needs to be retained in order to comply with accounting and tax regulation cf. GDPR art. 6 (1) c.</i></p>
      </li>
      <li>
        <p><strong>Usage information.</strong><span> When you as a user interact with the Services, we collect metadata to provide additional context about the way the Service is being used. The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is our server software and our analytics tracking system.</span></p>
        <ul>
          <li>
            <p><strong>Product Analytics data.</strong><span> {{('design' | env).legalName }} logs clicks when you as a user interact with our websites or apps, when you visit a page, room or have a conversation. We will never collect or record the content in conversations.</span></p>
          </li>
          <li>
            <p><strong>Technical log data.</strong><span> Like most digital services, our servers automatically collect information when you access or use our Websites or Services and record it in log files. This log data may include the Internet Protocol (IP) address, the address of the web page visited within the Services, browser type and settings, the date and time the Services were used, information about browser configuration and plugins, language preferences and cookie data.</span></p>
          </li>
          <li>
            <p><strong>Device information.</strong><span> {{('design' | env).legalName }} may collect information about devices used to access the Services, including type of device, what operating system is used, device settings, application IDs, location, unique device identifiers and crash data. Whether we collect some or all of this information depends on the type of device used and its settings.</span></p>
          </li>
          <li>
            <p><strong>Location information.</strong><span> We receive information from you and other third-parties that helps us approximate your location. We may, for example, use a business address submitted by your employer, or an IP address received from your browser or device to determine approximate location. {{('design' | env).legalName }} may also collect location information from devices in accordance with the consent process provided by your device.</span></p>
          </li>
        </ul>
        <p><i>The legal basis for this processing is our legitimate interests cf. GDPR art. 6 (1) f, namely using this data for the purpose of ensuring the proper administration of our website and business, analyzing the use of the website and services, monitoring and improving our website and services, improving the user experience, preventing abuse, and assisting users with support inquiries. For information about cookies and how to opt out of cookies.</i></p>
      </li>
      <li>
        <p><strong>Customer Support Information.</strong><span> We may process information that you send to us, should you chose to submit a ticket to our support email. If you contact us, we may use your Account, Room, Transaction or Usage Information to respond.</span></p>
        <p><i>Collecting this information it is required for performing the contract we entered into with you, at your request (our Terms of Service), as well as our legitimate interest of handling your requests cf. GDPR art. 6 (1) f.</i></p>
      </li>
      <li>
        <p><strong>Product & Marketing communication.</strong><span> We may process information that you provide to us for the purpose of subscribing to our email newsletters. You can opt in to emails such as digests, newsletters, and activity notifications through your account’s “Settings > Consents” page.</span></p>
        <p><i>The notification data may be processed for the purposes of sending you relevant product information or newsletters. The legal basis for this processing is your consent cf. GDPR art. 6 (1) a.</i></p>
      </li>
      <li>
        <p><strong>Service and transactional notifications.</strong><span> Sometimes we’ll send you emails about your account, service changes or new policies. You can’t opt out of this type of “service or transactional” emails (unless you delete your account).</span></p>
        <p><i>The legal grounds for collecting this information is that it is required for performing our commitment about communicating changes in plans and pricing to you in the contract we entered into with you, at your request (our Terms of Service) cf. GDPR art. 6 (1) b, and our legitimate interest of communicating important information about your account to you, cf. GDPR art. 6 (1) f.</i></p>
      </li>
      <li>
        <p><strong>Correspondence information.</strong><span> We may process information that you choose to share with us if you participate in a focus group, contest, activity or event, apply for a job, interact with our social media accounts or otherwise communicate with {{('design' | env).legalName }}</span></p>
        <p><i>The correspondence data may be processed for the purposes of communicating with you and record-keeping. The legal basis for this processing is our legitimate interests cf. GDPR art. 6 (1) f, namely the proper administration of our website and business and communications with users.</i></p>
      </li>
    </ul>
    
    <div class="legal-page-title-secondary">How we process media (audio/video)</div>
    <p>We will never store any media sent between participants in a room. Customers who have access to the “Recording” feature will be able to record meetings, and they are then responsible for collecting consents from all participants in the meeting prior to starting the recording. They are also responsible for storing and processing the recording in compliance with regulations after downloading it from {{('design' | env).legalName }}.</p>
    
    <div class="legal-page-title-third">Security</div>
    <p>Video and audio transmitted in the Service is then sent directly between the participants in a room and is encrypted (DTLS-SRTP) with client-generated encryption keys. In cases where a user is behind a strict firewall or NAT, video and audio need to be relayed via a TURN server, but end-to-end encryption is still maintained. Calls using “Recording” mode will use a dedicated server infrastructure to allow more people in conversation, and better stability. Your stream will be sent through video router servers which transmits it to the other participants in the call, and also transmits their streams to you. Streams will always be encrypted (DTLS-SRTP) in transit, but will be decrypted and re-encrypted when passing through the video routers. We operate an infrastructure of video routers distributed across the world, and you will be automatically routed to the closest one. The video router servers and all of our infrastructure adhere to strict security measures, preventing any eavesdropping or interruption of the video/audio streams. {{('design' | env).legalName }} uses the AES cipher with 128-bit keys to encrypt audio and video, and HMAC-SHA1 to verify data integrity. {{('design' | env).legalName }} uses HTTPS support, secure RTP, HTTP authentication, secure WebSockets and application level authentication using tokens to ensures that your information is always safe.</p>

    <div class="legal-page-title-secondary">Providing your personal data to others</div>
    <p>We may share information about with third parties in some circumstances, including: (1) with your consent; (2) to a service provider or partner who meets our data protection standards; (3) with academic or non-profit researchers, with aggregation, anonymization, or pseudonomization; (4) when we have a good faith belief it is required by law, such as pursuant to a subpoena or other legal process; (5) to protect the vital interest of others, when we have reason to believe that doing so will prevent harm to someone or illegal activities.</p>

    <p>Our categories of service providers and partners are:</p>
    <ul>
      <li><p>Hosting/infrastructure/storage providers</p></li>
      <li><p>Payment processors</p></li>
      <li><p>Analysis tools providers</p></li>
      <li><p>Customer Support tools providers</p></li>
      <li><p>Marketing and email providers</p></li>
      <li><p>Recruiting tools providers</p></li>
      <li><p>Internal communication tools providers</p></li>
    </ul>

    <div class="legal-page-title-third">Business Transfers</div>
    <p>We may disclose your personal data to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes, and on the legal bases, set out in this policy.</p>
    <p>In the case where we are involved in a merger, acquisition, bankruptcy, reorganization or sale of assets such that your information would be transferred or become subject to a different privacy policy, we will notify you.</p>

    <div class="legal-page-title-secondary">International transfers of your personal data</div>
    <p>In some circumstances your personal data may be transferred to countries outside the European Economic Area (EEA). You acknowledge that personal data that you submit for publication through our website or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data by others.</p>
    <p>We and our other group companies have offices and facilities in EU, Turkey and United States. The hosting facilities for Account information stored by {{('design' | env).legalName }} are situated in Microsoft Azure. The hosting facilities for Usage information are situated in Microsoft Azure. Transfers to the United States will be protected by appropriate safeguards, namely the use of standard data protection clauses adopted or approved by the European Commission, a copy of which can be obtained from https://commission.europa.eu/law/law-topic/data-protection/reform/rules-business-and-organisations/obligations/what-rules-apply-if-my-organisation-transfers-data-outside-eu_en.</p>
    
    <div class="legal-page-title-secondary">Retaining and deleting personal data</div>
    <p>Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.</p>
    
    <p>We will retain your personal data as follows:</p>
    <ul>
      <li>
        <p>Transaction information will be retained for a minimum period of 5 years following date of the transaction, and for a maximum period of 10 years following the date of the transaction.</p>
      </li>
    </ul>

    <p>In some cases it is not possible for us to specify in advance the periods for which your personal data will be retained. In such cases, we will determine the period of retention based on the following criteria:</p>
    <ul>
      <li>
        <p>Account information, Room information will be retained until you decide to delete your account or delete a room in {{('design' | env).legalName }}</p>
      </li>
      <li>
        <p>Information about you used for Product & Marketing communication will be retained as long as you have given us consent to use this information</p>
      </li>
      <li>
        <p>The period of retention of usage information will be determined based on the need for historical data to determine statistical validity and relevance for product decisions and technical monitoring.</p>
      </li>
    </ul>

    <p>Regardless of the provisions above, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</p>

    <div class="legal-page-title-secondary">Changes to this policy</div>
    <p>We can change these Terms at any time. If a change is material, we’ll let you know before it takes effect. By using {{('design' | env).legalName }} on or after that effective date, you agree to the new Terms. If you don’t agree to them, you should delete your account before they take effect, otherwise your use of the Service and Content will be subject to the new Terms.</p>

    <div class="legal-page-title-secondary">Managing and deleting your personal information</div>
    <p>If you have an {{('design' | env).legalName }} account, you can access, modify or export your personal information, or delete your account in Settings. If you delete your account, your information and content will be unrecoverable after that time. You may instruct us at any time not to process your personal information for marketing purposes, by adjusting your Privacy settings). We may withhold personal information that you request to the extent permitted by law.</p>

    <div class="legal-page-title-secondary">Your rights</div>
    <p>As an individual you are granted rights according to the applicable data protection law:</p>
    <ul>
      <li><p>The right to access to your personal data</p></li>
      <li><p>The right to rectification of your personal data</p></li>
      <li><p>The right to object to and restriction of our processing of your personal data</p></li>
      <li><p>The also right to be forgotten; erasure of your data.</p></li>
      <li><p>The right to data portability.</p></li>
    </ul>

    <p>The rights are not absolute, and you may read more about your rights in the EU general data protection regulation Chapter III, or at https://ec.europa.eu/info/law/law-topic/data-protection/reform/rights-citizens_en</p>
    <p>To exercise your rights or if you otherwise have any questions regarding our processing of your personal data, we encourage you to contact us as described below.</p>

    <div class="legal-page-title-secondary">Data protection officer</div>
    <p>For any questions about this privacy policy, please contact {{('design' | env).legalMail }}.</p>
  </div>
</div>