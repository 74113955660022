<div class="room-wrapper">
    <div style="position: absolute" *ngIf="iframeWorkflowData">
        <div class="workflow-box" cdkDrag  handleResize  [(height)]="boxHeight" [(width)]="boxWidth" 
        [style.width.px]="boxWidth" [style.height.px]="boxHeight" [maxWidth]="700" [maxHeight]="700" [minWidth]="400" [minHeight]="300">
            <div cdkDragHandle style="cursor: move; border-bottom: solid 1px #eeeeee; padding-bottom: 5px;">
                <div class="d-flex align-items-center">
                    <div class="col-2">
                        <img src="assets/img/vsight-amblem.svg" alt="" height="26px">
                    </div>
                    <div class="col-8">
                        <label class="cursor-move text-grey">{{iframeWorkflowData.workflowName}}</label>
                    </div>
                </div>
                <div class="close text-grey" (click)="close()">
                    <i class="mi mi-close"></i>
                </div>
            </div>
            <!--<app-workflows style="display:block; height:100%; width: 100%;margin-top: 20px;min-width: 200px;min-height:200px;" [workflow]="workflow" (workflowSubmitted)="workflowSubmitted($event)"></app-workflows>-->
            <div class="workflow-iframe" *ngIf="iframeWorkflowData.src">
                <iframe width="100%" height="100%" [src]="iframeWorkflowData.src | safeHtml" scrolling="no" style="overflow: hidden;" #myIframe></iframe>
            </div>
            <div class="handler"></div>
        </div>
    </div>

    <div class="video-chat" [ngClass]="{ 'sidebar-open': sidebarOpen }">
        <div class="video-chat-overlay" (click)="onToggleSidebar()"></div>
        <app-video-chat [sidebarOpen]="sidebarOpen" *ngIf="callService.inRoom"></app-video-chat>
    </div>

    <div class="sidebar" [ngClass]="{ 'sidebar-open': sidebarOpen }">
        <a class="sidebar-toggle" (click)="onToggleSidebar(); openChatTutorial.next({collaborate: true});"
            #openChatTutorial="tutorial" tutorial="openChat" [ignored]="sidebarOpen">
        <div *ngIf="unreadDot" class="unread-dot"></div>
        <i class="hamburger-icon mi mi-menu"></i>
        <div class="sidebar-toggle-placeholder"></div>
        </a>
        <app-sidebar (unreadMessage)="unreadNewMessage($event)" (workflowDataChanged)= "workflowDataChanged($event)" *ngIf="callService.inRoom" ></app-sidebar>
    </div>
</div>

<ng-template #endCallTemplate let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ENDCALL_MODAL.TITLE_LEAVE' | translate }}</h5>
          </div>
          <div class="modal-body px-0 py-4">
              <p class="mx-3">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ENDCALL_MODAL.MESSAGE_LEAVE' | translate }}</p>
          </div>
  
          <div class="modal-footer d-block text-right py-2 px-3">
              <button class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">
                  {{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ENDCALL_MODAL.CANCEL' | translate }}
              </button>
              <button class="btn btn-sm btn-danger px-4" (click)="callbacks.yes()">
                  {{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.ENDCALL_MODAL.LEAVE' | translate }}
              </button>
          </div>
      </div>
  </div>
</ng-template>

<ng-template #otErrorTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">{{ 'APP.MAIN.ROOM.OT_ERROR_MODAL.TITLE' | translate }}</h5>
          </div>
          <div class="modal-body px-0 py-4">
              <p class="mx-3">{{ dataModel.error_key | translate }}</p>
              <p class="mx-3 mt-2" *ngIf="dataModel.error_key2">{{ dataModel.error_key2 | translate }}</p>
              <p class="mx-3 mt-3">CODE: {{ dataModel.code }}</p>
          </div>
          <div class="modal-footer d-block text-right py-2 px-3">
              <button class="btn btn-sm btn-dark px-4" (click)="callbacks.close()">
                  {{ 'APP.MAIN.ROOM.OT_ERROR_MODAL.CLOSE' | translate }}
              </button>
              <button *ngIf="dataModel.action === 'rejoin'" class="btn btn-sm btn-danger px-4" (click)="callbacks.rejoin()">
                  {{ 'APP.MAIN.ROOM.OT_ERROR_MODAL.REJOIN' | translate }}
              </button>
              <button *ngIf="dataModel.action === 'endcall'" class="btn btn-sm btn-danger px-4" (click)="callbacks.endcall()">
                {{ 'APP.MAIN.ROOM.OT_ERROR_MODAL.ENDCALL' | translate }}
            </button>
          </div>
      </div>
  </div>
</ng-template>