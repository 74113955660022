<div class="dropzone d-flex justify-content-center align-items-center p-3 text-center" *ngIf="dropzoneOpen"
    filedrop (filesDropped)="onDropFile($event)" (filesHovered)="onHoverFile($event)">
    {{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_SHARE.DRAG_HERE' | translate }}
</div>

<ng-template #uploadModalTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <span class="mr-2">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_SHARE.UPLOAD_TITLE' | translate }}</span>
                    <span *ngIf="dataModel.totalBytes !== 0 && dataModel.uploading">{{ dataModel.transferredBytes / dataModel.totalBytes | percent }}</span>
                </h5>
            </div>
            <div class="modal-body upload-modal-body">

                <div class="d-block" *ngFor="let uploadFile of dataModel.uploadFiles; let i = index">

                    <div class="upload-file d-flex justify-content-between align-items-center px-3"
                        [ngClass]="{'highlighted': uploadFile.highlighted}"
                        [class.exceed-size]="(uploadFile.file.size > fileSizeLimit.bytes && !ticketId) || restrictedExtensions.includes(uploadFile.extension)">

                        <!-- SHOW PROGRESS -->
                        <div class="upload-progress" [ngStyle]="{'width.%':uploadFile.progress}"
                            [class.error]="uploadFile.uploadTask?.snapshot.state === 'canceled'"
                            [class.success]="uploadFile.uploadTask?.snapshot.state === 'success'">

                            <p *ngIf="uploadFile.progress > 7" class="text-dark bg-light p-1 rounded">
                                {{ uploadFile.progress }}%
                            </p>
                        </div>

                        <div class="file-info">
                            <i class="file-icon mi mi-insert-drive-file"></i>
                            <p class="file-name" title="{{ uploadFile.file.name }}">{{ uploadFile.file.name }}</p>
                            <span *ngIf="uploadFile.file.size > fileSizeLimit.bytes && !ticketId" class="badge badge-light" style="margin-left: 10px;">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_SHARE.LARGER_THAN' | translate:fileSizeLimit }}</span>
                            <span *ngIf="restrictedExtensions.includes(uploadFile.extension)" class="badge badge-light" style="margin-left: 10px;">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_SHARE.FILETYPE_PROHIBITED' | translate }}</span>
                        </div>

                        <!-- REMOVE OR CANCEL -->
                        <a *ngIf="!uploadFile.uploadTask && !(uploadFile.file.size > fileSizeLimit.bytes || restrictedExtensions.includes(uploadFile.extension))" class="file-remove text-warning" (click)="callbacks.remove(i)" >
                            <i class="mi mi-remove-circle"></i>
                        </a>
                        <a *ngIf="!uploadFile.uploadTask && (((uploadFile.file.size > fileSizeLimit.bytes) && !ticketId) || restrictedExtensions.includes(uploadFile.extension))" class="file-remove-large text-danger" (click)="callbacks.remove(i)" >
                            <i class="mi mi-cancel"></i>
                        </a>
                        <a *ngIf="uploadFile.uploadTask?.snapshot.state === 'running'" class="file-cancel text-danger"
                                (click)="uploadFile.uploadTask.cancel()" >
                            <i class="mi mi-cancel"></i>
                        </a>

                        <!-- SHOW STATUS -->
                        <a *ngIf="uploadFile.uploadTask?.snapshot.state === 'canceled'" class="file-canceled text-danger" >
                            <i class="mi mi-cancel"></i>
                        </a>
                        <a *ngIf="uploadFile.uploadTask?.snapshot.state === 'success'" class="file-succeeded text-success">
                            <i class="mi mi-check-circle"></i>
                        </a>

                    </div>

                    <hr class="upload-divider m-0">
                </div>
            </div>

            <div class="modal-footer upload-modal-footer">
                <div *ngIf="dataModel.multipleObjects" class="multiple-objects text-danger text-center py-2 px-3" style="font-size: 14px;">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_SHARE.MULTIPLE_OBJECTS' | translate }}</div>

                <div class="file-types d-flex flex-row mr-0 px-3 py-2">
                    <div class="rounded py-2 mx-auto" [ngClass]="{'border border-dark': dataModel.types.includesImage}">
                        <img src="assets/img/img.png">
                        <p class="mt-1" [ngClass]="{'text-muted': !dataModel.types.includesImage}">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_SHARE.IMAGE' | translate }}</p>
                    </div>
                    <div class="rounded py-2 mx-auto" [ngClass]="{'border border-dark': dataModel.types.includesPdf}">
                        <img src="assets/img/pdf.png">
                        <p class="mt-1" [ngClass]="{'text-muted': !dataModel.types.includesPdf}">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_SHARE.PDF' | translate }}</p>
                    </div>
                    <div class="rounded py-2 mx-auto" [ngClass]="{'border border-dark': dataModel.types.includesObject}">
                        <img src="assets/img/obj.png">
                        <p class="mt-1" [ngClass]="{'text-muted': !dataModel.types.includesObject}">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_SHARE.3D_OBJECT' | translate }}</p>
                    </div>
                    <div class="rounded py-2 mx-auto" [ngClass]="{'border border-dark': dataModel.types.includesOther}">
                        <img src="assets/img/other.png">
                        <p class="mt-1" [ngClass]="{'text-muted': !dataModel.types.includesOther}">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_SHARE.OTHER' | translate }}</p>
                    </div>
                </div>

                <div class="upload-modal-buttons ml-0 px-4">
                    <button class="btn btn-sm btn-info btn-block mt-2" (click)="callbacks.upload()"
                            [disabled]="dataModel.uploading || dataModel.completed || dataModel.multipleObjects || sizeLimitExceeded || restrictedExtensionDetected">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_SHARE.START_UPLOAD' | translate }}</button>
                    <button *ngIf="dataModel.uploading" class="btn btn-sm btn-danger btn-block mt-1 mb-3"
                            (click)="callbacks.cancel()">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_SHARE.CANCEL_UPLOAD' | translate }}</button>
                    <button *ngIf="dataModel.completed || !dataModel.uploading" class="btn btn-sm btn-dark btn-block mt-1 mb-3"
                            (click)="callbacks.close()">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_SHARE.CLOSE_UPLOAD' | translate }}</button>
                </div>

            </div>
        </div>
    </div>
</ng-template>

<ng-template #cancelUploadTemplate let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_SHARE.CANCEL_MODAL_TITLE' | translate }}</h5>
            </div>
            <div class="modal-body">
                <p>{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_SHARE.CANCEL_MODAL_MESSAGE' | translate }}</p>
            </div>

            <div class="modal-footer">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.close()">
                    {{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_SHARE.CANCEL_MODAL_CLOSE' | translate }}
                </button>
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.cancel()">
                    {{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_SHARE.CANCEL_MODAL_CANCEL' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>