import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FullscreenService {

  isFullscreen: Observable<{fullscreen: boolean, supported: boolean}>;
  private _isFullscreen: boolean = false;
  private isFullscreenSource = new BehaviorSubject<{fullscreen: boolean, supported: boolean}>({fullscreen: false, supported: true});

  constructor() {
    this.isFullscreen = this.isFullscreenSource.asObservable();

    document.addEventListener("fullscreenchange", () => {
      this._isFullscreen = !this._isFullscreen;
      this.isFullscreenSource.next({fullscreen: this._isFullscreen, supported: true});
    });
    document.addEventListener("webkitfullscreenchange", () => {
      this._isFullscreen = !this._isFullscreen;
      this.isFullscreenSource.next({fullscreen: this._isFullscreen, supported: true});
    });
    document.addEventListener("mozfullscreenchange", () => {
      this._isFullscreen = !this._isFullscreen;
      this.isFullscreenSource.next({fullscreen: this._isFullscreen, supported: true});
    });
    document.addEventListener("MSFullscreenChange", () => {
      this._isFullscreen = !this._isFullscreen;
      this.isFullscreenSource.next({fullscreen: this._isFullscreen, supported: true});
    });
  }

  requestFullscreen(element): boolean {
    if (element.requestFullscreen) {
      element.requestFullscreen();
      return true;
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
      return true;
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
      return true;
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
      return true;
    } else {
      // Events not working, set true manually
      this._isFullscreen = true;
      this.isFullscreenSource.next({fullscreen: this._isFullscreen, supported: false});
      return false;
    }
  }

  exitFullscreen(): boolean {
    const _document: any = document;

    if (_document.exitFullscreen) {
      _document.exitFullscreen();
      return true;
    } else if (_document.webkitExitFullscreen) {
      _document.webkitExitFullscreen();
      return true;
    } else if (_document.mozCancelFullScreen) {
      _document.mozCancelFullScreen();
      return true;
    } else if (_document.msExitFullscreen) {
      _document.msExitFullscreen();
      return true;
    } else {
      // Events not working, set false manually
      this._isFullscreen = false;
      this.isFullscreenSource.next({fullscreen: this._isFullscreen, supported: false});
      return false;
    }
  }
}
