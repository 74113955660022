import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared.module';

import { NgModule } from '@angular/core';

// RouterModule - router imports
import { AppRoutingModule } from './app-routing/app-routing.module';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { ModalModule } from 'ngx-bootstrap/modal';

// FormsModule - ngForm imports
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CarouselModule } from 'ngx-bootstrap/carousel';

// Multilanguage support - ngx-translate imports
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';

import { AngularResizedEventModule } from 'angular-resize-event';

import { AnnouncekitModule } from 'announcekit-angular';

// Environment - For accessing environment variables
import { environment } from '../environments/environment';

// Support Components
import { LoaderComponent } from './components/support/loader/loader.component';
import { ModalComponent } from './components/support/modal/modal.component';
import { TutorialComponent } from './components/support/tutorial/tutorial.component';
import { FlashMessageComponent } from './components/support/flash-message/flash-message.component';

// Public Components
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { DownloadsComponent } from './components/externals/downloads/downloads.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

// Authenticate Requiring components
import { MainComponent } from './components/main/main.component';
import { LobbyComponent } from './components/main/lobby/lobby.component';
import { NavbarComponent } from './components/main/navbar/navbar.component';
import { SessionRatingComponent } from './components/main/session-rating/session-rating.component';
import { RoomComponent } from './components/main/room/room.component';
import { OwnRoomsComponent } from './components/main/lobby/own-rooms/own-rooms.component';
import { SidebarComponent } from './components/main/room/sidebar/sidebar.component';
import { VideoChatComponent } from './components/main/room/video-chat/video-chat.component';
import { ChatComponent } from './components/main/room/sidebar/chat/chat.component';
import { CloudComponent } from './components/main/room/sidebar/cloud/cloud.component';
import { SettingsComponent } from './components/main/room/sidebar/settings/settings.component';
import { PublisherComponent } from './components/main/room/video-chat/publisher/publisher.component';
import { SubscriberComponent } from './components/main/room/video-chat/subscriber/subscriber.component';
import { ImageCollaborationWrapperComponent } from './components/main/room/video-chat/image-collaboration/image-collaboration-wrapper/image-collaboration-wrapper.component';
import { PdfCollaborationWrapperComponent } from './components/main/room/video-chat/pdf-collaboration/pdf-collaboration-wrapper/pdf-collaboration-wrapper.component';
import { FileShareComponent } from './components/main/room/sidebar/chat/file-share/file-share.component';

import { ControlBarComponent } from './components/main/room/video-chat/control-bar/control-bar.component';
import { TermsComponent } from './components/pages/terms/terms.component';
import { PrivacyComponent } from './components/pages/privacy/privacy.component';
import { VsGuideComponent } from './components/pages/vs-guide/vs-guide.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CollaborationComponent } from './components/externals/collaboration/collaboration.component';
import { SecurityInformationComponent } from './components/pages/security-information/security-information.component';
import { EulaComponent } from './components/pages/eula/eula.component';
import { JoinWithLinkComponent } from './components/externals/join-with-link/join-with-link.component';
import { ParticipantsComponent } from './components/main/room/sidebar/participants/participants.component';
import { ContactsComponent } from './components/main/lobby/contacts/contacts.component';
import { AuthenticateComponent } from './components/externals/authenticate/authenticate.component';
import { ArchiveListComponent } from './components/externals/archive-list/archive-list.component';
import { TicketsComponent } from './components/main/lobby/tickets/tickets.component';
import { FeedbackComponent } from './components/main/lobby/feedback/feedback.component';
import { WhatsnewComponent } from './components/main/lobby/whatsnew/whatsnew.component';
import { AdLoginComponent } from './components/externals/ad-login/ad-login.component';
import { SsoLoginComponent } from './components/externals/sso-login/sso-login.component';
import { AdErrorComponent } from './components/externals/ad-error/ad-error.component';
import { LobbyContentComponent } from './components/main/lobby/lobby-content/lobby-content.component';
import { MeetingsComponent } from './components/main/lobby/meetings/meetings.component';
import { MobileFeedbackComponent } from './components/externals/mobile-feedback/mobile-feedback.component';
import { DownloadExportComponent } from './components/externals/download-export/download-export.component';

import { TagInputModule } from 'ngx-chips';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { SessionsComponent } from './components/main/lobby/sessions/sessions.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SessionDurationPipe } from './pipes/session-duration.pipe';
import { TicketDetailComponent } from './components/main/lobby/tickets/ticket-detail/ticket-detail.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { TicketsModalComponent } from './components/main/lobby/tickets-modal/tickets-modal.component';
import { SessionDetailComponent } from './components/main/lobby/sessions/session-detail/session-detail.component';
import { ArchivesComponent } from './components/main/lobby/archives/archives.component';
import { SessionFileComponent } from './components/main/lobby/sessions/session-file/session-file.component';
import { SessionChatComponent } from './components/main/lobby/sessions/session-chat/session-chat.component';
import { DurationFormatPipe } from './pipes/duration-format.pipe';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { AppSelectComponent } from './components/main/lobby/app-select/app-select.component';
import { RoomsFilesComponent } from './components/main/room/sidebar/rooms-files/rooms-files.component';
import { WorkflowsComponent } from './components/main/lobby/workflows/workflows.component';
import { WorkflowListComponent } from './components/main/lobby/workflow-list/workflow-list.component';
import { ResizeDirective } from './components/main/room/resize.directive';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { GetFileComponent } from './components/externals/get-file/get-file.component';
import { ReconnectRoomComponent } from './components/main/reconnect-room/reconnect-room.component';
import { DownloadAppComponent } from './components/externals/download-app/download-app.component';
import { ConnectExpertsComponent } from './components/externals/connect-experts/connect-experts.component';
import { MobileSubscriberComponent } from './components/main/room/video-chat/mobile-subscriber/mobile-subscriber.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    DownloadsComponent,
    NotFoundComponent,
    LobbyComponent,
    NavbarComponent,
    RoomComponent,
    ResizeDirective,
    OwnRoomsComponent,
    SidebarComponent,
    VideoChatComponent,
    ChatComponent,
    CloudComponent,
    SettingsComponent,
    PublisherComponent,
    SubscriberComponent,
    LoaderComponent,
    ModalComponent,
    TutorialComponent,
    FlashMessageComponent,
    ImageCollaborationWrapperComponent,
    PdfCollaborationWrapperComponent,
    FileShareComponent,
    ControlBarComponent,
    TermsComponent,
    PrivacyComponent,
    VsGuideComponent,
    SecurityInformationComponent,
    EulaComponent,
    CollaborationComponent,
    JoinWithLinkComponent,
    ParticipantsComponent,
    ContactsComponent,
    AuthenticateComponent,
    ArchiveListComponent,
    TicketsComponent,
    FeedbackComponent,
    WhatsnewComponent,
    AdLoginComponent,
    SsoLoginComponent,
    AdErrorComponent,
    LobbyContentComponent,
    MeetingsComponent,
    MobileFeedbackComponent,
    SessionRatingComponent,
    WhatsnewComponent,
    DownloadExportComponent,
    SessionsComponent,
    TicketDetailComponent,
    ConfirmModalComponent,
    TicketsModalComponent,
    SessionDetailComponent,
    ArchivesComponent,
    SessionFileComponent,
    SessionChatComponent,
    AppSelectComponent,
    RoomsFilesComponent,
    WorkflowsComponent,
    WorkflowListComponent,
    GetFileComponent,
    ReconnectRoomComponent,
    DownloadAppComponent,
    ConnectExpertsComponent,
    MobileSubscriberComponent
  ],
  imports: [
    AnnouncekitModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    CarouselModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    BsDatepickerModule.forRoot(),
    HttpClientModule,
    TagInputModule,
    TranslateModule.forRoot(),
    AngularResizedEventModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ModalModule.forRoot(),
    PdfViewerModule
  ],
  providers: [ SessionDurationPipe, DurationFormatPipe, TitleCasePipe,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
