import { Injectable } from '@angular/core';

// Router imports
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// rxjs imports
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DbService } from '@services/core/db.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private dbService: DbService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    // Return observable of <auhtenticated or not>
    return this.dbService.isAuthenticated.pipe(
      take(1),
      map(authenticated => {
        if (authenticated) {
          return true;
        } else {
          this.router.navigate(['/login']);
          return false;
        }
      })
    );
  }
}