import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/core/auth.service';

@Component({
  selector: 'app-session-rating',
  templateUrl: './session-rating.component.html',
  styleUrls: ['./session-rating.component.scss']
})
export class SessionRatingComponent implements OnInit {
  endCallResponse: { roomId: string, sessionId: string, sessionEnded: boolean } = null;
  ratingId: string = null;
  hoverCount = 0;
  chosenStar = 0;
  isRated = false;
  clientReview = '';
  titles = ["APP.MAIN.SESSION_RATING.BAD", "APP.MAIN.SESSION_RATING.POOR", "APP.MAIN.SESSION_RATING.FAIR", "APP.MAIN.SESSION_RATING.GOOD", "APP.MAIN.SESSION_RATING.EXCELLENT"];

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (!(navigation && navigation.extras && navigation.extras.state)) {
      this.router.navigate(['/']);
    } else {
      this.endCallResponse = <any>navigation.extras.state;
    }
  }

  ngOnInit(): void {

  }

  onStarHover(i:number) {
    this.hoverCount = i;
  }
  onClear() {
    this.hoverCount = 0;
  }
  onStarSelected (selected: number) {
    this.chosenStar = selected;
    this.isRated = true;
  }

  close() {
    this.router.navigate(['/']);
  }

  save() {
    this.pushToDb();
    this.router.navigate(['/']);
  }

  pushToDb() {
    const currentUser = this.authService.currentUser;
    const ratingData: any = {
      user_id: currentUser.id,
      account_id: currentUser.account_id,
      room_id: this.endCallResponse.roomId,
      session_id: this.endCallResponse.sessionId,
      rating: this.chosenStar
    }
    if (this.clientReview){
      ratingData.review = this.clientReview;
    }
    this.authService.sendRatingData(ratingData);
  }
}
