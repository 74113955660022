import { Component, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnDestroy {
  public onClose: Subject<boolean> = new Subject();

  title;
  cancelTitle = 'CANCEL';
  approveTitle = 'DELETE';
  cancelClass = 'btn-dark';
  approveClass = 'btn-danger';
  description = '';
  instantClose = true;
  showLoading = false;

  constructor(private modalRef: BsModalRef) {}

  ngOnDestroy(): void {
    this.onClose.complete();
  }

  confirm(isConfirmed): void {
    this.showLoading = true;
    if (isConfirmed) {
      if (this.instantClose) {
        this.onClose.next(true);
        this.modalRef.hide();
        return;
      }
      this.onClose.next(true);
    } else {
      this.onClose.next(false);
      this.modalRef.hide();
    }
  }
}

