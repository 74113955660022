import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@services/core/auth.service';
import { DbService } from '@services/core/db.service';
import { LoaderService } from '@services/support/loader.service';
import { first } from 'rxjs/operators';
import { environment } from 'environments/environment';

declare var hbspt: any;
declare var $: any;

@Component({
  selector: 'app-mobile-feedback',
  templateUrl: './mobile-feedback.component.html',
  styleUrls: ['./mobile-feedback.component.scss']
})
export class MobileFeedbackComponent implements OnInit {

  @ViewChild('feedbackContainer', { static: true }) feedbackContainer: ElementRef;

  submittedForm: boolean = false;
  loading: boolean = true;
  feedbackiFrame: any = null;
  feedbackId: string = null;
  currentRoom: any = null;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private loaderService: LoaderService,
    private dbService: DbService
  ) { }

  formReadyFn = async () => {
    this.feedbackId = this.dbService.createPushId();
    const currentUser = this.authService.currentUser;
    const feedbackForm = environment["feedbackForm"];

    const iframe = $(this.feedbackContainer.nativeElement).find('iframe').contents();
    const feedbackInput = iframe.find("input[name='feedback_id']");
    feedbackInput.val(this.feedbackId).change();

    const emailInput = iframe.find("#email-" + feedbackForm.formId);
    emailInput.val(currentUser.email).change();

    const companyInput = iframe.find("#company-" + feedbackForm.formId);
    companyInput.val(currentUser.auth.account_name).change();

    if (this.currentRoom) {
      const roomInput = iframe.find("#vsight_room_name-" + feedbackForm.formId);
      roomInput.val(this.currentRoom.room_data.name).change();
    }

    const now = new Date();
    const formattedDate = now.getFullYear() + '-' + ("0"+(now.getMonth()+1)).slice(-2) + '-' + ("0" + now.getDate()).slice(-2);

    const dateInput = iframe.find("#incident_date-" + feedbackForm.formId);
    dateInput.val(formattedDate).change();

    const hourInput = iframe.find("#hout-" + feedbackForm.formId);
    hourInput.val(now.getHours()).change();

    const minuteInput = iframe.find("#minute-" + feedbackForm.formId);
    minuteInput.val(now.getMinutes()).change();
  }

  formSubmitFn = async (form) => {
    if (this.feedbackId) {
      const currentUser = this.authService.currentUser;

      const feedbackData: any = {
        account_id: currentUser.account_id,
        company_name: currentUser.company_name,
        user: {
          name: currentUser.name,
          role: currentUser.role,
          username: currentUser.user_principal_name ? currentUser.user_principal_name : currentUser.auth.username,
          allow_archiving: currentUser.allow_archiving,
          allow_calling: currentUser.allow_calling,
          allow_join_link: currentUser.allow_join_link,
          call_archive_perm_needed: currentUser.call_archive_perm_needed
        }
      };
      if (currentUser.ad_user) {
        feedbackData.user.ad_user = true;
      }
      if (currentUser.email) {
        feedbackData.user.email = currentUser.email;
      }
      if (this.currentRoom) {
        feedbackData.session = {
          room_name: this.currentRoom.room_data.name,
          vs: this.currentRoom.room_data.session.vs,
          ot: this.currentRoom.room_data.session.ot,
          peer_to_peer: this.currentRoom.room_data.session.peer_to_peer === "true",
          training_room: this.currentRoom.room_data.session.training_room === "true",
          auto_archive: this.currentRoom.room_data.session.auto_archive === "true",
          full_hd: this.currentRoom.room_data.full_hd === "true",
          create_time: +this.currentRoom.room_data.session.create_time,
          archive_perm_needed: this.currentRoom.room_data.archive_perm_needed === "true"
        };
      }
      this.authService.sendFeedbackData(this.feedbackId, feedbackData);
    }
  }

  formSubmittedFn = () => {
    this.submittedForm = true;
  }

  ngOnInit(): void {
    this.authenticate();
  }

  async authenticate() {
    const params = await this.route.queryParams.pipe(first()).toPromise();
    if (!(params && params.token)) {
      this.loading = false;
      return;
    }
    if (params.in_room === "true") {
      this.currentRoom = {
        room_data: {
          name: params.name,
          session: {
            vs: params.vs,
            ot: params.ot,
            peer_to_peer: params.peer_to_peer,
            training_room: params.training_room,
            auto_archive: params.auto_archive,
            create_time: params.create_time
          },
          full_hd: params.full_hd,
          archive_perm_needed: params.archive_perm_needed
      }
    };
  }

    this.loaderService.show();
    const token = atob(decodeURIComponent(params.token));
    
    this.authService.login(token)
    .then(() => {
      const feedbackForm = environment["feedbackForm"];
      if (feedbackForm){
        hbspt.forms.create({
          region: feedbackForm.region,
          portalId: feedbackForm.portalId,
          formId: feedbackForm.formId,
          target: "#feedback-modal",
          onFormReady: this.formReadyFn,
          onFormSubmit: this.formSubmitFn,
          onFormSubmitted: this.formSubmittedFn
        });
      }
    })
    .catch(error => { this.loading = false; })
    .finally(() => this.loaderService.hide());
  }

}
