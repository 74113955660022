import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  showLoader: Observable<boolean>;
  private showLoaderSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.showLoader = this.showLoaderSource.asObservable();
  }

  show() { this.showLoaderSource.next(true) }

  hide() { this.showLoaderSource.next(false) }
}
