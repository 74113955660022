import { Injectable } from '@angular/core';

import { DbService } from './db.service';
import { AuthService } from './auth.service';
import { RoomSessionService } from './room-session.service';
import { FileShareService } from '@services/other/file-share.service';
import { LogService } from './log.service';

import { LogMessage } from '@models/LogMessage';
import { Collaboration } from '@models/Collaboration';
import { CollaborationData } from '@models/CollaborationData';
import { JoinRoomResponse } from '@models/JoinRoomResponse';

import { Observable, Subject, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { User } from '@models/User';

@Injectable({
  providedIn: 'root'
})
export class CollaborationService {

  STATE_WAITING = "waiting";
  STATE_SUCCEEDED = "succeeded";
  STATE_FAILED = "failed";

  currentCollaborationPath: string = null;
  currentSessionPath: string = null;
  private collaborationsPath: string = null;

  collaboration: Observable<CollaborationData>;
  private collaborationSource: Subject<CollaborationData> = new Subject<CollaborationData>();

  private collaborationSub: Subscription = null;
  private external: boolean = false;

  newcoll: boolean = true;

  getCollaborationImageFunction: () => Promise<File> = null;

  constructor(
    private authService: AuthService,
    private roomSessionService: RoomSessionService,
    private fileShareService: FileShareService,
    private logService: LogService,
    private dbService: DbService
  ) {
    this.authService.on("externalLogin", this.onExternalLogin);
    this.collaboration = this.collaborationSource.asObservable();

    this.roomSessionService.on("sessionCreated", this.onSessionCreated);
    this.roomSessionService.on("sessionDestroyed", this.onSessionDestroyed);
  }

  private onSessionCreated = (joinResponse: JoinRoomResponse, sessionPath: string) => {
    this.currentSessionPath = sessionPath;

    this.collaborationsPath = this.currentSessionPath + '/collaborations';
    //this.collaborationsRef = this.afdb.list<Collaboration>(this.collaborationsPath);

    this.collaborationSub = this.dbService.listen<any>(sessionPath + '/session_data/collaboration')
    .subscribe(collaboration => {
      if (collaboration.data) {
        const collData: CollaborationData = collaboration.data;
        this.currentCollaborationPath = this.currentSessionPath +  '/collaborations/' + collData.id;
        this.collaborationSource.next(collData);
      } else {
        this.currentCollaborationPath = null;
        this.collaborationSource.next(null);
      }
    });
  }

  onExternalLogin = (user: User, params: any) => {
    this.external = true;

    this.currentSessionPath = `accounts/${params.account_id}/sessions/${params.room_id}/${params.session_id}`;

    // Backward compatibility Start
    if (!this.newcoll) {
      this.collaborationSub = this.dbService.listen<any>(this.currentSessionPath + `/session_data/collaboration`)
      .subscribe(collaboration => {
        if (collaboration.data) {
          const collData: CollaborationData = collaboration.data;
          this.currentCollaborationPath = this.currentSessionPath + '/collaborations/' + collData.id;
          this.collaborationSource.next(collData);
        } else {
          this.currentCollaborationPath = null;
          this.collaborationSource.next(null);
        }
      });
    }
    // Backward compatibility End
  }

  externalCollaborationOpen(collData: any) {
    this.currentCollaborationPath = this.currentSessionPath + '/collaborations/' + collData.id;
    this.collaborationSource.next(collData);
  }

  externalCollaborationClose() {
    this.currentCollaborationPath = null;
    this.collaborationSource.next(null);
  }

  openCollaboration(newCollaboration: Collaboration) {
    return this.dbService.transaction(this.currentSessionPath + '/session_data', 'openCollaboration', newCollaboration);
  }

  closeCollaboration() {
    if (this.getCollaborationImageFunction) {

      return this.getCollaborationImageFunction()
      .then(file => {
        return this.executeCloseCollaboration()
        .then(res => {
          // Do not chain promises, wait for upload not required
          this.fileShareService.uploadSnapshot({ file: file, extension: "jpg", file_type: 'image', progress: 0 })
            .then(up1 => this.fileShareService.saveUserFileToChatDb(this.fileShareService.createDatabaseUserFile(up1, true)))
            .then(up2 => this.logService.sendShareScreenshotLog(up2, true))
            .catch(error => console.log(error));
          return res;
        });
      })
      .catch(error => {
        return this.executeCloseCollaboration();
      })
    } else {
      return this.executeCloseCollaboration();
    }
  }

  private executeCloseCollaboration() {
    const collPath = this.currentCollaborationPath;
    return this.dbService.transaction(this.currentSessionPath + '/session_data', 'closeCollaboration')
    .then(result => {
      if (result.committed) {
        return this.dbService.update(collPath+'/controls', { close: true })
        .then(() => new Promise<void>(resolve => setTimeout(() => resolve(), 1000)))
        .then(() => true);
      } else {
        return false;
      }
    });
  }
/*
  getFileCollaborations(key: string) {
    return this.afdb.list<Collaboration>(this.collaborationsPath, ref => ref.orderByChild('collaboration_data/key').equalTo(key))
    .snapshotChanges(['child_added']).pipe(first()).toPromise()
    .then(collSnaps => collSnaps.map(collSnap =>  collSnap.key));
  }
*/
  private onSessionDestroyed = () => {
    this.collaborationsPath = null;
    if (this.collaborationSub) { this.collaborationSub.unsubscribe() }
  }

  getCollaborationData(collaborationId: string): Observable<CollaborationData> {

    const path = this.currentSessionPath +  '/collaborations/' + collaborationId + '/collaboration_data';
    return this.dbService.listen<CollaborationData>(path);
  }

  setCollaborationLoaded() {
    if (this.external) {
      let w: any = window;
      if (w.webkit && w.webkit.messageHandlers) {
        try {
          w.webkit.messageHandlers.opened.postMessage("COLLABORATION_OPENED");
        } catch(error) {
          console.log("Cannot Send Opened Message")
        }
      }
    }
    return this.dbService.set(this.currentCollaborationPath + '/collaboration_data/status/' + this.authService.currentUser.id + '/state',this.STATE_SUCCEEDED);
  }

  sendCloseCollaboration() {
    // @ts-ignore
    if (window.webkit && window.webkit.messageHandlers) {
      try {
        // @ts-ignore
        window.webkit.messageHandlers.closeCollaboration.postMessage("COLLABORATION_SAVE_DONE");
      } catch(error) {
        console.log("Cannot Send Opened Message");
      }
    }
  }

  sendCollaborationBase64(data: string) {
    // @ts-ignore
    if (window.webkit && window.webkit.messageHandlers) {
      try {
        // @ts-ignore
        window.webkit.messageHandlers.getCollaboration.postMessage(data);
      } catch(error) {
        console.log("Cannot Send Opened Message");
      }
    }
  }

  toggleAnnotate(annotating: boolean){
    return this.dbService.update(this.currentCollaborationPath, {
      "collaboration_data/annotating": annotating,
      "controls/hide": !annotating
    });
  }
/*
  collaborationToggleAnnotate(annotating: boolean){
    return this.dbService.update(this.currentCollaborationPath + '/collaboration_data',{annotating: annotating});
  }

  imageToggleAnnotate(annotating: boolean){
    this.dbService.update(this.currentCollaborationPath + '/controls', {hide: !annotating});
  }

  pdfToggleAnnotate(annotating: boolean){
    this.dbService.update(this.currentCollaborationPath + '/controls',{hide: !annotating});
  }
*/
  async createCollaboration(message: LogMessage): Promise<Collaboration> {
    const users =  await this.roomSessionService.currentRoom.pipe(first(), map(room => room.room_data.users)).toPromise();
    const status: any = {};
    users.forEach((user: any) => {
      if (user.in_room) {
        status[user.user_id] = {
          state: this.STATE_WAITING,
          name: user.name
        }
      }
    });
    const collaboration: Collaboration = {
      collaboration_data: {
        type: message.type2,
        key: message.content,
        name: message.extra,
        annotating: false,
        status: status
      },
      controls: {
        zoom_ratio: 1.0,
        pdf_scroll_ratio: 0,
        pdf_current_page: 1,
        polar_angle: 0,
        azimuthal_angle: 0,
        final_polar_angle: 0,
        final_azimuthal_angle: 0,
        hide:true,
        close:false,
        page_ratio: 0,
        x: 0.5,
        y: 0.5,
        z: 1,
        refresh: "no",
        revert: "no"
      },
      annotations: {
        arrays: {
          existing_drawObjects: [],
          deleted_drawObjects:[]
        },
        drawObjects: []
      }
    };
    if (message.url) {
      collaboration.collaboration_data.url = message.url;
    }
    return this.dbService.push(this.collaborationsPath,collaboration)
    .then(key => {
      collaboration.collaboration_data.id = key;
      return collaboration;
    });
  }
}