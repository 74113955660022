<div class="legal-page-container px-3">
  <div class="bar-container">
    <div class="logo-container" *ngIf="('design' | env).legalLogoText"><img class="logo-image" [src]="('design' | env).legalLogo"><span>{{ ('design' | env).legalLogoText }}</span></div>
    <div class="logo-container" *ngIf="!('design' | env).legalLogoText"><img class="logo-only-image" [class.wide]="('design' | env).wideLegalLogo" [src]="('design' | env).legalLogo"></div>
    <a routerLink="/login" class="back-link text-info mr-3"></a>
  </div>

  <div class="legal-page-content">
    <div class="legal-page-title">End-User License Agreement</div>

    <p class="my-4">Last updated: September 23th, 2020</p>

    <p>This VSight End-User License Agreement ("Agreement") is an Agreement between Customer (either an individual or a single entity) and UAB VSIGHT ("Company") for the Company software, which is part of the Company's real-time system which enables hands-free visual collaboration among field service personnel, end users, experts and management using augmented reality and other technologies (the "Product"), and includes computer software and may include associated media, equipment, printed materials, and "online" or electronic documentation ("Software").</p>
    <p>By downloading, installing, copying, or otherwise using the Software, Customer agrees to be bound by the terms of this Agreement and the Company's Terms of Service, which can be found at https://app.vsight.io/terms and the Company's Privacy Policy, which can be found at https://app.vsight.io/privacy (together the "Terms of Service"). If Customer does not agree to the terms of this Agreement and / or the Terms of Service, you are requested to not install or use the Software and/or the Product. The term “Customer” shall mean all the relevant user/s /end client/s connected to the Product which user/s shall be bound by the terms of this EULA upon use of the Software. The Term “Software” shall also mean and include (i) the computer programming code to be developed by Company; and (ii) a mobile application programing code which was developed by and/or for the Company; and (iii) all specifications, documents, manuals, diagrams and other information required for all aspects of installation, configuration, maintenance including repair of problems and bugs in the Software, user support, system administration, or performance optimization of the Software (Software shall also pertain to upgrades whether existing or in the future).</p>
    
    <ul class="legal-page-list">
      <li>
        <p><strong>License of Software.</strong><span> ​The Software is protected by copyright laws and international copyright treaties, as well as other intellectual property laws and treaties. All right, title and interest in and relating to the Software and all intellectual property rights, including all copyrights, patent rights, trade secret rights, mask work rights and other rights in and relating therein (and any derivative works, translation, customized versions, amendments, modifications, enhancements or upgrades thereof) shall remain solely and exclusively with Company.</span></p>
      </li>
      <li>
        <p>The Software is licensed to you under the terms herein, but not sold. You hereby acknowledge that the Software, and all copies thereof made by you, are the exclusive property of Company and that title to the above shall at all times remain with Company. You further acknowledge that you have no rights in the Software except those expressly granted by this Agreement.</p>
      </li>
      <li>
        <p><strong>Grant of License.</strong><span> ​This Agreement grants Customer (as defined above) the following rights:</span></p>
        <ul class="legal-page-list-secondary">
          <li><p>Company grants Customer a non-exclusive, personal, non transferable, non assignable limited license to use the Software (the “License”) for a perpetual time, on his/her Device and/or PCs. Customer may download the Software to supported Devices in his possession in accordance with the instructions found on the Product, or to use the Software license to access VSight web application. Customer hereby acknowledges that Company has not authorized Customer to modify, distribute, disseminate, resell, disclose or demonstrate the Software in any way. It is clarified that the license is not tied to any specific hardware.</p></li>
          <li><p>Customer acknowledges and declares that any and all users of the Software using their Devices are bound by the terms of this Agreement immediately upon the use of the Software.</p></li>
          <li><p>It is clarified that the "Device(s)" may be mobile phone or tablets (iOS or Android) /supported smart glasses.</p></li>
        </ul>
      </li>
      <li>
        <p><strong>Other Rights and Limitations.</strong></p>
        <ul class="legal-page-list-secondary">
          <li><p>Customer may not resell, or otherwise transfer for value, the Software to any third party without prior written authorization from Company.</p></li>
          <li><p>Limitations on Reverse Engineering, Decompilation, and Disassembly. Customer may not: (i) reverse engineer, decompile, disassemble, re-engineer or otherwise create or attempt to create or permit, allow, or assist others to create the source code of the Software, or its structural framework except and only to the extent that such activity is expressly permitted by applicable law notwithstanding this limitation; (ii) use the Software in whole or in part for any purpose except as expressly provided under this Agreement; or (iii) modify the Software or make derivative works thereof.</p></li>
          <li><p>Separation of Components. The Software is licensed as a single product along with the Product.</p></li>
          <li><p>Lease. Customer may not rent, lease, or lend the Software.</p></li>
          <li><p>Support Services. Company may provide Customer with support services related to the Software ("Services"). Use of Services is governed by Company’s policies and programs described in the user manual, in "online" documentation, and/or in other Company provided materials. Any supplemental software code provided to Customer as part of the Services shall be considered part of the Software and subject to the terms and conditions of this Agreement. With respect to technical information Customer provides to Company as part of the Services, Company may use such information for its business purposes, including for product support and development.</p></li>
          <li><p>Software Transfer. Customer may not transfer any of its rights under this Agreement without prior written agreement from Company.</p></li>
          <li><p>Termination. As detailed in the Terms of Service of the Company.</p></li>
        </ul>
      </li>
      <li>
        <p><strong>Copyright and Confidentiality.</strong><span> All title and copyrights in and to the Software and the Product (including but not limited to any images, photographs, animations, video, audio, music, text, and "applets" incorporated into the Software), the accompanying printed materials, and any copies of the Software are owned by Company or its suppliers. The Software is protected by copyright laws and international treaty provisions. Therefore, Customer must treat the Software like any other copyrighted material except that it may install the Software on its Site provided it keeps the original solely for backup or archival purposes. Customer may not copy the printed materials accompanying the Software. Customer shall not remove, alter, cover, or distort any copyright, trademark, or other proprietary rights notice placed by Company in or on the Software and shall ensure that all such notices are reproduced on all authorized copies of the Software made by Customer. The Software contain confidential information and trade secrets that Company states that has been developed by Company through the expenditure of a great deal of time and money. Customer is requested to maintain and protect the confidentiality of these trade secrets and agrees not to disclose them or use them for any purpose not contemplated by this Agreement.</span></p>
      </li>
      <li>
        <p><strong>Warranties and Warranty Disclaimers.</strong><span> To the maximum extent permitted by applicable law, Company warrants to You that the software under normal use will be free from defects in material and workmanship, and that the Software shall substantially conform to its user manual, as it exists at the date of delivery.</span></p>
      </li>
      <li>
        <p>Except for the limited warranty set forth herein, THE SOFTWARE IS PROVIDED "AS IS" AND COMPANY MAKES NO WARRANTY AS TO ITS USE OR PERFORMANCE. EXCEPT FOR ANY WARRANTY, CONDITION, REPRESENTATION OR TERM THE EXTENT TO WHICH CANNOT BE EXCLUDED OR LIMITED BY APPLICABLE LAW. COMPANY, ITS SUPPLIERS AND AUTHORIZED PATNERS MAKE NO WARRANTY, CONDITION, REPRESENTATION, OR TERM (EXPRESS OR IMPLIED, WHETHER BY STATUTE, COMMOM LAW, CUSTOM, USAGE OR OTHERWISE) AS TO ANY MATTER INCLUDING, WITHOUT LIMITATION, NONINFRINGEMENT OF THIRD PARTY RIGHTS, COMPANY MAKES NO WARRANTY THAT THE SOFTWARE WILL BE ERROR-FREE OR FREE FROM INTERRUPTIONS OR OTHER FAILURES OR THAT THE SOFTWARE WILL MEET YOUR REQUIREMENTS.</p>
      </li>
      <li>
        <p>EXCLUSION OF INCIDENTAL, CONSEQUENTIAL AND CERTAIN OTHER DAMAGES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL COMPANY OR ITS SUPPLIERS BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS OR CONFIDENTIAL OR OTHER INFORMATION, FOR BUSINESS INTERRUPTION, FOR PERSONAL INJURY, FOR LOSS OF PRIVACY, FOR FAILURE TO MEET ANY DUTY INCLUDING OF GOOD FAITH OR OF REASONABLE CARE, FOR NEGLIGENCE, AND FOR ANY OTHER PECUNIARY OR OTHER LOSS WHATSOEVER) ARISING OUT OF OR IN ANY WAY RELATED TO THE USE OF OR INABILITY TO USE THE SOFTWARE, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR OTHERWISE UNDER OR IN CONNECTION WITH ANY PROVISION OF THIS AGREEMENT, EVEN IN THE EVENT OF THE FAULT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, BREACH OF CONTRACT OR BREACH OF WARRANTY OF COMPANY OR ANY SUPPLIER, AND EVEN IF COMPANY OR ANY SUPPLIER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. COMPANY’S LIMITATION OF LIABILITY UNDER THIS SECTION SHALL NOT APPLY TO ACTS OF GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.</p>
      </li>
      <li>
        <p><strong>Limitation of Liability and Remedies.</strong><span> Not with standing any damages that Customer might incur for any reason whatsoever (including, without limitation, all damages referenced above and all direct or general damages), the entire liability of Company and any of its suppliers under any provision of this Agreement and Customer’s exclusive remedy for all of the foregoing shall be limited to the greater of the amount actually paid by Customer for the Software. The foregoing limitations, exclusions and disclaimers shall apply to the maximum extent permitted by applicable law, even if any remedy fails its essential purpose.</span></p>
      </li>
      <li>
        <p><strong>Assignment.</strong><span> Company may assign the contract to third parties and does not require the client’s consent. It is clarified that no assignment will relieve Company from its obligations to the client.</span></p>
      </li>
      <li>
        <p><strong>Miscellaneous.</strong><span> This Agreement and all amendments thereto and the rights of the Parties shall be construed under and be governed by the laws of Germany. Any lawsuit relating to any matter arising under this Agreement, shall be initiated in the competent courts located in Frankfurt, Germany and the parties hereto hereby irrevocably consent to the jurisdiction of such courts.</span></p>
      </li>
    </ul>
    <p>If Customer has a specific question regarding this Agreement, Customer may send inquiries via electronic mail to: info@vsight.io.</p>
  </div>
</div>