import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/core/auth.service';
import { DbService } from '@services/core/db.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  mandatoryTicket: Observable<boolean>;
  mandatoryTicketSource: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private authService: AuthService,
    private dbService: DbService,
    private http: HttpClient
  ) {
    this.mandatoryTicket = this.mandatoryTicketSource.asObservable();
   }

  getTicketList(): Observable<any[]> {
    return new Observable<any[]>(subscriber => {
      const tickets: any[] = [];
      const sub1 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/ticket_system/users_tickets/${this.authService.currentUser.id}`, "child_added")
        .subscribe(snp => {
          const ticket = snp.data;
          ticket.id = snp.key;
          tickets.push(ticket);
          subscriber.next(tickets);
        });

      const sub2 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/ticket_system/users_tickets/${this.authService.currentUser.id}`, "child_changed")
        .subscribe(snp => {
          const i = tickets.findIndex(t => t.id === snp.key);
          const ticket = snp.data;
          ticket.id = snp.key;
          if (i > -1) {
            tickets[i] = ticket;
          } else {
            tickets.push(ticket);
          }
          subscriber.next(tickets);
        });

      const sub3 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/ticket_system/users_tickets/${this.authService.currentUser.id}`, "child_removed")
        .subscribe(snp => {
          const i = tickets.findIndex(t => t.id === snp.key);
          if (i > -1) {
            tickets.splice(i, 1);
            subscriber.next(tickets);
          }
        });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
        sub3.unsubscribe();
      }
    });
  }

  getTicketData(ticketId: string) {
    return this.dbService.listen<any>(`accounts/${this.authService.currentUser.account_id}/ticket_system/tickets/${ticketId}`);
  }

  getTicketSessions(ticketId: string) {
    return this.dbService.listen<any>(`accounts/${this.authService.currentUser.account_id}/ticket_system/tickets_sessions/${ticketId}`)
  }

  getTicketSessions2(ticketId: string): Observable<any[]> {
    return new Observable<any[]>(subscriber => {
      const ticketSessions: any[] = [];
      subscriber.next(ticketSessions);
      const sub1 = this.dbService.querySnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`, [{key:"orderByChild",value:"attached_ticket"}, {key:"equalTo",value:ticketId}], "child_added")
      .subscribe(snp => {
        const session = snp.data;
        session.id = snp.key;
        ticketSessions.push(session);
        subscriber.next(ticketSessions);
      });
      const sub2 = this.dbService.querySnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`, [{key:"orderByChild",value:"attached_ticket"}, {key:"equalTo",value:ticketId}], "child_changed")
      .subscribe(snp => {
        const i = ticketSessions.findIndex(s => s.id === snp.key);
        const session = snp.data;
        session.id = snp.key;
        if (i>-1) {
          ticketSessions[i] = session;
        } else {
          ticketSessions.push(session);
        }
        subscriber.next(ticketSessions);
      });
      const sub3 = this.dbService.querySnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`, [{key:"orderByChild",value:"attached_ticket"}, {key:"equalTo",value:ticketId}], "child_removed")
      .subscribe(snp => {
        const i = ticketSessions.findIndex(s => s.id === snp.key);
        if (i>-1) {
          ticketSessions.splice(i, 1);
        }
        subscriber.next(ticketSessions);
      });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
        sub3.unsubscribe();
      }
    });
  }

  getUsersTickets() {
    return this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/ticket_system/users_tickets/${this.authService.currentUser.id}`, "child_added")
  }
  getTickets() {
      const url = this.dbService.getEndPoint("getUserTickets");
      return this.http.post<any>(url,{ token: this.authService.currentUser.token }).toPromise();
  }

  getTicketDetail(ticketId: string) {
    const url = this.dbService.getEndPoint("getTicketDetails");
    return this.http.post<any>(url,{ token: this.authService.currentUser.token, ticket_id: ticketId }).toPromise();
  }

  createTicket(ticketData: any) {
    return this.http.post<any>(this.dbService.getEndPoint('createticket'), {token: this.authService.currentUser.token, ticket: ticketData}).pipe(first()).toPromise();
  }

  attachTicket(ticketId: string, roomId: string, sessionId: string) {
    return this.http.post<any>(this.dbService.getEndPoint('attachticket'), {token: this.authService.currentUser.token, ticket_id: ticketId, room_id: roomId, session_id: sessionId}).pipe(first()).toPromise();
  }

  updateTicket(changes: any) {
    return this.http.post<any>(this.dbService.getEndPoint('updateticket'), {token: this.authService.currentUser.token, changes: changes}).pipe(first()).toPromise();
  }

  deleteTicket(ticketId: string) {
    return this.http.post<any>(this.dbService.getEndPoint('deleteticket'), {token: this.authService.currentUser.token, ticket: ticketId}).pipe(first()).toPromise();
  }

  getTicketFiles(ticketId) {
    return this.dbService.listen<any>(`accounts/${this.authService.currentUser.account_id}/ticket_system/tickets_files/${ticketId}`);
  }
}
