import { Component } from '@angular/core';

import { LoaderService } from '@services/support/loader.service';

@Component({
  selector: 'app-loader',
  template: `<div *ngIf=" loaderService.showLoader | async " class="loader-wrapper d-flex justify-content-center align-items-center">
              <div class="loader"><div></div><div></div><div></div><div></div><div></div></div>
            </div>`,
  styles: [`.loader-wrapper { position: absolute; top: 0; left: 0; right: 0; height: 100vh; z-index: 699; background-color: #00000090; }`]
})
export class LoaderComponent {

  // Loader Service used in html template
  // loaderService.showLoader | async
  constructor(
    public loaderService: LoaderService
  ) { }
}
