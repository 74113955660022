<div class="reconnect-wrapper">
    <div class="message-area d-flex align-items-center justify-content-center flex-column">
        <div class="px-3 text-center">{{ 'APP.MAIN.RECONNECT_ROOM.RECONNECT_MESSAGE' | translate }}</div>
        <div class="loader la-dark loader-sm mt-3"><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    <div class="fake-navbar">
        <nav class="navbar navbar-light p-0">
            <div class="center-bar container-fluid">
                <div class="controlbar-wrapper px-2">
                    <div class="mic-cam-btn-group btn-group">
                        <a class="controlbar-btn text-center disabled">
                            <i class="mi" [ngClass]="{ 'mi-mic': pAudio, 'mi-mic-off': !pAudio }"></i>
                            <div class="d-none d-sm-block">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.MIC_OFF' | translate }}</div>
                        </a>
                        <a class="mic-cam-dropdown-toggle dropdown-toggle dropdown-toggle-split disabled">
                            <i class="mi mi-arrow-drop-down"></i>
                        </a>
                    </div>
                    <div class="mic-cam-btn-group btn-group">
                        <a class="controlbar-btn text-center ml-2 disabled">
                            <i class="mi" [ngClass]="{ 'mi-videocam': pVideo, 'mi-videocam-off': !pVideo }"></i>
                            <div class="d-none d-sm-block">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.CAM_OFF' | translate }}</div>
                        </a>
                        <a class="mic-cam-dropdown-toggle dropdown-toggle dropdown-toggle-split disabled">
                            <i class="mi mi-arrow-drop-down"></i>
                        </a>
                    </div>
                </div>
                <div class="controlbar-wrapper px-2">
                    <a class="controlbar-btn text-center" (click)="tryToCancelReconnect()">
                        <i class="mi mi-call-end text-danger"></i>
                        <div class="d-none d-sm-block">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.CONTROL_BAR.LEAVE_CALL' | translate }}</div>
                    </a>
                </div>
            </div>
        </nav>
    </div>
</div>

<ng-template #archivePermissionModal let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body">
                <p>{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.MESSAGE' | translate }}</p>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.deny()">{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.DENY' | translate }}</button>
                <button class="btn btn-sm btn-success px-4" (click)="callbacks.allow()">{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.ALLOW' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>