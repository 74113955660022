import { Directive, AfterContentInit, OnDestroy, ContentChildren, QueryList } from '@angular/core';
import { AnimatedGroupToggleDirective } from './animated-group-toggle.directive';
import { AnimatedGroupBtnDirective } from './animated-group-btn.directive';

import { Subscription, merge } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

@Directive({
    selector: '[animatedBtnGroup]',
    exportAs: 'animatedBtnGroup'
})
export class AnimatedBtnGroupDirective implements AfterContentInit, OnDestroy {

    @ContentChildren(AnimatedGroupToggleDirective, { descendants: true }) toggleList: QueryList<AnimatedGroupToggleDirective>;
    @ContentChildren(AnimatedGroupBtnDirective, { descendants: true }) btnList: QueryList<AnimatedGroupBtnDirective>;

    private subscription: Subscription = null;
    private subscription2: Subscription = null;

    constructor() {}

    ngAfterContentInit() {
        this.subscription = this.toggleList.changes.pipe(
            switchMap((tList: QueryList<AnimatedGroupToggleDirective>) => {
                const list = tList.toArray().map(t => t.expandedChange.asObservable().pipe(map(e => t)));
                return merge(...list);
            })
        )
        .subscribe(toggle => {
            const toggleArray = this.toggleList.toArray();
            const index = toggleArray.indexOf(toggle);
            if (toggle.expanded) {
                toggleArray.filter((t, i) => (t.expanded === true && i !== index)).forEach(tg => tg.collapse());
            }
            if (toggle.activeGroup) {
                toggleArray.filter((t, i) => (toggle.activeGroup === t.activeGroup && t.active === true && i !== index)).forEach(tg => tg.deactivate());
                this.btnList.toArray().filter(b => (toggle.activeGroup === b.activeGroup && b.active === true)).forEach(bg => bg.deactivate());
            }
        });
        this.subscription = this.btnList.changes.pipe(
            switchMap((bList: QueryList<AnimatedGroupBtnDirective>) => {
                const list = bList.toArray().map(b => b.activeChange.asObservable().pipe(map(e => b)));
                return merge(...list);
            })
        )
        .subscribe(btn => {
            if (btn.active) {
                const btnArray = this.btnList.toArray();
                const index = btnArray.indexOf(btn);
                
                if (btn.activeGroup) {
                    btnArray.filter((b, i) => (btn.activeGroup === b.activeGroup && b.active === true && i !== index)).forEach(bg => bg.deactivate());
                    this.toggleList.toArray().filter(t => t.activeGroup === btn.activeGroup).forEach(tb => tb.deactivate());
                } else {
                    btnArray.filter((b, i) => (btn.id === b.id && b.active === true && i !== index)).forEach(bg => bg.deactivate());
                }
            }
            this.toggleList.toArray().find(t => t.id === btn.id).collapse();
        });
        this.toggleList.notifyOnChanges();
        this.btnList.notifyOnChanges()
    }

    ngOnDestroy() {
        if (this.subscription) { this.subscription.unsubscribe() }
        if (this.subscription2) { this.subscription2.unsubscribe() }
    }

    collapseAll() {
        if (this.toggleList) {
            this.toggleList.forEach(t => {
                if (t.expanded) { t.collapse() }
            });
        }
    }
}