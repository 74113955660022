<div class="participants-container">
    <div class="participants-wrapper p-1">
        <div *ngFor="let user of roomUsers" class="participant py-1 pl-3 pr-2" [ngClass]="{'in-room': user.in_room}" style="margin-top: 2px;">
            <div class="status"></div>
            <div class="participant-name py-2">{{ user.name }} <span *ngIf="user.status === 'master'" style="color: #4582b3;">{{ 'APP.MAIN.ROOM.SIDEBAR.PARTICIPANTS.HOST' | translate }}</span></div>
            <div class="switch-wrapper" *ngIf="isMaster && user.status !== 'master'">
                <a class="raise-hand mr-1" (click)="onLowerHand(user)" *ngIf="user.status === 'hand-raised' && !runningTransactions[user.user_id]"><img src="assets/raise-hand.svg"></a>
                <div *ngIf="runningTransactions[user.user_id] || (sessionActive && user.in_room && user.status === 'try-to-publish')" class="participant-loader d-flex justify-content-center align-items-center">
                    <div class="loader loader-sm"><div></div><div></div><div></div><div></div><div></div></div>
                </div>
                <div class="switch" [ngClass]="{'d-none': runningTransactions[user.user_id]}">
                    <input type="checkbox" [id]="user.user_id" name="switch" [checked]="user.status === 'try-to-publish' || user.status === 'publishing'" (change)="onToggleParticipant(user)">
                    <label [for]="user.user_id"></label>
                </div>
            </div>
            <!--<div class="call-status" *ngIf="outgoingCall?.calling[user.user_id]">{{ outgoingCall.calling[user.user_id].status }}</div>-->
        </div>
    </div>

    <div class="bottom-controls" *ngIf="isMaster">
        <button class="btn btn-sm btn-info" (click)="lowerAllHands()" [disabled]="runningLowerHands">{{ 'APP.MAIN.ROOM.SIDEBAR.PARTICIPANTS.LOWER_ALL_HANDS' | translate }}</button>
    </div>
</div>