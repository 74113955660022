import { Injectable } from '@angular/core';

import { AuthService } from '@services/core/auth.service';
import { DbService } from '@services/core/db.service';
import { CollaborationService } from '@services/core/collaboration.service';

import { Observable, Subject, BehaviorSubject, Subscription } from 'rxjs';

import { CollaborationControls } from '@models/CollaborationControls';
import { DrawObject } from '@models/DrawObject';

@Injectable({
  providedIn: 'root'
})

export class ImageCollaborationService {

  getControls: Observable<{ type: string, value: any }>;
  private getControlsSource: Subject<{ type: string, value: any }> = new Subject();

  initializeImage: Observable<{width: number, height: number, container_width: number, container_height: number, ratio: number}>;
  private initializeImageSource: BehaviorSubject<{width: number, height: number, container_width: number, container_height: number, ratio: number}> = new BehaviorSubject(null);

  resizeImage: Observable<{ width: number, height: number,left: number, top: number, zoom_ratio: number, container_width: number}>;
  private resizeImageSource: BehaviorSubject<{ width: number, height: number,left: number, top: number, zoom_ratio: number, container_width: number}> = new BehaviorSubject(null);

  private collaborationControls: CollaborationControls = null;
  private collaborationControlsSub: Subscription = null;

  private scale_constant: number;
  private default_zoom_ratio: number;
  private default_scroll_constant: number;
  private scroll_ratio: number;
  private max_zoom_ratio: number;
  private refresh_id: string = "";
  private revert_id: string = "";
  public isLoaded: boolean = false;

  constructor(
    private authService: AuthService,
    private collaborationService: CollaborationService,
    private dbService: DbService
  ) {
    this.getControls = this.getControlsSource.asObservable();
    this.initializeImage = this.initializeImageSource.asObservable();
    this.resizeImage = this.resizeImageSource.asObservable();

    // set default zoom-related values
    this.default_zoom_ratio = 1.0;
    this.scale_constant = 1.5;

    // set default scroll-related values
    this.scroll_ratio = 5;
    this.max_zoom_ratio = Math.pow(this.scale_constant, 4);
  }

  setImageLoaded(): Promise<void> {
    return this.collaborationService.setCollaborationLoaded()
  }

  setLoaded(val: boolean){
    this.isLoaded = val;
  }

  setInitializeImage(coordinates: { width: number, height: number, container_width: number, container_height: number, ratio: number}) {
    if(!isNaN(coordinates.width)){
      this.initializeImageSource.next(coordinates);

      // calculate the default value of a scroll
      this.default_scroll_constant = coordinates.container_width / this.scroll_ratio;
    }
  }

  setCollaborationControlToNull(){
    this.collaborationControls = null;
    this.isLoaded = false;
    this.revert_id="";
  }

  resize(coordinates: { width: number, height: number,left: number, top: number, zoom_ratio: number, container_width: number }){
    if(!isNaN(coordinates.width)){
      this.resizeImageSource.next(coordinates);
      this.default_scroll_constant = coordinates.container_width / this.scroll_ratio;
    }

  }
 
  changeColor(color: string){
    this.getControlsSource.next({type: "changeColor", value: color});
  }
  changeWeight(weight: string){
    this.getControlsSource.next({type: "changeWeight", value: weight});
  }
  changeTextClick(showText: boolean){
    this.getControlsSource.next({type: "changeTextClick", value: showText});
  }
  changePenClick(showPen: boolean){
    this.getControlsSource.next({type: "changePenClick", value: showPen});
  }
  changeMouseClick(showMouse: boolean){
    this.getControlsSource.next({type: "changeMouseClick", value: showMouse});
  }
  changePointerClick(showPointer: boolean){
    this.getControlsSource.next({type: "changePointerClick", value: showPointer});
  }
  changeShape(shape: string){
    this.getControlsSource.next({type: "changeShape", value: shape});
  }

  openControls(){
    this.getControlsSource.next({type: "openControls", value: true});
  }
 
  closeCollaboration(){
    this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{ close: true});
  }

  initializeAnnotation(){
    this.getControlsSource.next({type: "sendInitialize", value:0});
  }

  triggerImageScroll(left, top){
    this.getControlsSource.next({type: "scrollImageFromMouse", value:{left: left, top: top}});
  }

  triggerCompleteMove(dx,dy){
    this.getControlsSource.next({type: "moveFinished", value:{dx: dx, dy:dy}});
  }

  startListeningControls() {
    this.collaborationControlsSub = this.dbService.listen<CollaborationControls>(this.collaborationService.currentCollaborationPath + '/controls')
    .subscribe(controls => {
      if (controls) {
        if(this.collaborationControls){
          //check zoom changes
          if (this.collaborationControls.zoom_ratio > controls.zoom_ratio) {
            this.getControlsSource.next({type: "zoomOut", value: { zoom_ratio: controls.zoom_ratio, scrollX: controls.x, scrollY: controls.y }});
          } else if(this.collaborationControls.zoom_ratio < controls.zoom_ratio) {
            this.getControlsSource.next({type: "zoomIn", value: controls.zoom_ratio});
          }
          if (this.collaborationControls.x != controls.x) {
            this.getControlsSource.next({type: "scrollX", value: controls.x});
          }
          if (this.collaborationControls.y != controls.y) {
            this.getControlsSource.next({type: "scrollY", value: controls.y});
          }
          if (controls.refresh != this.collaborationControls.refresh ) {
            this.getControlsSource.next({type: "refresh", value: 0});
            this.refresh_id = this.dbService.createPushId();
          }
          if(controls.revert != this.collaborationControls.revert){
            this.getControlsSource.next({type: "remove", value: 0});
            this.revert_id = this.dbService.createPushId();
          }
        } else {
          this.collaborationControls = controls;
          this.getControlsSource.next({type: "zoomIn", value: controls.zoom_ratio});
          this.getControlsSource.next({type: "scrollX", value: controls.x});
          this.getControlsSource.next({type: "scrollY", value: controls.y});
        }
        this.collaborationControls = controls;
      }
    });
  }

  updateScrollXY(values: any) {
    return this.dbService.transaction(this.collaborationService.currentCollaborationPath+'/controls', 'updateImageScroll', values);
  }

  getPointerCollaborationDots() {
    return this.dbService.listen(this.collaborationService.currentCollaborationPath + '/pointer_annotation/');
  }

  setDot(data: string) {
    this.dbService.update(this.collaborationService.currentCollaborationPath + '/pointer_annotation/',{[this.authService.currentUser.id] : data });
  }

  getOldChanges(){
    if(this.collaborationControls){   

      this.getControlsSource.next({type: "zoomIn", value: this.collaborationControls.zoom_ratio});

      setTimeout(() => {
        this.getControlsSource.next({type: "scrollX", value: this.collaborationControls.x});

      }, 100);
      setTimeout(() => {
        this.getControlsSource.next({type: "scrollY", value: this.collaborationControls.y});

      },200);
      setTimeout(() => {
        this.getControlsSource.next({type: "resize", value: 0});
      }, 300);

    }

  }

  getDrawObjects(){
    return this.dbService.listen<DrawObject>(this.collaborationService.currentCollaborationPath + '/annotations/drawObjects', "child_added");
  }
  getArrays(){
    return this.dbService.listen<any>(this.collaborationService.currentCollaborationPath + '/annotations/arrays');
  }

  hideButtons(hide: boolean){
    if(hide)
      this.getControlsSource.next({type: "hide", value: 0});
    else
      this.getControlsSource.next({type: "show", value: 0});
  }

  endListeningControls() {
    if (this.collaborationControlsSub) { this.collaborationControlsSub.unsubscribe() }
    this.initializeImageSource.next(null);
    this.resizeImageSource.next(null);
  }

  changeSmall(size: string){
    this.getControlsSource.next({type: "changeSmall", value: size});
  }

  triggerResize(){
    this.getControlsSource.next({type: "resize", value: 0});
  }

  addDrawObject(drawObject: DrawObject){
    this.dbService.push(this.collaborationService.currentCollaborationPath + '/annotations/drawObjects',drawObject);
  }

  updateArrays(existing_drawObjects: any[], deleted_drawObjects: any[]){
    this.dbService.set(this.collaborationService.currentCollaborationPath + '/annotations/arrays',{existing_drawObjects: existing_drawObjects, deleted_drawObjects: deleted_drawObjects});
  }

  undo(){
    this.getControlsSource.next({type: "undo", value: 0});
  }

  redo(){
    this.getControlsSource.next({type: "redo", value: 0});
  }
  
  zoomIn(val?: number) {

    // calculate the zoom ratio after zoom In and check whether the new ratio exceeds the maximum. If so, set the new value as maximum value
    let temp_ratio = this.collaborationControls.zoom_ratio * this.scale_constant;

    if(val){
      temp_ratio = this.collaborationControls.zoom_ratio * val;
    }
    if( temp_ratio > this.max_zoom_ratio)
      temp_ratio = this.max_zoom_ratio;

    // if there is an error when calculating, then sometimes the value can be NaN. Check this situation. If no problem, update the database.
    if(!isNaN(temp_ratio)) {
      this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{ zoom_ratio: temp_ratio })
    }
  }

  zoomOut(val?: number) {

    // calculate the zoom ratio after zoom Out and check whether the new ratio falls under the default zoom ratio. If so, set the new value as default value
    let temp_ratio = this.collaborationControls.zoom_ratio / this.scale_constant;
    
    if(val){
      temp_ratio = this.collaborationControls.zoom_ratio / val;
    }

    if( temp_ratio < 1.0)
      temp_ratio = 1.0;

    // if there is an error when calculating, then sometimes the value can be NaN. Check this situation. If no problem, update the database.
    /*
    if(!isNaN(temp_ratio)) {
      this.getControlsSource.next({type: "zoomOutBeforeDb", value: temp_ratio});
    }
    */
    if(!isNaN(temp_ratio)) {
      this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{ zoom_ratio: temp_ratio});
    }
  }

  /*
  zoomOutDb(val?: number) {
    // calculate the zoom ratio after zoom Out and check whether the new ratio falls under the default zoom ratio. If so, set the new value as default value
    let temp_ratio = val;

    // if there is an error when calculating, then sometimes the value can be NaN. Check this situation. If no problem, update the database.
    if(!isNaN(temp_ratio)) {
      this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{ zoom_ratio: temp_ratio});
    }
  }
  */

  moveRight(type: String) {

    let temp =-this.default_scroll_constant;
    if(type=="keyboard"){
      temp /= 2;
    }
    // send message to image viewer in order to calculate new x ratio after scroll
    this.getControlsSource.next({type: "calculateX", value: temp});
  }

  moveLeft(type: String) {

    let temp = this.default_scroll_constant;
    if(type=="keyboard"){
      temp /= 2;
    }

    // send message to image viewer in order to calculate new x ratio after scroll
    this.getControlsSource.next({type: "calculateX", value: temp});
  }

  updateXRatio(x_ratio: number){

    // update database with the new value of the x_ratio
    if(!isNaN(x_ratio)) {
      this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{x: x_ratio});
    }
  }

  moveUp(type: String) {

    let temp = this.default_scroll_constant;
    if(type=="keyboard"){
      temp /= 2;
    }

    // send message to image viewer in order to calculate new y ratio after scroll
    this.getControlsSource.next({type: "calculateY", value: temp});
  }

  moveDown(type: String) {
    let temp = -this.default_scroll_constant;
    if(type=="keyboard"){
      temp /= 2;
    }

    // send message to image viewer in order to calculate new y ratio after scroll
    this.getControlsSource.next({type: "calculateY", value: temp});
  }

  updateYRatio(y_ratio: number){
    // update database the new value of the x_ratio
    if(!isNaN(y_ratio)) {
      this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{y: y_ratio});
    }
  }

  revert() {
    // update the revert id
    this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{ revert: this.revert_id });

    // remove the annotations node
    this.dbService.remove(this.collaborationService.currentCollaborationPath + '/annotations');

    // clear the arrays
    this.updateArrays([],[]);

    // update the database with the default zoom ratio
    if(!isNaN(this.default_zoom_ratio)) {
      this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{ zoom_ratio: this.default_zoom_ratio });
    }

    // update the database with the default y ratio
    this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{y: 0.5});

    // update the database with the default x ratio
    this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{x: 0.5});
  }
}
