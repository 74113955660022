import { Component, OnDestroy, ViewChild, TemplateRef } from '@angular/core';

import { environment } from 'environments/environment';

// For change title
import { Title } from '@angular/platform-browser';
import { SwUpdate } from '@angular/service-worker';
import { Subscription } from 'rxjs';
import { ModalService } from '@services/support/modal.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { LoaderService } from '@services/support/loader.service';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  @ViewChild("updateTemplate", { static: true }) private updateTemplate: TemplateRef<any>;

  private langSub: Subscription = null;

  constructor(
    private titleService: Title,
    private multilanguageService: MultilanguageService,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private flashMessageService: FlashMessageService,
    private SWUpdate: SwUpdate
  ) {
    // Set title depend on language

    this.langSub = this.multilanguageService.onLangChange.pipe(startWith(null)).subscribe(() => {
      this.setAppName()
    });

    this.SWUpdate.available.subscribe(event => {
      const modalId = this.modalService.show({
        template: this.updateTemplate,
        context: {
          dataModel: null,
          callbacks: {
            update: () => {
              this.modalService.hide(modalId);
              this.loaderService.show();
              this.SWUpdate.activateUpdate()
              .then(() => {
                document.location.reload();
              })
              .catch(error => {
                this.loaderService.hide();
                this.flashMessageService.showTranslated('APP.UPDATE_MODAL.UPDATE_FAILED', { timeout: 10000 });
              });
            }
          }
        }
      });
    });
  }

  setAppName() {
    if (environment.design.addRemoteToName) {
      this.titleService.setTitle(`${environment.design.appName} ${this.multilanguageService.getInstantTranslation('TITLE')}`);
    } else {
      this.titleService.setTitle(environment.design.appName);
    }
  }

  ngOnDestroy() {
    if (this.langSub) { this.langSub.unsubscribe() }
  }
}
