<div class="main-wrapper container-fluid py-5">
    <div class="row justify-content-center">
        <div class="col-xl-9 col-lg-10 col-md-11 col-12">
            <div class="main-header text-muted d-flex align-items-center justify-content-between">
                <span class="py-2">{{ 'APP.MAIN.LOBBY.CONTACTS.TITLE' | translate }}</span>
                <div>
                    <button class="multiple-button button-pad btn btn-sm rounded-0 py-1 mb-2" [class.select-multiple]="selectMultiple" (click)="onToggleMultiSelect();">
                        {{ 'APP.MAIN.LOBBY.CONTACTS.SELECT_MULTI' | translate }}
                    </button>
                    <button class="button-pad btn btn-sm btn-success rounded-0 ml-2 py-1 mb-2" *ngIf="selectMultiple" [disabled]="!callUsersSelected()" (click)="callMultiUsers();">
                        {{ 'APP.MAIN.LOBBY.CONTACTS.CALL' | translate }}
                    </button>
                </div>
            </div>

            <!--<div class="contact card-1 rounded-0 mt-1 px-2" *ngFor="let user of users; let i = index" [class.selectable]="selectMultiple" (click)="onContactClicked(user.id);">
                <div class="row px-2">
                    <div class="col-7 d-flex align-items-center text-muted">
                        <i class="text-muted mi mi-account-circle mr-3"></i>
                        <span class="py-2" style="font-size: 14px;">{{ user.name }}</span>
                    </div>
                    <div class="col-3 d-flex align-items-center text-muted" style="font-size: 12px;" [ngSwitch]="user.role">
                        <span *ngSwitchCase="'admin'" class="ml-1">{{ 'APP.MAIN.LOBBY.CONTACTS.ADMIN' | translate }}</span>
                        <span *ngSwitchCase="'coadmin'" class="ml-1">{{ 'APP.MAIN.LOBBY.CONTACTS.COADMIN' | translate }}</span>
                        <span *ngSwitchCase="'expert'" class="ml-1">{{ 'APP.MAIN.LOBBY.CONTACTS.EXPERT' | translate }}</span>
                        <span *ngSwitchCase="'user'" class="ml-1">{{ (licenseType === 'concurrent_user_based' ? 'APP.MAIN.LOBBY.CONTACTS.USER' : 'APP.MAIN.LOBBY.CONTACTS.SUBSCRIBER') | translate }}</span>
                    </div>
                    <div class="col-2 d-flex justify-content-end">
                        <button *ngIf="!selectMultiple" class="btn d-flex align-items-center p-0" (click)="callSingleUser(user.id);"><i class="mi mi-call text-success"></i></button>
                        <div *ngIf="selectMultiple" class="custom-control custom-checkbox d-flex align-items-center" (click)="$event.stopPropagation();">
                            <input type="checkbox" class="custom-control-input" [attr.id]="user.id" [attr.name]="user.id" [(ngModel)]="selectedUsers[user.id]">
                            <label class="custom-control-label" [attr.for]="user.id"></label>
                        </div>
                    </div>
                </div>
            </div>-->

            <div class="filters container-fluid">
                <div class="row">
                    <div class="col-sm mb-2 mt-2">
                        <input class="field form-control py-2" placeholder="{{ 'APP.MAIN.LOBBY.CONTACTS.NAME_USERNAME' | translate }}" #name="ngModel" [(ngModel)]="nameUserName" 
                            [ngClass]="{'is-invalid':name.errors && name.touched}" type="text" name="nameUserName" minlength="1" (ngModelChange)="onSearchTextChange($event)">
                    </div>
                    <div class="col-sm mb-2 mt-2">
                        <ng-select [items]="roles" name="roles" [clearSearchOnAdd]="true" [closeOnSelect]="false" placeholder="{{ 'APP.MAIN.LOBBY.CONTACTS.ALL_ROLES' | translate }}" 
                            [multiple]="true" [(ngModel)]="selectedFilters" (change)="onFilterChanged()">
                        </ng-select>
                    </div>
                </div>
            </div>

            <div class="t-container t-no-hover container-fluid mt-1">
                <div class="t-head row sm-hide" style="border-bottom: 3px solid #ececec;">
                    <div class="t-cell col-1"></div>
                    <div class="t-cell col-4 font-weight-bold">{{ 'APP.MAIN.LOBBY.CONTACTS.NAME' | translate }}</div>
                    <div class="t-cell col-3 font-weight-bold">{{ 'APP.MAIN.LOBBY.CONTACTS.USERNAME' | translate }}</div>
                    <div class="t-cell col-3 font-weight-bold">{{ 'APP.MAIN.LOBBY.CONTACTS.ROLE' | translate }}</div>
                    <div class="t-cell col-1"></div>
                </div>

                <div class="empty-list bg-white" *ngIf="!callingAllowed">
                    <div class="no-contact-message ticket-list-empty py-2">{{ 'APP.MAIN.LOBBY.CONTACTS.NO_PERMISSION' | translate }}</div>
                </div>
                <div class="bg-white empty-list" *ngIf="callingAllowed && users.length === 0 && !isLoading">
                    <div class="no-contact-message ticket-list-empty py-2">{{ 'APP.MAIN.LOBBY.CONTACTS.NO_CONTACT' | translate }}</div>    
                </div>
                <ng-container *ngIf="isLoading" [ngTemplateOutlet]="loadingTemplate"></ng-container>


                <div class="t-row row" *ngFor="let user of users; let i = index" [class.selectable]="currentUserId !== user.id && selectMultiple === true" (click)="onContactClicked(user.id);">
                    <div class="t-cell col-1 sm-hide">
                        <i style="position: relative;"  class="text-muted mi mi-account-circle">
                            <div *ngIf="user.status && user.status.web_status && user.status.web_status !== 'offline'" class="status-circle" [ngClass]="getStatusColor(user.status.web_status)"></div>
                        </i>
                        <img *ngIf="user.ad_user" class="mt-1 ml-1" style="width: 14px; height: 14px;" src="assets/img/azure_blue.png">
                    </div>
                    <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'APP.MAIN.LOBBY.CONTACTS.NAME' | translate }}</div>
                    <div class="t-cell col-6 col-md-4 col-lg-4">{{ user.name }}</div>
                    <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'APP.MAIN.LOBBY.CONTACTS.USERNAME' | translate }}</div>
                    <div class="t-cell col-6 col-md-4 col-lg-3 overflow-break-word">{{ user.ad_user ? user.user_principal_name : user?.auth?.username }}</div>
                    <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'APP.MAIN.LOBBY.CONTACTS.ROLE' | translate }}</div>
                    <div class="t-cell col-6 col-md-4 col-lg-3" [ngSwitch]="user.role">
                        <span *ngSwitchCase="'admin'">{{ 'APP.MAIN.LOBBY.CONTACTS.ADMIN' | translate }}</span>
                        <span *ngSwitchCase="'coadmin'">{{ 'APP.MAIN.LOBBY.CONTACTS.COADMIN' | translate }}</span>
                        <span *ngSwitchCase="'expert'">{{ 'APP.MAIN.LOBBY.CONTACTS.EXPERT' | translate }}</span>
                        <span *ngSwitchCase="'user'">{{ (licenseType === 'concurrent_user_based' ? 'APP.MAIN.LOBBY.CONTACTS.USER' : 'APP.MAIN.LOBBY.CONTACTS.SUBSCRIBER') | translate }}</span>
                    </div>
                    <div class="t-cell sm-show col-6 col-md-8"></div>
                    <div class="t-cell col-6 col-md-4 col-lg-1 d-flex justify-content-end no-padding">
                        <button *ngIf="!selectMultiple && currentUserId !== user.id" class="call-btn btn d-flex align-items-center justify-content-end p-0" (click)="callSingleUser(user.id);"><i class="mi mi-call text-success"></i></button>

                        <div *ngIf="selectMultiple && currentUserId !== user.id" class="custom-control custom-checkbox d-flex align-items-center" (click)="$event.stopPropagation();">
                            <input type="checkbox" class='custom-control-input' [attr.id]="user.id" [attr.name]="user.id" [(ngModel)]="selectedUsers[user.id]">
                            <label class="custom-control-label" [attr.for]="user.id"></label>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<ng-template #notSupportedTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.LOBBY.NOT_SUPPORTED_TITLE' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p *ngIf="!dataModel.ios" class="mx-3">{{ 'APP.MAIN.LOBBY.NOT_SUPPORTED_MESSAGE' | translate }}</p>
                <ng-container *ngIf="dataModel.ios">
                    <p *ngIf="!dataModel.ios_version_low" class="mx-3 mb-3">{{ 'APP.MAIN.LOBBY.NO_IOS_MESSAGE' | translate:('design' | env) }}</p>
                    <p *ngIf="dataModel.ios_version_low" class="mx-3 mb-3">{{ 'APP.MAIN.LOBBY.IOS_VERSION_MESSAGE' | translate:('design' | env) }}</p>
                    <div class="d-flex justify-content-center">
                        <a [href]="('design' | env).appStoreLink" target="_blank" rel="noopener">
                            <img class="d-block mx-auto" style="width: 80%; max-width: 200px; object-fit: contain;" src="assets/badges/applestore.png">
                        </a>
                    </div>
                </ng-container>
            </div>

            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.close()">{{ 'APP.MAIN.LOBBY.NOT_SUPPORTED_CLOSE' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #joinTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog join-room-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div class="video-container">
                    
                    <div *ngIf="dataModel.status === 'permission-denied'">
                        <div class="redbox d-flex justify-content-center">
                            <i class="mi mi-videocam-off" style="color: white; font-size: 50px; opacity: 0.9;"></i>
                        </div>
                    </div>
                    <video [ngStyle]="{'transform': cameraMirror ? 'scaleX(-1)' : 'scaleX(1)'}" #videoElement autoplay="true" playsinline="true" muted></video>
                    
                    <div *ngIf="dataModel.status === 'running' && !dataModel.videoEnabled">
                        <div class="blackbox d-flex justify-content-center">
                            <i class="mi mi-videocam-off" style="color: white; font-size: 50px; opacity: 0.9;"></i>
                        </div>
                    </div>
                    <video [ngStyle]="{'transform': cameraMirror ? 'scaleX(-1)' : 'scaleX(1)'}" #videoElement autoplay="true" playsinline="true" muted></video>

                    <div class="video-controls">
                        <a class="control" (enabledClick)="callbacks.toggleVideo();" [fakeDisabled]="dataModel.status !== 'running' || dataModel.videos.length === 0" [ngClass]="{'video-disabled': !dataModel.videoEnabled}">
                            <i class="mi" [ngClass]="{'mi-videocam': dataModel.videoEnabled, 'mi-videocam-off': !dataModel.videoEnabled}"></i>
                        </a>
                        <a class="control ml-2" (enabledClick)="callbacks.toggleAudio();" [fakeDisabled]="dataModel.status !== 'running' || dataModel.audios.length === 0" [ngClass]="{'audio-disabled': !dataModel.audioEnabled}">
                            <div class="audio-level" [style.height.%]="dataModel.audioEnabled ? dataModel.audioLevel : 0"></div>
                            <i class="mi" [ngClass]="{'mi-mic': dataModel.audioEnabled, 'mi-mic-off': !dataModel.audioEnabled}"></i>
                        </a>
                    </div>
                    <div class="video-settings">
                        <a class="control" (enabledClick)="dataModel.settingsOn = !dataModel.settingsOn" [fakeDisabled]="dataModel.status !== 'running'">
                            <i class="mi mi-settings"></i>
                        </a>
                    </div>
                </div>
                <div class="settings-container pt-3 pb-2" [ngClass]="{'show': dataModel.settingsOn}" style="border-bottom: 1px solid #dee2e6;">
                    <form #settingsForm="ngForm">
                        <div class="form-row justify-content-center" *ngIf="('design' | env).showPreferredResolution">
                            <div class="form-group col-11 col-sm-10 mb-1">
                                <label class="mb-1" for="publishResolution">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.PREFERRED_RESOLUTION' | translate }}</label>
                                <ng-select name="publishResolution" [items]="dataModel.publishResolutions" [clearable]="false" [searchable]="false" [dropdownPosition]="'top'"
                                    [(ngModel)]="opentokService.selectedPublishResolution"></ng-select>
                            </div>
                            <div class="text-info col-11 col-sm-10 mb-3">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.RESOLUTION_NOTE' | translate }}</div>
                        </div>
                        <div class="form-row justify-content-center">
                            <div class="form-group col-11 col-sm-5 mb-2 mb-sm-0">
                                <ng-select [items]="dataModel.videos" [clearable]="false" name="videoSetting" bindLabel="label" bindValue="deviceId" [searchable]="false" [disabled]="!dataModel.videoEnabled"
                                    placeholder="Video Device" [(ngModel)]="dataModel.selectedVideo" (change)="callbacks.changeVideoSource($event)" [dropdownPosition]="'top'"></ng-select>
                            </div>
                            <div class="form-group col-11 col-sm-5 mb-0">
                                <ng-select [items]="dataModel.audios" [clearable]="false" name="audioSetting" bindLabel="label" bindValue="deviceId" [searchable]="false" [disabled]="!dataModel.audioEnabled"
                                    placeholder="Audio Device" [(ngModel)]="dataModel.selectedAudio" (change)="callbacks.changeAudioSource($event)" [dropdownPosition]="'top'"></ng-select>
                            </div>
                        </div>
                        <div class="form-row justify-content-center">
                            <div class="form-group col-11 col-sm-10 mb-0">
                                <div class="custom-control custom-checkbox d-flex align-items-center">
                                    <input type="checkbox" class="custom-control-input" id="mirror" name="mirror" [(ngModel)]="cameraMirror" (ngModelChange)="cameraMirrorChanged($event)">
                                    <label class="custom-control-label" for="mirror">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.MIRROR_VIDEO' | translate }}</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="contacts-container py-2" [ngClass]="{'show': dataModel.selectedUsers.length > 0}">
                    <div class="container">
                        <div class="row px-3 mt-1">
                            <div class="contacts-col col-12 col-sm-6 px-2 mb-1" *ngFor="let us of dataModel.selectedUsers; let i = index">
                                <div class="contact-name px-3">
                                    <span>{{ us.name }}</span>
                                    <i class="mi mi-call ml-3"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="message-container" [ngSwitch]="dataModel.status">
                    <span *ngSwitchCase="'permission-required'" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.CAM_MIC_PERM_REQUIRED' | translate }}</span>
                    <span *ngSwitchCase="'permission-denied'" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.CAM_MIC_PERM_DENIED' | translate }}</span>
                    <span *ngSwitchCase="'cant-access-devices'" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.CAM_MIC_CANT_ACCESS' | translate }}</span>
                    <span *ngSwitchCase="'no-devices-found'" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN_NO_DEVICE' | translate }}</span>
                    <ng-container *ngSwitchCase="'running'">
                        <span *ngIf="!dataModel.audioEnabled && dataModel.videoEnabled" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN_MIC_OFF' | translate }}</span>
                        <span *ngIf="!dataModel.videoEnabled && dataModel.audioEnabled" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN_CAM_OFF' | translate }}</span>
                        <span *ngIf="!dataModel.audioEnabled && !dataModel.videoEnabled" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN_CAM_MIC_OFF' | translate }}</span>    
                    </ng-container>
                </div>
            </div>
            <div *ngIf="dataModel.status === 'permission-denied'" class="prompt-text px-3 py-4">
                <p class="px-1 py-2 mt-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.ALLOW_ACCESS' | translate }}</p>
                <p class="font-weight-bold px-3 py-2 mb-1">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.TRY_AGAIN' | translate }}</p>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-responsive btn-sm btn-outline-dark px-3" (click)="callbacks.close()" [disabled]="!dataModel.enableCancel">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.CANCEL' | translate }}</button>
                <button class="btn btn-responsive btn-sm btn-success px-4" (click)="callbacks.join()" [disabled]="!dataModel.enableJoin">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.CALL' | translate }}</button>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #noDeviceTemplate let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.LOBBY.NO_DEVICE_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p class="mx-3">{{ 'APP.MAIN.LOBBY.NO_DEVICE_MODAL.CALL_BODY' | translate }}</p>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-outline-dark px-4" (click)="callbacks.cancel()">
                    {{ 'APP.MAIN.LOBBY.NO_DEVICE_MODAL.CANCEL' | translate }}
                </button>
                <button class="btn btn-sm btn-success px-4" (click)="callbacks.join()">
                    {{ 'APP.MAIN.LOBBY.NO_DEVICE_MODAL.CALL' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #archivePermissionModal let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body">
                <p>{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.MESSAGE' | translate }}</p>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.deny()">{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.DENY' | translate }}</button>
                <button class="btn btn-sm btn-success px-4" (click)="callbacks.allow()">{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.ALLOW' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #loadingTemplate>
    <div class="loading-template">
      <div class="d-flex justify-content-center">
        <div class="loader loader-sm la-dark">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
</ng-template>