import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { RoomSessionService } from '@services/core/room-session.service';
import { LoaderService } from '@services/support/loader.service';

import { first } from 'rxjs/operators';

@Component({
  selector: 'app-download-export',
  templateUrl: './download-export.component.html',
  styleUrls: ['./download-export.component.scss']
})
export class DownloadExportComponent implements OnInit {

  loading: boolean = true;

  constructor(
    private roomSessionService: RoomSessionService,
    private loaderService: LoaderService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.processUrl()
  }

  async processUrl() {
    const params = await this.route.queryParams.pipe(first()).toPromise();
    if (!(params && params.id)) {
      this.loading = false;
      return;
    }
    this.loaderService.show();
    await (this.roomSessionService.getSessionExportDownloadLink(params.id)
    .then(url => { window.location.href = url; })
    .catch(error => { this.loading = false; })
    .finally(() => this.loaderService.hide()));
  }
}