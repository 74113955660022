<div class="app-select-container">
    <div class="selection-wrapper" *ngIf="currentUser">
        <div class="selection-title text-secondary">Select the VSight Application that you want to use.</div>
        <div class="selection-list">
            <button class="selection btn btn-outline-light text-secondary border border-secondary rounded" (click)="onAppSelect('remote')">
                <img src="../../../../../assets/img/square_logo.png">
                <div>VSight Remote</div>
            </button>
            <button class="selection btn btn-outline-light text-secondary border border-secondary rounded" (click)="onAppSelect('admin')">
                <img src="../../../../../assets/img/square_logo.png">
                <div>VSight Admin</div>
            </button>
        </div>    
    </div>
    <div *ngIf="!currentUser">
        <div class="loader" style="color: black;"><div></div><div></div><div></div><div></div><div></div></div>
    </div>
</div>
<!--
<div class="container-fluid py-5">
    <div class="row justify-content-center">
        <div class="col-xl-9 col-lg-10 col-md-11 col-12">
            <div class="card rounded-0 select-card">
                <div class="card-header py-3 bg-white">
                    <div class="select-title">Select An Application</div>
                </div>
                <div class="card-body py-4">
                    <div class="mb-3">
                        <span>Select the VSight Application that you want to choose.</span>
                    </div>
                    <div class="d-flex justify-content-around">
                        <button class="btn btn-secondary d-flex align-items-center justify-content-center select-button" (click)="onAppSelect('remote')" [ngStyle]="{'border-color' : selection === 'remote' ? '#000' : null}">
                            <img src="../../../../../assets/img/square_logo.png" >
                            <span class="mr-3">VSight Remote</span>    
                        </button>
                        <button class="btn btn-secondary d-flex align-items-center justify-content-center select-button" (click)="onAppSelect('admin')" [ngStyle]="{'border-color' : selection === 'admin' ? '#000' : null}">
                            <img src="../../../../../assets/img/square_logo.png" >
                            <span class="mr-3">VSight Admin</span>    
                        </button>
                    </div>
                    <div class="d-flex mt-3 justify-content-end">
                        <button [disabled]="!selection" class="btn btn-primary" (click)="onSelect()">Select</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
-->