import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoomSessionService } from '@services/core/room-session.service';
import { LoaderService } from '@services/support/loader.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-archive-list',
  templateUrl: './archive-list.component.html',
  styleUrls: ['./archive-list.component.scss']
})
export class ArchiveListComponent implements OnInit {

  loading: boolean = true;
  failed: boolean = false;

  timezone: string = "UTC";
  archives: any[] = [];

  params: any = null;

  downloadLinks: { [key: string]: { loading: boolean, url?: string }} = {};

  constructor(
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private roomSessionService: RoomSessionService
  ) { }

  ngOnInit(): void {
    this.loaderService.show();
    this.loading = true;
    this.getArchives()
    .then(result => {
      if (result) {
        this.timezone = result.timezone;
        this.archives = result.archives;
        this.failed = false;
      } else {
        this.failed = true;
      }
    })
    .catch(error => {
      this.failed = true;
    })
    .finally(() => {
      this.loaderService.hide();
      this.loading = false;
    });
  }

  async getArchives(): Promise<any> {
    this.params = await this.route.queryParams.pipe(first()).toPromise();
    if (this.params && this.params.account && this.params.name && this.params.room && this.params.session && this.params.id) {
      return this.roomSessionService.getSessionArchivesWithLink(this.params.account, this.params.name, this.params.room, this.params.session, this.params.id);
    } else {
      return null;
    }
  }

  getArchiveDownloadUrl(archive: any) {
    if (!(this.downloadLinks[archive.id] && this.downloadLinks[archive.id].url)) {
      this.downloadLinks[archive.id] = { loading: true };
      this.roomSessionService.getArchiveDownloadUrl(this.params.account, this.params.name, this.params.room, this.params.session, this.params.id, archive.id)
      .then(result => {
        this.downloadLinks[archive.id].url = result.url;
      })
      .catch(error => console.log(error))
      .finally(() => {
        this.downloadLinks[archive.id].loading = false;
      })
    }
  }

  getArchiveStatusKey(archive: any): string {
    switch (archive.storage_status) {
      case "uploaded":
        return "APP.EXTERNALS.ARCHIVE_LIST.AVAILABLE";
      case "disconnected":
        return "APP.EXTERNALS.ARCHIVE_LIST.DISCONNECTED";
      case "expired":
      case "deleted":
        return "APP.EXTERNALS.ARCHIVE_LIST.DELETED";
      case "pending":
        return "APP.EXTERNALS.ARCHIVE_LIST.WAITING";
      case "failed":
        return "APP.EXTERNALS.ARCHIVE_LIST.FAILED";
      default:
        return "APP.EXTERNALS.ARCHIVE_LIST.FAILED";
    }
  }

  getBadgeClass(archive: any) {
    switch (archive.storage_status) {
      case "uploaded":
        return {"badge-success": true};    
      case "pending":
        return {"badge-warning": true};
      case "disconnected":
        return {"badge-secondary": true};
      case "expired":
      case "deleted":
      case "failed":
      default:
        return {"badge-danger": true};
    }
  }
}
