import { Component } from '@angular/core';

import { ModalService } from '@services/support/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {

    // modalService used in html template
    constructor(
        public modalService: ModalService
    ) { }
}
