<div class="legal-page-container">
  <div class="bar-container">
    <div class="logo-container" *ngIf="('design' | env).legalLogoText"><img class="logo-image" [src]="('design' | env).legalLogo"><span>{{ ('design' | env).legalLogoText }}</span></div>
    <div class="logo-container" *ngIf="!('design' | env).legalLogoText"><img class="logo-only-image" [class.wide]="('design' | env).wideLegalLogo" [src]="('design' | env).legalLogo"></div>
  </div>
  
  <div class="guide-content px-3">
    <div class="guide-header mb-5 ml-3" *ngIf="('design' | env).addRemoteToName">{{ 'APP.HELP_CENTER.TITLE_WITH_REMOTE' | translate:('design' | env) }}</div>
    <div class="guide-header mb-5 ml-3" *ngIf="!('design' | env).addRemoteToName">{{ 'APP.HELP_CENTER.TITLE' | translate:('design' | env) }}</div>

    <ng-container *ngIf="guides; else nodata">

      <div class="card rounded-0 mb-1" *ngFor="let guide of guides; let i = index">
        <div class="card-header py-2 rounded-0" id="{{ 'heading'+i }}">
          <button class="guide-title-link btn btn-link text-left w-100 px-0" type="button" data-toggle="collapse" [attr.data-target]="'#collapse'+i">
            {{ guide.title }}
          </button>
        </div>
        <div class="collapse" id="{{ 'collapse'+i }}" [attr.aria-labelledby]="'heading'+i">
          <div class="card-body" [innerHTML]="guide.html | safeHtml"></div>
        </div>
      </div>
    </ng-container>    
  </div>
</div>

<ng-template #nodata>
    <div *ngIf="!failed" class="d-flex justify-content-center my-5">
      <div class="loader"><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    <div *ngIf="failed" class="d-flex justify-content-center my-5 py-2">
      <p class="text-danger">{{ 'APP.HELP_CENTER.FAILED' | translate }}</p>
    </div>
</ng-template>