import { Component, OnDestroy, ViewChild } from "@angular/core";
import { Room } from "@models/Room";
import { Ticket } from "@models/Ticket";
import { RoomSessionService } from "@services/core/room-session.service";
import { TicketService } from "@services/other/ticket.service";
import { FlashMessageService } from "@services/support/flash-message.service";
import { LoaderService } from "@services/support/loader.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subject, Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { TicketDetailComponent } from "../tickets/ticket-detail/ticket-detail.component";
import { TicketsComponent } from "../tickets/tickets.component";

@Component({
  selector: "app-tickets-modal",
  templateUrl: "./tickets-modal.component.html",
  styleUrls: ["./tickets-modal.component.scss"],
})
export class TicketsModalComponent implements OnDestroy {
  onClose: Subject<void> = new Subject();

  @ViewChild(TicketsComponent) ticketsComponentRef: TicketsComponent;
  canCreate = true;
  selectedTicket: Ticket;
  currentRoom: Room;
  attachedTicketId;
  title?: string;
  confirmButtonText?: string;
  disableClose?: boolean;

  private subscriptions: Subscription[] = [];
  mandatoryTicketSub


  constructor(
    private loaderService: LoaderService,
    private ticketService: TicketService,
    private modalRef: BsModalRef,
    private flashMessageService: FlashMessageService,
    private modalService: BsModalService,
    private roomSessionService: RoomSessionService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.onClose.complete();
    if(this.mandatoryTicketSub) { this.mandatoryTicketSub.unsubscribe(); }
  }

  setSelectedTicket(eventData: Ticket): void {
    this.selectedTicket = eventData;
  }

  attach(): void {
    this.subscriptions.push(this.roomSessionService.sessionData.pipe(first()).subscribe(sessionData => {
      this.attachedTicketId = sessionData.attached_ticket;
      if(!this.attachedTicketId ) {
        this.loaderService.show();
        this.ticketService
        .attachTicket(
          this.selectedTicket.id,
          this.currentRoom.id,
          this.currentRoom.room_data.session.vs
        )
        .then(() => {
          this.modalRef.hide();
          this.mandatoryTicketSub = this.ticketService.mandatoryTicketSource.next(false)
        })
        .catch((error) =>
          this.flashMessageService.showTranslated('APP.MAIN.LOBBY.TICKETS.ERRORS.ERROR_OCCURED')
        )
        .finally(() => this.loaderService.hide());
      }else {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.TICKETS.ERRORS.TICKET_ALREADY_ATTACHED')
        this.modalRef.hide();
      }
    }));
  }

  create(): void {
    const initialState= {
      mode:'create',
      roomSession: true,
      currentRoom: this.currentRoom
    };
    const modalRef = this.modalService.show(TicketDetailComponent,  {initialState, backdrop:'static', class: 'white modal-lg', animated:false});
    modalRef.content.onClose.subscribe(() => {
      this.ticketsComponentRef.getTickets();
    });
  }

  close(): void {
    this.selectedTicket = null;
    this.onClose.next();
    this.modalRef.hide();
  }
}
