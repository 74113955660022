import { Injectable } from '@angular/core';

import { CollaborationService } from '@services/core/collaboration.service';
import { DbService } from '@services/core/db.service';

import { Observable, Subject, BehaviorSubject, Subscription } from 'rxjs';

import { CollaborationControls } from '@models/CollaborationControls';
import { DrawObject } from '@models/DrawObject';

@Injectable({
  providedIn: 'root'
})
 
export class PdfCollaborationService {

  getControls: Observable<{ type: string, value: any }>;
  private getControlsSource: Subject<{ type: string, value: any }> = new Subject();

  private collaborationControls: CollaborationControls = null;
  private collaborationControlsSub: Subscription = null;

  initializePdf: Observable<{width: number, height: number, container_width: number, container_height: number, default_zoom: number}>;
  private initializePdfSource: BehaviorSubject<{width: number, height: number, container_width: number, container_height: number, default_zoom: number}> = new BehaviorSubject(null);

  resizePdf: Observable<{ container_height: number, width: number, height: number,left: number, top: number, zoom_ratio: number}>;
  private resizePdfSource: BehaviorSubject<{container_height: number,  width: number, height: number,left: number, top: number, zoom_ratio: number}> = new BehaviorSubject(null);

  private max_zoom_ratio: number;
  private default_zoom_ratio: number;
  private scale_constant: number;
  private current_page: number = 1;
  private scroll: number;
  private scroll_constant: number;
  private max_scroll: number;
  private ratio: number;
  private default_scroll_constant: number;
  private scroll_ratio: number;
  private pageRatio: number;
  private refresh_id: string = "";
  private revert_id: string = "";
  public isLoaded: boolean = false;
  public percentage: string = "loading";

  constructor(
    private collaborationService: CollaborationService,
    private dbService: DbService
  ) {
    this.getControls = this.getControlsSource.asObservable();
    this.initializePdf = this.initializePdfSource.asObservable();
    this.resizePdf = this.resizePdfSource.asObservable();

    this.default_zoom_ratio= 1.0;
    this.max_zoom_ratio = 3.375;
    this.scale_constant = 1.5;
    this.scroll=0;
    this.scroll_constant = 1.22;
    this.ratio = 0.1;
    this.scroll_ratio = 5;
  } 

  startListeningControls() {
    this.collaborationControlsSub = this.dbService.listen<CollaborationControls>(this.collaborationService.currentCollaborationPath + '/controls')
    .subscribe(controls => {
      if(this.collaborationControls) {    
        if(this.collaborationControls.x != controls.x) {
          this.getControlsSource.next({type: "scroll", value: controls.x});
        }
        if(this.collaborationControls.pdf_scroll_ratio != controls.pdf_scroll_ratio) {
          this.getControlsSource.next({type: "scrollTop", value: -controls.pdf_scroll_ratio});
          this.scroll = this.collaborationControls.pdf_scroll_ratio;
        }
        if(this.collaborationControls.zoom_ratio > controls.zoom_ratio) {
          this.getControlsSource.next({type: "zoomOut", value: controls.zoom_ratio});
        } else if(this.collaborationControls.zoom_ratio < controls.zoom_ratio) {
          this.getControlsSource.next({type: "zoomIn", value: controls.zoom_ratio});
        }
        if(this.collaborationControls.pdf_current_page!=controls.pdf_current_page) {
          this.getControlsSource.next({type: "page", value: controls.pdf_current_page});
          this.getControlsSource.next({type: "remove", value: 0});
        }
        if(controls.refresh != this.collaborationControls.refresh ) {
          this.getControlsSource.next({type: "refresh", value: 0});
          this.refresh_id = this.dbService.createPushId();
        }
        if(controls.revert != this.collaborationControls.revert) {
          this.getControlsSource.next({type: "remove", value: 0});
          this.revert_id = this.dbService.createPushId();
        }
      } else{
        this.collaborationControls = controls;
        this.getControlsSource.next({type: "page", value: controls.pdf_current_page});
        setTimeout( () => {
          this.getControlsSource.next({type: "zoomIn", value: controls.zoom_ratio});
        },1000); 
        setTimeout( () => {
          this.getControlsSource.next({type: "scroll", value: controls.x});
        },2000); 
        setTimeout( () => {
          this.getControlsSource.next({type: "scrollTop", value: -controls.pdf_scroll_ratio});
        },3000); 
        this.scroll = this.collaborationControls.pdf_scroll_ratio;
      }
      this.collaborationControls = controls;
    });
  }

  setPdfLoaded(): Promise<void> {
    return this.collaborationService.setCollaborationLoaded();
  }

  setLoaded(val: boolean){
    this.isLoaded = val;
  }

  setPercentage(val: string){
    this.percentage = val;
  }

  setCollaborationControlToNull(){
    this.collaborationControls = null;
    this.isLoaded = false;
    this.revert_id="";
  }

  setInitializePdf(coordinates: { width: number, height: number, container_width: number, container_height: number, default_zoom: number}) {
    if(!isNaN(coordinates.container_width)){
      this.initializePdfSource.next(coordinates);
      this.default_scroll_constant = coordinates.container_width / this.scroll_ratio;
      this.getControlsSource.next({type: "loaded", value: 0});
      // Send null for cleanup purpose
      this.pageRatio=coordinates.width/coordinates.height;
      this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{page_ratio: this.pageRatio});
      this.initializePdfSource.next(null);
    }
  }

  resize(coordinates: { container_height:number, width: number, height: number,left: number, top: number, zoom_ratio: number }){
    if(!isNaN(coordinates.width)){
      //this.default_zoom_ratio = coordinates.zoom_ratio;
      this.resizePdfSource.next(coordinates);
      this.default_scroll_constant = (coordinates.container_height*4/3) / this.scroll_ratio;
    }
  }

  changeSmall(size: string){
    this.getControlsSource.next({type: "changeSmall", value: size});
  }

  getOldChanges(){
    if(this.collaborationControls){   

      setTimeout(() => {
        this.getControlsSource.next({type: "page", value: this.collaborationControls.pdf_current_page});

      }, 100);
      setTimeout(() => {
        this.getControlsSource.next({type: "zoomIn", value: this.collaborationControls.zoom_ratio});

      }, 200);
      setTimeout(() => {
        this.getControlsSource.next({type: "scroll", value: this.collaborationControls.x});

      }, 300);
      setTimeout(() => {
        this.getControlsSource.next({type: "scrollTop", value: -this.collaborationControls.pdf_scroll_ratio});
        this.scroll = this.collaborationControls.pdf_scroll_ratio;      

      }, 400);
      setTimeout(() => {
        this.getControlsSource.next({type: "resize", value: 0});
      }, 600);

    }

  }

  getDrawObjects(){
    return this.dbService.listen<DrawObject>(this.collaborationService.currentCollaborationPath + '/annotations/drawObjects', "child_added");
  }
  getArrays(){
    return this.dbService.listen<any>(this.collaborationService.currentCollaborationPath + '/annotations/arrays');
  }

  hideButtons(hide: boolean){

    if(hide)
      this.getControlsSource.next({type: "hide", value: 0});
    else
      this.getControlsSource.next({type: "show", value: 0});

  }

  closeCollaboration(){
    this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{ close: true});
  }

  endListeningControls() {
    if (this.collaborationControlsSub) { this.collaborationControlsSub.unsubscribe(); }
    this.initializePdfSource.next(null);

  }

  triggerResize(){
    this.getControlsSource.next({type: "resize", value: 0});
  }

  undo(){
    this.getControlsSource.next({type: "undo", value: 0});
  }

  redo(){
    this.getControlsSource.next({type: "redo", value: 0});
  }

  setTotalPage(val: number){
    if(!isNaN(val))
      this.getControlsSource.next({type: "total_page", value: val});
  }


  addDrawObject(drawObject: DrawObject){
    this.dbService.push(this.collaborationService.currentCollaborationPath + '/annotations/drawObjects',drawObject);
  }

  updateArrays(existing_drawObjects: any[], deleted_drawObjects: any[]){
    this.dbService.set(this.collaborationService.currentCollaborationPath + '/annotations/arrays',{existing_drawObjects: existing_drawObjects, deleted_drawObjects: deleted_drawObjects});
  }


  prevPage(){
    this.scroll = 0;
    this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{ pdf_current_page: this.collaborationControls.pdf_current_page-1, zoom_ratio: this.default_zoom_ratio, pdf_scroll_ratio: this.scroll});
  }

  nextPage(){
    this.scroll = 0;
    this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{ pdf_current_page: this.collaborationControls.pdf_current_page+1, zoom_ratio: this.default_zoom_ratio, pdf_scroll_ratio: this.scroll});
  }

  goToPage(value: number){
    this.scroll = 0;
    this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{ pdf_current_page: value, zoom_ratio: this.default_zoom_ratio, pdf_scroll_ratio: this.scroll});
  }
  
  zoomIn() {

    let tempRatio = this.collaborationControls.zoom_ratio * this.scale_constant;
    if(tempRatio > this.max_zoom_ratio)
      tempRatio = this.max_zoom_ratio

    if(!isNaN(tempRatio))
    this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{ zoom_ratio: tempRatio });
  }

  zoomOut() {
    let tempRatio = this.collaborationControls.zoom_ratio / this.scale_constant;
    if(tempRatio <= 1.0)
      tempRatio = 1.0;

    if(!isNaN(tempRatio))
      this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{ zoom_ratio: tempRatio});
  }

  moveUp(type: String) {
    
    let tempRatio = this.collaborationControls.pdf_scroll_ratio-this.ratio;

    // if triggered from keyboard, scroll half amount
    if(type=="keyboard"){
      tempRatio = this.collaborationControls.pdf_scroll_ratio-this.ratio/2;
    }

    if(tempRatio<0)
      tempRatio = 0; 

    if(!isNaN(tempRatio))
      this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{pdf_scroll_ratio: tempRatio});
  }

  moveDown(type: String) {
    
    let tempRatio = this.collaborationControls.pdf_scroll_ratio+this.ratio;

    // if triggered from keyboard, scroll half amount
    if(type=="keyboard"){
      tempRatio = this.collaborationControls.pdf_scroll_ratio + this.ratio/2;
    }

    if(tempRatio>1)
      tempRatio = 1;
    
    if(!isNaN(tempRatio))
      this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{pdf_scroll_ratio: tempRatio});
  }

  moveRight(type: String) {

    let temp = -this.default_scroll_constant;

    // if triggered from keyboard, scroll half amount
    if(type=="keyboard"){
      temp /= 2;
    }

    this.getControlsSource.next({type: "calculateX", value: temp});
  }

  moveLeft(type: String) {

    let temp = this.default_scroll_constant;
    
    // if triggered from keyboard, scroll half amount
    if(type=="keyboard"){
      temp /= 2;
    }
    
    this.getControlsSource.next({type: "calculateX", value: temp});
  }

  updateXRatio(x_ratio: number){
    if(!isNaN(x_ratio))
      this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{x: x_ratio});
  }
 
  revert() {
    this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{ revert: this.revert_id });
    this.dbService.remove(this.collaborationService.currentCollaborationPath + '/annotations');

    this.updateArrays([],[]);

    this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{ zoom_ratio: this.default_zoom_ratio });
    this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{pdf_scroll_ratio: 0});
    this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{x: 0.5});
  }

  refresh(){
    this.dbService.update(this.collaborationService.currentCollaborationPath + '/controls',{ refresh: this.refresh_id });
  }

  changeColor(color: string){
    this.getControlsSource.next({type: "changeColor", value: color});
  }
  changeWeight(weight: string){
    this.getControlsSource.next({type: "changeWeight", value: weight});
  }
  changeTextClick(showText: boolean){
    this.getControlsSource.next({type: "changeTextClick", value: showText});
  }
  changePenClick(showPen: boolean){
    this.getControlsSource.next({type: "changePenClick", value: showPen});
  }
  changeShape(shape: string){
    this.getControlsSource.next({type: "changeShape", value: shape});
  }

  openControls(){
    this.getControlsSource.next({type: "openControls", value: true});
  }
}
