import { Injectable } from '@angular/core';

// Router imports
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// rxjs imports
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '@services/core/auth.service';

@Injectable({ providedIn: 'root' })
export class TicketsGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.addOns.pipe(
      take(1),
      map(addOns => {
        if (addOns.ticket) {
          return true;
        } else {
          this.router.navigate(['/']);
          return false;
        }
      })
    );
  }
}