import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[customRegexList][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => CustomRegexValidatorDirective), multi: true }
  ]
})

export class CustomRegexValidatorDirective implements Validator {
  @Input('customRegexList') customRegexList: { [errorName: string]: string };

  validate(control : AbstractControl): ValidationErrors {
    let errors: any = {};
    if (this.customRegexList) {
      for (const errorName of Object.keys(this.customRegexList)) {
        if (!(new RegExp(this.customRegexList[errorName])).test(control.value)) {
          errors[errorName] = true;
        }
      }
      if (Object.keys(errors).length > 0) {
        return errors;
      }
    }
    return null;
  }
}