import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DragDropModule } from '@angular/cdk/drag-drop';

// Directives and Pipes
import { FileDropDirective } from './directives/file-drop.directive';
import { FileDropEnterDirective } from './directives/file-drop-enter.directive';
import { AddNativeElementDirective } from './directives/add-native-element.directive';
import { AddTooltipDirective } from './directives/add-tooltip.directive';
import { DynamicTooltipDirective } from './directives/dynamic-tooltip.directive';
import { TutorialDirective } from './directives/tutorial.directive';
import { CopyClipboardDirective } from './directives/copy-clipboard.directive';
import { AnimatedGroupToggleDirective } from './directives/animated-group-toggle.directive';
import { AnimatedBtnGroupDirective } from './directives/animated-btn-group.directive';
import { AnimatedGroupBtnDirective } from './directives/animated-group-btn.directive';
import { FakeDisabledDirective } from './directives/fake-disabled.directive';
import { MustMatchDirective } from './directives/must-match.directive';
import { UndesiredPasswordValidatorDirective } from './directives/undesired-password.directive';
import { WhitespaceValidatorDirective } from './directives/whitespace-validator.directive';
import { CustomRegexValidatorDirective } from './directives/custom-regex-validator.directive';
import { MinlengthDirective } from './directives/minlength.directive';
import { MatTableResponsiveDirective } from './directives/mat-table-responsive.directive';

import { EnvPipe } from './pipes/env.pipe';
import { LinkifyPipe } from './pipes/linkify.pipe';
import { SafeHtmlPipe } from './pipes/safehtml.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { UtcCalcPipe } from './pipes/utc-calc.pipe';
import { UtcCalcAccountPipe } from './pipes/utc-calc-account.pipe';
import { ConvertUtcPipe } from './pipes/convert-utc.pipe';
import { ObjectToArrayPipe } from './pipes/object-to-array.pipe';
import { SessionDurationPipe } from './pipes/session-duration.pipe';
import { GetIdsPipe } from './pipes/get-ids.pipe';
import { FilterGuestsPipe } from './pipes/filter-guests.pipe';
import { DurationFormatPipe } from './pipes/duration-format.pipe';
import { ObjSizePipe } from './pipes/obj-size.pipe';

@NgModule({
  declarations: [
    FileDropDirective,
    FileDropEnterDirective,
    AddNativeElementDirective,
    AddTooltipDirective,
    DynamicTooltipDirective,
    TutorialDirective,
    CopyClipboardDirective,
    AnimatedGroupToggleDirective,
    AnimatedBtnGroupDirective,
    AnimatedGroupBtnDirective,
    FakeDisabledDirective,
    UndesiredPasswordValidatorDirective,
    MustMatchDirective,
    WhitespaceValidatorDirective,
    CustomRegexValidatorDirective,
    MinlengthDirective,
    MatTableResponsiveDirective,
    EnvPipe,
    LinkifyPipe,
    SafeHtmlPipe,
    FileSizePipe,
    ObjSizePipe,
    UtcCalcPipe,
    ConvertUtcPipe,
    UtcCalcAccountPipe,
    ObjectToArrayPipe,
    SessionDurationPipe,
    DurationFormatPipe,
    GetIdsPipe,
    FilterGuestsPipe
  ],
  imports: [
    CommonModule,
    DragDropModule
  ],
  exports: [
    DragDropModule,
    FileDropDirective,
    FileDropEnterDirective,
    AddNativeElementDirective,
    AddTooltipDirective,
    DynamicTooltipDirective,
    TutorialDirective,
    CopyClipboardDirective,
    AnimatedGroupToggleDirective,
    AnimatedBtnGroupDirective,
    AnimatedGroupBtnDirective,
    FakeDisabledDirective,
    MustMatchDirective,
    UndesiredPasswordValidatorDirective,
    WhitespaceValidatorDirective,
    CustomRegexValidatorDirective,
    MinlengthDirective,
    EnvPipe,
    LinkifyPipe,
    SafeHtmlPipe,
    FileSizePipe,
    ObjSizePipe,
    UtcCalcPipe,
    ConvertUtcPipe,
    UtcCalcAccountPipe,
    ObjectToArrayPipe,
    SessionDurationPipe,
    GetIdsPipe,
    FilterGuestsPipe,
    DurationFormatPipe
  ]
})
export class SharedModule{
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: []
    }
  }
}

