import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { TutorialController } from '@models/TutorialController';
import { AuthService } from '@services/core/auth.service';
import { DbService } from '@services/core/db.service';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {

    tutorial: Observable<{[tag: string]: true}>;
    private tutorialSource: BehaviorSubject<{[tag: string]: true}> = new BehaviorSubject<{[tag: string]: true}>(null);

    initialTutorialModalOpen: boolean = false;
    private initialAfterTags: {[tag: string]: true};

    currentController: TutorialController = null;

    constructor(
        private authService: AuthService,
        private dbService: DbService
    ) {
        this.tutorial = this.tutorialSource.asObservable();
    }

    startTutorial(tags: {[tag: string]: true}, initialModalTag: string = null): Promise<boolean> {
        if (initialModalTag) {
            this.initialTutorialModalOpen = true;
            this.initialAfterTags = tags;
        } else {
            this.initialTutorialModalOpen = false;
        }
        this.currentController = new TutorialController();

        const promise = new Promise<boolean>((resolve) => {
            this.currentController._controller.asObservable()
            .subscribe(
                t => this.tutorialSource.next(t),
                err => this.tutorialSource.next(null),
                () => {
                    this.tutorialSource.next(null);
                    resolve(this.currentController.isSkipped());
                }
            );
            this.currentController.next(initialModalTag ? { [initialModalTag]: true } : tags);
        });
        return promise;
    }

    continueAfterInitialTutorial() {
        this.initialTutorialModalOpen = false; 
        this.tutorialSource.next(this.initialAfterTags);
    }

    isTutorialCompleted(name: string) {
        return this.authService.currentUser.tutorials && this.authService.currentUser.tutorials[name]
    }

    saveCompletedTutorial(name: string) {
        this.dbService.set(`accounts/${this.authService.currentUser.account_id}/users/${this.authService.currentUser.id}/tutorials/${name}`, true);
    }
}