import { AbstractControl } from '@angular/forms';

export function undesiredPasswordListValidator(control: AbstractControl) {
    const value = control.value;
    if (!value) { 
        return null; 
    }
    
    const undesiredPasswordList = '123456,pAssw0rd!,123456789,password,12345678,111111,123123,1234567890,1234567,qwerty,abc123,000000,password1,123321,666666,112233,123abc,123qwe,121212,abcd1234,123654,11111111,asdasd,asd123,222222,888888,7777777,1234qwer,password123,12341234,123456,101010,1111111,samsung,apple'
    const errors: any = {};
    for (const undesiredPassword of undesiredPasswordList.split(",")) {
        if (value === undesiredPassword) {
            errors["undesiredPassword"] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        return { undesiredPassword: true }
    } else {
        return null;
    }
}