import { Component, Input, OnDestroy, SimpleChanges } from '@angular/core';
import { LogMessage } from '@models/LogMessage';
import { SessionDataMore } from '@models/SessionData';
import { RoomSessionService } from '@services/core/room-session.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-session-chat',
  templateUrl: './session-chat.component.html',
  styleUrls: ['./session-chat.component.scss']
})
export class SessionChatComponent implements OnDestroy {

  @Input('session') session: SessionDataMore;
  @Input('session_id') session_id: string;
  chatMessages: LogMessage[] = [];
  
  private logFilesSub: Subscription = null;

  constructor(
    private sessionService: RoomSessionService,
    private flashMessageService: FlashMessageService
  ) { }

  ngOnDestroy(): void {
    this.logFilesSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.session){
      this.logFilesSub = this.sessionService.getSessionChat(this.session,this.session_id).subscribe(res => {
        this.chatMessages = res;
      },err => {
       this.flashMessageService.show('There is an error occured.')
      })
    }
  }

  getMessageTranslationKey(message: LogMessage): string {
    switch (message.type) {
      case "info":
        if (message.type2 === "joinRoom") {
          return "APP.MAIN.ROOM.SIDEBAR.CHAT.JOIN_ROOM";
        } else if (message.type2 === "leaveRoom") {
          return "APP.MAIN.ROOM.SIDEBAR.CHAT.LEAVE_ROOM";
        }
        break;
      case "request":
        if (message.type2 === "screenshot") {
          return "APP.MAIN.ROOM.SIDEBAR.CHAT.REQUEST_SCREENSHOT";
        } else if (message.type2 === "reaction") {
          return "APP.MAIN.ROOM.SIDEBAR.CHAT.REACTION";
        }
        break;
      case "file":
        if (message.type2 === "image") {
          return "APP.MAIN.ROOM.SIDEBAR.CHAT.SHARE_IMAGE";
        } else if (message.type2 === "screenshot") {
          return "APP.MAIN.ROOM.SIDEBAR.CHAT.SHARE_SCREENSHOT";
        } else if (message.type2 === "pdf") {
          return "APP.MAIN.ROOM.SIDEBAR.CHAT.SHARE_PDF";
        } else if (message.type2 === "other") {
          return "APP.MAIN.ROOM.SIDEBAR.CHAT.SHARE_OTHER";
        }
        break;
      case "object":
        return "APP.MAIN.ROOM.SIDEBAR.CHAT.SHARE_OBJECT";
      case "collaboration":
        return "APP.MAIN.ROOM.SIDEBAR.CHAT.COLLABORATED";
    }
  }

  getExtension(filename: string): string {
    return filename.split('.').pop().toLowerCase()
  }

  messageHasFile(message: LogMessage) {
    if (message.type === "file" || message.type === "object") {
      return true;
    } else {
      return false;
    }
  }

}
