import { Component, OnInit, ViewChild, OnDestroy, TemplateRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { environment } from 'environments/environment';

// Form object import
import { NgForm } from '@angular/forms';
// Router import for navigate
import { Router, ActivatedRoute } from '@angular/router';
// Service imports
import { AuthService } from '@services/core/auth.service';
import { LoaderService } from '@services/support/loader.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { ModalService } from '@services/support/modal.service';

import { Language } from '@models/Language';

// rxjs imports
import { Subscription } from 'rxjs';
import { DbService } from '@services/core/db.service';

// Form data model
interface LoginUser {
  account: string,
  username: string,
  password: string
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  // Login form object
  @ViewChild('loginForm', { static: true }) form: NgForm;
  @ViewChild("multiLoginWarningModal", { static: true }) private multiLoginWarningModal: TemplateRef<any>;

  // Login form binded data
  loginUser: LoginUser = {
    account: '',
    username: '',
    password: ''
  }

  // Booleans for enable and disable login button
  isLoginProgress: boolean = false;
  isFormValid: boolean = false;

  // Authentication subscription - saved for unsubscribe
  private authSubScrition: Subscription = null;

  currentLang: Language;
  allLangs: Language[] = [];
  showLangs: boolean = false;
  langSub: Subscription = null;

  accountNameRemeber: boolean = false;

  verification = {
    open: false,
    code: null
  };
  verifyUser: {
    account: string,
    username: string,
    password: string
  };

  useChinaServer: boolean = false;

  msLoginUrl: string = null;
  msLoginMode: boolean = false;

  autoJoinRoom: string = null;

  constructor(
    private authService: AuthService,
    private dbService: DbService,
    private router: Router,
    private flashMessageService: FlashMessageService,
    private multilanguageService: MultilanguageService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private loaderService: LoaderService
  ) {
    this.route.params.subscribe(params => {
      if (params.account_name) {
        this.loginUser.account = params['account_name'];
      }
    });
    this.route.queryParams.subscribe(params => {
      if (params.joinroom) {
        this.autoJoinRoom = params['joinroom'];
      }
    });
  }

  ngOnInit() {
    if (environment.azureAdConfig && environment.azureAdConfig['name']) {
      this.msLoginUrl = this.renderTemplate(environment.azureAdConfig.msLoginUrl, { name: environment.azureAdConfig['name'] })
    }

    this.useChinaServer = this.dbService.getSourceType() === "socket";
    const savedAccountName = localStorage.getItem('vsightAccountName');
    if (savedAccountName) {
      this.accountNameRemeber = true;
      this.loginUser.account = savedAccountName;
    }

    const logoutReason = localStorage.getItem('logoutReason');
    if (logoutReason && !this.authService.loggedInOnce) {
      localStorage.removeItem('logoutReason');
      this.authService.showLogoutReason(logoutReason);
    }

    // Navigate main if user already authenticated
    this.authSubScrition = this.authService.isAuthenticated().subscribe(authenticated => {
      if (authenticated) {
        this.isLoginProgress = false;
        if (this.autoJoinRoom) {
          localStorage.setItem('vsightAutoJoinRoom', JSON.stringify({ room: this.autoJoinRoom, now: Date.now() }))
        }
        this.router.navigate(['/']);
      }
    });

    // Listen for form status
    this.form.statusChanges.subscribe(result => {
      if (result == 'VALID') {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
    });

    this.currentLang = this.multilanguageService.currentLang;
    this.allLangs = this.multilanguageService.allLangs;
    this.langSub = this.multilanguageService.onLangChange.subscribe(change => {
      this.currentLang = change.lang;
    });
  }

  renderTemplate(templateStr: string, args: any): string {
    templateStr = templateStr.replace(/`/g, '\\`');
    const keys = Object.keys(args);
    const fn = new Function(...keys, 'return `' + templateStr + '`');
    return fn(...keys.map(key => args[key]));
  }


  loginWithMicrosoft(form: NgForm) {
    if (this.msLoginUrl) {
      window.location.href = this.msLoginUrl;
    } else {
      if (form.value.account && form.value.account.length >= 2) {
        if (this.accountNameRemeber) { localStorage.setItem('vsightAccountName', form.value.account) }
        else { localStorage.removeItem('vsightAccountName') }

        if (this.autoJoinRoom) {
          localStorage.setItem('vsightAutoJoinRoom', JSON.stringify({ room: this.autoJoinRoom, now: Date.now() }))
        }
        window.location.href = this.renderTemplate(environment.azureAdConfig.msLoginUrl, { name: form.value.account });
      } else {
        this.msLoginMode = true;
        form.controls.account.markAsTouched();
      }
    }
  }

  chinaServerChange(useChina: boolean) {
    this.dbService.setSourceType(useChina ? "socket" : "firebase");
  }

  onLanguageSelected(lang: Language) {
    this.multilanguageService.setCurrentLanguage(lang.code);
  }

  ngOnDestroy() {
    // Stop subscribing on destroy - prevent memory leaks
    if (this.authSubScrition) { this.authSubScrition.unsubscribe() }
    if (this.langSub) { this.langSub.unsubscribe() }
  }

  onLogin(credentials: { account: string, username: string, password: string }, kick_other: boolean = false) {
    // Start login process if form valid
    // Form always valid. When form not valid -> Login button disabled
    this.isLoginProgress = true;
    this.loaderService.show();
    this.authService.sendLoginCredentials(credentials.account, credentials.username, credentials.password, kick_other)
    .then(result => {
      return this.authService.login(result.token)
      .then(() => {
        if (this.accountNameRemeber) { localStorage.setItem('vsightAccountName', this.loginUser.account) }
        else { localStorage.removeItem('vsightAccountName') }
      })
      .finally(() => {
        this.loaderService.hide();
        this.isLoginProgress = false;
      });
    })
    .catch(error => {
      this.loaderService.hide();
      this.isLoginProgress = false;
      this.showLoginError(credentials, error);
    });
  }

  showLoginError(credentials: any, error: any) {
    if (error instanceof HttpErrorResponse) {
      if (error.error === 'wrong-username-password') {
        this.flashMessageService.showTranslated('APP.LOGIN.USERNAME_PASSWORD_ERROR');
      } else if (error.error === 'account-disabled') {
        this.flashMessageService.showTranslatedWithData('APP.LOGIN.ACCOUNT_DISABLED', environment.design);
      } else if (error.error === 'invalid-verification-code') {
        this.flashMessageService.showTranslated('APP.LOGIN.INVALID_VERIFICATION_CODE');
      } else if (error.error === 'user-already-online') {
        const modalId = this.modalService.show({
          template: this.multiLoginWarningModal,
          context: {
            dataModel: null,
            callbacks: {
              cancel: () => this.modalService.hide(modalId),
              proceed: () => {
                this.modalService.hide(modalId);
                this.onLogin(credentials, true);
              }
            }
          }
        });
      } else if (!navigator.onLine) {
        this.flashMessageService.showTranslated('APP.LOGIN.NO_INTERNET_ERROR');
      } else {
        this.flashMessageService.showTranslated('APP.LOGIN.UNKNOWN_ERROR');
      }
    } else if (!navigator.onLine) {
      this.flashMessageService.showTranslated('APP.LOGIN.NO_INTERNET_ERROR');
    } else {
      this.flashMessageService.showTranslated('APP.LOGIN.UNKNOWN_ERROR');
    }
  }

  onTermsofService() {
    let url = window.location.origin+"/terms";

    const termsUrls = environment.design['termsUrls'];
    const defaultTermsLang = environment.design['defaultTermsLang'];
    if (termsUrls) {
      if (termsUrls[this.multilanguageService.currentLang.code]) {
        url = termsUrls[this.multilanguageService.currentLang.code];
      } else if (defaultTermsLang && termsUrls[defaultTermsLang]) {
        url = termsUrls[defaultTermsLang];
      }
    }
    window.open(url);
  }

  onPrivacyPolicy() {
    let url = window.location.origin+"/privacy";

    const privacyUrls = environment.design['privacyUrls'];
    const defaultPrivacyLang = environment.design['defaultPrivacyLang'];
    if (privacyUrls) {
      if (privacyUrls[this.multilanguageService.currentLang.code]) {
        url = privacyUrls[this.multilanguageService.currentLang.code];
      } else if (defaultPrivacyLang && privacyUrls[defaultPrivacyLang]) {
        url = privacyUrls[defaultPrivacyLang];
      }
    }
    window.open(url);
  }
}
