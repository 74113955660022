import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbService } from '@services/core/db.service';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit {

  url: string
  loading = true

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private dbService: DbService
  ) { }

  ngOnInit(): void {
    this.route.params.pipe(
      switchMap(params => (params && params['id'] ? this.getDownloadUrl(params['id']) : of(null))))
    .subscribe(downloadUrl => {
      if (downloadUrl) {
        this.url = downloadUrl
        window.location.href = downloadUrl
      }
      this.loading = false
    }, error => {
      console.log(error)
      this.loading = false
    })
  }

  getDownloadUrl(path: string) {
    const url = this.dbService.getEndPoint('getDownloadLink')
    return this.http.post<any>(url, { env: environment.design.environmentName, path: path }).pipe(map(result => result.url))
  }

  onClick() {
    window.location.href = this.url
  }
}
