import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Frame } from '@models/Frame';
import { CallService } from '@services/core/call.service';
import { OpentokService } from '@services/core/opentok.service';

@Component({
  selector: 'app-mobile-subscriber',
  templateUrl: './mobile-subscriber.component.html',
  styleUrls: ['./mobile-subscriber.component.scss']
})
export class MobileSubscriberComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('frame') frame: Frame;
  @Output() switchFrame = new EventEmitter<any>();
  @ViewChild('subscriberContainer', { static: true }) subscriberContainer: ElementRef;


  subscriberInitials: string = 'U';
  subscriber: OT.Subscriber;
  subscriberData: any = null;

  movingAvg = null;

  isActive = false;

  subscribeAudio: boolean = true;
  subscribeVideo: boolean = true;
  continueToTrySubscribe: boolean = true;


  constructor(private callService: CallService, private changeDetectorRef: ChangeDetectorRef, private opentokService: OpentokService) { }

  ngOnInit(): void {
    if(this.frame?.stream?.connection?.data) {
      this.subscriberData = JSON.parse(this.frame.stream.connection.data);
    }
    if (this.subscriberData?.name?.length > 0) {
      const nameFields = this.subscriberData.name.split(' '); 
      this.subscriberInitials = nameFields.length > 1 ? nameFields[0][0].toUpperCase() + nameFields[1][0].toUpperCase() : nameFields[0][0].toUpperCase();
    }else{
      this.subscriberInitials = 'Y'
    }
  }

  ngOnDestroy(): void {
    if (this.subscriber) {
      this.subscriber.off("audioLevelUpdated", this.audioLevelUpdated)
    }
  }

  ngAfterViewInit(): void {
    if(this.frame.type !=='publisher') {
      this.tryToSubscribe(3)
      .catch(error => {
        // Catch opentok errors first
        this.callService.otSubscriberFailed(error.name, error.code);
        return null;
      })
      .then(subscriber => {
        if (subscriber) {
          this.subscriber = subscriber;
          this.subscriber.on("audioLevelUpdated", this.audioLevelUpdated)
        }
      })
    }
  }

  async tryToSubscribe(maxTry: number) {
    let subscriber = null;
    let error = null;
    let retryCount = 0;
    while (this.continueToTrySubscribe && !subscriber && retryCount < maxTry) {
      subscriber = await (this.opentokService.startSubscribe(this.subscriberContainer, this.frame.stream, this.subscribeAudio, this.subscribeVideo)
        .catch((err: OT.OTError) => {
          this.opentokService.saveOtError("subscriber", err);
          error = err;
          if (!this.isRetryRequired(err.name)) {
            retryCount = maxTry;
          }
          return null;
        }));
      retryCount = retryCount + 1;
    }
    if (this.continueToTrySubscribe && !subscriber) {
      throw error;
    }
    return subscriber;
  }

  isRetryRequired(errorName: string) {
    return errorName === 'OT_CREATE_PEER_CONNECTION_FAILED' ||
      errorName === 'OT_INVALID_PARAMETER' ||
      errorName === 'OT_ICE_WORKFLOW_FAILED' ||
      errorName === 'OT_MEDIA_ERR_ABORTED' ||
      errorName === 'OT_MEDIA_ERR_DECODE' ||
      errorName === 'OT_MEDIA_ERR_NETWORK' ||
      errorName === 'OT_MEDIA_ERR_SRC_NOT_SUPPORTED' ||
      errorName === 'OT_NOT_CONNECTED' ||
      errorName === 'OT_SET_REMOTE_DESCRIPTION_FAILED'
  }

  onToggleAudio() {
    this.subscribeAudio = !this.subscribeAudio;
    this.subscriber.subscribeToAudio(this.subscribeAudio);
    this.opentokService.setSubscriberAudioVideoStatus(this.subscriberData.uid, this.subscribeAudio, this.subscribeVideo);
  }


  changeFrame(index) {
    this.switchFrame.emit(index)
  }

  extractNameFromJson(obj) {

    obj = JSON.parse(obj);
    let x = obj.name.split(" ").map((n) => n[0]).join("");
    if (x.length < 2) {
      x = x + ' '
    }
    if (x.length > 2) {
      x = x.slice(0, 2)
    }
    return x ? x.toUpperCase() : 'U '
  }

  private audioLevelUpdated = (event: OT.Event<"audioLevelUpdated", OT.Subscriber> & { audioLevel: number; }) => {
    if (this.movingAvg === null || this.movingAvg <= event.audioLevel) {
      this.movingAvg = event.audioLevel;
    } else {
      this.movingAvg = 0.7 * this.movingAvg + 0.3 * event.audioLevel;
    }
    // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
    var logLevel = (Math.log(this.movingAvg) / Math.LN10) / 1.5 + 1;
    logLevel = Math.min(Math.max(logLevel, 0), 1);
    if (logLevel > 0.2) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }
}
