import { Injectable, Input } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { saveAs } from "file-saver";

import { AuthService } from '@services/core/auth.service';
import { DbService } from '@services/core/db.service';
import { RoomSessionService } from '@services/core/room-session.service';

import { Observable } from 'rxjs';

import { UserFile } from '@models/UserFile';
import { UploadModel } from '@models/UploadModel';
import { UserObject } from '@models/UserObject';
import { UploadFile } from '@models/UploadFile';
import { LogService } from '@services/core/log.service';
import JSZip from 'jszip';

@Injectable({
  providedIn: 'root'
})
export class FileShareService {

  @Input('session') session: any;
  @Input('session_id') session_id: string;
  static imageExtensions: string[] = ['jpg', 'jpeg', 'png'];
  static pdfExtensions: string[] = ['pdf'];
  static objectSpecificExtensions: string[] = ['obj', 'stl', 'gltf', 'glb', 'dae', 'scn'];
  static objectAllExtensions: string[] = ['obj', 'mtl', 'stl', 'gltf', 'glb', 'dae', 'scn', 'bin', 'png', 'jpg', 'jpeg', 'bmp'];

  static arkitExtensions: string[] = ['obj', 'gltf', 'glb', 'dae', 'scn'];
  static arcoreExtensions: string[] = ['gltf','glb'];
  static wikitudeExtensions: string[] = ['wt3'];

  constructor(
    private dbService: DbService,
    private http: HttpClient,
    private authService: AuthService,
    private roomSessionService: RoomSessionService,
    private logService: LogService
  ) { }

  uploadFiles(uploadModel: UploadModel): [Observable<any>, boolean] {
    return this.dbService.uploadFiles(this.authService.currentUser.account_id, this.roomSessionService.currentRoomId, this.roomSessionService.currentSessionId, uploadModel, this.authService.currentUser.token);
  }

  uploadRoomFiles(uploadModel: UploadModel): [Observable<any>, boolean] {
    return this.dbService.uploadRoomFiles(this.authService.currentUser.account_id, this.roomSessionService.currentRoomId, uploadModel, this.authService.currentUser.token);
  }

  uploadTicketFiles(formData, ticketId?){
    return this.dbService.uploadTicketFiles(formData, ticketId, this.authService.currentUser.token);
  }

  uploadSnapshot(upFile: UploadFile): Promise<UploadFile> {
    return this.dbService.uploadSnapshot(this.roomSessionService.currentSessionPath, upFile, this.authService.currentUser.token);
  }

  async downloadFile(url: string, filename: string): Promise<void> {
  if (typeof window.showSaveFilePicker === 'function') {
      const handle = await window.showSaveFilePicker({
        suggestedName: filename
      });

      const response = await this.http.get(url, { observe: 'response', responseType: 'blob' }).toPromise();
      const blob = new Blob([response.body], { type: response.headers.get('Content-Type') });

      const writable = await handle.createWritable();
      await writable.write(blob);
      await writable.close();
    } 
   else {
    return this.http.get(url, { observe: 'response', responseType: 'blob' }).toPromise()
      .then(response => {
        saveAs(response.body, filename);
      });
  }
}

  async downloadSelectedFilesAsZip(files: any[], zipFileName: string): Promise<void> {
    const zip = new JSZip();

    for (const file of files) {
      if (file.exportPdf) {
        const url = this.dbService.getEndPoint("getExportPDF");
        const body = {
          room_id: file.room_id,
          session_id: file.session_id,
          token: this.authService.currentUser.token
        };
        const response = await this.http.post(url, body, { observe: 'response', responseType: 'blob' }).toPromise();
        const blob = new Blob([response.body], { type: response.headers.get('Content-Type') });
        zip.file(file.name, blob);
      } else if (file.url) {
        const response = await this.http.get(file.url, { observe: 'response', responseType: 'blob' }).toPromise();
        const blob = new Blob([response.body], { type: response.headers.get('Content-Type') });
        zip.file(file.name, blob);
      }
    }

    const content = await zip.generateAsync({ type: 'blob' });

    if (typeof window.showSaveFilePicker === 'function') {
      const handle = await window.showSaveFilePicker({
        suggestedName: zipFileName,
        types: [
          {
            description: 'ZIP Files',
            accept: {
              'application/zip': ['.zip']
            }
          }
        ]
      });

      const writable = await handle.createWritable();
      await writable.write(content);
      await writable.close();
    } else {
      saveAs(content, zipFileName);
    }
  }

  async getSessionExportPDF(room_id: string, session_id: string) {
    const url = this.dbService.getEndPoint("getExportPDF");
    const body = {
      room_id: room_id,
      session_id: session_id,
      token: this.authService.currentUser.token
    }

    if (typeof window.showSaveFilePicker === 'function') {
      const defaultFileName = 'export.pdf';

      const options = {
        types: [
          {
            description: 'PDF Files',
            accept: {
              'application/pdf': ['.pdf'],
            },
          },
        ],
        suggestedName: defaultFileName,
      };

      const handle = await window.showSaveFilePicker(options);
      const fileName = handle.name || defaultFileName;

      const response = await this.http.post(url, body, { observe: 'response', responseType: 'blob' }).toPromise();
      const blob = new Blob([response.body], { type: response.headers.get('Content-Type') });

      const writable = await handle.createWritable();
      await writable.write(blob);
      await writable.close();
    } else {
      const response = await this.http.post(url, body, { observe: 'response', responseType: 'blob' }).toPromise();
      const blob = new Blob([response.body], { type: response.headers.get('Content-Type') });
      saveAs(blob, 'export.pdf');
    }
  }

/*
  deleteFileFromStorage(key: string, extension: string) {
    return this.storage.ref(this.roomSessionService.currentSessionPath).child(key + '.' + extension).delete()
  }
*/
  deleteFileWithCollaborations(changes: any) {
    return this.dbService.update(this.roomSessionService.currentSessionPath, changes);
  }

  getFileDetails(key: string) {
    return this.dbService.get(this.roomSessionService.currentSessionPath + '/files/' + key);
  }

  getObjectDetails(key: string) {
    return this.dbService.get<UserObject>(this.roomSessionService.currentSessionPath + '/objects/' + key);
  }

  convertCanvasToJPEGFile(canvas: HTMLCanvasElement, filename: string, compressionRatio: number): Promise<File> {
    return new Promise<File>(resolve => {
      if (canvas.toBlob) {
        canvas.toBlob(blob => {
          resolve(new File([blob], filename, { type: "image/jpeg", lastModified: (new Date()).getTime() }));
        }, "image/jpeg", compressionRatio);
      } else {
        resolve(this.convertBase64toFile(canvas.toDataURL("image/jpeg", compressionRatio).slice('data:image/jpeg;base64,'.length), "image/jpeg", filename));
      }
    });
  }

  convertBase64toFile(content: string, contentType: string, filename: string): File {
    const sliceSize = 512;
    // Method which converts base64 to binary
    const byteCharacters = window.atob(content);
    const byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
 
    // Method which converts byteArrays to blob
    const blob = new Blob(byteArrays, { type: contentType });

    // File constructor for create file
    let f;
    try {
      f = new File([blob], filename, { type: contentType, lastModified: (new Date()).getTime() });
    } catch(error) {
      const lastModifiedDate = new Date();
      Object.defineProperties(blob, {
        name: {
          value: filename
        },
        lastModifiedDate: {
          value: lastModifiedDate
        },
        lastModified: {
          value: lastModifiedDate.getTime()
        },
        toString: {
          value() {
            return '[object File]'
          }
        }
      });
      f = blob;
    }
    return f;
  }

  convertBase64toJpeg(content: string, width: number, height: number, compressionRatio, filename: string): Promise<File> {
    return new Promise(resolve => {
      const img: HTMLImageElement = new Image(width, height);
      img.src = content;
      img.onload = () => resolve(img)
      img.onerror = (err) => resolve(null)
    })
    .then((img: HTMLImageElement) => {
      if (!img) { return null; }

      const cnv: HTMLCanvasElement = document.createElement("canvas");
      cnv.width = width;
      cnv.height = height;
      const ctx: CanvasRenderingContext2D = cnv.getContext('2d');
      if (!ctx) { return null; }

      ctx.drawImage(img, 0, 0, width, height);
      return this.convertCanvasToJPEGFile(cnv, filename, compressionRatio)
    });
  }

  createDatabaseUserFile(upload: UploadFile, isSavedCollaboration: boolean = false): UserFile {
    return {
      key: upload.key,
      name: upload.file.name,
      size: upload.file.size,
      type: upload.file.type,
      url: upload.url,
      file_type: upload.file_type,
      extension: upload.extension,
      saved_collaboration: isSavedCollaboration,
      create_time: this.dbService.timestamp()
    }
  }

  saveUserFileToFilesDb(file: UserFile): Promise<UserFile> {
    return this.dbService.set(`accounts/${this.authService.currentUser.account_id}/rooms_files/${this.roomSessionService.currentRoomId}/${file.key}`, file)
    .then(() => file);
  }

  saveUserFileToChatDb(file: UserFile): Promise<UserFile> {
    return this.dbService.set(this.roomSessionService.currentSessionPath + '/files/' + file.key, file)
    .then(() => file);
  }

  createDatabaseUserObject(uploadFiles: UploadFile[]): UserObject {
    const object: UserObject = {
      key: this.dbService.createPushId(),
      files: []
    }

    uploadFiles.forEach(upload => {
      const f: any = {
        key: upload.key,
        name: upload.file.name,
        size: upload.file.size,
        type: upload.file.type,
        url: upload.url,
        file_type: upload.file_type,
        extension: upload.extension,
        create_time: this.dbService.timestamp()
      };
      if (upload.main_object_file) {
        f.main_object_file = true;
        object.name = upload.file.name;
        object.extension = upload.extension;
      }
      object.files.push(f);
    });
    return object;
  }

  saveUserObjectToChatDb(object: UserObject) {
    return this.dbService.set(this.roomSessionService.currentSessionPath + '/objects/' + object.key, object)
    .then(() => object);
  }

  saveUserObjectToFilesDb(object: UserObject) {
    return this.dbService.set(`accounts/${this.authService.currentUser.account_id}/rooms_files/${this.roomSessionService.currentRoomId}/${object.key}`, object)
    .then(() => object);
  }

  saveUserObjectToTicketsDb(object: UserObject, ticketId:string) {
    return this.dbService.set(`accounts/${this.authService.currentUser.account_id}/ticket_system/tickets/${ticketId}/${object.key}`, object)
    .then(() => object);
  }

  uploadStepFile(url: string, formData: FormData): Promise<File> {   
    return this.http.post(url, formData, { observe: 'response', responseType: 'blob' }).toPromise()
      .then(response => {
        const type = response.headers.get('Content-Type')
        const blob = new Blob([response.body], { type: type })

        // TODO extract filename from response
        const filename = "converted.glb"
        return new File([blob], filename, { type: type, lastModified: (new Date()).getTime() })
      })
  }

  copyFileRoomToSession(roomId: string, sessionId: string, fileId: string, isObj: boolean){
    const url = this.dbService.getEndPoint("copyFileRoomToSession");
    return this.http.post<any>(url, {
      token: this.authService.currentUser.token,
      roomId: roomId,
      sessionId: sessionId,
      fileId: fileId,
      isObj: isObj
    }).toPromise()
    .then(result => {
      if (isObj){
        this.logService.sendShareObjectLog(result.file);
      } else {
        this.logService.sendShareFileLog(result.file);
      }
    });
  }

  copyFileSessionToRoom(roomId: string, sessionId: string, fileId: string, fileType: string){
    const url = this.dbService.getEndPoint("copyFileSessionToRoom");
    return this.http.post<any>(url, {
      token: this.authService.currentUser.token,
      roomId: roomId,
      sessionId: sessionId,
      fileId: fileId,
      fileType: fileType
    }).toPromise()
    .then(result => { return; });
  }

  deleteFromRoomFiles(roomId: string, fileId: string, isObj: boolean) {
    const url = this.dbService.getEndPoint("deleteRoomFile");
    return this.http.post<any>(url, {
      token: this.authService.currentUser.token,
      roomId: roomId,
      fileId: fileId,
      isObj: isObj
    }).toPromise()
    .then(result => { return; });
  }

  copyFileTicketToSession(roomId, sessionId, fileId, isObj, ticketId) {
    const url = this.dbService.getEndPoint("copyFileTicketToSession");
    return this.http.post<any>(url, {
      token: this.authService.currentUser.token,
      roomId: roomId,
      sessionId: sessionId,
      fileId: fileId,
      isObj: isObj,
      ticketId: ticketId
    }).toPromise()
    .then(result => {
      if (isObj){
        this.logService.sendShareObjectLog(result.file);
      } else {
        this.logService.sendShareFileLog(result.file);
      }
    });
  }

  deleteTicketFiles(ticketId: string, fileId: string) {
    const url = this.dbService.getEndPoint("deleteTicketFile");
    return this.http.post<any>(url, {
      token: this.authService.currentUser.token,
      ticketId,
      fileId
    }).toPromise()
    .then(result => { return; });
  }

}
