import { Observable } from "rxjs";
import { share } from "rxjs/operators";

export class SoundMeter {
    level: number = 0.0;

    private context;
    private script;
    private mic;

    constructor(context: any) {
        this.context = context;
        this.script = context.createScriptProcessor(2048, 1, 1);

        this.script.onaudioprocess = (event) => {
            const input = event.inputBuffer.getChannelData(0);
            let i;
            let sum = 0.0;
            let clipcount = 0;
            for (i = 0; i < input.length; ++i) {
                sum += input[i] * input[i];
                if (Math.abs(input[i]) > 0.99) {
                    clipcount += 1;
                }
            }
            this.level = Math.sqrt(sum / input.length);
        };
    }

    connectToSource(stream: MediaStream) {
        return new Observable<number>(subscriber => {
            let interval;
            try {
                this.mic = this.context.createMediaStreamSource(stream);
                this.mic.connect(this.script);
                this.script.connect(this.context.destination);
                interval = setInterval(() => subscriber.next(this.level), 200);
            } catch (e) {
                subscriber.error(e);
            }
            return () => {
                if (interval) {
                    clearInterval(interval);
                    this.stop();
                }
            }
        }).pipe(share());
    };

    private stop() {
        this.mic.disconnect();
        this.script.disconnect();
    };
}