import { Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { RoomSessionService } from '@services/core/room-session.service';
import { ModalService } from '@services/support/modal.service';
import { AuthService } from '@services/core/auth.service';
import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Archive } from '@models/Archive';
import { FlashMessageService } from '@services/support/flash-message.service';


@Component({
  selector: 'app-archives',
  templateUrl: './archives.component.html',
  styleUrls: ['./archives.component.scss']
})
export class ArchivesComponent implements OnInit {
  @ViewChild("watchArchiveTemplate", { static: true }) private watchArchiveTemplate: TemplateRef<any>;



  @Input() room_id: string;
  @Input() session_id: string;
  dwUrl = "";

  pendingExists: boolean = false;
  firstLoaded: boolean = false;
  showArchives: boolean = true;
  webdavEnabled: boolean = false;
  timezone = "UTC";
  subscriptions: Subscription[] = [];

  confirmModalRef: BsModalRef;
  selectedArchive: Archive = null;
  downloadLinks: { [key: string]: { loading: boolean, url?: string } } = {};


  archives=[]
  refreshing= false;

  constructor( private sessionService: RoomSessionService,
    private modalService: ModalService,
    private authService: AuthService,
    private flashMessageService: FlashMessageService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.authService.addOns.subscribe(addOns => {
      this.webdavEnabled = addOns.webdav;
    })) 
    this.sessionService.getArchive(this.room_id, this.session_id)
    .subscribe(archiveList => {
      this.archives = archiveList;
      this.archives.sort((a, b) => a.create_time > b.create_time ? -1 : 1);

      if (this.archives.length > 0 && this.archives.filter(a => a.storage_status === 'pending').length > 0) {
        this.pendingExists = true;
      } else {
        this.pendingExists = false;
      }

      if (!this.firstLoaded) {
        this.firstLoaded = true;
      }
    });
    this.subscriptions.push(this.authService.timezone.subscribe(res=>{
       this.timezone = res;
    })) 
  };

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  refreshArchives(): void {
    this.refreshing = true;
    this.sessionService.checkSessionArchives(this.room_id, this.session_id).finally(() => {
      this.refreshing = false;
    });
  };

  getArchiveStatusKey(archive): string {
    switch (archive.storage_status) {
      case "uploaded":
        return "APP.MAIN.LOBBY.ARCHIVES.ARCHIVE_LIST.AVAILABLE";
      case "disconnected":
        return "APP.MAIN.LOBBY.ARCHIVES.ARCHIVE_LIST.DISCONNECTED";
      case "expired":
      case "deleted":
        return "APP.MAIN.LOBBY.ARCHIVES.ARCHIVE_LIST.DELETED";
      case "pending":
        return "APP.MAIN.LOBBY.ARCHIVES.ARCHIVE_LIST.WAITING";
      case "failed":
        return "APP.MAIN.LOBBY.ARCHIVES.ARCHIVE_LIST.FAILED";
      default:
        return "APP.MAIN.LOBBY.ARCHIVES.ARCHIVE_LIST.FAILED";
    }
  }

  getBadgeClass(archive) {
    switch (archive.storage_status) {
      case "uploaded":
        return {"badge-success": true};    
      case "pending":
        return {"badge-warning": true};
      case "disconnected":
        return {"badge-secondary": true};
      case "expired":
      case "deleted":
      case "failed":
      default:
        return {"badge-danger": true};
    }
  }

  async onDownloadArchive(archive) {
    if (!(this.downloadLinks[archive.id] && this.downloadLinks[archive.id].url)) {
      this.downloadLinks[archive.id] = { loading: true };
      await this.sessionService.downloadArchive(this.room_id, this.session_id, archive)
      .then(url => this.downloadLinks[archive.id].url = url )
      .catch(error => this.flashMessageService.show('There is an error occured.'))
      .finally(() => { this.downloadLinks[archive.id].loading = false })
    }
  }

  async onWatchArchive(archive) {    
    this.selectedArchive = archive;
    const modalId = this.modalService.show({
      template: this.watchArchiveTemplate,
      context: {
        dataModel: "",
        callbacks: {
          close: () => {
            this.modalService.hide(modalId);
            this.dwUrl = ""
          }
        }
      }
    });
    
    if (!(this.downloadLinks[archive.id] && this.downloadLinks[archive.id].url)) {
      this.downloadLinks[archive.id] = { loading: true };
      await this.sessionService.downloadArchive(this.room_id, this.session_id, archive)
      .then(url => this.downloadLinks[archive.id].url = url )
      .catch(error => this.flashMessageService.show('There is an error occured.'))
      .finally(() => { this.downloadLinks[archive.id].loading = false })
    }
    this.dwUrl = this.downloadLinks[archive.id].url
  }
};
