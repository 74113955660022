import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, ElementRef, Input } from '@angular/core';

import { Router } from '@angular/router';

import { FormBuilder, Validators, NgForm } from '@angular/forms';
import { EmailValidation } from 'app/validators/EmailValidation';

import { DbService } from '@services/core/db.service';
import { AuthService } from '@services/core/auth.service';
import { CallService } from '@services/core/call.service';
import { RoomSessionService } from '@services/core/room-session.service';
import { LoaderService } from '@services/support/loader.service';
import { ModalService } from '@services/support/modal.service';

import { FlashMessageService } from '@services/support/flash-message.service';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { NavigationService } from '@services/support/navigation.service';

import { Language } from '@models/Language';

import { Subject, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { first } from 'rxjs/operators';

import { User } from '@models/User';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
declare var hbspt: any;
declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  @Input('openProfileRequest') openProfileRequest: Subject<void>;

  @ViewChild("logoutTemplate", { static: true }) private logoutTemplate: TemplateRef<any>;
  @ViewChild("profileTemplate", { static: true }) private profileTemplate: TemplateRef<any>;
  @ViewChild("changePassTemplate", { static: true }) private changePassTemplate: TemplateRef<any>;
  @ViewChild("changeEmailTemplate", {static: true}) private changeEmailTemplate: TemplateRef<any>;
  @ViewChild("feedbackTemplate", { static: true }) private feedbackTemplate: TemplateRef<any>;

  @ViewChild('changePassForm') changePassForm: NgForm;
  @ViewChild('feedbackContainer', { static: false }) feedbackContainer: ElementRef;

  profileSub: Subscription = null;

  currentUser: User = null;
  userSub: Subscription = null;

  isCollapsed: boolean = true;
  selectedStatusColor: string = '';
  submittedForm: boolean = false;

  passwordModel = {
    oldPassword: "",
    newPassword: "",
    repeatPassword: "",
    valid: false
  }
  formValidSub: Subscription = null;

  passwordPolicySub: Subscription = null;
  minPassLength: number = 6;
  AZContains: boolean = false;
  azContains: boolean = false;
  numberContains: boolean = false;
  specialCharacterContains: boolean = false;
  regexList: Object = {}
  passwordGroup = {
    password: "",
    confirmPassword: "",
    oldPassword: ""
  }
  changePasswordModalId: number = 0;

  inRoomName: string = null;
  currentRoomSub: Subscription = null;

  currentLang: Language;
  allLangs: Language[] = [];
  showLangs: boolean = false;
  langSub: Subscription = null;

  showSidebarToggler: boolean = false;
  sidebarTogglerSub: Subscription = null;

  appVersion:string = "";

  feedbackId: string = null;

  secondLogo: any = null;
  announceCount: number = 0;

  addOnsSub: Subscription = null;
  addOns: { [key: string]: boolean } = { webstatus: false };

  constructor(
    private authService: AuthService,
    private callService: CallService,
    private roomSessionService: RoomSessionService,
    private dbService: DbService,
    private loaderService: LoaderService,
    private multilanguageService: MultilanguageService,
    private modalService: ModalService,
    private navigationService: NavigationService,
    private flashMessageService: FlashMessageService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.appVersion = environment.version;

    this.getPasswordOptions();

    this.userSub = this.authService.user.subscribe(user => {
      this.currentUser = user;

      if (user?.status?.web_status) {
        switch (user?.status?.web_status) {
          case 'available':
            this.selectedStatusColor = '#66BB6A';
            break;
          case 'busy':
            this.selectedStatusColor = '#DC3545';
            break;
          case 'offline':
            this.selectedStatusColor = '#E1E1E1';
            break;
          default:
            this.selectedStatusColor = ''; 
            break;
        }    
      }
    });

    this.authService.getSecondLogo().then(data => {
      if (data && data.show) {
        this.secondLogo = data.url;
      }
    });

    this.currentRoomSub = this.roomSessionService.currentRoom.subscribe(room => {
      this.inRoomName = room ? room.room_data.name : null;
    });

    this.currentLang = this.multilanguageService.currentLang;
    this.allLangs = this.multilanguageService.allLangs;
    this.langSub = this.multilanguageService.onLangChange.subscribe(change => {
      this.currentLang = change.lang;
    });

    this.sidebarTogglerSub = this.navigationService.sidebarTogglerStatus.subscribe(show => {
      this.showSidebarToggler = show;
    });

    this.profileSub = this.openProfileRequest.subscribe(() => this.onProfile());

    this.addOnsSub = this.authService.addOns.subscribe(addOns => { this.addOns = addOns; });
  }

  onWidgetClose() {
    this.announceCount = 0;
  }

  onWidgetUnread(unreadCount: number) {
    this.announceCount = unreadCount;
  }

  onDropdownOpenChanged(isOpen) {
    if (!isOpen) {
      this.showLangs = false;
    }
  }

  onLanguageSelected(lang: Language) {
    this.multilanguageService.setCurrentLanguage(lang.code);
  }

  onPreCallTest() {
    if (this.callService.inRoom) {
      this.flashMessageService.showTranslated('APP.MAIN.NAVBAR.PRECALL_END_SESSION');
    } else {
      this.router.navigate(['/precall']);
    }
  }

  onBrandClick() {
    if (this.callService.inRoom) {
      this.router.navigate(['/room']);
    } else {
      this.router.navigate(['/']);
    }
  }

  onToggleSidebar() {
    this.navigationService.sidebarTogglerClicked();
  }

  formReadyFn = async () => {
    this.feedbackId = this.dbService.createPushId();
    const currentUser = this.authService.currentUser;
    const currentRoom = await this.roomSessionService.currentRoom.pipe(first()).toPromise();
    const feedbackForm = environment["feedbackForm"];

    const iframe = $(this.feedbackContainer.nativeElement).find('iframe').contents();
    const feedbackInput = iframe.find("input[name='feedback_id']");
    feedbackInput.val(this.feedbackId).change();

    const emailInput = iframe.find("#email-" + feedbackForm.formId);
    emailInput.val(currentUser.email).change();

    const companyInput = iframe.find("#company-" + feedbackForm.formId);
    companyInput.val(currentUser.auth.account_name).change();

    if (currentRoom) {
      const roomInput = iframe.find("#vsight_room_name-" + feedbackForm.formId);
      roomInput.val(currentRoom.room_data.name).change();
    }

    const now = new Date();
    const formattedDate = now.getFullYear() + '-' + ("0"+(now.getMonth()+1)).slice(-2) + '-' + ("0" + now.getDate()).slice(-2);

    const dateInput = iframe.find("#incident_date-" + feedbackForm.formId);
    dateInput.val(formattedDate).change();

    const hourInput = iframe.find("#hout-" + feedbackForm.formId);
    hourInput.val(now.getHours()).change();

    const minuteInput = iframe.find("#minute-" + feedbackForm.formId);
    minuteInput.val(now.getMinutes()).change();
  }

  formSubmitFn = async (form) => {
    if (this.feedbackId) {
      const currentUser = this.authService.currentUser;
      const currentRoom = await this.roomSessionService.currentRoom.pipe(first()).toPromise();

      const feedbackData: any = {
        account_id: currentUser.account_id,
        company_name: currentUser.company_name,
        user: {
          name: currentUser.name,
          role: currentUser.role,
          username: currentUser.user_principal_name ? currentUser.user_principal_name : currentUser.auth.username,
          allow_archiving: currentUser.allow_archiving,
          allow_calling: currentUser.allow_calling,
          allow_join_link: currentUser.allow_join_link,
          call_archive_perm_needed: currentUser.call_archive_perm_needed
        }
      };
      if (currentUser.ad_user) {
        feedbackData.user.ad_user = true;
      }
      if (currentUser.email) {
        feedbackData.user.email = currentUser.email;
      }
      if (currentRoom) {
        feedbackData.session = {
          room_name: currentRoom.room_data.name,
          vs: currentRoom.room_data.session.vs,
          ot: currentRoom.room_data.session.ot,
          peer_to_peer: currentRoom.room_data.session.peer_to_peer,
          training_room: currentRoom.room_data.session.training_room,
          auto_archive: currentRoom.room_data.session.auto_archive,
          full_hd: currentRoom.room_data.full_hd ? true : false,
          create_time: currentRoom.room_data.session.create_time,
          archive_perm_needed: currentRoom.room_data.archive_perm_needed
        };
      }
      this.authService.sendFeedbackData(this.feedbackId, feedbackData);
    }
  }

  formSubmittedFn = () => {
    this.submittedForm = true;
  }

  onFeedback() {
    const feedbackForm = environment["feedbackForm"];
    if (feedbackForm){
      hbspt.forms.create({
        region: feedbackForm.region,
        portalId: feedbackForm.portalId,
        formId: feedbackForm.formId,
        target: "#feedback-modal",
        onFormReady: this.formReadyFn,
        onFormSubmit: this.formSubmitFn,
        onFormSubmitted: this.formSubmittedFn
      });
    }
    const modalId = this.modalService.show({
      template: this.feedbackTemplate,
      context: {
        dataModel: null,
        callbacks: {
          close: () => {
            this.modalService.hide(modalId);
          }
        }
      }
    });
  }

  onProfile() {
    const modalId = this.modalService.show({
      template: this.profileTemplate,
      context: {
        dataModel: { user: this.currentUser },
        callbacks: {
          close: () => {
            this.modalService.hide(modalId);
          },
          changePassword: () => {
            this.passwordGroup = {
              password: "",
              confirmPassword: "",
              oldPassword: ""
            }
            this.onChangePassword();
          },
          changeEmail: () => {
            this.onChangeEmail(modalId);
          }
        }
      }
    });
  }

  onPasswordFormSubmit(form: NgForm) {
    this.loaderService.show();
    this.authService.changePassword(form.controls.oldPassword.value, form.controls.password.value)
    .then(() => {
      this.flashMessageService.showTranslated('APP.MAIN.NAVBAR.CHANGE_PASS_SUCCESS', { cssClass: "alert-success" });
    })
    .catch(error => this.flashMessageService.showTranslated('APP.MAIN.NAVBAR.CHANGE_PASS_FAILED'))
    .finally(() => {
      this.closeModal();
      this.loaderService.hide();
    });
  }

  closeModal() {
    this.modalService.hide(this.changePasswordModalId)
  }

  onChangePassword() {
    this.getPasswordOptions().then(() => {
      const modalId = this.modalService.show({
        template: this.changePassTemplate,
        context: {
          dataModel: null,
          callbacks: {}
        }
      });
      this.changePasswordModalId = modalId;
    });
  }

  getPasswordOptions() {
    return this.authService.getPasswordPolicy()
      .then(ad => {
        if (ad) {
          const strongEnabled = ad.strong;
          if (strongEnabled === true) {
            const options = ad.options;

            this.minPassLength = options.length ? options.length : this.minPassLength;
            
            this.AZContains = options.AZ ? options.AZ : false;
            if (this.AZContains) { this.regexList["containsUppercase"] = '.*[A-Z].*' }
            
            this.azContains = options.az ? options.az : false;
            if (this.azContains) { this.regexList["containsLowercase"] = '.*[a-z].*' }
            
            this.numberContains = options.number ? options.number : false;
            if (this.numberContains) { this.regexList["containsNumber"] = '.*[0-9].*' }
            
            this.specialCharacterContains = options.special ? options.special : false;
            if (this.specialCharacterContains) { this.regexList["containsSpecialCharacter"] = '.*[-*/?#&()@%+\'!$^:,~_.½£₺é;{}ß>|æ].*' }
          } 
          else {
            this.minPassLength = 6;
            this.AZContains = false;
            this.azContains = false;
            this.numberContains = false;
            this.specialCharacterContains = false;
          }
        } else {
          this.minPassLength = 6;
          this.AZContains = false;
          this.azContains = false;
          this.numberContains = false;
          this.specialCharacterContains = false;
        }
      })
      .catch(err => { console.log(err) });
  }

  onChangeEmail(profileModalId: number) {
    const form = this.fb.group({
      newEmail: ['', [Validators.required, Validators.pattern("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,})+$")]],
      repeatEmail: [''],
    }, {
      validator: EmailValidation.MatchEmail
    });

    const modalId = this.modalService.show({
      template: this.changeEmailTemplate,
      context: {
        dataModel: { form: form },
        callbacks: {
          close: () => {
            this.modalService.hide(modalId);
          },
          change: () => {
            this.loaderService.show();
            this.authService.changeEmail(form.controls.newEmail.value)
            .then(() => {
              this.modalService.hide(modalId);
              this.flashMessageService.showTranslated('APP.MAIN.NAVBAR.CHANGE_EMAIL_SUCCESS', { cssClass: "alert-success" });
            })
            .catch(() => {
              this.flashMessageService.showTranslated('APP.MAIN.NAVBAR.CHANGE_EMAIL_FAILED');
            })
            .finally(() => {
              this.loaderService.hide();
            }); 
          }
        }
      }
    });
  }

  onLogout() {
    const modalId = this.modalService.show({
      template: this.logoutTemplate,
      context: {
        dataModel: { inRoom: this.callService.inRoom ? true : false },
        callbacks: {
          no: () => {
            this.modalService.hide(modalId);
          },
          yes: () => {
            this.modalService.hide(modalId);
            this.loaderService.show();
            this.logout()
            .then(() => {
              this.loaderService.hide();
            })
            .catch(error => {
              this.modalService.hide(modalId);
              this.loaderService.hide();
              this.flashMessageService.showTranslated('APP.MAIN.NAVBAR.LOGOUT_FAILED');
            });
          }
        }
      }
    });
  }

  logout(): Promise<void> {
    if (this.callService.inRoom) {
      return this.callService.endCall()
      .then(() => {
        return this.authService.logout();
      });
    } else {
      // For prevent loader flashing
      return new Promise(resolve => setTimeout(resolve, 500))
      .then(() => {
        return this.authService.logout();
      });
    }
  }

  setStatusColor(status: string, dropdown: BsDropdownDirective): void {
    this.authService.setUserStatus(status, status !== "online")
    dropdown.hide();
  }

  ngOnDestroy() {
    if (this.userSub) { this.userSub.unsubscribe() }
    if (this.currentRoomSub) { this.currentRoomSub.unsubscribe() }
    if (this.langSub) { this.langSub.unsubscribe() }
    if (this.sidebarTogglerSub) { this.sidebarTogglerSub.unsubscribe() }
    if (this.profileSub) { this.profileSub.unsubscribe() }
    if (this.addOnsSub) { this.addOnsSub.unsubscribe() }
  }
}