import { Component } from '@angular/core';

import { AuthService } from '@services/core/auth.service';
import { DbService } from '@services/core/db.service';

import { Guide } from '@models/Guide';

import { first } from 'rxjs/operators';

@Component({
  selector: 'app-vs-guide',
  templateUrl: './vs-guide.component.html',
  styleUrls: ['./vs-guide.component.scss']
})
export class VsGuideComponent {

  guides: Guide[] = null;
  failed: boolean = false;

  constructor(
    private authService: AuthService,
    private dbService: DbService
  ) {
    this.dbService.get<any>("guides")
    .then(guides => { this.guides = guides })
    .catch(err => { this.failed = true })
    .then(() => this.authService.isAuthenticated().pipe(first()).toPromise())
    .then(authenticated => {
      // prevent seting user status as offline, when this tab closed
      if (authenticated) {
        this.dbService.setManageUserStatus(false);
        this.authService.logout();
      }
    });
  }
}
