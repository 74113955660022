import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@models/User';
import { AuthService } from '@services/core/auth.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-app-select',
  templateUrl: './app-select.component.html',
  styleUrls: ['./app-select.component.scss']
})
export class AppSelectComponent implements OnInit, OnDestroy {

  currentUser: User = null;
  userSub: Subscription = null;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userSub = this.authService.user.subscribe(user => {
      this.currentUser = user;

      if (user && !(user.role === 'admin' || user.role === 'coadmin' || user.coadmin)) {
        this.router.navigate(['/']);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.userSub) { this.userSub.unsubscribe() }
  }

  onAppSelect(selection: string) {
    if (selection === 'admin') {
      this.authService.switchToAdmin();
    } else {
      this.router.navigate(['/']);
    }
  }


}
