<div class="feedback-wrapper container-fluid py-5">
    <div class="row justify-content-center">
        <div class="col-xl-9 col-lg-10 col-md-11 col-12">
            <div class="feedback-header text-muted d-flex align-items-center justify-content-between">
                <span class="py-2">{{ 'APP.MAIN.LEFT_BAR.FEEDBACK' | translate }}</span>
            </div>
            <div class="feedback-inside container-fluid bg-white mt-1 pt-4 px-4 px-sm-5" style="background-color: white;">
                <div class="d-flex justify-content-center pt-3" id="feedback-modal" #feedbackContainer></div>
                <div *ngIf="submittedForm" class="feedback-hubspot-hide-success"></div>
                <div *ngIf="submittedForm" class="success-message">
                    {{ 'APP.MAIN.NAVBAR.SUCCESS_MESSAGE' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>