import { Injectable } from '@angular/core';

import { DbService } from './db.service';
import { AuthService } from './auth.service';
import { CallService } from './call.service';
import { RoomSessionService } from './room-session.service';

import { JoinRoomResponse } from '@models/JoinRoomResponse';
import { UserObject } from '@models/UserObject';

import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArCollaborationService {

  arCollaborationData: any = null;
  private currentSessionPath: string = null;

  static arColorCodes = {
    "red": "dc3545",
    "blue": "17a2b8",
    "green": "28a745",
    "orange": "ff9800",
    "purple": "dc35f5",
    "white": "ffffff"
  };
  static arColorNames = {
    "dc3545": "red",
    "17a2b8": "blue",
    "28a745": "green",
    "ff9800": "orange",
    "dc35f5": "purple",
    "ffffff": "white"
  };

  constructor(
    private authService: AuthService,
    private callService: CallService,
    private roomSessionService: RoomSessionService,
    private dbService: DbService
  ) {
    this.roomSessionService.on("sessionCreated", this.onSessionCreated);
    this.roomSessionService.on("sessionDestroyed", this.onSessionDestroyed);
  }

  private onSessionCreated = (joinResponse: JoinRoomResponse, sessionPath: string) => {
    this.currentSessionPath = sessionPath;

    this.roomSessionService.sessionData.pipe(first(), map(sessionData => sessionData.ar_collaboration)).toPromise()
    .then(arCollaboration => {
      this.arCollaborationData = arCollaboration;
    });
  }

  private onSessionDestroyed = () => {
    this.arCollaborationData = null;
  }

  changeColor(colorCode: string): Promise<void> {
    return this.dbService.set(`${this.callService.currentRoomPath}/room_data/users/${this.authService.currentUser.id}/ar_color`, colorCode);
  }

  getPublisherDots(destination: string): Observable<any>  {
    return this.dbService.listen<any>(this.currentSessionPath + '/ar_collaboration/'+destination);
  }

  getSubscriberDots(destination: string): Observable<any> {
    return this.dbService.listen<any>(this.currentSessionPath + '/ar_collaboration/'+destination);
  }

  setDot(source: string, destination: string, data: string) {
    this.dbService.update(this.currentSessionPath + '/ar_collaboration/'+destination, { [source] : data });
  }

  openHdPhoto(destination: string): Promise<{ committed: boolean, data: any }> {
    return this.dbService.transaction(this.currentSessionPath + '/session_data', 'openHdPhoto', destination);
  }

  openArPlus(destination: string): Promise<{ committed: boolean, data: any }> {
    return this.dbService.transaction(this.currentSessionPath + '/session_data', 'openArPlus', destination);
  }

  closeArPlus(destination: string) {
    return this.dbService.transaction(this.currentSessionPath + '/session_data/devices/'+destination+'/device_status/arkit', 'closeArPlus');
  }

  closeAllArPluses() {
    return this.dbService.transaction(this.currentSessionPath + '/session_data/devices', 'closeAllArPluses');
  }

  createArPlusArrow(destination: string, left: number, top: number, color: string, weight: string, type: string) {
    let arrowId = this.dbService.createPushId();
    this.dbService.update(this.currentSessionPath + '/ar_plus/' + destination, {
      ['arrays/deleted']: null,
      ['arrays/visible/' + arrowId]: {
        add_time: this.dbService.timestamp(),
        color: color,
        weight: weight,
        coordinates: { left: left, top: top },
        type: 'arrow',
        type2: type
      },
      ['arrays/info/' + arrowId]: {
        hit: false,
        done: true
      }
    });
    return arrowId;
  }

  createArPlusText(destination: string, left: number, top: number, color: string, weight: string, content: string) {
    let arTextId = this.dbService.createPushId();
    this.dbService.update(this.currentSessionPath + '/ar_plus/' + destination, {
      ['arrays/deleted']: null,
      ['arrays/visible/' + arTextId]: {
        add_time: this.dbService.timestamp(),
        color: color,
        weight: weight,
        coordinates: { left: left, top: top },
        type: 'artext',
        content: content
      },
      ['arrays/info/' + arTextId]: {
        hit: false,
        done: true
      }
    });
    return arTextId;
  }

  createArPlusCustomObject(destination: string, left: number, top: number, color: string, weight: string, customObjId: string) {
    let objectId = this.dbService.createPushId();
    this.dbService.update(this.currentSessionPath + '/ar_plus/' + destination, {
      ['arrays/deleted']: null,
      ['arrays/visible/' + objectId]: {
        add_time: this.dbService.timestamp(),
        color: color,
        weight: weight,
        coordinates: { left: left, top: top },
        type: 'custom',
        type2: customObjId
      },
      ['arrays/info/' + objectId]: {
        hit: false,
        done: true
      }
    });
    return objectId;
  }

  createArPlusLine(destination: string, left: number, top: number, color: string, weight: string) {
    let lineId = this.dbService.createPushId();
    this.dbService.update(this.currentSessionPath + '/ar_plus/' + destination, {
      ['arrays/deleted']: null,
      ['arrays/visible/' + lineId]: {
        add_time: this.dbService.timestamp(),
        color: color,
        weight: weight,
        coordinates: { left: left, top: top },
        type: 'line'
      },
      ['arrays/info/' + lineId]: {
        hit: false,
        done: false
      }
    });
    return lineId;
  }

  addArPlusPointToLine(destination: string, lineId: string, left: number, top: number) {
    this.dbService.set(this.currentSessionPath + '/ar_plus/' + destination + '/arrays/visible/' + lineId + '/coordinates', { left: left, top: top });
  }

  endArPlusLine(destination: string, lineId: string) {
    this.dbService.set(this.currentSessionPath + '/ar_plus/' + destination + '/arrays/info/' + lineId + '/done', true);
  }

  undoArPlus(destination: string) {
    return this.dbService.transaction(this.currentSessionPath + '/ar_plus/' + destination + '/arrays', 'undoArPlus');
  }

  redoArPlus(destination: string) {
    return this.dbService.transaction(this.currentSessionPath + '/ar_plus/' + destination + '/arrays', 'redoArPlus');
  }

  clearArPlus(destination: string) {
    this.dbService.remove(this.currentSessionPath + '/ar_plus/' + destination);
  }

  getObjects(): Observable<UserObject> {
    return this.dbService.listen<UserObject>(`${this.currentSessionPath}/objects`, 'child_added');
  }
}
