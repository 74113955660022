import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[filedropenter]'
})
export class FileDropEnterDirective {

  @Output() dropEnter = new EventEmitter<void>();

  constructor() { }

  @HostListener('dragenter', ['$event'])
  onDragOver($event) {
    event.preventDefault();

    this.dropEnter.emit();
  }
}
