<nav class="navbar navbar-light navbar-default navbar-static-top navbar-expand px-0">
    <div class="container-fluid">
        <a class="sidebar-toggler ml-3 h-100" *ngIf="showSidebarToggler" (click)="onToggleSidebar()"><i class="mi mi-menu"></i></a>
        <a *ngIf="('design' | env).logoText" class="navbar-brand px-3 mr-0" (click)="onBrandClick()">
            <img class="logo" [src]="('design' | env).logo">
            <span class="ml-2">{{ ('design' | env).logoText }}</span>
        </a>
        <a *ngIf="!('design' | env).logoText" class="navbar-only-logo-brand px-3 mr-0" (click)="onBrandClick()">
            <img class="logo" [class.wide]="('design' | env).wideLogo" [src]="('design' | env).logo">
        </a>
        <a class="navbar-only-logo-brand" *ngIf="secondLogo">
            <a class="for-label navbar-only-logo-brand text-muted">for</a>
            <img class="logo-second" [src]="secondLogo">
        </a>
        
        <div class="room-name-wrapper" *ngIf="inRoomName">{{ inRoomName }}</div>

        <div class="navbar-collapse">
            <ul class="navbar-nav ml-auto">
                <ng-container *ngIf="currentUser">
                    <li class="nav-item d-flex flex-row align-items-center" dropdown>
                        <a class="nav-link d-flex flex-row align-items-center" id="nav-lang-dropdown-link" dropdownToggle aria-controls="nav-lang-dropdown-menu">
                            <i class="mi mi-language"></i>
                            <span class="language-code ml-1">{{ currentLang.code | uppercase }}</span>
                        </a>
                        <ul id="nav-lang-dropdown-menu" *dropdownMenu class="dropdown-menu dropdown-menu-right py-1" role="menu" aria-expanded="false" aria-labelledby="nav-lang-dropdown-link">
                            <ng-container *ngFor="let lang of allLangs">
                                <li class="language-item" role="menuitem" *ngIf="currentLang !== lang">
                                    <a class="dropdown-item language-link text-center px-2" (click)="onLanguageSelected(lang)">{{ lang.code | uppercase }}</a>
                                </li>
                            </ng-container>
                        </ul>
                    </li>

                    <announcekit *ngIf="('design' | env).showAnnounceKit" [boosters]="false" [widgetStyle]="{display:'none',opacity:'0'}" [widget]="('design' | env).announceKitWidgetUrl"
                            [user]="{ id: currentUser.id, name: currentUser.company_name }" [data]="{ whitelabel: ('design' | env).environmentName }" (onWidgetClose)="onWidgetClose()" (onWidgetUnread)="onWidgetUnread($event)">
                        <li class="nav-item announcekit-container d-flex flex-row align-items-center">
                            <a class="nav-link d-flex flex-row align-items-center">
                                <i class="mi mi-notifications"></i>
                                <span class="announce-count" *ngIf="announceCount !== 0">{{ announceCount }}</span>
                            </a>
                        </li>
                    </announcekit>

                    <li class="nav-item dropdown mr-1" dropdown #dropdown="bs-dropdown" [insideClick]="true" (isOpenChange)="onDropdownOpenChanged($event)">
                        <a class="nav-link d-flex flex-row align-items-center" id="nav-dropdown-link" dropdownToggle aria-controls="nav-dropdown-menu">
                            <i class="account-icon mi mi-account-circle">
                                <div *ngIf="addOns.webstatus" class="status-circle" [ngStyle]="{ 'background-color': selectedStatusColor }"></div>
                            </i>
                            <div class="account-info d-inline-block">
                                <div class="user-name">{{ currentUser.name }}</div>
                                <div class="company-name">{{ currentUser.company_name }}</div>
                            </div>
                            <i class="down-icon mi mi-keyboard-arrow-down"></i>
                        </a>
                        <ul id="nav-dropdown-menu" *dropdownMenu class="dropdown-menu dropdown-menu-right py-1" role="menu" aria-expanded="false" aria-labelledby="nav-dropdown-link">
                            <li>
                                <label class="dropdown-item app-version text-center">v{{ appVersion }}</label>
                            </li>
                            <ng-container *ngIf="addOns.webstatus">
                                <hr style="margin-top: 5px;">
                                <li>
                                    <span class="dropdown-item status" (click)="setStatusColor('available', dropdown)"><i class="status-circle-dropdown available"></i>{{ 'APP.MAIN.NAVBAR.STATUS.AVAILABLE' | translate }}</span>
                                </li>
                                <li>
                                    <span class="dropdown-item status" (click)="setStatusColor('busy', dropdown)"><i class="status-circle-dropdown busy"></i>{{ 'APP.MAIN.NAVBAR.STATUS.BUSY' | translate }}</span>
                                </li>
                                <!--<li>
                                    <div class="status-circle-dropdown away"></div>
                                    <span (click)="setStatusColor('away', dropdown)">Away</span>
                                </li>-->
                                <li>
                                    <span class="dropdown-item status" (click)="setStatusColor('offline', dropdown)"><i class="status-circle-dropdown offline"></i>{{ 'APP.MAIN.NAVBAR.STATUS.APPEAR_OFFLINE' | translate }}</span>
                                </li>
                                <hr style="margin-bottom: 5px;">
                            </ng-container>
                            <li *ngIf="!currentUser.guest" role="menuitem"><a class="dropdown-item px-3" role="menuitem"(click)="onProfile(); dropdown.hide();">
                                <i class="mi mi-person-outline mr-3"></i><span>{{ 'APP.MAIN.NAVBAR.PROFILE_TITLE' | translate }}</span></a>
                            </li>
                            <li *ngIf="('design' | env).showFeedbackForm" role="menuitem"><a class="dropdown-item px-3" (click)="onFeedback(); dropdown.hide();">
                                <i class="mi mi-textsms mr-3 mt-1"></i><span>{{ 'APP.MAIN.LEFT_BAR.FEEDBACK' | translate }}</span></a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item px-3" (click)="onLogout(); dropdown.hide();">
                                <i class="mi mi-power-settings-new mr-3"></i><span>{{ 'APP.MAIN.NAVBAR.LOGOUT' | translate }}</span></a>
                            </li>
                        </ul>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
</nav>

<ng-template #feedbackTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog feedback-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                  <h5 class="modal-title">{{ 'APP.MAIN.NAVBAR.FEEDBACK' | translate }}</h5>
            </div>
            <div class="modal-body">
                <div *ngIf="!submittedForm" class="feedback-inside container-fluid bg-white px-1" style="background-color: white;">
                    <div class="d-flex justify-content-center pt-1" id="feedback-modal" #feedbackContainer></div>
                </div>
                <div *ngIf="submittedForm" class="success-message">
                    {{ 'APP.MAIN.NAVBAR.SUCCESS_MESSAGE' | translate }}
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.close()">
                    {{ 'APP.MAIN.NAVBAR.CLOSE' | translate }}
                </button>
            </div>
        </div>
    </div>
  </ng-template>

<ng-template #profileTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.NAVBAR.PROFILE_TITLE' | translate }}</h5>
            </div>
            <div class="modal-body p-0">
                <table class="table">
                    <tbody>
                        <tr>
                            <th class="pl-4" scope="row">{{ 'APP.MAIN.NAVBAR.PROFILE_NAME' | translate }}</th>
                            <td>{{ this.currentUser?.name }}</td>
                        </tr>
                        <tr>
                            <th class="pl-4" scope="row">{{ 'APP.MAIN.NAVBAR.PROFILE_PASSWORD' | translate }}</th>
                            <td><span class="mr-2">&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;</span>
                            <a *ngIf="this.currentUser && !this.currentUser.ad_user" class="text-info" style="cursor: pointer;" (click)="callbacks.changePassword();">( {{ 'APP.MAIN.NAVBAR.PROFILE_CHANGE_LINK' | translate }} )</a>
                            </td>
                        </tr>
                        <tr>
                           <!--  this.currentUser.email ? this.currentUser.email ?  -->
                            <th class="pl-4" scope="row">{{ 'APP.MAIN.NAVBAR.PROFILE_EMAIL' | translate }}</th>
                            <td><span class="mr-2">{{ this.currentUser?.email }}</span>
                            <ng-container *ngIf="this.currentUser && !this.currentUser.ad_user">
                                <a *ngIf="this.currentUser.email" class="text-info" style="cursor: pointer;" (click)="callbacks.changeEmail()">( {{ 'APP.MAIN.NAVBAR.PROFILE_CHANGE_LINK' | translate }} )</a>
                                <a *ngIf="!(this.currentUser.email)" class="text-info" style="cursor: pointer;" (click)="callbacks.changeEmail()">( {{ 'APP.MAIN.NAVBAR.PROFILE_ADD_LINK' | translate }} )</a>
                            </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.close()">
                    {{ 'APP.MAIN.NAVBAR.PROFILE_CLOSE' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #changePassTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog password-change" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.NAVBAR.CHANGE_PASS_TITLE' | translate }}</h5>
            </div>
            <div class="modal-body p-2">
                <form #passwordForm="ngForm" autocomplete="off" class="form-inline" (ngSubmit)="onPasswordFormSubmit(passwordForm)" [mustMatch]="['password', 'confirmPassword']">
                    <div class="row mb-3">
                        <div class="col-sm mt-2 ml-3 mr-3 py-1">
                            <div class="form-group password-control col-12 mb-4">
                                <label class="mr-4 mb-1" for="oldPassword">{{ 'APP.MAIN.NAVBAR.OLD_PASSWORD' | translate }}</label>
                                <input
                                    #oldPassword="ngModel" 
                                    class="form-control" 
                                    type="password" 
                                    name="oldPassword" 
                                    placeholder="{{ 'APP.MAIN.NAVBAR.OLD_PASSWORD' | translate }}"
                                    [ngClass]="{'is-invalid':oldPassword.errors && oldPassword.touched}"
                                    [(ngModel)]="passwordGroup.oldPassword"
                                    readonly
                                    onfocus="this.removeAttribute('readonly');"
                                    required
                                />
                            </div>
                            <div class="form-group password-control col-12 mb-2">
                                <label class="mr-4 mb-1" for="password">{{ 'APP.MAIN.NAVBAR.CHANGE_PASS_NEW_PASS' | translate }}</label>
                                <input
                                    #password="ngModel" 
                                    class="form-control" 
                                    type="password" 
                                    name="password" 
                                    placeholder="{{ 'APP.MAIN.NAVBAR.CHANGE_PASS_NEW_PASS' | translate }}"
                                    [ngClass]="{'is-invalid':password.errors && password.touched}"
                                    [(ngModel)]="passwordGroup.password"
                                    [customRegexList]="regexList"
                                    whitespaceValidator
                                    undesiredPasswordListValidator
                                    [minlengthValidator]='minPassLength'
                                    required
                                />
                                <small class="invalid-feedback" *ngIf="password.errors?.whitespace">{{ 'APP.MAIN.NAVBAR.NOT_WHITESPACE' | translate }}</small>
                                <small class="invalid-feedback" *ngIf="password.errors?.undesiredPassword">{{ 'APP.MAIN.NAVBAR.DO_NOT_CREATE_SIMPLE' | translate }}</small>
                            </div>
                            <div class="form-group password-control col-12 mb-2">
                                <label class="mr-4 mb-1" for="confirmPassword">{{ 'APP.MAIN.NAVBAR.CHANGE_PASS_RPT_PASS' | translate }}</label>
                                <input 
                                    #confirmPassword="ngModel"
                                    class="form-control" 
                                    type="password" 
                                    name="confirmPassword" 
                                    placeholder="{{ 'APP.MAIN.NAVBAR.CHANGE_PASS_RPT_PASS' | translate }}" 
                                    [ngClass]="{'is-invalid':confirmPassword.errors && confirmPassword.touched}"
                                    [(ngModel)]="passwordGroup.confirmPassword"
                                    required
                                    readonly 
                                    onfocus="this.removeAttribute('readonly');"
                                />
                                <small class="invalid-feedback" *ngIf="confirmPassword.errors?.mustMatch">{{ 'APP.MAIN.NAVBAR.CHANGE_PASS_NOT_MATCH' | translate }}</small>
                            </div>
                        </div>
                        <div class="col-sm ml-3 mt-2 py-2">
                            <div class="mb-2 title">{{ 'APP.MAIN.NAVBAR.CREATE_NEW_PASS' | translate }}</div>
                            <div class="d-flex align-items-center" *ngIf="AZContains">
                                <i class="mi mi-done mr-1" [ngClass]="{'warning': password.errors?.containsUppercase, 'success': !(password.errors?.containsUppercase)}"></i>
                                <p style="font-size: 12px;">{{ 'APP.MAIN.NAVBAR.UPPERCASE' | translate }}<strong class="ml-2">(A-Z)</strong></p>
                            </div>
                            <div class="d-flex align-items-center" *ngIf="azContains">
                                <i class="mi mi-done mr-1" [ngClass]="{'warning': password.errors?.containsLowercase, 'success': !(password.errors?.containsLowercase)}"></i>
                                <p style="font-size: 12px;">{{ 'APP.MAIN.NAVBAR.LOWERCASE' | translate }}<strong class="ml-2">(a-z)</strong></p>
                            </div>
                            <div class="d-flex align-items-center" *ngIf="numberContains">
                                <i class="mi mi-done mr-1" [ngClass]="{'warning': password.errors?.containsNumber, 'success': !(password.errors?.containsNumber)}"></i>
                                <p style="font-size: 12px;">{{ 'APP.MAIN.NAVBAR.NUMBER' | translate }}<strong class="ml-2">(0-9)</strong></p>
                            </div>
                            <div class="d-flex align-items-center" *ngIf="specialCharacterContains">
                                <i class="mi mi-done mr-1" [ngClass]="{'warning': password.errors?.containsSpecialCharacter, 'success': !(password.errors?.containsSpecialCharacter)}"></i>
                                <p style="font-size: 12px;">{{ 'APP.MAIN.NAVBAR.SPECIAL' | translate }}<strong class="ml-2">(-*/?#&())</strong></p>
                            </div>
                            <div class="d-flex align-items-center mb-4">
                                <i class="mi mi-done mr-1" [ngClass]="{'success': !password.errors?.minlengthAccessed, 'warning': password.errors?.minlengthAccessed}"></i>
                                <p style="font-size: 12px;">{{ 'APP.MAIN.NAVBAR.AT_LEAST_PASSWORD_LENGTH' | translate:{passwordlength:minPassLength} }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="d-flex flex-row-reverse mt-1">
                            <button class="btn btn-sm btn-info px-4 ml-2" type="submit" [disabled]="!passwordForm.valid">{{ 'APP.MAIN.NAVBAR.CHANGE_PASS_CHANGE_BTN' | translate }}</button>
                            <button class="btn btn-sm btn-dark px-4" (click)="closeModal()">{{ 'APP.MAIN.NAVBAR.PROFILE_CLOSE' | translate }}</button> 
                        </div>
                    </div>       
                </form>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #changeEmailTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.NAVBAR.CHANGE_EMAIL_TITLE' | translate }}</h5>
            </div>
            <div class="modal-body p-2">
                <form [formGroup]="dataModel.form" autocomplete="off" class="form-inline" (ngSubmit)="callbacks.change()">
                    <input autocomplete="false" name="hidden" type="text" style="display:none;"> <!--for disable autocomplete-->
                    <div class="form-group col-12">
                        <label class="col-5" for="newEmail">{{ 'APP.MAIN.NAVBAR.CHANGE_EMAIL_NEW_EMAIL' | translate }}</label>
                        <input
                            formControlName="newEmail"
                            [formGroup]="dataModel.form"
                            class="col-6 rounded-0 field form-control"
                            type="email"
                            style="font-size: 14px;"
                        />
                        <div class="col-5"></div>
                        <small class="col-7" [hidden]="!dataModel.form.controls.newEmail.touched || !dataModel.form.controls.newEmail.errors?.required">{{ 'APP.MAIN.NAVBAR.CHANGE_EMAIL_REQUIRED' | translate }}</small>
                        <small class="col-7" [hidden]="!dataModel.form.controls.newEmail.touched || !dataModel.form.controls.newEmail.errors?.pattern">{{ 'APP.MAIN.NAVBAR.CHANGE_EMAIL_NOT_VALID' | translate }}</small>
                    </div>
                    <div class="form-group col-12">
                        <label class="col-5" for="repeatEmail">{{ 'APP.MAIN.NAVBAR.CHANGE_EMAIL_RPT_EMAIL' | translate }}</label>
                        <input
                            formControlName="repeatEmail"
                            [formGroup]="dataModel.form"
                            class="col-6 rounded-0 field form-control"
                            type="email"
                            style="font-size: 14px;"
                        />
                        <div class="col-5"></div>
                        <small class="col-7" [hidden]="!dataModel.form.controls.repeatEmail.touched || !dataModel.form.controls.repeatEmail.errors?.MatchEmail">{{ 'APP.MAIN.NAVBAR.CHANGE_EMAIL_NOT_MATCH' | translate }}</small>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.close()">
                    {{ 'APP.MAIN.NAVBAR.CHANGE_EMAIL_CLOSE_BTN' | translate }}
                </button>
                <button class="btn btn-sm btn-info px-4" [disabled]="dataModel.form.status !== 'VALID'" (click)="callbacks.change()">
                    {{ 'APP.MAIN.NAVBAR.CHANGE_EMAIL_CHANGE_BTN' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #logoutTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
      <div class="modal-content">
          <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.NAVBAR.LOGOUT_MODAL_TITLE' | translate }}</h5>
          </div>
          <div class="modal-body">
              <p *ngIf="dataModel.inRoom">{{ 'APP.MAIN.NAVBAR.LOGOUT_MODAL_MESSAGE_IN_ROOM' | translate }}</p>
              <p *ngIf="dataModel.inRoom" class="mt-2" style="color: red">{{ 'APP.MAIN.NAVBAR.CAUTION' | translate }}</p>
              <p *ngIf="!dataModel.inRoom">{{ 'APP.MAIN.NAVBAR.LOGOUT_MODAL_MESSAGE' | translate }}</p>
          </div>
          <div class="modal-footer">
              <button class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">
                  {{ 'APP.MAIN.NAVBAR.LOGOUT_MODAL_NO' | translate }}
              </button>
              <button class="btn btn-sm btn-danger px-4" (click)="callbacks.yes()">
                  {{ 'APP.MAIN.NAVBAR.LOGOUT_MODAL_YES' | translate }}
              </button>
          </div>
      </div>
  </div>
</ng-template>