<div class="sidebar-container" [ngClass]="workflowTicketClass">
    <div class="extra-container">
        <div class="d-flex action-buttons">
            <button class="open-workflow-btn btn" (click)="openWorkflow()">
                <img src="assets/img/workflow.svg" alt="">
                <span>{{ 'APP.MAIN.ROOM.SIDEBAR.OPEN_WORKFLOW' | translate }}</span>
            </button>
            <button class="attach-ticket-btn btn" (click)="onTicketModal()">
                <i class="mi mi-style"></i>
                {{ (attachedTicketId ? 'APP.MAIN.ROOM.SIDEBAR.SHOW_TICKET' : 'APP.MAIN.ROOM.SIDEBAR.ATTACH_TO_TICKET') | translate }}
            </button>
        </div>
        
        <div class="container-fluid">
            <div class="row tabs">
                <div class="px-0 text-center" [ngClass]="(currentUser.role === 'admin' || currentUser.role === 'coadmin' || currentUser.role === 'expert' || roomFilesSubscription) && filesSectionAvailable ? 'col-3': 'col-4'">
                    <div class="tab py-2" [ngClass]="{'active': tabs[0]}">
                        <a (click)="onTabClicked(0)">
                            <i class="mi mi-chat"></i>
                            <p>{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT_TITLE' | translate }}</p>
                        </a>
                    </div>
                </div>
                <div class="px-0 text-center" style="border-left: 1px solid rgba(0,0,0,.0625);" [ngClass]="(currentUser.role === 'admin' || currentUser.role === 'coadmin' || currentUser.role === 'expert' || roomFilesSubscription) && filesSectionAvailable ? 'col-3': 'col-4'">
                    <div class="tab py-2" [ngClass]="{'active': tabs[1]}">
                        <a (click)="onTabClicked(1)">
                            <i class="mi mi-supervisor-account"></i>
                            <p>{{ 'APP.MAIN.ROOM.SIDEBAR.PARTICIPANTS_TITLE' | translate }}</p>
                        </a>
                    </div>
                </div>
                <div *ngIf="(currentUser.role === 'admin' || currentUser.role === 'coadmin' || currentUser.role === 'expert' || roomFilesSubscription) && filesSectionAvailable" class="col-3 px-0 text-center" style="border-left: 1px solid rgba(0,0,0,.0625);">
                    <div class="tab py-2" [ngClass]="{'active': tabs[2]}">
                        <a (click)="onTabClicked(2)">
                            <i class="mi mi-description"></i>
                            <p>{{ 'APP.MAIN.ROOM.SIDEBAR.FILES_TITLE' | translate }}</p>
                        </a>
                    </div>
                </div>
    <!--
                <div class="col-4 px-0 text-center">
                    <div class="tab py-2" [ngClass]="{'active': tabs[1]}">
                        <a (click)="onTabClicked(1)">
                            <i class="mi mi-cloud"></i>
                            <p>{{ 'APP.MAIN.ROOM.SIDEBAR.CLOUD_TITLE' | translate }}</p>
                        </a>
                    </div>
                </div>
    -->
                <div class="px-0 text-center" style="border-left: 1px solid rgba(0,0,0,.0625);" [ngClass]="(currentUser.role === 'admin' || currentUser.role === 'coadmin' || currentUser.role === 'expert' || roomFilesSubscription) && filesSectionAvailable ? 'col-3': 'col-4'">
                    <div class="tab py-2" [ngClass]="{'active': tabs[3]}">
                        <a (click)="onTabClicked(3)">
                            <i class="mi mi-settings"></i>
                            <p>{{ 'APP.MAIN.ROOM.SIDEBAR.SETTINGS_TITLE' | translate }}</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sidebar-content">
        <app-chat (unreadMessage)="unreadNewMessage($event)" [sessionActive]="sessionActive" [ngClass]="{ 'd-inline' : tabs[0], 'd-none' : !tabs[0] }"></app-chat>
        <app-participants [ngClass]="{ 'd-inline' : tabs[1], 'd-none' : !tabs[1] }"></app-participants>
        <app-rooms-files *ngIf="(currentUser.role === 'admin' || currentUser.role === 'coadmin' || currentUser.role === 'expert' || roomFilesSubscription) && filesSectionAvailable" [ngClass]="{ 'd-inline' : tabs[2], 'd-none' : !tabs[2] }"></app-rooms-files>
        <app-settings [ngClass]="{ 'd-inline' : tabs[3], 'd-none' : !tabs[3] }"></app-settings>
    </div>
</div>



<!--
                <form *ngIf="ticket.custom">
                    <div class="form-row" [formGroup]="ticket.form">
                        <div class="form-group" *ngFor="let bcControl of ticket.form.controls | objectToArray:'ticketFormFieldName'; index as i;">
                            <button type="button" (click)="asd(bcControl);">BUTTON</button>

                            {{ bcControl }}
                            <label for="tLicenseName" style="white-space: nowrap;">License Display Name</label>
                            <input class="form-control" type="text" formControlName="name" placeholder="License Display Name"
                                [ngClass]="{'is-invalid':bcControl.controls.name.errors && bcControl.controls.name.touched}">
                            <small class="invalid-feedback mx-2" [hidden]="!bcControl.controls.name.errors?.required">Display name required</small>
                        </div>
                    </div>
                </form>
-->
<!--
                    <div class="form-row mb-3">
                        <div class="user-access col-12">
                            <div class="font-weight-bold">User Access</div>
                            <div class="ml-4 mt-2">
                                <div class="access-header d-flex justify-content-between align-items-center">
                                    <div>Room Access</div>
                                    <button type="button" class="add-btn btn btn-secondary btn-sm d-flex justify-content-between align-items-center">
                                        <i class="mi mi-add"></i>
                                    </button>
                                </div>
                                <div class="rooms-body">
                                    <div class="room p-2 mt-1 rounded border">
                                        <div class="room-header d-flex justify-content-between align-items-center">
                                            <div class="room-name ml-1">OzkanAS Demo Room</div>
                                            <button type="button" class="delete-room-btn btn btn-danger btn-sm p-0 rounded-circle d-flex justify-content-center align-items-center">
                                                <i class="mi mi-remove"></i>
                                            </button>
                                        </div>
                                        <div class="room-body">
                                            <div class="ticket-user rounded border bg-white p-1 mt-1 ml-1" *ngFor="let u of ['Özkan SARI', 'Vedat Omak', 'Cihat Kahraman']">
                                                <div class="ticket-user-can-edit custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="remember" name="remember" [(ngModel)]="asd">
                                                    <label class="custom-control-label" for="remember"></label>
                                                </div>
                                                <span class="ticket-user-name">{{ u }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ml-4 mt-2">
                                <div class="access-header d-flex justify-content-between align-items-center">
                                    <div class="ml-1">User Access</div>
                                    <button type="button" class="add-btn btn btn-secondary btn-sm d-flex justify-content-between align-items-center">
                                        <i class="mi mi-add"></i>
                                    </button>
                                </div>
                                <div class="users-body">
                                    <div class="ticket-user rounded border bg-white p-1 mt-1 ml-1" *ngFor="let u of ['Özkan SARI', 'Basri Balaban', 'İsmet Özmen', 'Hande Şirikçi']">
                                        <div class="ticket-user-can-edit custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="remember" name="remember" [(ngModel)]="asd">
                                            <label class="custom-control-label" for="remember"></label>
                                        </div>
                                        <span class="ticket-user-name">{{ u }}</span>
                                        <button type="button" class="delete-user-btn btn btn-danger btn-sm rounded-circle p-0 ml-1">
                                            <div class="d-flex justify-content-center align-items-center">
                                                <i class="mi mi-remove"></i>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
-->