<div class="archive-list-wrapper">
    <div class="archive-list-container">
        <div class="logo-container" *ngIf="('design' | env).logoText">
            <img class="logo-image" [src]="('design' | env).logo">
            <div class="logo-text ml-2">{{ ('design' | env).logoText }}</div>
        </div>
        <div class="logo-container" *ngIf="!('design' | env).logoText">
            <img class="logo-only-image" [class.wide]="('design' | env).wideLogo" [src]="('design' | env).logo">
        </div>

        <div *ngIf="loading" class="session-info text-center mb-3">{{ 'APP.EXTERNALS.ARCHIVE_LIST.LOADING' | translate }}</div>
        <ng-container *ngIf="!loading">
            <div *ngIf="!failed" class="session-info text-center mb-3">{{ 'APP.EXTERNALS.ARCHIVE_LIST.TITLE' | translate }}</div>
            <div *ngIf="failed" class="session-info text-center mb-3">{{ 'APP.EXTERNALS.ARCHIVE_LIST.LOAD_FAILED' | translate }}</div>
        </ng-container>

        <div *ngIf="!loading && !failed" class="t-container t-no-hover container-fluid">
            <div class="t-head row sm-hide">
                <div class="t-cell col-1">#</div>
                <div class="t-cell col-4">{{ 'APP.EXTERNALS.ARCHIVE_LIST.CREATED_AT' | translate }}</div>
                <div class="t-cell col-2 text-lg-center">{{ 'APP.EXTERNALS.ARCHIVE_LIST.SIZE' | translate }}</div>
                <div class="t-cell col-2 text-lg-center">{{ 'APP.EXTERNALS.ARCHIVE_LIST.STATUS' | translate }}</div>
                <div class="t-cell col-3 text-lg-center">{{ 'APP.EXTERNALS.ARCHIVE_LIST.DOWNLOAD' | translate }}</div>
            </div>

            <div class="t-row row" *ngFor="let archive of archives; index as i;">
                <div class="t-cell col-1 sm-hide">{{ i+1 }}</div>
                <div class="t-cell sm-show col-6 col-md-8">{{ 'APP.EXTERNALS.ARCHIVE_LIST.CREATED_AT' | translate }}</div>
                <div class="t-cell col-6 col-md-4 col-lg-4">{{ archive.create_time | utcCalc:timezone }} ({{ timezone }})</div>
                <div class="t-cell sm-show col-6 col-md-8">{{ 'APP.EXTERNALS.ARCHIVE_LIST.SIZE' | translate }}</div>
                <div class="t-cell col-6 col-md-4 col-lg-2 text-lg-center">{{ (archive.size ? archive.size : 0) | fileSize }}</div>
                <div class="t-cell sm-show col-6 col-md-8">{{ 'APP.EXTERNALS.ARCHIVE_LIST.STATUS' | translate }}</div>
                <div class="t-cell col-6 col-md-4 col-lg-2 text-lg-center"><span class="badge" style="font-size: 10px;"[ngClass]="getBadgeClass(archive)">{{ getArchiveStatusKey(archive) | translate }}</span></div>
                <div class="t-cell sm-show col-6 col-md-8">{{ 'APP.EXTERNALS.ARCHIVE_LIST.DOWNLOAD' | translate }}</div>
                <div class="t-cell col-6 col-md-4 col-lg-3 text-lg-center">
                    <div class="btn-group" dropdown (onShown)="getArchiveDownloadUrl(archive)">
                        <button [id]="'dropdown-button-'+i" dropdownToggle type="button" class="btn btn-sm btn-info dropdown-toggle" [attr.aria-controls]="'dropdown-'+i">
                            <i class="mi mi-cloud-download" style="font-size: 16px;"></i><span>{{ 'APP.EXTERNALS.ARCHIVE_LIST.DOWNLOAD' | translate }}</span>
                        </button>
                        <ul [id]="'dropdown-'+i" *dropdownMenu class="dropdown-menu dropdown-menu-right py-1" role="menu" [attr.aria-labelledby]="'dropdown-button-'+i">
                            <li *ngIf="downloadLinks[archive.id]?.loading" role="menuitem">
                                <a class="dropdown-item" style="font-size: 13px;">{{ 'APP.EXTERNALS.ARCHIVE_LIST.GENERATING' | translate }}</a>
                            </li>
                            <li *ngIf="downloadLinks[archive.id]?.url" role="menuitem">
                                <a class="dropdown-item" style="font-size: 13px;" [href]="downloadLinks[archive.id].url" download="record.mp4" target="_blank">{{ 'APP.EXTERNALS.ARCHIVE_LIST.CLICK_TO_DOWNLOAD' | translate }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
