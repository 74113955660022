<div class="frame-box-wrapper">
  <div *ngIf="frame; else noCollaboration" (resized)="onResized($event)" [ngSwitch]="frame.type" class="frame-box">
    <div [addNativeElement]="frame" [ngStyle]="frame.style2" class="frame-box-inner">
      <app-image-collaboration-wrapper *ngSwitchCase="'image'" [frame]="frame" [size]="frame.size" [external]="true" [nohover]="true"></app-image-collaboration-wrapper>
      <app-image-collaboration-wrapper *ngSwitchCase="'screenshot'" [frame]="frame" [size]="frame.size" [external]="true" [nohover]="true"></app-image-collaboration-wrapper>
      <app-pdf-collaboration-wrapper *ngSwitchCase="'pdf'" [frame]="frame" [size]="frame.size" [external]="true" [nohover]="true"></app-pdf-collaboration-wrapper>
    </div>
  </div>
</div>

<ng-template #noCollaboration>
  <div class="d-flex justify-content-center align-items-center h-100">
    <div class="rounded-circle d-flex align-items-center" style="width: 200px; height: 200px;"><span class="text-center w-100"></span></div>
  </div>
</ng-template>