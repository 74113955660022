import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { RoomSessionService } from './room-session.service';

import { Observable, BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { LogMessage } from '@models/LogMessage';
import { Collaboration } from '@models/Collaboration';
import { UserFile } from '@models/UserFile';
import { UserObject } from '@models/UserObject';
import { JoinRoomResponse } from '@models/JoinRoomResponse';
import { HttpClient, HttpHeaders } from '@angular/common/http';











import { MultilanguageService } from '@services/support/multilanguage.service';
import { DbService } from './db.service';
import { User } from '@models/User';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  private logsPath: string = null;
  private logListenTime: number = 0;

  logMessages: Observable<{type: "added" | "changed", message: LogMessage, messages: LogMessage[]}>;
  private messages: LogMessage[] = [];
  private logMessagesSource = new BehaviorSubject<{type: "added" | "changed", message: LogMessage, messages: LogMessage[]}>(null);
  private translationTarget: string = "en";
  private translationTarget2: string = "en";

  onSreenshotRequest: Observable<LogMessage>;
  private onSreenshotRequestSource = new Subject<LogMessage>();

  onReactionRequest: Observable<LogMessage>;
  private onReactionRequestSource = new Subject<LogMessage>();

  features: { [key: string]: boolean } = {
    imagecollaboration: false,
    pdfcollaboration: false,
    threedcollaboration: false
  };
  featuresSub: Subscription = null;

  logAddedSub: Subscription = null;
  logChangedSub: Subscription = null;

  constructor(
    private authService: AuthService,
    private roomSessionService: RoomSessionService,
    private dbService: DbService,
    private http: HttpClient,
    private multilanguageService: MultilanguageService
  ) {
    this.logMessages = this.logMessagesSource.asObservable().pipe(filter(d => d !== null));
    this.onSreenshotRequest = this.onSreenshotRequestSource.asObservable();
    this.onReactionRequest = this.onReactionRequestSource.asObservable();

    this.authService.on("externalLogin", this.onExternalLogin);

    this.roomSessionService.on("sessionCreated", this.onSessionCreated);
    this.roomSessionService.on("sessionDestroyed", this.onSessionDestroyed);
    this.multilanguageService.translationLanguage.subscribe(languageCode => {
      this.translationTarget = languageCode;
    });
    this.multilanguageService.translationLanguage2.subscribe(languageCode => {
      this.translationTarget2 = languageCode;
    });
  }

  onExternalLogin = (user: User, params: any) => {
    const sessionPath = 'accounts/'+params.account_id+'/sessions/'+params.room_id+'/'+params.session_id;
    this.logsPath = sessionPath + '/logs';
  }

  private onSessionCreated = (joinResponse: JoinRoomResponse, sessionPath: string) => {
    this.logsPath = sessionPath + '/logs';

    /*
    this.logsRef = this.afdb.list<LogMessage>(path);
    this.fbLogsRef = this.afdb.database.ref(path);
    */
    if (this.featuresSub) { this.featuresSub.unsubscribe() }
    this.featuresSub = this.authService.features.subscribe(features => {
      if (this.messages) {
        if (this.features.imagecollaboration !== features.imagecollaboration) {
          this.messages.filter(m => m.type === 'file' &&  (m.type2 === 'image' || m.type2 === 'screenshot')).forEach(m => {
            m.details.coll_unavailable = !features.imagecollaboration;
          });
        }
        if (this.features.pdfcollaboration !== features.pdfcollaboration) {
          this.messages.filter(m => m.type === 'file' &&  m.type2 === 'pdf').forEach(m => {
            m.details.coll_unavailable = !features.pdfcollaboration;
          });
        }
        if (this.features.threedcollaboration !== features.threedcollaboration) {
          this.messages.filter(m => m.type === 'object').forEach(m => {
            m.details.coll_unavailable = !features.threedcollaboration;
          });
        }
      }
      this.features = features;
    });

    this.messages = [];

    this.logListenTime = Date.now();

    this.logAddedSub = this.dbService.listenSnap<LogMessage>(this.logsPath, 'child_added')
    .subscribe(logSnap => this.onLogAdded(logSnap.data, logSnap.key));
    this.logChangedSub = this.dbService.listenSnap<LogMessage>(this.logsPath, 'child_changed')
    .subscribe(logSnap => this.onLogChanged(logSnap.data, logSnap.key));

    /*
    this.fbLogsRef.on("child_added", this.onLogAdded);
    this.fbLogsRef.on("child_changed", this.onLogChanged);
    */
  }

  private onSessionDestroyed = () => {
    if (this.featuresSub) {
      this.featuresSub.unsubscribe()
      this.featuresSub = null;
      this.features = {
        pdfcollaboration: false,
        threedcollaboration: false
      }
    }
    this.messages = null;
    this.logMessagesSource.next(null);

    if (this.logAddedSub) { this.logAddedSub.unsubscribe() }
    if (this.logChangedSub) { this.logChangedSub.unsubscribe() }
  }

  private onLogAdded = (message: LogMessage, key: string) => {
    const prevMessage = this.messages[this.messages.length-1];
    message.id = key;

    message.details = {};
    message.details.coll_unavailable = ((message.type === 'file' && ((message.type2 === 'pdf' && !this.features.pdfcollaboration)
    || ((message.type2 === 'image' || message.type2 === 'screenshot') && !this.features.imagecollaboration)))
    || (message.type === 'object' && !this.features.threedcollaboration));
    message.details.own_message = message.owner === this.authService.currentUser.id;
    message.details.translation_key = this.getMessageTranslationKey(message);
    message.details.has_file = this.messageHasFile(message);
    if (message.details.has_file) {
      message.details.extension = message.extra.split('.').pop().toLowerCase();
    }
    message.details.collaboratable = (message.type === 'file' ? message.type2 !== 'other' : false)
    message.details.show_owner = message.type === 'text';
    message.translation = {};
    message.translation.has_link = message.type === 'text' && !message.details.own_message;
    if (this.messages.length > 0) {
      if (message.details.show_owner) {
        message.details.show_owner = !(prevMessage.type === 'text' && prevMessage.owner === message.owner);
      }
      if (prevMessage.translation.has_link) {
        prevMessage.translation.has_link = !(message.type === 'text' && prevMessage.owner === message.owner);
      }
    }

    this.messages.push(message);
    this.logMessagesSource.next({type: "added", message: message, messages: this.messages});

    if (message.type === 'request') {
      if (message.type2 === 'reaction') {
        if (this.logListenTime < Date.now() - 2000) {
          this.onReactionRequestSource.next(message);
        }
      } else if (message.type2 === 'screenshot' && message.content === this.authService.currentUser.id) {
        this.onSreenshotRequestSource.next(message);
      }
    }
  }

  private onLogChanged = (message: LogMessage, key: string) => {
    message.id = key;

    const index = this.messages.findIndex(el => el.id === message.id)
    if (index > -1) {
      message.details = this.messages[index].details;
      message.translation = this.messages[index].translation;
      this.messages[index] = message;
      this.logMessagesSource.next({type: "changed", message: message, messages: this.messages});
    }
  }

  private getMessageTranslationKey(message: LogMessage) {
    switch (message.type) {
      case "info":
        if (message.type2 === "joinRoom") {
          return "APP.MAIN.ROOM.SIDEBAR.CHAT.JOIN_ROOM";
        } else if (message.type2 === "leaveRoom") {
          return "APP.MAIN.ROOM.SIDEBAR.CHAT.LEAVE_ROOM";
        }
        break;
      case "request":
        if (message.type2 === "screenshot") {
          return "APP.MAIN.ROOM.SIDEBAR.CHAT.REQUEST_SCREENSHOT";
        } else if (message.type2 === "reaction") {
          return "APP.MAIN.ROOM.SIDEBAR.CHAT.REACTION";
        }
        break;
      case "file":
        if (message.type2 === "image") {
          return "APP.MAIN.ROOM.SIDEBAR.CHAT.SHARE_IMAGE";
        } else if (message.type2 === "screenshot") {
          if (message.saved_collaboration) {
            return "APP.MAIN.ROOM.SIDEBAR.CHAT.SAVED_COLLABORATION";
          } else {
            return "APP.MAIN.ROOM.SIDEBAR.CHAT.SHARE_SCREENSHOT";
          }
        } else if (message.type2 === "pdf") {
          return "APP.MAIN.ROOM.SIDEBAR.CHAT.SHARE_PDF";
        } else if (message.type2 === "other") {
          return "APP.MAIN.ROOM.SIDEBAR.CHAT.SHARE_OTHER";
        }
        break;
      case "object":
        return "APP.MAIN.ROOM.SIDEBAR.CHAT.SHARE_OBJECT";
      case "collaboration":
        return "APP.MAIN.ROOM.SIDEBAR.CHAT.COLLABORATED";
    }
  }

  private messageHasFile(message: LogMessage) {
    if (message.type === "file" || message.type === "object") {
      return true;
    } else {
      return false;
    }
  }

  async seeTranslation(index: number, message: LogMessage) {
    const target: string = this.translationTarget;
    const translated: {index: number, text: string}[] = [];
    const notTranslated: {index: number, text: string}[] = [];

    try {
      this.messages[index].translation.loading = true;

      for (let i = index; i > 0; i--) {
        if (this.messages[i].translation.target === target) {
          translated.push({index: i, text: this.messages[i].content})
        } else {
          notTranslated.push({index: i, text: this.messages[i].content})
        }
  
        if (this.messages[i-1].type !== 'text' || message.owner !== this.messages[i-1].owner) {
          break;
        }
      }
      if (notTranslated.length > 0) {
        const response = await this.translate(notTranslated.map(t => t.text), target);
        if (response && response.result) {
          for (let i = 0; i < notTranslated.length; i++) {
            this.messages[notTranslated[i].index].translation.text = response.result[i];
            this.messages[notTranslated[i].index].translation.target = target;
            this.messages[notTranslated[i].index].translation.visible = true;
          }
        }
      }
      if (translated.length > 0) {
        for (const t of translated) {
          this.messages[t.index].translation.visible = true;
        }
      }
      this.messages[index].translation.loading = false;
    } catch(error) {
      this.messages[index].translation.loading = false;
    }
  }

  translateMessage(message: string) {
    return this.translate([message], this.translationTarget2);
  }

  seeOriginal(index: number, message: LogMessage) {
    for (let i = index; i > 0; i--) {
      this.messages[i].translation.visible = false;
      if (this.messages[i-1].type !== 'text' || message.owner !== this.messages[i-1].owner) {
        break;
      }
    }
  }

  translate(texts: string[], target: string): Promise<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type':  'application/json' }) };

    return this.http.post(this.dbService.getEndPoint("translate"), {
      texts: texts,
      target: target,
      token: this.authService.currentUser.token
    }, httpOptions).pipe(first()).toPromise();
  }

  sendMessage(chatMessage: string, translatedMessage: string = null) {
    const message: any = {
      owner: this.authService.currentUser.id,
      owner_info: this.authService.currentUser.name,
      create_time: this.dbService.timestamp(),
      type: "text"
    }
    if (translatedMessage) {
      message.content = translatedMessage;
      message.original_content = chatMessage;
    } else {
      message.content = chatMessage;
    }
    this.dbService.push(this.logsPath, message);
  }

  sendShareFileLog(file: UserFile) {
    const log: LogMessage = {
      owner: this.authService.currentUser.id,
      owner_info: this.authService.currentUser.name,
      create_time: this.dbService.timestamp(),
      type: "file",
      type2: file.file_type,
      url: file.url,
      content: file.key,
      extra: file.name
    };
    this.dbService.push(this.logsPath, log);
  }

  sendShareScreenshotLog(file: UserFile, isSavedCollaboration: boolean = false) {
    const log: LogMessage = {
      owner: this.authService.currentUser.id,
      owner_info: this.authService.currentUser.name,
      create_time: this.dbService.timestamp(),
      type: "file",
      type2: "screenshot",
      url: file.url,
      content: file.key,
      extra: file.name,
      saved_collaboration: isSavedCollaboration
    };
    this.dbService.push(this.logsPath, log);
  }

  sendShareObjectLog(object: UserObject) {
    const log: LogMessage = {
      owner: this.authService.currentUser.id,
      owner_info: this.authService.currentUser.name,
      create_time: this.dbService.timestamp(),
      type: "object",
      type2: "object",
      content: object.key,
      extra: object.name
    };
    this.dbService.push(this.logsPath, log);
  }

  sendCollaborationLog(collaboration: Collaboration) {
    const log: LogMessage = {
      content: collaboration.collaboration_data.id,
      owner: this.authService.currentUser.id,
      owner_info: this.authService.currentUser.name,
      create_time: this.dbService.timestamp(),
      type: "collaboration",
      type2: collaboration.collaboration_data.type,
      key: collaboration.collaboration_data.key
    }
    if (collaboration.collaboration_data.url) {
      log.url = collaboration.collaboration_data.url;
    }
    this.dbService.push(this.logsPath, log);
  }

  sendScreenshotRequestLog(uid: string, name: string) {
    const log = {
      content: uid,
      owner: this.authService.currentUser.id,
      owner_info: this.authService.currentUser.name,
      create_time: this.dbService.timestamp(),
      type: "request",
      type2: "screenshot",
      extra: name
    }
    this.dbService.push(this.logsPath, log);
  }

  sendReactionLog(reaction: string) {
    this.dbService.push(this.logsPath, {
      content: reaction,
      owner: this.authService.currentUser.id,
      owner_info: this.authService.currentUser.name,
      create_time: this.dbService.timestamp(),
      type: "request",
      type2: "reaction"
    });
  }
}
