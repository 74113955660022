<div class="whatsnew-wrapper container-fluid py-5">
    <div class="row justify-content-center">
        <div class="col-xl-9 col-lg-10 col-md-11 col-12">
            <div class="whatsnew-header text-muted d-flex align-items-center justify-content-between">
                <span class="py-2">{{ 'APP.MAIN.LOBBY.WHATSNEW.WHATSNEW_TITLE' | translate }}</span>
            </div>
            <div class="whatsnew-inside container-fluid bg-white mt-1 pt-4 px-4 px-sm-5" style="background-color: white;">
                <div class="d-flex justify-content-center pt-3" id="whatsnew-modal" #feedbackContainer></div>
                
            </div>
        </div>
    </div>
</div>