<div class="subscriber-wrapper" [class.frame-pinned]="frame.pinned || isTrainingMaster" [class.small]="size === 'small' && !fullscreenState.fullscreen" #subscriberTutorial="tutorial" tutorial="subscriber">
    <div class="subscriber-container" #subscriberContainer (mouseup)="onDocumentMouseUp($event)" (mousedown)="onDocumentMouseDown($event)" (mouseleave)="onDocumentMouseLeave($event)"
        (touchstart)="onDocumentTouchStart($event)" (touchend)="onDocumentTouchEnd($event)"></div>


    <div class="audio-level-indicator" [ngStyle]="audioLevelIndicatorStyle"></div>

    <a *ngIf="indicatorOn" class="video-disabled-indicator">
        <i class="mi mi-videocam-off"></i>
        <div class="disabled-messages">
            <div *ngIf="audioOnly" class="audio-only">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.AUDIO_ONLY_MODE' | translate }}</div>
        </div>
    </a>

    <button *ngIf="!trainingDisable && (arCollaborationOpen || arPlusOpen)" class="close-ar-btn btn" (click)="endAr()" title="Close"><i class="mi mi-close"></i></button>

    <div class="battery-status-wrapper" [class.charging]="deviceStatus.battery.charging" [class.low-battery]="!deviceStatus.battery.charging && (deviceStatus.battery.level < 21)"
            *ngIf="features.batterystatus && deviceStatus && deviceStatus.battery && deviceStatus.battery.available && !arCollaborationActive && frame.stream.videoType === 'camera'">
        <div class="battery-status">
            <span class="battery-percent">{{ '%' + deviceStatus.battery.level }}</span><img class="battery-icon" [src]="(deviceStatus.battery.charging ? 'assets/img/battery-charging.png' : getBatteryImageName(deviceStatus.battery.level))">
        </div>
        <div class="battery-message mt-1 justify-content-center">
            <span class="battery-charging">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.CHARGING' | translate }}</span>
            <span class="battery-low">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.LOW_BATTERY' | translate }}</span>
        </div>
    </div>

    <div class="top-controls" [ngClass]="{'nohover': nohover}">
        <div class="screenshot-wrapper mr-2" #snapshotTutorial="tutorial" tutorial="snapshot" [ignored]="!subscriberData || frame.stream.videoType === 'screen' || !videoAvailable"
                #snapshotPopover="bs-popover" [popover]="takeSnapshotTmp" triggers="" placement="bottom" container="body" containerClass="tutorial-popover"
                [dynamicTooltip]="'APP.SHARED.FEATURE_UNAVAILABLE'" [tooltipDisabled]="features.snapshot" [tooltipDelay]="200" [tooltipPosition]="'bottom'"
                (change)="$event ? snapshotPopover.show() : snapshotPopover.hide()">
            <a class="screenshot-request" (click)="onScreenshotRequest(); (features.imagecollaboration ? snapshotTutorial.next({collaborate: true, subscriber: true, openChat: true}) : snapshotTutorial.next({showTutorial: true}))"
                    data-placement="bottom" [dynamicTooltip]="'APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.SNAPSHOT'" [tooltipDisabled]="nohover"
                    [fakeDisabled]="!features.snapshot || !subscriberData || this.hdPhotoOpen || (arPlusOpen && (subscriberData?.platform === 'android' || subscriberData?.platform === 'android-glasses') && !androidArSnapshotAvailable) || frame.stream.videoType === 'screen' || !videoAvailable || trainingDisable">
                <i class="mi mi-add-a-photo"></i>
            </a>
        </div>
        <div class="screenshot-wrapper mr-2">
            <a class="screenshot-request" placement="bottom" (click)="onToggleHdPhoto();" [style.color]="hdPhotoOpen ? '#'+arColorCode : ''"
                    [fakeDisabled]="!hdPhotoOpen && !(deviceStatus?.photo?.available && subscriberData && !arPlusOpen && frame.stream.videoType !== 'screen' && videoAvailable && subscribeVideo && videoCanAvailable && !trainingDisable)">
                <i class="mi mi-add-a-photo"></i>
            </a>
        </div>
        <div class="ar-wrapper mr-2" #arCollTutorial="tutorial" tutorial="arColl" [ignored]="arkitAvailable || !subscriberData || frame.stream.videoType === 'screen' || !videoAvailable || !subscribeVideo || !videoCanAvailable"
                #arCollPopover="bs-popover" [popover]="openArTmp" triggers="" placement="bottom" container="body" containerClass="tutorial-popover" (change)="$event ? arCollPopover.show() : arCollPopover.hide()"
                [dynamicTooltip]="'APP.SHARED.FEATURE_UNAVAILABLE'" [tooltipDisabled]="features.arcollaboration" [tooltipDelay]="200" [tooltipPosition]="'bottom'">
            <a class="ar-collaboration" placement="bottom" [dynamicTooltip]="'APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.AR_PLUS'" [tooltipDisabled]="nohover" [style.color]="arCollaborationOpen ? '#'+arColorCode : ''"
                    [fakeDisabled]="!features.arcollaboration || !subscriberData || hdPhotoOpen || frame.stream.videoType === 'screen' || !videoAvailable || !subscribeVideo || !videoCanAvailable || arPlusOpen || trainingDisable"
                    (click)="onToggleArCollaboration(); arCollTutorial.next({makeArColl: true, noArPlus: true, subscriber: true})">
                <i class="mi mi-touch-app"></i>
            </a>
        </div>
        <div class="ar-plus-wrapper" #arPlusTutorial="tutorial" tutorial="arPlus" [ignored]="!arkitAvailable || !subscriberData || frame.stream.videoType === 'screen' || !videoAvailable || !subscribeVideo || !videoCanAvailable"
                #arPlusPopover="bs-popover" [popover]="openArPlusTmp" triggers="" placement="bottom" container="body" containerClass="tutorial-popover" (change)="$event ? arPlusPopover.show() : arPlusPopover.hide()"
                [dynamicTooltip]="'APP.SHARED.FEATURE_UNAVAILABLE'" [tooltipDisabled]="features.arplus" [tooltipDelay]="200" [tooltipPosition]="'bottom'">
            <a class="ar-plus" placement="bottom" [dynamicTooltip]="'APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.AR_PLUS'" [tooltipDisabled]="nohover" [style.color]="arPlusOpen ? '#'+arColorCode : ''"
                    [fakeDisabled]="!this.arPlusOpen && !(features.arplus && deviceStatus?.arkit?.available && subscriberData && !hdPhotoOpen && frame.stream.videoType !== 'screen' && videoAvailable && subscribeVideo && videoCanAvailable && !trainingDisable)"
                    (click)="onToggleArPlus(); arPlusTutorial.next({makeArPlus: true, noArPlus: true, subscriber: true})">
                <span class="ar">AR</span>
                <span class="plus">+</span>
            </a>
        </div>
    </div>

    <div class="top-controls refresh-surface" *ngIf="showPlaneSurfacesButton && arPlusOpen">
        <div class="refresh-surfaces-wrapper">
            <a class="refresh-surfaces" (click)="onRefreshSurfacesButtonClicked();">
                <div class="d-flex align-items-center">
                    <i class="mi mi-autorenew mr-1"></i>
                    <span class="mr-1">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.REFRESH_SURFACES' | translate }}</span>
                </div>
            </a>
        </div>
    </div>

    <div class="left-controls" [ngClass]="{'nohover': nohover}" *ngIf="cameraControls && !arCollaborationActive && frame.stream.videoType === 'camera' && videoAvailable && subscribeVideo"
            [dynamicTooltip]="'APP.SHARED.FEATURE_UNAVAILABLE'" [tooltipDisabled]="features.cameracontrols || nohover" [tooltipDelay]="200">
        <a *ngIf="cameraControls.flash.available && !isLocked" class="flash-control" [class.disabled]="!(features.cameracontrols && cameraControls.flash.available && !this.trainingDisable)" (click)="onToggleFlash()" placement="right"
                [dynamicTooltip]="'APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.FLASH'">
            <img [src]="cameraControls.flash.status === 'on' ? 'assets/flash-on.svg' : 'assets/flash-off.svg'" draggable="false" ondragstart="return false;">
        </a>
        <div *ngIf="cameraControls.zoom.available" class="zoom-control" [class.disabled]="!(features.cameracontrols && cameraControls.zoom.available && !this.trainingDisable)">
            <a class="zoom-in d-flex justify-content-center align-items-center" title="Zoom In" (click)="onZoomIn()" ><i class="mi mi-add"></i></a>
            <span class="my-2">{{ cameraControls.zoom.status + 1 }}X</span>
            <a class="zoom-out d-flex justify-content-center align-items-center" title="Zoom Out" (click)="onZoomOut()" ><i class="mi mi-remove"></i></a>
        </div>
    </div>

    <div *ngIf="objSelected" class="select-obj-controls" [ngClass]="{'nohover': nohover}">
        <div class="scale-menu-wrapper d-flex align-items-center flex-row mr-2">
            <a class="scale-button d-flex justify-content-center align-items-center" style="cursor: pointer;" title="Scale Down" (click)="scaleDown();" ><i class="mi mi-remove"></i></a>
            <span class="scale-percent mx-2">{{ currentScale }}%</span>
            <a class="scale-button d-flex justify-content-center align-items-center" style="cursor: pointer;" title="Scale Up" (click)="scaleUp();"><i class="mi mi-add"></i></a>
        </div>
        <div class="delete-wrapper">
            <a class="delete-button btn btn-sm" (click)="onDelete();">DELETE</a>
        </div>
        <div class="close-wrapper">
            <a class="close-button bg-white" (click)="onClose();"><i class="mi mi-cancel text-secondary"></i></a>
        </div>
    </div>

    <div class="right-controls" *ngIf="arPlusOpen && !isLocked && subscribeVideo && videoAvailable" [ngClass]="{'nohover': nohover}">
        <div class="ar-plus-controls2">
            <a class="d-flex justify-content-center align-items-center" title="Clear" [fakeDisabled]="trainingDisable" (enabledClick)="onArPlusClear()" (disabledClick)="showARPlusDisableReason()"><i class="mi mi-delete"></i></a>
            <a class="d-flex justify-content-center align-items-center" title="Undo" [fakeDisabled]="trainingDisable" (enabledClick)="onArPlusUndo()" (disabledClick)="showARPlusDisableReason()"><i class="mi mi-undo"></i></a>
            <a class="d-flex justify-content-center align-items-center" title="Redo" [fakeDisabled]="trainingDisable" (enabledClick)="onArPlusRedo()" (disabledClick)="showARPlusDisableReason()"><i class="mi mi-redo"></i></a>
        </div>
    </div>

    <div class="center-info" *ngIf="cameraControls && !arCollaborationActive">
        <div class="zoom-message" *ngIf="showZoom">{{ cameraControls.zoom.status + 1 }}X</div>
        <div class="flash-message" *ngIf="showFlash">{{ cameraControls.flash.status === 'on' ? 'FLASH ON' : 'FLASH OFF' }}</div>
    </div>

    <div class="ar-coll-controls">
        <div #arCollBtnGroup="animatedBtnGroup" animatedBtnGroup class="animated-btn-group" [ngClass]="{'show': arCollaborationOpen}">
            <div class="group-wrapper">
                <div class="group-list">
                    <button animatedGroupBtn="colors" [active]="arColorCode === color" (onActivated)="onChangeArColor(color)" *ngFor="let color of getArColorCodeArray()" class="btn group-btn">
                        <i [ngStyle]="{'color': '#'+color}" class="mi mi-lens ar-coll-color"></i>
                    </button>
                </div>
                <button animatedGroupToggle="colors" class="btn group-toggle"><i [ngStyle]="{'color': '#'+arColorCode}" class="mi mi-color-lens"></i></button>
            </div>
            <div class="group-wrapper">
                <div class="group-list">
                    <button animatedGroupBtn="shapes" [active]="arShape === 'disc'" (onActivated)="arShape = 'disc'" class="btn group-btn">
                        <img class="ar-coll-img" [src]="arShape === 'disc' ? 'assets/arplus/disc-'+arColor+'.svg' : 'assets/arplus/disc-white.svg'"></button>
                    <button animatedGroupBtn="shapes" [active]="arShape === 'hand'" (onActivated)="arShape = 'hand'" class="btn group-btn">
                        <img class="ar-coll-img" [src]="arShape === 'hand' ? 'assets/arplus/hand-'+arColor+'.svg' : 'assets/arplus/hand-white.svg'"></button>
                    <button animatedGroupBtn="shapes" [active]="arShape === 'arrow'" (onActivated)="arShape = 'arrow'" class="btn group-btn">
                        <img class="ar-coll-img" [src]="arShape === 'arrow' ? 'assets/arplus/arrow-'+arColor+'.svg' : 'assets/arplus/arrow-white.svg'"></button>
                </div>
                <button animatedGroupToggle="shapes" class="btn group-toggle"><i class="mi mi-widgets"></i></button>
            </div>
        </div>
    </div>
    <div class="ar-plus-controls">
        <div #arPlusBtnGroup="animatedBtnGroup" animatedBtnGroup class="animated-btn-group" [ngClass]="{'show': (videoAvailable && arPlusOpen && subscribeVideo && !trainingDisable && !objSelected)}">
            <div class="group-wrapper">
                <div class="group-list">
                    <button animatedGroupBtn="colors" [active]="arColorCode === color" (onActivated)="onChangeArColor(color)" *ngFor="let color of getArColorCodeArray()" class="btn group-btn">
                        <i [ngStyle]="{'color': '#'+color}" class="mi mi-lens ar-plus-color"></i>
                    </button>
                </div>
                <button animatedGroupToggle="colors" [groupToggleDisabled]="isLocked" (click)="isLocked && onLockedButtonClick()" class="btn group-toggle"><i [ngStyle]="{'color': '#'+arColorCode}" class="mi mi-color-lens"></i></button>
            </div>

            <div class="group-wrapper">
                <div class="group-list">
                    <button animatedGroupBtn="weights" [active]="arPlusWeight === 'thin'" class="btn group-btn" (onActivated)="arPlusWeight = 'thin'">
                        <div class="ar-plus-weight" [ngStyle]="{'background-color': (arPlusWeight === 'thin' ? '#'+arColorCode : '#ffffff')}" style="height: 2px;"></div></button>
                    <button animatedGroupBtn="weights" [active]="arPlusWeight === 'normal'" class="btn group-btn" (onActivated)="arPlusWeight = 'normal'">
                        <div class="ar-plus-weight" [ngStyle]="{'background-color': (arPlusWeight === 'normal' ? '#'+arColorCode : '#ffffff')}" style="height: 4px;"></div></button>
                    <button animatedGroupBtn="weights" [active]="arPlusWeight === 'thick'" class="btn group-btn" (onActivated)="arPlusWeight = 'thick'">
                        <div class="ar-plus-weight" [ngStyle]="{'background-color': (arPlusWeight === 'thick' ? '#'+arColorCode : '#ffffff')}" style="height: 6px;"></div></button>
                </div>
                <button animatedGroupToggle="weights" [groupToggleDisabled]="isLocked" (click)="isLocked && onLockedButtonClick()" class="btn group-toggle"><i class="mi mi-line-weight"></i></button>
            </div>

            <div *ngIf="!showPlaneSurfacesButton" class="group-wrapper ml-4">
                <button #line="animatedGroupToggle" [animatedGroupToggle]="'line'" [groupToggleDisabled]="isLocked" (click)="isLocked && onLockedButtonClick()" [activeGroup]="'drawType'" [active]="arPlusDrawType === 'line'" (onActivated)="arPlusDrawType = 'line'" class="btn group-toggle">
                    <i class="mi mi-gesture" [ngStyle]="{'color': (arPlusDrawType === 'line' ? '#'+arColorCode : '#ffffff')}"></i>
                </button>
            </div>

            <div class="group-wrapper" [ngClass]="{'ml-4': showPlaneSurfacesButton}">
                <div class="group-list">
                    <button #down="animatedGroupBtn" [animatedGroupBtn]="'arrows'" (onActivated)="onChangeArPlusArrowType('arrow-down')" [activeGroup]="'drawType'" class="btn group-btn">
                        <i class="mi mi-arrow-downward" [ngStyle]="{'color': ((arPlusDrawType === 'arrow' && arPlusArrowType === 'arrow-down') ? '#'+arColorCode : '#ffffff')}"></i></button>
                    <button #up="animatedGroupBtn" [animatedGroupBtn]="'arrows'" (onActivated)="onChangeArPlusArrowType('arrow-up')" [activeGroup]="'drawType'" class="btn group-btn">
                        <i class="mi mi-arrow-upward" [ngStyle]="{'color': ((arPlusDrawType === 'arrow' && arPlusArrowType === 'arrow-up') ? '#'+arColorCode : '#ffffff')}"></i></button>
                    <button #right="animatedGroupBtn" [animatedGroupBtn]="'arrows'" (onActivated)="onChangeArPlusArrowType('arrow-right')" [activeGroup]="'drawType'" class="btn group-btn">
                        <i class="mi mi-arrow-forward" [ngStyle]="{'color': ((arPlusDrawType === 'arrow' && arPlusArrowType === 'arrow-right') ? '#'+arColorCode : '#ffffff')}"></i></button>
                    <button #left="animatedGroupBtn" [animatedGroupBtn]="'arrows'" (onActivated)="onChangeArPlusArrowType('arrow-left')" [activeGroup]="'drawType'" class="btn group-btn">
                        <i class="mi mi-arrow-back" [ngStyle]="{'color': ((arPlusDrawType === 'arrow' && arPlusArrowType === 'arrow-left') ? '#'+arColorCode : '#ffffff')}"></i></button>
                </div>
                <button animatedGroupToggle="arrows" [groupToggleDisabled]="isLocked" (click)="isLocked && onLockedButtonClick()" class="btn group-toggle">
                    <i class="mi ar-plus-arrow-toggle" [ngClass]="{'mi-arrow-downward': arPlusArrowType === 'arrow-down', 'mi-arrow-upward': arPlusArrowType === 'arrow-up', 'mi-arrow-forward': arPlusArrowType === 'arrow-right', 'mi-arrow-back': arPlusArrowType === 'arrow-left'}"
                            [ngStyle]="{'color': (arPlusDrawType === 'arrow' ? '#'+arColorCode : '#ffffff')}"></i>
                </button>
            </div>

            <div *ngIf="!showPlaneSurfacesButton && arTextAvailable" class="group-wrapper" [dynamicTooltip]="'APP.SHARED.APP_UPDATE_NEEDED'" [tooltipDisabled]="arTextAvailable" [tooltipDelay]="500" [tooltipPosition]="'top'">
                <div class="group-list group-list-wide">
                    <div class="ar-text-group d-flex justify-content-between align-items-center" *ngFor="let at of arTextList; index as i;" animatedGroupBtn="artextgroup" [active]="currentArText === at.content"
                            (onActivated)="currentArText = at.content; arPlusDrawType = 'artext'; at.active = true;" (onDeactivated)="at.active = false;" [activeGroup]="'drawType'" style="cursor: pointer;">
                        <span class="ar-text-item" [ngStyle]="{'color': (at.active ? '#'+arColorCode : '#ffffff')}">{{ at.content }}</span>
                        <a class="ar-text-button" style="background-color: rgb(220, 53, 69, 0.6);" (click)="onArTextDelete($event, i)">
                            <span class="mi mi-remove" style="font-size: 16px;"></span>
                        </a>
                    </div>
                    <form class="ar-text-group d-flex justify-content-between align-items-center" (ngSubmit)="onArTextAdd(arTextInput)">
                        <input #arTextInput class="ar-text-item" type="text" placeholder="Add text">
                        <a class="ar-text-button" style="background-color: rgb(40, 167, 69, 0.6);" (click)="onArTextAdd(arTextInput)">
                            <span class="mi mi-add" style="font-size: 16px;"></span>
                        </a>
                    </form>
                </div>
                <button animatedGroupToggle="artextgroup" [groupToggleDisabled]="isLocked || !arTextAvailable" (click)="isLocked && onLockedButtonClick()" class="btn group-toggle">
                    <i class="mi mi-title" [ngStyle]="{'color': (arPlusDrawType === 'artext' ? '#'+arColorCode : '#ffffff')}"></i>
                </button>
            </div>

            <div class="group-wrapper" *ngIf="objects.length > 0">
                <div class="group-list group-list-wide">
                    <button class="btn group-btn custom-object-btn" *ngFor="let obj of objects" animatedGroupBtn="customs" [active]="customObject?.key === obj.key"
                            (onActivated)="customObject = obj; arPlusDrawType = 'custom'; obj.active = true;" (onDeactivated)="obj.active = false;" [activeGroup]="'drawType'">
                        <span [ngStyle]="{'color': (obj.active ? '#'+arColorCode : '#ffffff')}">{{ obj.name }}</span></button>
                </div>
                <button animatedGroupToggle="customs" [groupToggleDisabled]="isLocked" (click)="isLocked && onLockedButtonClick()" class="btn group-toggle">
                    <i class="mi mi-nature-people" [ngStyle]="{'color': (arPlusDrawType === 'custom' ? '#'+arColorCode : '#ffffff')}"></i>
                </button>
            </div>

            <div class="group-wrapper ml-4" *ngIf="arkitLockAvailable">
                <button #move="animatedGroupToggle" [animatedGroupToggle]="'move'" [groupToggleDisabled]="isLocked" (click)="isLocked && onLockedButtonClick()" [activeGroup]="'drawType'" [active]="arPlusDrawType === 'move'" (onActivated)="arPlusDrawType = 'move'" class="btn group-toggle">
                    <i class="mi mi-open-with" [ngStyle]="{'color': (move.active ? '#'+arColorCode : '#ffffff')}"></i>
                </button>
            </div>

            <div class="group-wrapper" *ngIf="arkitLockAvailable">
                <button #lock="animatedGroupToggle" [animatedGroupToggle]="'lock'" [activeGroup]="'drawType'" [active]="arPlusDrawType === 'lock'" (click)="onLockClick()" class="btn group-toggle">
                    <i class="mi mi-lock" [ngStyle]="{'color': (lock.active ? '#'+arColorCode : '#ffffff')}"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="controls-container d-flex justify-content-between align-items-center px-2">
        <div style="overflow: hidden;" class="d-flex align-items-center">
            <a class="signal-button mr-2" *ngIf="!showStatsDetails"
                [ngClass]="{
                        'data-available': (subscribeVideo && videoAvailable) || (subscribeAudio && audioAvailable),
                        'excellent': subscriberStats?.signalState === 3, 'good': subscriberStats?.signalState === 2, 'ok': subscriberStats?.signalState === 1,
                        'bad': subscriberStats?.signalState === 0}">
                <img src="assets/signal2.svg">
            </a>
            <a class="signal-button mr-2" *ngIf="showStatsDetails"
                [ngClass]="{'data-available': (subscribeVideo && videoAvailable) || (subscribeAudio && audioAvailable),
                            'excellent': subscriberStats.signalState === 3, 'good': subscriberStats.signalState === 2, 'ok': subscriberStats.signalState === 1,
                            'bad': subscriberStats.signalState === 0}"
                #subscriberStatsPopover="bs-popover" [popover]="subscriberDataTemplate" triggers="hover" placement="top" container="body" containerClass="stats-popover" [delay]='500'>
                <img src="assets/signal2.svg">
            </a>
            <i *ngIf="!frame?.stream?.hasAudio" class="mi mi-mic-off mic-muted mr-1"></i>
            <div class="subscriber-name" style="color: white">{{ subscriberData?.name }}</div>
        </div>
        <div class="controls">
            <a class="control"(click)="onToggleAudio()" title="Toggle Audio">
                <i class="mi" [ngClass]="{'mi-volume-up': subscribeAudio, 'mi-volume-off': !subscribeAudio}"></i>
            </a>
            <a class="control ml-2" (click)="onToggleVideo()" title="Toggle Video">
                <i class="mi" [ngClass]="{'mi-videocam': subscribeVideo, 'mi-videocam-off': !subscribeVideo}"></i>
            </a>
            <a *ngIf="pipEnabled" class="control d-flex align-items-center ml-2" (click)="onTogglePictureInPicture()" title="Picture In Picture">
                <i class="mi mi-picture-in-picture-alt pip-control"></i>
            </a>
            <a class="control ml-2" (click)="onToggleFullscreen()" title="Fullscreen">
                <i class="mi" [ngClass]="{'mi-fullscreen': !fullscreenState.fullscreen, 'mi-fullscreen-exit': fullscreenState.fullscreen}"></i>
            </a>
        </div>
    </div>

    <div class="ar-dots" [ngClass]="{'ar-plus': arPlusOpen}">
        <img [src]="'assets/arplus/'+arDot.shape+'-'+arDot.color+'.svg'" class="ar-dot" *ngFor="let arDot of arDots;" [ngStyle]="{left: arDot.left, top: arDot.top}">
    </div>

    <div class="ar-names" [class.small]="size === 'small' && !fullscreenState.fullscreen">
        <div class="ar-name" *ngFor="let arDot of arDots;" [style.color]="'#'+arDot.colorCode">{{ arDot.name }}</div>
    </div>

    <div class="reaction-wrapper" *ngIf="showReaction">
        <img [src]="'assets/arplus/'+reactionType+'.svg'" class="reaction">
        <span class="reaction-source">{{ reactionSource }}</span>
    </div>

    <div class="loading-overlay" *ngIf="deviceStatus?.arkit?.available && (deviceStatus?.arkit?.status === 'open' || deviceStatus?.arkit?.status === 'close')">
        <div class="loading-text">Loading</div>
    </div>

    <div style="position: absolute; bottom: 0; left: 50%;" #makeArCollTutorial="tutorial" tutorial="makeArColl" (change)="$event ? makeArCollPopover.show() : makeArCollPopover.hide()"
    #makeArCollPopover="bs-popover" [popover]="makeArCollTmp" triggers="" placement="top" container="body" containerClass="tutorial-popover"></div>

    <div style="position: absolute; bottom: 0; left: 50%;" #makeArPlusTutorial="tutorial" tutorial="makeArPlus" (change)="$event ? makeArPlusPopover.show() : makeArPlusPopover.hide()"
        #makeArPlusPopover="bs-popover" [popover]="makeArPlusTmp" triggers="" placement="top" container="body" containerClass="tutorial-popover"></div>

    <div style="position: absolute; bottom: 0; left: 50%;" #noArPlusTutorial="tutorial" tutorial="noArPlus" [ignored]="subscriberData && frame.stream.videoType !== 'screen' && videoAvailable && subscribeVideo && videoCanAvailable"
        #noArPlusPopover="bs-popover" [popover]="noArPlusTmp" triggers="" placement="top" container="body" containerClass="tutorial-popover" (change)="$event ? noArPlusPopover.show() : noArPlusPopover.hide()"></div>

    <div style="position: absolute; bottom: 0; left: 50%;" #noSnapshotTutorial="tutorial" tutorial="noSnapshot" [ignored]="subscriberData && frame.stream.videoType !== 'screen' && videoAvailable"
        #noSnapshotPopover="bs-popover" [popover]="noSnapshotTmp" triggers="" placement="top" container="body" containerClass="tutorial-popover" (change)="$event ? noSnapshotPopover.show() : noSnapshotPopover.hide()"></div>
</div>

<ng-template #takeSnapshotTmp>
    <div>{{ 'APP.TUTORIALS.TAKE_SNAPSHOT' | translate }}</div>
</ng-template>

<ng-template #openArTmp>
    <div>{{ 'APP.TUTORIALS.OPEN_AR' | translate }}</div>
</ng-template>

<ng-template #openArPlusTmp>
    <div>{{ 'APP.TUTORIALS.OPEN_AR_PLUS' | translate }}</div>
</ng-template>

<ng-template #makeArCollTmp>
    <div>{{ 'APP.TUTORIALS.HOLD_TO_HIGHLIGHT' | translate }}</div>
    <a (click)="features.snapshot ? makeArCollTutorial.next({snapshot: true, noSnapshot: true, subscriber: true}) : makeArCollTutorial.next({showTutorial: true})">{{ 'APP.TUTORIALS.NEXT' | translate }}</a>
</ng-template>

<ng-template #makeArPlusTmp>
    <div>{{ 'APP.TUTORIALS.DRAW_ON_SURFACE' | translate }}</div>
    <a (click)="features.snapshot ? makeArPlusTutorial.next({snapshot: true, noSnapshot: true, subscriber: true}) : makeArPlusTutorial.next({showTutorial: true})">{{ 'APP.TUTORIALS.NEXT' | translate }}</a>
</ng-template>

<ng-template #noArPlusTmp>
    <div>{{ 'APP.TUTORIALS.NO_CAMERA' | translate }}</div>
    <div>{{ 'APP.TUTORIALS.WAIT_OR_SKIP' | translate }}</div>
    <a (click)="noArPlusTutorial.next({showTutorial: true})">{{ 'APP.TUTORIALS.END' | translate }}</a>
</ng-template>

<ng-template #noSnapshotTmp>
    <div>{{ 'APP.TUTORIALS.NO_CAMERA' | translate }}</div>
    <div>{{ 'APP.TUTORIALS.WAIT_OR_SKIP' | translate }}</div>
    <a (click)="noSnapshotTutorial.next({showTutorial: true})">{{ 'APP.TUTORIALS.END' | translate }}</a>
</ng-template>

<ng-template #closeCollaborationTemplate let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.CLOSE_COLLABORATION_TITLE' | translate }}
                </h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p class="mx-3">
                    {{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.CLOSE_COLLABORATION_MESSAGE' | translate }}
                </p>
            </div>

            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">
                    {{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.CLOSE_PREV_COLLABORATION_NO' | translate }}
                </button>
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.yes()">
                    {{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.CLOSE_PREV_COLLABORATION_YES' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #closeArPlusTemplate let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.CLOSE_AR_PLUS_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p class="mx-3">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.CLOSE_AR_PLUS_MODAL.MESSAGE' | translate }}</p>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.CLOSE_AR_PLUS_MODAL.NO' | translate }}</button>
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.yes()">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.CLOSE_AR_PLUS_MODAL.YES' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #disableFocusTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.DISABLE_FOCUS_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p class="mx-3" *ngIf="dataModel.authorized">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.DISABLE_FOCUS_MODAL.MESSAGE_AUTHORIZED' | translate }}</p>
                <p class="mx-3" *ngIf="!dataModel.authorized">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.DISABLE_FOCUS_MODAL.MESSAGE_UNAUTHORIZED' | translate }}</p>
            </div>

            <div class="modal-footer d-block text-right py-2 px-3">
                <ng-container *ngIf="dataModel.authorized">
                    <button class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.DISABLE_FOCUS_MODAL.CANCEL' | translate }}</button>
                    <button class="btn btn-sm btn-danger px-4" (click)="callbacks.yes()">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.DISABLE_FOCUS_MODAL.PROCEED' | translate }}</button>
                </ng-container>
                <button *ngIf="!dataModel.authorized" class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.DISABLE_FOCUS_MODAL.CLOSE' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #subscriberDataTemplate>
    <div style="font-size: 10px">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.AUDIO_BITRATE' | translate }}: <img src="assets/south-24px.svg" width="10px" height="10px"> {{ (audioKbps !== 0) ? audioKbps : 'NA' }} <strong> kbps </strong> <img src="assets/north-24px.svg" width="10px" height="10px"> {{ subscriberStats ? (subscriberStats.audio !== 10000 && subscriberStats.audio !== 0) ? (subscriberStats.audio | number: '1.0-0') : 'NA' : 'NA' }} <strong>kbps</strong></div>
    <div style="font-size: 10px">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.AUDIO_PACKET_LOSS' | translate }}: <img src="assets/south-24px.svg" width="10px" height="10px"> {{ audioPacketLossRatio }} <img src="assets/north-24px.svg" width="10px" height="10px"> {{ subscriberStats ? subscriberStats.audioPacketLossRatio : 'NA' }} </div>
    <div style="font-size: 10px">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.VIDEO_BITRATE' | translate }}: <img src="assets/south-24px.svg" width="10px" height="10px"> {{ (videoKbps !== 0) ? videoKbps : 'NA' }} <strong> kbps </strong> <img src="assets/north-24px.svg" width="10px" height="10px"> {{ subscriberStats ? (subscriberStats.video !== 10000 && subscriberStats.video !== 0) ? (subscriberStats.video | number: '1.0-0') : 'NA' : 'NA' }} <strong>kbps</strong></div>
    <div style="font-size: 10px">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.VIDEO_PACKET_LOSS' | translate }}: <img src="assets/south-24px.svg" width="10px" height="10px"> {{ videoPacketLossRatio }} <img src="assets/north-24px.svg" width="10px" height="10px"> {{ subscriberStats ? subscriberStats.videoPacketLossRatio : 'NA' }} </div>
    <div style="font-size: 10px">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.RESOLUTION' | translate }}: {{ screenWidth }}x{{ screenHeight }}</div>
</ng-template>
