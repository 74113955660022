import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/core/auth.service';
import { DbService } from '@services/core/db.service';
import { of } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  constructor(private authService: AuthService,
    private dbService: DbService,
    private http: HttpClient
    ) {}

  saveWorkflowReport(workflow) {
    return this.http.post<any>(this.dbService.getEndPoint('saveworkflowreport'), {token: this.authService.currentUser.token, data: workflow}).pipe(first()).toPromise();
  }

  getAllWorkflows() {
    const url = this.dbService.getEndPoint("getworkflowlist");
    return this.http.post<any>(url,{ token: this.authService.currentUser.token}).toPromise();
  }

  workflowRemoteIntegrationCall(linkId, appVersion,platform, token) {
    const url = this.dbService.getEndPoint("workflowRemoteIntegrationCall");
    return this.http.post<any>(url,{ token: token, integration_id: linkId, app_version: appVersion, platform: 'web'}).toPromise();
  }
  
}
