import { Component, OnInit, ViewChild, ElementRef, Renderer2, TemplateRef, OnDestroy, Output, EventEmitter, Input, SimpleChanges, OnChanges } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { AuthService } from '@services/core/auth.service';
import { ArCollaborationService } from '@services/core/ar-collaboration.service';

import { LogService } from '@services/core/log.service';
import { CollaborationService } from '@services/core/collaboration.service';
import { FileShareService } from '@services/other/file-share.service';

import { FlashMessageService } from '@services/support/flash-message.service';
import { ModalService } from '@services/support/modal.service';
import { TutorialService } from '@services/support/tutorial.service';

import { Subscription, Subject, fromEvent, from } from 'rxjs';
import { auditTime } from 'rxjs/operators';

import { User } from '@models/User';
import { LogMessage } from '@models/LogMessage';
import { UserFile } from '@models/UserFile';
import { UserObject } from '@models/UserObject';
import { Collaboration } from '@models/Collaboration';
import { RoomSessionService } from '@services/core/room-session.service';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { LoaderService } from '@services/support/loader.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('list', { static: true }) list: ElementRef;
  @ViewChild("closeCollaborationTemplate", { static: true }) private closeCollaborationTemplate: TemplateRef<any>;
  @ViewChild("disableFocusTemplate", { static: true }) private disableFocusTemplate: TemplateRef<any>;
  @ViewChild("closeArPlusTemplate", { static: true }) private closeArPlusTemplate: TemplateRef<any>;
  @ViewChild("fileDetailsTemplate", { static: true }) private fileDetailsTemplate: TemplateRef<any>;
  @ViewChild("deleteFileTemplate", { static: true }) private deleteFileTemplate: TemplateRef<any>;
  @ViewChild("welcomeTutorialTemplate", { static: true }) private welcomeTutorialTemplate: TemplateRef<any>;

  @Output() unreadMessage = new EventEmitter<boolean>();
  @Input('sessionActive') sessionActive: boolean = false;

  currentUser: User = null;

  logMessages: LogMessage[] = [];
  private logMessagesSub: Subscription = null;

  logMessage: string = "";

  dropzoneStateSource = new Subject<boolean>();
  chosenFilesSource = new Subject<FileList>();

  unreadNewMessage: boolean = false;
  unreadMessageCount: number = 0;
  unreadSub: Subscription = null;

  collDebounce: boolean = false;
  collaborationKey: string = null;
  collaborationSub: Subscription = null;

  features: { [key: string]: boolean } = {
    filesharing: false,
    chattranslation: false,
    roomfiles: false
  };
  featuresSub: Subscription = null;
  featureMessage: "Your licence doesn't contain this feature.";

  trainingDisable: boolean = false;
  roomUserStatus: { training_room: boolean, session_active: boolean, user_status: string } = null;
  private roomUserStatusSub: Subscription = null;

  translationEnabled: boolean = false;
  lastTranslationOrj: string = null;
  translatedMessage: string = "";
  translating: boolean = false;
  translationLangSub: Subscription = null;
  translationSub: Subscription = null;

  constructor(
    private authService: AuthService,
    private logService: LogService,
    private renderer: Renderer2,
    private flashMessageService: FlashMessageService,
    private collaborationService: CollaborationService,
    private fileShareService: FileShareService,
    private roomSessionService: RoomSessionService,
    private modalService: ModalService,
    private tutorialService: TutorialService,
    private arCollaborationService: ArCollaborationService,
    private multilanguageService: MultilanguageService,
    private loaderService: LoaderService
  ) {
    this.currentUser = this.authService.currentUser;
  }

  ngOnInit() {
    const currentUser = this.authService.currentUser;
    const el = this.list.nativeElement;
    if (this.unreadSub) { this.unreadSub.unsubscribe() }
    this.unreadSub = fromEvent(el, 'scroll').pipe(auditTime(50)).subscribe(e => {
      if ((el.scrollHeight - el.scrollTop - el.clientHeight) < 5) {
        this.unreadNewMessage = false;
        this.unreadMessage.emit(false);
        this.unreadMessageCount = 0;
      }
    });
    this.logMessagesSub = this.logService.logMessages.subscribe(messagesEvent => {
      if (messagesEvent.type === "added") {
        if ((el.scrollHeight - el.scrollTop - el.clientHeight) < 5 || (messagesEvent.message.type === 'text' && messagesEvent.message.owner === currentUser.id)) {
          setTimeout(() => {
            this.scrollChatToBottom();
          }, 300);
        } else {
          this.unreadNewMessage = true;
          this.unreadMessage.emit(true);
          this.unreadMessageCount = this.unreadMessageCount + 1
        }
      }
      this.logMessages = messagesEvent.messages;
    });
    this.collaborationSub = this.collaborationService.collaboration.subscribe(collData => {
      if (collData) {
        this.collaborationKey = collData.key;
      } else {
        this.collaborationKey = null;
      }
    });
    this.featuresSub = this.authService.features.subscribe(features => {
      this.features = features;
    });

    this.roomUserStatusSub = this.roomSessionService.roomUserStatus.subscribe(roomUserStatus => {
      this.roomUserStatus = roomUserStatus;
      this.trainingDisable = roomUserStatus.training_room ? (roomUserStatus.user_status !== "master" && roomUserStatus.user_status !== "publishing") : false;
    });
    this.translationLangSub = this.multilanguageService.translationLanguage2
    .subscribe(languageCode => {
      if (this.translationEnabled && this.sessionActive && this.logMessage.length !== 0) {
        if (this.translationSub) {
          this.translationSub.unsubscribe();
          this.translationSub = null;
        }
        this.reloadTranslation();
      }
    });
  }

  toggleTranslation() {
    if (!this.features.chattranslation) {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE');
      return;
    }

    this.translationEnabled = !this.translationEnabled;

    if (this.translationEnabled) {
      this.reloadTranslation();
      setTimeout(() => this.scrollChatToBottom(), 300);
    } else if (this.translationSub) {
      this.translationSub.unsubscribe();
      this.translationSub = null;
    }
  }

  reloadTranslation() {
    if (!this.translating) {
      this.translating = true;
      this.translatedMessage = "";

      this.translationSub = from(this.logService.translateMessage(this.logMessage))
      .subscribe(response => {
        if (this.sessionActive || this.roomUserStatus?.training_room) {
          this.lastTranslationOrj = this.logMessage;
          this.translatedMessage = response.result[0];  
        }
      }, error => {},
      () => {
        this.translating = false;
        this.translationSub = null;
      });
    }
  }

  seeTranslation(index: number, message: LogMessage) {
    if (this.features.chattranslation) {
      this.logService.seeTranslation(index, message);
    } else {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE');
    }
  }

  seeOriginal(index: number, message: LogMessage) {
    this.logService.seeOriginal(index, message);
  }

  showTutorial() {
    this.tutorialService.startTutorial({micCamOff: true})
    .then(skipped => {
      if (!skipped) {
        this.tutorialService.saveCompletedTutorial("main");
      }
    });
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.sessionActive.previousValue !== changes.sessionActive.currentValue){
      this.sessionActive = changes.sessionActive.currentValue;

      if (!this.sessionActive) {
        this.logMessage = "";

        if (this.translationEnabled) {
          this.translationEnabled = false;
          this.translatedMessage = "";
        }
        if (this.translationSub) {
          this.translationSub.unsubscribe();
          this.translationSub = null;
        }
      }
    }
  }

  scrollChatToBottom() {
    const el = this.list.nativeElement;
    this.renderer.setProperty(el, "scrollTop", el.scrollHeight);

    this.unreadNewMessage = false;
    this.unreadMessage.emit(false);
    this.unreadMessageCount = 0;
  }

  ngOnDestroy() {
    if (this.unreadSub) { this.unreadSub.unsubscribe() }
    if (this.logMessagesSub) { this.logMessagesSub.unsubscribe() }
    if (this.featuresSub) { this.featuresSub.unsubscribe() }
    if (this.roomUserStatusSub) { this.roomUserStatusSub.unsubscribe() }
    if (this.translationSub) { this.translationSub.unsubscribe() }
    if (this.translationLangSub) { this.translationLangSub.unsubscribe() }
  }

  sendMessage(message: string) {
    if (!this.sessionActive && !this.roomUserStatus?.training_room) {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.CHAT.PLEASE_WAIT_OTHERS', { cssClass: 'alert-info', timeout: 3000 });
      return;
    }

    if (this.logMessage.length > 0) {
      this.logService.sendMessage(message);
      this.logMessage = "";
    }
  }

  sendTranslation(message: string, translatedMessage: string) {
    if (!this.sessionActive && !this.roomUserStatus?.training_room) {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.CHAT.PLEASE_WAIT_OTHERS', { cssClass: 'alert-info', timeout: 3000 });
      return;
    }

    if (this.translatedMessage.length > 0) {
      this.logService.sendMessage(message, translatedMessage);
      this.translatedMessage = "";
      this.logMessage = "";
      this.lastTranslationOrj = "";
    }
  }

  onDropEnter() {
    if (this.features.filesharing) {
      if (this.sessionActive) {
        this.dropzoneStateSource.next(true);
      }
    } else {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE');
    }
  }

  onChooseFile(files: FileList) {
    this.logMessage = ''
    if (this.features.filesharing) {
      this.chosenFilesSource.next(files);
    } else {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE');
    }
  }

  onAttachment(fileinput) {
    if (this.features.filesharing) {
      fileinput.value = '';
      fileinput.click();
    } else {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE');
    }
  }

  uploadedFile(file: UserFile) {
    this.logService.sendShareFileLog(file);
  }

  uploadedObject(object: UserObject) {
    this.logService.sendShareObjectLog(object);
  }

  startCollaboration(message: LogMessage) {
    if (message.details.coll_unavailable) {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE');
    } else {
      if (this.sessionActive && !this.collDebounce) {
        this.collaborationService.createCollaboration(message)
        .then(collaboration => {
          this.openCollaboration(collaboration);
        });
      }
    }
  }

  endCollaboration(message: LogMessage) {
    if (this.sessionActive && !this.collDebounce) {
      const modalId = this.modalService.show({
        template: this.closeCollaborationTemplate,
        context: {
          dataModel: { previous: false },
          callbacks: {
            no: () => {
              this.modalService.hide(modalId);
            },
            yes: () => {
              this.modalService.hide(modalId);
              this.collaborationService.closeCollaboration()
            }
          }
        }
      });
    }
  }

  showCollaborationDisableReason() {
    if (this.trainingDisable) {
      this.flashMessageService.showTranslated('APP.SHARED.TRAINING_DISABLED');
      return;
    }
  }

  openCollaboration(collaboration: Collaboration) {
    if (!this.collDebounce) {
      this.collDebounce = true;
      setTimeout(() => {
        this.collDebounce = false;
      }, 1000);

      this.collaborationService.openCollaboration(collaboration)
      .then(result => {
        if (result.committed) {
          this.logService.sendCollaborationLog(collaboration);
        } else if (result.data.focus) {
          this.showDisableFocusModal(collaboration, this.roomUserStatus && this.roomUserStatus.user_status === "master");
        } else if (result.data.collaboration.data) {
          this.showCloseCollaborationModal(collaboration);
        } else {
          this.showCloseArPlusModal(collaboration);
        }
      });
    }
  }

  showDisableFocusModal(newCollaboration: Collaboration, authorized: boolean) {
    const modalId = this.modalService.show({
      template: this.disableFocusTemplate,
      context: {
        dataModel: { authorized: authorized },
        callbacks: {
          no: () => this.modalService.hide(modalId),
          yes: () => {
            this.modalService.hide(modalId);
            this.roomSessionService.disableFrameFocus()
            .then(success => {
              if (success) {
                this.openCollaboration(newCollaboration);
              }
            });
          }
        }
      }
    });
  }

  showCloseCollaborationModal(newCollaboration: Collaboration) {
    const modalId = this.modalService.show({
      template: this.closeCollaborationTemplate,
      context: {
        dataModel: { previous: true },
        callbacks: {
          no: () => {
            this.modalService.hide(modalId);
          },
          yes: () => {
            this.modalService.hide(modalId);
            this.collaborationService.closeCollaboration()
            .then(success => {
              if (success) {
                this.openCollaboration(newCollaboration);
              }
            });
          }
        }
      }
    });
  }

  showCloseArPlusModal(collaboration) {
    const modalId = this.modalService.show({
      template: this.closeArPlusTemplate,
      context: {
        dataModel: null,
        callbacks: {
          no: () => {
            this.modalService.hide(modalId);
          },
          yes: () => {
            this.modalService.hide(modalId);
            this.arCollaborationService.closeAllArPluses()
            .then(() => {
              setTimeout(() => {
                this.openCollaboration(collaboration);
              }, 1000);
            });
          }
        }
      }
    });
  }

  onDownloadFile(message: LogMessage) {
    if (message.type === 'file') {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.CHAT.DOWNLOAD_STARTED_SOON', { cssClass: 'alert-info', timeout: 2000 });
      this.fileShareService.downloadFile(message.url, message.extra)
      .catch(error => {
        this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.CHAT.DOWNLOAD_FAILED');
      });
    } else if (message.type === 'object') {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.CHAT.DOWNLOAD_STARTED_SOON', { cssClass: 'alert-info', timeout: 2000 });
      this.fileShareService.getObjectDetails(message.content)
      .then(res => {
        res.files.forEach(file => {
          this.fileShareService.downloadFile(file.url, file.name)
          .catch(error => {
            this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.CHAT.DOWNLOAD_FAILED');
          })
        });
      })
    } else {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.CHAT.DOWNLOAD_NOT_SUPPORTED');
    }
  }

  onSendToRoomFiles(message: LogMessage) {
      this.loaderService.show();
      this.fileShareService.copyFileSessionToRoom(this.roomSessionService.currentRoomId, this.roomSessionService.currentSessionId, message.content, message.type)
      .catch(error => {
        if(error.error.code == 'permission-denied') {
          this.flashMessageService.showTranslated('APP.MAIN.LOBBY.TICKETS.ERRORS.PERMISSION_DENIED');
        }
        if(error.error.code == 'file-already-exists') {
          this.flashMessageService.showTranslated('APP.MAIN.LOBBY.TICKETS.ERRORS.FILE_ALREADY_EXIST');
        }
        if(error.error.code == 'internal-error') {
          this.flashMessageService.showTranslated('APP.HELP_CENTER.FAILED');
        }
        console.log(error);
      })
      .finally(() => {
        this.loaderService.hide();
      })
  }
/*
  onDeleteFile(message: LogMessage) {
    const modalId = this.modalService.show({
      template: this.deleteFileTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () =>  this.modalService.hide(modalId),
          delete: () => {
            this.loaderService.show();
            let p: Promise<void>;
            if (this.collaborationKey) {
              p = this.collaborationService.closeCollaboration().then(() => this.deleteFile(message));
            } else {
              p = this.deleteFile(message);
            }
            p.finally(() => {
              this.loaderService.hide();
              this.modalService.hide(modalId);
            });
          }
        }
      }
    });
  }

  deleteFile(message: LogMessage) {
    // Find collaborations associated with this file
    return this.collaborationService.getFileCollaborations(message.content)
    .then(colls => {
      // Create update object for delete file
      const session: any = {
        ['files/'+message.content]: null,        // delete file object
        ['logs/'+message.id+'/deleted']: true    // add deleted=true in logs
      };
      for(const coll of colls) {
        session['collaborations/'+coll] = null;  // delete all associated collaborations
      }
      return this.fileShareService.deleteFileFromStorage(message.content, message.details.extension)
      .then(() => this.fileShareService.deleteFileWithCollaborations(session));
    });
  }
*/
  onFileDetails(message: LogMessage) {
    const dataModel = {
      filename: message.extra,
      file: null,
      type: 'file'
    };
    const modalId = this.modalService.show({
      template: this.fileDetailsTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          download: () => this.onDownloadFile(message),
          close: () =>  this.modalService.hide(modalId)
        }
      }
    });

    this.fileShareService.getFileDetails(message.content)
    .then(file => dataModel.file = file);
  }

  onObjectDetails(message: LogMessage) {
    const dataModel = {
      filename: '',
      file: null,
      type: 'object',
      totalSize: 0,
      fileCount: 0,
    };

    this.fileShareService.getObjectDetails(message.content)
    .then(res => {
      dataModel.filename = res.name;
      dataModel.fileCount = res.files.length;

      res.files.forEach(file => {
        if(file.main_object_file) {
          dataModel.file = file;
        }

        dataModel.totalSize += file.size;
      });
    })

    const modalId = this.modalService.show({
      template: this.fileDetailsTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          download: () => this.onDownloadFile(message),
          close: () => this.modalService.hide(modalId)
        }
      }
    });
  }

  onPaste(event:ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let files = clipboardData.files
    if(files?.length) {
      setTimeout(() => {
        this.onChooseFile(files)
      });
      this.logMessage = "";
    }
  }
}
