<div class="video-chat-container" #container>
    <div *ngIf="audioUnavailable || videoUnavailable" class="alert alert-danger no-mic-cam" role="alert">
        <p *ngIf="audioUnavailable && videoUnavailable" >{{ 'APP.MAIN.ROOM.VIDEO_CHAT.NO_CAM_MIC' | translate }}</p>
        <p *ngIf="!audioUnavailable && videoUnavailable" >{{ 'APP.MAIN.ROOM.VIDEO_CHAT.NO_CAM' | translate }}</p>
        <p *ngIf="audioUnavailable && !videoUnavailable" >{{ 'APP.MAIN.ROOM.VIDEO_CHAT.NO_MIC' | translate }}</p>
    </div>
    <ng-container *ngIf="!(isMobile && isPortrait) || mobileFullscreen">
        <div *ngFor="let frame of frames; let i = index;" (resized)="onFrameResized(frame, $event)"
        [ngSwitch]="frame.type" [ngStyle]="frame.style" class="frame-box" (dblclick)="onMaximize(i)">
        <div [ngStyle]="frame.style2" [addNativeElement]="frame" class="frame-box-inner" *ngIf="!(isMobile && isPortrait) || mobileFullscreen">
            <app-subscriber *ngSwitchCase="'subscriber'" [frame]="frame" [size]="frame.size" (arPlusStatusChanged)="onSubscriberArPlusStatusChanged($event, i)" (toggleFullscreen)="toggleFullscreen($event, frame)" [nohover]="isTouchDevice"></app-subscriber>
            <app-publisher  *ngSwitchCase="'publisher'" [frame]="frame" [size]="frame.size" (toggleFullscreen)="toggleFullscreen($event, frame)" [nohover]="isTouchDevice"></app-publisher>

            <app-image-collaboration-wrapper *ngSwitchCase="'image'" [frame]="frame" [size]="frame.size" (toggleFullscreen)="toggleFullscreen($event, frame)" [nohover]="isTouchDevice"></app-image-collaboration-wrapper>
            <app-image-collaboration-wrapper *ngSwitchCase="'screenshot'" [frame]="frame" [size]="frame.size" (toggleFullscreen)="toggleFullscreen($event, frame)" [nohover]="isTouchDevice"></app-image-collaboration-wrapper>
            <app-pdf-collaboration-wrapper *ngSwitchCase="'pdf'" [frame]="frame" [size]="frame.size" (toggleFullscreen)="toggleFullscreen($event, frame)" (toggleDownloadButtonClicked)="toggleDownloadButtonClicked($event, frame)" [nohover]="isTouchDevice"></app-pdf-collaboration-wrapper>

            <a class="pin-button" (click)="pinFrame(frame)" *ngIf="(roomUserStatus.user_status === 'master' || isExpert || frame.pinned) && !((videoUnavailable || audioUnavailable) && frame.uid === currentUser.id)" [class.master]="roomUserStatus.user_status === 'master' || isExpert" [class.frame-pinned]="frame.pinned"><img src="assets/pin.svg"></a>
        </div>
        
    </div>
    </ng-container>
    

    <ng-container *ngIf="isMobile && isPortrait && !mobileFullscreen">
        <ng-container *ngFor="let frame of frames; let i = index;">
        <div *ngIf="(isMobile && i<2)"  (resized)="onFrameResized(frame, $event)"
            [ngSwitch]="frame.type"  class="frame-box-mobile w-100" (dblclick)="onMaximize(i)" [ngClass]="{'height-47' : i == 0, 'height-30' : i == 1, 'first-frame': i == 0, 'mobile-fullscreen': mobileFullscreen }" >
            <div *ngIf="i==1 && frames.length>2"> <i class="down-icon mi mi-keyboard-arrow-left frame-arrow-left" (click)="changeFrameLine(i)"></i></div>
            <div [ngStyle]="frame.style2" [addNativeElement]="frame" class="frame-box-inner" >
                <app-publisher  *ngSwitchCase="'publisher'" [frame]="frame" [size]="frame.size" (toggleFullscreen)="toggleFullscreen($event, frame)" [nohover]="isTouchDevice"></app-publisher>
                <app-subscriber *ngSwitchCase="'subscriber'" [frame]="frame" [size]="frame.size" (arPlusStatusChanged)="onSubscriberArPlusStatusChanged($event, i)" (toggleFullscreen)="toggleFullscreen($event, frame)" [nohover]="isTouchDevice"></app-subscriber>
    
                <app-image-collaboration-wrapper *ngSwitchCase="'image'" [frame]="frame" [size]="frame.size" (toggleFullscreen)="toggleFullscreen($event, frame)" [nohover]="isTouchDevice"></app-image-collaboration-wrapper>
                <app-image-collaboration-wrapper *ngSwitchCase="'screenshot'" [frame]="frame" [size]="frame.size" (toggleFullscreen)="toggleFullscreen($event, frame)" [nohover]="isTouchDevice"></app-image-collaboration-wrapper>
                <app-pdf-collaboration-wrapper *ngSwitchCase="'pdf'" [frame]="frame" [size]="frame.size" (toggleFullscreen)="toggleFullscreen($event, frame)" (toggleDownloadButtonClicked)="toggleDownloadButtonClicked($event, frame)" [nohover]="isTouchDevice"></app-pdf-collaboration-wrapper>
    
                <a class="pin-button" (click)="pinFrame(frame)" *ngIf="(roomUserStatus.user_status === 'master' || isExpert || frame.pinned) && !((videoUnavailable || audioUnavailable) && frame.uid === currentUser.id)" [class.master]="roomUserStatus.user_status === 'master' || isExpert" [class.frame-pinned]="frame.pinned"><img src="assets/pin.svg"></a>
            </div>
            <div *ngIf="i==1 && frames.length>2"> <i class="down-icon mi mi-keyboard-arrow-right frame-arrow-right" (click)="changeFrameLine(i)"></i></div>
        </div>
        </ng-container>

        <div class="d-flex align-items-center" style="position: absolute;bottom: 15px; " *ngIf="isMobile && isPortrait">
            <div *ngIf="frames.length >= 2"  class="d-flex align-items-end ml-2" style="background-color: #787676; border-radius: 5px;padding: 7px 10px;" 
                (click)="changeShowUserStatus()">
                <span class="text-white mi mi-people mr-1"  style="font-size: 40px;"></span>
                <span class="text-white" style="font-size: 22px; padding-bottom: 8px;"> ({{ (frames[0].type == 'image' || frames[0].type == 'screenshot' || frames[0].type == 'pdf') ? frames.length - 1 : frames.length}})</span>
            </div>
            <div class="mobile-subscribers ml-3" style="overflow-x: scroll;" *ngIf="showUsers">
                <div *ngFor="let frame of frames | slice: 2; let index = index" [ngSwitch]="frame.type" (click)="changeFrame(index)">
                    <app-mobile-subscriber *ngSwitchCase="'subscriber'" [frame]="frame"></app-mobile-subscriber>
                    <ng-container *ngIf="(frames[0].type == 'image' || frames[0].type == 'screenshot' || frames[0].type == 'pdf' || frames[0].pinned)">
                        <app-mobile-subscriber *ngSwitchCase="'publisher'" [frame]="frame"></app-mobile-subscriber>
                    </ng-container>

                </div>
            </div>
        </div>
        
    </ng-container>

    <div *ngIf="archiving" class="recording-wrapper">
        <i *ngIf="roomSessionStarted" class="text-danger mi" [ngClass]="{'mi-radio-button-checked': archiveIndicator, 'mi-radio-button-unchecked': !archiveIndicator}"></i>
        <i *ngIf="!roomSessionStarted" class="text-primary mi mi-pause"></i>
        <span class="text-white font-weight-bold mx-1">{{ roomSessionStarted ? 'RECORDING' : 'RECORD PAUSED' }}</span>
    </div>

    <div class="reaction-wrapper" *ngIf="showReaction">
        <!--<i class="mi reaction-icon mb-2" [ngClass]="'mi-'+reactionType"></i>-->
        <img [src]="'assets/reactions/'+reactionType+'.svg'" class="reaction">
        <span class="reaction-source">{{ reactionSource }}</span>
    </div>

    <div *ngIf="roomSessionStarted && !isOpentokStarted" class="connecting-area d-flex justify-content-center align-items-center">
        <div class="loader text-info"><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    <div *ngIf="!roomSessionStarted" class="waiting-area d-flex flex-column justify-content-center align-items-center">
        <div *ngIf="audioUnavailable || videoUnavailable" class="alert alert-danger no-mic-cam" role="alert">
            <p *ngIf="audioUnavailable && videoUnavailable" >{{ 'APP.MAIN.ROOM.VIDEO_CHAT.NO_CAM_MIC' | translate }}</p>
            <p *ngIf="!audioUnavailable && videoUnavailable" >{{ 'APP.MAIN.ROOM.VIDEO_CHAT.NO_CAM' | translate }}</p>
            <p *ngIf="audioUnavailable && !videoUnavailable" >{{ 'APP.MAIN.ROOM.VIDEO_CHAT.NO_MIC' | translate }}</p>
        </div>
        <p *ngIf="!roomUserStatus.training_room" class="text-center">{{ (waitExpert ? 'APP.MAIN.ROOM.VIDEO_CHAT.WAIT_EXPERT' : 'APP.MAIN.ROOM.VIDEO_CHAT.WAIT_OTHER_USERS') | translate }}</p>
        <ng-container *ngIf="roomUserStatus.training_room">
            <p *ngIf="roomUserStatus.user_status !== 'master'" class="text-center">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.WAIT_MASTER' | translate }}</p>
            <button *ngIf="roomUserStatus.user_status === 'master'" class="start-session-btn btn btn-sm btn-info" (click)="startTrainingSession()">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.START_SESSION' | translate }}</button>    
        </ng-container>
    </div>
</div>

<div class="controlbar">
    <app-control-bar [archiving]="archiving" [archive]="archive" [sidebarOpen]="sidebarOpen"></app-control-bar>
</div>

<ng-template #tryToPublishTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.JOIN_VIDEO_AUDIO_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body p-3">
                <p class="p-2">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.JOIN_VIDEO_AUDIO_MODAL.MESSAGE' | translate }}</p>

                <div class="publish-settings d-flex flex-row align-items-center">
                    <div class="d-flex flex-row align-items-center pl-2">
                        <div>{{ 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.MICROPHONE' | translate }}</div>
                        <div class="switch pl-2">
                            <input type="checkbox" id="audio-switch" name="switch" [checked]="dataModel.audio" (change)="callbacks.toggleAudio()">
                            <label for="audio-switch"></label>
                        </div>
                    </div>
                    <div class="d-flex flex-row align-items-center pl-5">
                        <div>{{ 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.CAMERA' | translate }}</div>
                        <div class="switch pl-2">
                            <input type="checkbox" id="video-switch" name="switch" [checked]="dataModel.video" (change)="callbacks.toggleVideo()">
                            <label for="video-switch"></label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.JOIN_VIDEO_AUDIO_MODAL.DENY' | translate }}</button>
                <button class="btn btn-sm btn-success px-4" (click)="callbacks.yes()">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.JOIN_VIDEO_AUDIO_MODAL.ACCEPT' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #tryToFocusTemplate let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.TRY_TO_FOCUS_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body p-3">
                <p>{{ 'APP.MAIN.ROOM.VIDEO_CHAT.TRY_TO_FOCUS_MODAL.MESSAGE' | translate }}</p>
            </div>

            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.TRY_TO_FOCUS_MODAL.CANCEL' | translate }}</button>
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.yes()">{{ 'APP.MAIN.ROOM.VIDEO_CHAT.TRY_TO_FOCUS_MODAL.PROCEED' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>
