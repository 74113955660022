import { Component, EventEmitter, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Room } from '@models/Room';
import { TicketDetail } from '@models/TicketDetail';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/core/auth.service';
import { SessionExportService } from '@services/core/session-export.service';
import { FileShareService } from '@services/other/file-share.service';
import { TicketService } from '@services/other/ticket.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { LoaderService } from '@services/support/loader.service';
import { ModalService } from '@services/support/modal.service';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { UtilityService } from '@services/support/utility.service';
import { ConfirmModalComponent } from 'app/components/confirm-modal/confirm-modal.component';
import { environment } from 'environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.scss']
})

export class TicketDetailComponent implements OnInit, OnDestroy {

  @ViewChild("deleteFileTemplate", { static: true }) private deleteFileTemplate: TemplateRef<any>;
  onClose: Subject<void> = new Subject()
  
  isLoadingDetail = true;
  isLoadingUpdate = false;
  isLoadingCreate = false;
  isLoadingFiles = true;

  ticketId: string;
  mode: string;
  roomSession:boolean;
  currentRoom: Room;

  ticketStatuses: any[] = [{ id: "open", name: "Open" }, { id: "in-progress", name: "In Progress" }, { id: "closed", name: "Closed" }];
  ticketPriorities: any[] = [{ id: "low", name: "Low" }, { id: "medium", name: "Medium" }, { id: "high", name: "High" }];
  ticketTypes: any[] = [{ id: "service-ticket", name: "Service Ticket" }];

  rooms: any[] = [];
  users: any[] = [];
  timezone = "UTC";

  mailToUrl: string;

  selectedTicketRoomId: string = null;
  selectedTicketRoomUsers: any[] = [];
  sessionExportEnabled: boolean = false;
  webdavEnabled: boolean = false;

  ticketDetail: TicketDetail;
  isExpert: boolean = null;

  confirmModalRef: BsModalRef;
  dataModel: any = {
    ticket: {
      status: "open",
      priority: "low",
      followup: null,
      type: "service-ticket",
      assignee: null,
      title: "",
      description: "",
      resolution: "",
    },
    sessions: [],
    pristine: true,
    mode: "detail"
  }

  files = []

  isDropdownOpen: boolean = false;
  imageExtensions: string[] = ['jpg', 'jpeg', 'png'];
  pdfExtensions: string[] = ['pdf'];
  objectSpecificExtensions: string[] = ['obj', 'stl', 'gltf', 'glb', 'dae', 'scn'];

  chosenFilesSource = new Subject<FileList>();
  dropzoneStateSource = new Subject<boolean>();

  private subscriptions: Subscription[] = [];

  constructor(private ticketService: TicketService, private translateService: TranslateService, private flashMessageService: FlashMessageService, private authService: AuthService,
    private loaderService: LoaderService,public modalRef: BsModalRef, private modal: BsModalService, private sessionExportService: SessionExportService,private multilanguageService: MultilanguageService,
    private fileShareService: FileShareService, private modalService: ModalService) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.onClose.complete();
  }

  ngOnInit(): void {
    this.subscriptions.push(this.multilanguageService.onLangChange.pipe(startWith(null)).subscribe(() => {
      this.mapStatusAndPriority()
    }));

    this.subscriptions.push(this.authService.timezone.pipe(
      distinctUntilChanged()
    ).subscribe(res=>{
       this.timezone = res;
    })) 
    if(this.mode == 'detail'){
      this.getTicketDetail(this.ticketId);
    } else {
      this.dataModel.mode = 'create';
      this.isLoadingDetail=false;
    }
    this.subscriptions.push(this.authService.user.subscribe(user => {
      this.rooms = ((user && user.rooms) ? Object.keys(user.rooms).map(rid => { return { id: rid, name: user.rooms[rid].name }; }) : [])
        .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
        if(this.currentRoom){
          this.selectedTicketRoomId=this.currentRoom.id
        }else{
          this.selectedTicketRoomId = this.dataModel.ticket.room_id || this.rooms[0].id
        }
    }));
    this.subscriptions.push(this.authService.getAllUsers(true).subscribe(users => {
      if (users) {
        // [...users] needed for change detection mechanism of ng-select
        this.users = [...users].sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
        this.selectedTicketRoomUsers = this.selectedTicketRoomId ? this.users.filter(u => u.rooms && u.rooms[this.selectedTicketRoomId]) : [];
      } else {
        this.users = [];
      }
    }));
    this.subscriptions.push(this.authService.addOns.subscribe(addOns => {
      this.sessionExportEnabled = addOns.sessionexport;
      this.webdavEnabled = addOns.webdav;
    }));
    this.subscriptions.push(this.ticketService.getTicketFiles(this.ticketId).subscribe(files => {
      if (files) {
        this.files = Object.values(files);
      } else  {
        this.files = [];
      }
      this.isLoadingFiles = false
    }))
    this.isExpert = this.authService.currentUser.role === 'expert';
  }

  mapStatusAndPriority() {
    const open = this.translateService.instant('APP.MAIN.LOBBY.TICKETS.STATUS_OPEN')
    const in_progress = this.translateService.instant('APP.MAIN.LOBBY.TICKETS.STATUS_IN_PROGRESS');
    const closed = this.translateService.instant('APP.MAIN.LOBBY.TICKETS.STATUS_CLOSED');
    const low = this.translateService.instant('APP.MAIN.LOBBY.TICKETS.LOW');
    const medium = this.translateService.instant('APP.MAIN.LOBBY.TICKETS.MEDIUM');
    const high = this.translateService.instant('APP.MAIN.LOBBY.TICKETS.HIGH');
    this.ticketStatuses = [{ id: "open", name: open }, { id: "in-progress", name: in_progress }, { id: "closed", name: closed }];
    this.ticketPriorities = [{ id: "low", name: low }, { id: "medium", name: medium }, { id: "high", name: high }]; 
  }

  roomChanged (room):void {
    this.selectedTicketRoomId = room.id;
    this.selectedTicketRoomUsers = this.selectedTicketRoomId ? this.users.filter(u => u.rooms && u.rooms[this.selectedTicketRoomId]) : [];
    if (this.dataModel.ticket.assignee && this.selectedTicketRoomUsers.findIndex(u => u.id === this.dataModel.ticket.assignee) < 0) {
      this.dataModel.ticket.assignee = null;
    }
  }

  edit(ticketForm: NgForm):void {
    this.subscriptions.push(ticketForm.valueChanges.subscribe(val => {
      const changes = this.detectTicketChanges(val, this.dataModel.orjTicket);
      this.dataModel.pristine = Object.keys(changes).length === 0;
    }));
    this.dataModel.mode = "edit";
  }

  save(ticketForm: NgForm):void {
    this.loaderService.show();
    this.isLoadingUpdate = true;
    if (this.dataModel.pristine) {
      return;
    }
    const changes = {
      id:this.ticketId,
      properties: this.detectTicketChanges(ticketForm.value, this.dataModel.orjTicket)
    };
    this.ticketService.updateTicket(changes)
    .then(result => {
     this.close();
    })
    .catch(error => {this.flashMessageService.show('There is an error occured.')
    this.isLoadingUpdate = false;
  })
    .finally(() => this.loaderService.hide());
  }

  create(ticketForm: NgForm):void {
    let selectedRoomCreate;
    this.loaderService.show();
    this.isLoadingCreate = true;
    if(this.currentRoom){
      selectedRoomCreate = this.currentRoom
    }
    else{
      selectedRoomCreate = this.rooms[0] ? this.rooms[0] : null;
    }
    const dataModel: any = {
      ticket: {
        status: "open",
        priority: "low",
        followup: "",
        type: "service-ticket",
        assignee: null,
        title: "",
        description: "",
        resolution: "",
        room_id: selectedRoomCreate?.id,
        room_name: selectedRoomCreate?.name || "",
      },
      mode: "create"
    }
     dataModel.ticket = {
      type: ticketForm.value.type,
      assignee: ticketForm.value.assignee,
      creator: this.authService.currentUser.id,
      description: ticketForm.value.description,
      //followup: new Date().getTime(),
      priority: ticketForm.value.priority,
      resolution: ticketForm.value.resolution,
      room_id: ticketForm.value.room_id || selectedRoomCreate?.id,
      status: ticketForm.value.status,
      title: ticketForm.value.title
    };
    this.selectedTicketRoomId = selectedRoomCreate?.id;
    this.selectedTicketRoomUsers = this.selectedTicketRoomId ? this.users.filter(u => u.rooms && u.rooms[this.selectedTicketRoomId]) : [];
    if (ticketForm.value.followup) {
      dataModel.ticket.followup = this.formatDate(ticketForm.value.followup);
    }
    if (ticketForm.value.participants) {
      dataModel.ticket.participants = ticketForm.value.participants;
    }
    this.ticketService.createTicket(dataModel.ticket)
    .then(result => {
      if(result.ticket_id){
       this.close();
      }
    })
    .catch(error => {this.flashMessageService.show('There is an error occured.')
    this.isLoadingCreate = false;
  })
    .finally(() => this.loaderService.hide());
  }

  deleteTicket(): void {
    const initialState= {
      title: this.translateService.instant(`APP.MAIN.LOBBY.TICKETS.DELETE_TICKET_MODAL.TITLE`),
      description: this.translateService.instant(`APP.MAIN.LOBBY.TICKETS.DELETE_TICKET_MODAL.MESSAGE`, { title: this.ticketDetail.title }),
      instantClose:false
    };
    this.confirmModalRef = this.modal.show(ConfirmModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
    this.confirmModalRef.content.onClose.subscribe((res) => {
      if (res == true) {
        this.delete();
      }
    })
  };

  delete(): void {
    this.loaderService.show();
    this.ticketService.deleteTicket(this.ticketId)
    .then((res) => {
       if(res.status == 'ok'){
         this.confirmModalRef.hide();
         this.close();
       }
    })
    .catch(error => this.flashMessageService.show('There is an error occured.'))
    .finally(() => this.loaderService.hide());
 };

  close():void {
    this.modalRef.hide();
    this.onClose.next();
  };

  cancelEdit():void { 
    this.modalRef.hide();
  };

  getUsersName(id: string) {
    const user = this.users.find(u => u.id === id);
    return user ? user.name : "Unknown";
  }

  getRoomName(id: string) {
    const room = this.rooms.find(r => r.id === id);
    return room ? room.name : "Unknown";
  }

  private getTicketDetail(ticketId):void {
    this.isLoadingDetail=true;
    this.dataModel = {
      ticket: {
        status: "open",
        priority: "low",
        followup: null,
        type: "service-ticket",
        assignee: null,
        title: "",
        description: "",
        resolution: "",
        room_name: this.rooms[0] ? this.rooms[0].name : "", 
        room_id:  this.rooms[0] ? this.rooms[0].id : null
      },
      sessions: [],
      pristine: true,
      mode: "detail"
    }
    this.ticketService.getTicketDetail(ticketId).then(res => {
      this.isLoadingDetail=false;
      this.ticketDetail = res.ticket;
      this.dataModel.ticket = {
        status: this.ticketDetail.status,
        priority: this.ticketDetail.priority || "low",
        followup: this.ticketDetail.followup ? new Date(this.ticketDetail.followup) : null,
        create_time: this.ticketDetail.create_time,
        creator: this.ticketDetail.creator.name,
        type: this.ticketDetail.type,
        assignee: this.ticketDetail.assignee?.id || null,
        title: this.ticketDetail.title,
        description: this.ticketDetail.description,
        resolution: this.ticketDetail.resolution || "",
        room_name: this.ticketDetail.room?.name ||  "",
        room_id: this.ticketDetail.room?.id
      };
      this.dataModel.orjTicket = JSON.parse(JSON.stringify(this.dataModel.ticket));
      this.selectedTicketRoomId =this.dataModel.ticket.room_id;
      this.selectedTicketRoomUsers = this.selectedTicketRoomId ? this.users.filter(u => u.rooms && u.rooms[this.selectedTicketRoomId]) : [];
      this.isLoadingDetail = false;

      this.subscriptions.push(this.ticketService.getTicketSessions2(ticketId)
      .subscribe(sessions => {
        this.dataModel.sessions = sessions.sort((a,b) => a.create_time > b.create_time ? -1 : 1);
        this.calculateSessionStats(sessions)
        .then(stats => {
          this.dataModel.sessionStats = stats;
          this.generateMailToUrl(this.dataModel);
        });
      }));
    })
    .catch((error) => {
      console.log(error)
      this.isLoadingDetail = false;
      if (error.message === "permission-denied") {
            this.flashMessageService.showTranslated(
             "APP.EXTERNALS.AD_ERROR.PERMISSION_DENIED"
            );
          }
      if (error.message === "internal-error") {
             this.flashMessageService.showTranslated(
              "APP.EXTERNALS.AD_ERROR.INTERNAL_ERROR"
            );
          }
    });
  }

  async calculateSessionStats(sessions: any[]) {
    return new Promise(resolve => {
      const stats: any = {};
      stats.count = sessions.length;
      stats.duration = 0;
      stats.users = {};
      sessions.forEach(session => {
        const sessionDuration = session.start_time && session.end_time ? session.end_time - session.start_time : 0;
        stats.duration = stats.duration + sessionDuration;
        (session.users ? Object.keys(session.users) : []).forEach(userId => {
          if (!stats.users[userId]) {
            stats.users[userId] = {
              count: 0,
              duration: 0
            }
          }
          stats.users[userId].count = stats.users[userId].count + 1;
          stats.users[userId].duration = stats.user_durations && stats.user_durations[userId] ? stats.user_durations[userId] : 0;
        });
      });
      stats.duration = `${Math.ceil(stats.duration / 60000)} min`
      resolve(stats);
    });
  }
  formatDate(d: Date): string {
    return `${d.getFullYear()}-${("0"+(d.getMonth()+1)).slice(-2)}-${("0"+d.getDate()).slice(-2)}`;// YYYY-MM-DD
  }

  private detectTicketChanges(formValue: any, orjTicket: any) {
    const simpleTicketProps = Object.keys(orjTicket).filter(p => p !== 'followup');
    const changes: any = simpleTicketProps.reduce((changes, prop) => {
      if (formValue[prop] && (formValue[prop] !== orjTicket[prop])) {
        changes[prop] = formValue[prop];
      }
      return changes;
    }, {});

    if (formValue.followup) {
      if (!orjTicket.followup) {
        changes.followup = this.formatDate(formValue.followup);
      } else if (new Date(formValue.followup).getTime() !== new Date(orjTicket.followup).getTime()) {
        changes.followup = this.formatDate(formValue.followup);
      }
    } else if (orjTicket.followup) {
      changes.followup = null;
    }
    return changes;
  }

  private generateMailToUrl(dataModel: any) {
    if (!(dataModel.ticket && dataModel.sessions && dataModel.sessionStats)) {
      return;
    }
    const ticket = dataModel.ticket;
    const sessions = dataModel.sessions;
    const sessionStats = dataModel.sessionStats;

    const getUserName = (id: string) => {
      const user = this.users.find(u => u.id === id);
      return user ? user.name : "Unknown";
    }
    const getRoomName = (id: string) => {
      const room = this.rooms.find(r => r.id === id);
      return room ? room.name : "Unknown";
    }
    const getTimeString = (timestamp: number, timezone: string, appendTimezone: boolean) => {
      const d = new Date(timestamp+UtilityService.timezoneOffset+UtilityService.timezones[timezone]);
      const formatted = ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
      return appendTimezone ? `${formatted} ${timezone}` : formatted;
    }
    const getTimeString2 = (d: Date) => {
      return ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." + d.getFullYear();
    }
    const getSessionDuration = (session: any) => {
      return session.start_time && session.end_time ? Math.ceil((session.end_time - session.start_time) / 60000) + ' min' : '-';
    }

    let participants = "";
    const userIds = Object.keys(sessionStats.users);
    for (const uid of userIds) {
      participants = `${participants}
      ${getUserName(uid)} ${sessionStats.users[uid].count} Sessions`
    }
    if (userIds.length === 0) {
      participants = `
      `;
    }
    let stats = `Attached Session Count: ${sessionStats.count}
    Total Session Duration: ${sessionStats.duration}

    Participants: ${participants}`;

    let sessionList = "";
    sessions.forEach((s, i) => {
      sessionList = `${sessionList}
      ${i+1}. ${getTimeString(s.create_time, this.timezone, true)} ${getSessionDuration(s)}`;
    });
    if (sessions.length === 0) {
      sessionList = `
      `;
    }

    // Do not delete new lines in text, they are necessary...
    const body = `${environment.design.appName} Ticket Export on ${getTimeString(ticket.create_time, this.timezone, false)} for ${ticket.title} has been created:

    Title         : ${ticket.title}
    Description   : ${ticket.description}
    Resolution    : ${ticket.resolution ? ticket.resolution : ''}

    Status        : ${this.ticketStatuses.find(s => s.id === ticket.status).name}
    Priority      : ${this.ticketPriorities.find(p => p.id === ticket.priority).name}

    Room          : ${getRoomName(ticket.room_id)}
    Created on    : ${getTimeString(ticket.create_time, this.timezone, true)}
    Created by    : ${ticket.creator}
    Assignee      : ${ticket.assignee ? getUserName(ticket.assignee) : '' }
    Followup Date : ${ticket.followup ? getTimeString2(ticket.followup) : '' }

    ${stats}

    Attached Sessions:${sessionList}`;

    this.mailToUrl = `mailto:?subject=${encodeURIComponent(ticket.title)}&body=${encodeURIComponent(body)}`;
  }


  onSessionExportModal(roomId: string, sessionId: string): void {
    this.sessionExportService.sessionExportRequest.next([
      roomId,
      sessionId,
      true,
    ]);
  }

  onChooseFile(files: FileList) {
    this.chosenFilesSource.next(files);
  }

  onAttachment(fileinput) {
    fileinput.value = '';
    fileinput.click();
  }

  onSendToSession(file) {
    this.loaderService.show();
    const isObj = this.objectSpecificExtensions.includes(file.extension);
    
    const sessionId = this.currentRoom.room_data.session.vs
    this.fileShareService.copyFileTicketToSession(this.selectedTicketRoomId, sessionId, file.key, isObj, this.ticketId)
    .catch(error => {
      if(error.error.code == 'permission-denied') {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.TICKETS.ERRORS.PERMISSION_DENIED');
      }
      if(error.error.code == 'missing-parameters') {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.TICKETS.ERRORS.MISSING_PARAMETERS');
      }
      if(error.error.code == 'file-already-exists') {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.TICKETS.ERRORS.FILE_ALREADY_EXIST');
      }
      if(error.error.code == 'file-not-found') {
        this.flashMessageService.showTranslated('APP.DOWNLOADS.FILE_NOT_FOUND');
      }
      if(error.error.code == 'internal-error') {
        this.flashMessageService.showTranslated('APP.HELP_CENTER.FAILED');
      }
      console.log(error);
    })
    .finally(() => {
      this.loaderService.hide();
    })
  }

  onDownloadFile(file: any) {
    this.flashMessageService.showTranslated('APP.MAIN.LOBBY.SESSIONS.SESSION_FILES.DOWNLOAD_STARTED_SOON', { cssClass: 'alert-info', timeout: 2000 });
    if (file.extension === 'obj') {
      const files = file.files;
      for (let f of files) {
        this.fileShareService.downloadFile(f.url, f.name)
          .catch(error => { this.flashMessageService.showTranslated('APP.MAIN.LOBBY.SESSIONS.SESSION_FILES.DOWNLOAD_FAILED'); });
      }
    } else if (file.exportPdf) {
      this.fileShareService.getSessionExportPDF(file.room_id, file.session_id)
        .catch(error => { this.flashMessageService.showTranslated('APP.MAIN.LOBBY.SESSIONS.SESSION_FILES.DOWNLOAD_FAILED'); });
    }
    else {
      this.fileShareService.downloadFile(file.url, file.name)
          .catch(error => {this.flashMessageService.showTranslated('APP.MAIN.LOBBY.SESSIONS.SESSION_FILES.DOWNLOAD_FAILED'); });
    }
  }

  onDeleteFile(file: any) {
    const modalId = this.modalService.show({
      template: this.deleteFileTemplate,
      context: {
        dataModel: null,
        callbacks: {
          no: () => {
            this.modalService.hide(modalId);
          },
          yes: () => {
            this.loaderService.show();
            this.modalService.hide(modalId);
            this.fileShareService.deleteTicketFiles(this.ticketId, file.key)
            .catch(error => {
              if(error.error.code == 'permission-denied') {
                this.flashMessageService.showTranslated('APP.MAIN.LOBBY.TICKETS.ERRORS.PERMISSION_DENIED');
              }
              if(error.error.code == 'missing-parameters') {
                this.flashMessageService.showTranslated('APP.MAIN.LOBBY.TICKETS.ERRORS.MISSING_PARAMETERS');
              }
              if(error.error.code == 'file-not-found') {
                this.flashMessageService.showTranslated('APP.DOWNLOADS.FILE_NOT_FOUND');
              }
              if(error.error.code == 'internal-error') {
                this.flashMessageService.showTranslated('APP.HELP_CENTER.FAILED');
              }
            })
            .finally(() => {
              this.loaderService.hide();
            })
          }
        }
      }
    })
  }

}
