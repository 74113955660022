import { NgModule } from '@angular/core';

import { RouterModule, Routes, UrlMatcher, UrlMatchResult, UrlSegment } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { CanDeactivateGuard } from '../guards/can-deactivate.guard';
import { ContactsGuard } from 'app/guards/contacts.guard';
import { TicketsGuard } from 'app/guards/tickets.guard';
import { HelpCenterGuard } from 'app/guards/help-center.guard';
import { PrivacyPolicyGuard } from 'app/guards/privacy-policy.guard';

import { LoginComponent } from '../components/login/login.component';
import { TermsComponent } from '../components/pages/terms/terms.component';
import { PrivacyComponent } from '../components/pages/privacy/privacy.component';
import { SecurityInformationComponent } from '../components/pages/security-information/security-information.component';
import { EulaComponent } from '../components/pages/eula/eula.component';
import { DownloadsComponent } from 'app/components/externals/downloads/downloads.component';
import { NotFoundComponent } from '../components/not-found/not-found.component';
import { MainComponent } from '../components/main/main.component';
import { LobbyComponent } from '../components/main/lobby/lobby.component';
import { SessionRatingComponent } from 'app/components/main/session-rating/session-rating.component';
import { RoomComponent } from '../components/main/room/room.component';
import { VsGuideComponent } from 'app/components/pages/vs-guide/vs-guide.component';
import { CollaborationComponent } from 'app/components/externals/collaboration/collaboration.component';
import { JoinWithLinkComponent } from 'app/components/externals/join-with-link/join-with-link.component';
import { OwnRoomsComponent } from 'app/components/main/lobby/own-rooms/own-rooms.component';
import { LobbyContentComponent } from 'app/components/main/lobby/lobby-content/lobby-content.component';
import { MeetingsComponent } from 'app/components/main/lobby/meetings/meetings.component';
import { ContactsComponent } from 'app/components/main/lobby/contacts/contacts.component';
import { AuthenticateComponent } from 'app/components/externals/authenticate/authenticate.component';
import { ArchiveListComponent } from 'app/components/externals/archive-list/archive-list.component';
import { TicketsComponent } from 'app/components/main/lobby/tickets/tickets.component';
import { FeedbackComponent } from 'app/components/main/lobby/feedback/feedback.component';
import { WhatsnewComponent } from 'app/components/main/lobby/whatsnew/whatsnew.component';
import { AdLoginComponent } from 'app/components/externals/ad-login/ad-login.component';
import { SsoLoginComponent } from 'app/components/externals/sso-login/sso-login.component';
import { AdErrorComponent } from 'app/components/externals/ad-error/ad-error.component';
import { MobileFeedbackComponent } from 'app/components/externals/mobile-feedback/mobile-feedback.component';
import { DownloadExportComponent } from 'app/components/externals/download-export/download-export.component';
import { SessionsComponent } from 'app/components/main/lobby/sessions/sessions.component';
import { AppSelectComponent } from 'app/components/main/lobby/app-select/app-select.component';
import { GetFileComponent } from 'app/components/externals/get-file/get-file.component';
import { ReconnectRoomComponent } from 'app/components/main/reconnect-room/reconnect-room.component';
import { DownloadAppComponent } from 'app/components/externals/download-app/download-app.component';
import { ConnectExpertsComponent } from 'app/components/externals/connect-experts/connect-experts.component';

const downloadsUrlMatcher: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult => {
  const firstSegment = segments[0]
  if (firstSegment.path === "downloads") {
    const idSegments = segments.slice(1)
    if (idSegments.length === 0) {
      return ({ consumed: segments })
    }
    const idPaths = idSegments.map(segment => segment.path)
    const mergedId = idPaths.join('/')
    const idSegment: UrlSegment = new UrlSegment(mergedId, { id: mergedId })
    return ({ consumed: segments, posParams: { id: idSegment } })
  }
  return null
}

const routes: Routes = [
  { path: '', component: MainComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: LobbyComponent,
        children: [
          { path: '', component: LobbyContentComponent },
          { path: 'rooms', component: OwnRoomsComponent },
          { path: 'meetings', component: MeetingsComponent },
          { path: 'contacts', component: ContactsComponent, canActivate: [ContactsGuard] },
          { path: 'sessions', component: SessionsComponent },
          { path: 'precall', loadChildren: () => import('app/components/main/lobby/precall-test/precall-test.module').then(mod => mod.PrecallTestModule) },
          { path: 'converter', loadChildren: () => import('../components/main/lobby/converter/converter.module').then(mod => mod.ConverterModule) },
          { path: 'tickets', component: TicketsComponent, canActivate: [TicketsGuard] },
          { path: 'whatsnew', component: WhatsnewComponent },
          { path: 'feedback', component: FeedbackComponent }
        ]
      },
      { path: 'reconnect', component: ReconnectRoomComponent },
      { path: 'session-rating', component: SessionRatingComponent },
      { path: 'room', component: RoomComponent, canDeactivate: [CanDeactivateGuard] }
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'login/:account_name', component: LoginComponent },
  { path: 'authenticate', component: AuthenticateComponent },
  { path: 'adlogin', component: AdLoginComponent },
  { path: 'ssologin', component: SsoLoginComponent },
  { path: 'aderror', component: AdErrorComponent },
  { path: 'app-select', component: AppSelectComponent},
  { path: 'archive-list', component: ArchiveListComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'security-information', component: SecurityInformationComponent },
  { path: 'eula', component: EulaComponent },
  { path: 'help-center', component: VsGuideComponent, canActivate: [HelpCenterGuard] },
  { path: 'collaboration', component: CollaborationComponent },
  { path: 'joinroom', component: JoinWithLinkComponent },
  { path: 'mobile-feedback', component: MobileFeedbackComponent },
  { path: 'api/downloadexport', component: DownloadExportComponent },
  { path: 'file', component: GetFileComponent },
  { path: 'download', component: DownloadAppComponent },
  { path: 'connectexperts', component: ConnectExpertsComponent },
  { matcher: downloadsUrlMatcher, component: DownloadsComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  exports: [
    RouterModule
  ],
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  declarations: []
})
export class AppRoutingModule { }
