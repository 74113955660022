import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import * as OT from '@opentok/client';

import { CallService } from '@services/core/call.service';
import { RoomService } from '@services/other/room.service';
import { OpentokService } from '@services/core/opentok.service';
import { ModalService } from '@services/support/modal.service';
import { LoaderService } from '@services/support/loader.service';
import { UtilityService } from '@services/support/utility.service';
import { FlashMessageService } from '@services/support/flash-message.service';

import { Subscription } from 'rxjs';

import { Room } from '@models/Room';
import { AuthService } from '@services/core/auth.service';
import { RoomSessionService } from '@services/core/room-session.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-own-rooms',
  templateUrl: './own-rooms.component.html',
  styleUrls: ['./own-rooms.component.scss']
})
export class OwnRoomsComponent implements OnInit, OnDestroy {

  @ViewChild("archivePermissionModal", { static: true }) private archivePermissionModal: TemplateRef<any>;
  @ViewChild("notSupportedTemplate", { static: true }) private notSupportedTemplate: TemplateRef<any>;
  @ViewChild("joinTemplate", { static: true }) private joinTemplate: TemplateRef<any>;
  @ViewChild("scheduleMeetingTemplate", { static: true }) private scheduleMeetingTemplate: TemplateRef<any>;
  @ViewChild("cancelMeetingTemplate", { static: true }) private cancelMeetingTemplate: TemplateRef<any>;
  @ViewChild("noDeviceTemplate", {static: true}) private noDeviceTemplate: TemplateRef<any>;

  @ViewChild('videoElement') videoElement: ElementRef;

  rooms: Room[] = [];
  roomsLoading: boolean = true;
  roomsSub: Subscription = null;

  @Output() joinRoom = new EventEmitter<Room>();

  colors: string[] = ['bg-primary', 'bg-secondary', 'bg-info', 'bg-warning', 'bg-danger'];

  showRoomUsers: any = {};
  canJoin = true

  cameraMirror: boolean = false;
  private cameraMirrorSub: Subscription = null;

  constructor(
    private callService: CallService,
    private roomService: RoomService,
    private opentokService: OpentokService,
    private utilityService: UtilityService,
    private router: Router,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private flashMessageService: FlashMessageService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.roomsSub = this.roomService.allOwnRooms.subscribe(rooms => {
      if (rooms) {
        this.rooms = rooms.filter(a => !a.room_data.meeting_room && !a.room_data.personal_room).sort((a, b) => {
          if (a.room_data.in_room.count === 0 && b.room_data.in_room.count > 0) {
            return 1;
          } else if (a.room_data.in_room.count > 0 && b.room_data.in_room.count === 0) {
            return -1;
          } else {
            return this.alphabeticalSort(a, b);
          }
        });
        if(this.authService.currentUser?.default_room && this.canJoin && !this.roomService.isJoinedRoom) {
          let room = this.rooms.find(room => room.id == this.authService.currentUser.default_room)
          if(room) {
            this.onJoin(room)
            this.roomService.changeJoinStatus(true)
          }
        }
        this.roomsLoading = false;
      }
    })

    this.cameraMirrorSub = this.opentokService.cameraMirror.subscribe(mirror => {
      this.cameraMirror = mirror;
    });
  }

  cameraMirrorChanged(mirror: boolean) {
    this.opentokService.changeCameraMirror(mirror);
  }

  alphabeticalSort(a, b) {
    return a.room_data.name.toLocaleLowerCase().localeCompare(b.room_data.name.toLocaleLowerCase());
  }

  ngOnDestroy() {
    if (this.roomsSub) { this.roomsSub.unsubscribe() }
    if (this.cameraMirrorSub) { this.cameraMirrorSub.unsubscribe() }
  }

  onJoin(room: Room) {
    this.canJoin = false
    const parser = this.utilityService.getBrowserParser();
    const isTouchDevice = 'ontouchstart' in window;
    // Is Device iOS or ipadOS (detected as touch macOS)
    const isIOS = parser.is("iOS");
    const isIpad = parser.is("macOS") && isTouchDevice;

    if (isIOS || isIpad) {
      if (!parser.is("Safari")) {
        this.showNotSupportedModal({ ios: true });
        return;
      } else if (!parser.satisfies({safari: ">=12"})) {
        this.showNotSupportedModal({ ios: true, ios_version_low: true });
        return;
      }
    }
    if (parser.is("Microsoft Edge") && !parser.satisfies({edge: '>=79' })) {
      this.showNotSupportedModal({ ios: false });
      return;
    }

    if (!(environment.design.supportedBrowserList.some(browserName => parser.is(browserName)) && this.utilityService.checkOpentokRequirements())) {
      this.showNotSupportedModal({ ios: false });
      return;
    }
    this.showJoinModal(room);
  }

  showNotSupportedModal(dataModel: any) {
    const modalId = this.modalService.show({
      template: this.notSupportedTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          close: () => this.modalService.hide(modalId)
        }
      }
    });
  }

  showJoinModal(room: Room) {
    // @ts-ignore
    const AContext = window.AudioContext || window.webkitAudioContext;
    const model = {
      publishResolutions: ["320x180", "320x240", "640x360", "640x480", "1280x720", "1280x960"],
      currentStream: null,
      audios: [],
      videos: [],
      selectedVideo: null,
      selectedAudio: null,
      audioEnabled: true,
      videoEnabled: false,
            //@ts-ignore
      audioContext: new AContext(),
      audioLevel: 0,
      audioLevelSub: null,
      settingsOn: false,
      status: "waiting",
      enableJoin: false,
      enableCancel: false
    };
    const modalId = this.modalService.show({
      template: this.joinTemplate,
      context: {
        dataModel: model,
        callbacks: {
          toggleAudio: () => this.opentokService.togglePreviewAudio(model),
          toggleVideo: () => this.opentokService.togglePreviewVideo(model),
          changeAudioSource: (device: OT.Device) => this.opentokService.changePreviewSource("audio", model, this.videoElement),
          changeVideoSource: (device: OT.Device) => this.opentokService.changePreviewSource("video", model, this.videoElement),
          close: () => {
            this.opentokService.destroyPreview(model, this.videoElement);
            this.modalService.hide(modalId);
          },
          join: () => {
            this.opentokService.destroyPreview(model, this.videoElement);
            if (model.status === 'no-devices-found') {
              this.showNoDeviceModal(modalId, room, model);
            } else {
              this.modalService.hide(modalId);
              this.join(room, model, false);
            }
          }
        }
      }
    });

    setTimeout(() => {
      this.opentokService.startPreview(model, this.videoElement);
    }, 1000);
  }

  showNoDeviceModal(joinModalId: number, room: Room, model: any) {
    const modalId = this.modalService.show({
      template: this.noDeviceTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () => {
            this.modalService.hide(modalId);
          },
          join: () => {
            this.modalService.hide(modalId);
            this.modalService.hide(joinModalId);
            this.join(room, model, false);
          }
        }
      }
    });
  }

  join(room: Room, dataModel: any, archiveAllowed: boolean) {
    this.loaderService.show();
    this.callService.joinRoom(room.id, archiveAllowed)
    .then(() => {
      this.loaderService.hide();
      this.router.navigate(['/room']);
    })
    .catch(error => {
      this.loaderService.hide();
      if (error.message === 'concurrent-limit-reached') {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.JOIN_CONCURRENT_ERROR', { timeout: 10000 });
      } else if (error.message === 'expert-concurrent-limit-reached') {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.JOIN_EXPERT_CONCURRENT_ERROR', { timeout: 10000 });
      } else if (error.message === 'no-training-license') {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.NO_TRAINING_LICENSE');
      } else if (error.message === 'training-license-expired') {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.TRAINING_LICENSE_EXPIRED');
      } else if (error.message === 'training-license-error') {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.ASK_TRAINING_HOST');
      } else if (error.message === 'archive-permission-needed') {
        this.showArchivePermissionModal(room, dataModel);
      } else {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.JOIN_UNKNOwN_ERROR');
      }
    });
  }

  showArchivePermissionModal(room: Room, dataModel: any) {
    const modalId = this.modalService.show({
      template: this.archivePermissionModal,
      context: {
        dataModel: null,
        callbacks: {
          deny: () => this.modalService.hide(modalId),
          allow: () => {
            this.join(room, dataModel, true);
            this.modalService.hide(modalId);
          }
        }
      }
    });
  }
}
