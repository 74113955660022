import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Ticket } from '@models/Ticket';
import { TicketDetail } from '@models/TicketDetail';
import { User } from '@models/User';
import { AuthService } from '@services/core/auth.service';
import { SessionExportService } from '@services/core/session-export.service';
import { TicketService } from '@services/other/ticket.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { UtilityService } from '@services/support/utility.service';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TicketDetailComponent } from './ticket-detail/ticket-detail.component';
import { LoaderService } from '@services/support/loader.service';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {
  @ViewChild(MatSort) set sort(sort: MatSort) {
    if(this.dataSource?.sort ){
      this.dataSource.sort = sort;
    }
  };

  @Input() showFilter = true;
  @Input() isModalView = false;
  @Input() filter = "";
  @Input() roomId;
  @Output() onTicketSelection: EventEmitter<Ticket> = new EventEmitter();
  onClose: EventEmitter<any> = new EventEmitter();

  mode:string;

  modalRef: BsModalRef = null;

  timezone = "UTC";

  ticketStatuses: any[] = [{ id: "open", name: "Open" }, { id: "in-progress", name: "In Progress" }, { id: "closed", name: "Closed" }];
  ticketPriorities: any[] = [{ id: "low", name: "Low" }, { id: "medium", name: "Medium" }, { id: "high", name: "High" }];

  filteredTickets: any[] = [];
  userTickets: Ticket[];
  
  users: any[] = [];

  isExpert: boolean = null;

  rooms: any[] = [];

  filterStartEnd: Date[] = null;
  filterUsers: string[] = [];
  filterRooms: string[] = [];
  filterStatuses: string[] = [];
  filterCreators: string[] = [];

  selectedTicket: Ticket = null;
  selectedTicketRoomId: string = null;
  selectedTicketRoomUsers: any[] = [];

  mailToUrl: string;

  displayedColumns: string[] = ['index', 'title', 'room.name', 'creator.name', 'create_time', 'status'];
  dataSource: any;

  creatorFilterList;

  isLoading = false;

  private subscriptions: Subscription[] = [];

  ticketDetail: TicketDetail;
  isLoadingDetail = false;

  constructor(
    private authService: AuthService,
    private flashMessageService: FlashMessageService,
    private ticketService: TicketService,
    private sessionExportService: SessionExportService,
    private modal: BsModalService,
    private loaderService: LoaderService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit(): void {
    this.subscriptions.push(this.authService.timezone.pipe(
      distinctUntilChanged()
    ).subscribe(res=>{
       this.timezone = res;
    })) 
    this.getTickets();
    this.subscriptions.push(this.authService.user.subscribe(user => {
      this.rooms = ((user && user.rooms) ? Object.keys(user.rooms).map(rid => { return { id: rid, name: user.rooms[rid].name }; }) : [])
        .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
    }));

    this.subscriptions.push(this.authService.getAllUsers(true).subscribe(users => {
      if (users) {
        // [...users] needed for change detection mechanism of ng-select
        this.users = [...users].sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
        this.selectedTicketRoomUsers = this.selectedTicketRoomId ? this.users.filter(u => u.rooms && u.rooms[this.selectedTicketRoomId]) : [];
      } else {
        this.users = [];
      }
    }));
    this.isExpert = this.authService.currentUser.role === 'expert';
  }


  onRowClick(ticket: Ticket): void {
    this.selectedTicket = ticket;
    if(!this.isModalView) {
      this.openTicketDetailModal(ticket.id);
      return;
    }
    this.onTicketSelection.emit(ticket);
  }

  openTicketDetailModal(ticketId):void {
    const initialState= {
      ticketId,
      mode:'detail'
    };
    this.modalRef = this.modal.show(TicketDetailComponent,  {initialState, backdrop: 'static', class: 'modal-lg modal-dialog-centered', animated:false});
    this.subscriptions.push(this.modalRef.content.onClose.subscribe(() => {
      this.getTickets();
    }));
  }

  onCreateTicket():void {
    const initialState= {
      mode:'create'
    };
    this.modalRef = this.modal.show(TicketDetailComponent,  {initialState, backdrop:'static', class: 'modal-lg modal-dialog-centered', animated:false});
    this.subscriptions.push(this.modalRef.content.onClose.subscribe(() => {
      this.getTickets();
    }));
  }

  onFilterChanged(): void {
    if(this.filter){
      this.filteredTickets = this.userTickets.filter((ticket)=>ticket.room.id == this.roomId)
    }else{
      this.filteredTickets = this.userTickets
    .filter(t => this.filterRooms.length > 0 ? this.filterRooms.includes(t.room.id) : true)
    .filter(t => this.filterStatuses.length > 0 ? this.filterStatuses.includes(t.status) : true)
    .filter(t => this.filterCreators.length > 0 ? this.filterCreators.includes(t.creator.name) : true)
    .filter(t => {
      if (!this.filterStartEnd) { return true; }
      // Duplicate dates
      const start = new Date(this.filterStartEnd[0].getTime());
      const end = new Date(this.filterStartEnd[1].getTime());
      // Set hours to edges (midnight) in browser timezone
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);
      // Calculate midnight of account timezone
      const startTime = start.getTime()-UtilityService.timezoneOffset-UtilityService.timezones[this.timezone];
      const endTime = end.getTime()-UtilityService.timezoneOffset-UtilityService.timezones[this.timezone];
      // If this tickets' create time not inside this dates, filter it
      return startTime < t.create_time && t.create_time < endTime;
    });
    }
    this.dataSource = new MatTableDataSource([...this.filteredTickets]);
    this.dataSource.sortingDataAccessor = (item: Ticket, property) => {
      switch (property) {
        case "room.name":
          return item.room.name;
          case "creator.name":
            return item.creator.name;
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
  }

  getTickets(): void {
  this.isLoading = !!!this.modalRef;
   this.ticketService.getTickets()
   .then(res => {
    this.isLoading = false;
    this.userTickets = res.tickets.sort((a,b) => a.created_time > b.created_time ? 1 : -1);
    const uniqueCreators= new Set();
    this.userTickets.forEach((ticketItem) => {
        uniqueCreators.add(ticketItem.creator.name);
    });
    this.creatorFilterList = Array.from(uniqueCreators);
    this.onFilterChanged();
  })
  .catch((error) => {
    this.isLoading=false;
    if (error.message === "permission-denied") {
        this.flashMessageService.showTranslated(
           "APP.EXTERNALS.AD_ERROR.PERMISSION_DENIED"
          );
        }
    if (error.message === "internal-error") {
        this.flashMessageService.showTranslated(
          "APP.EXTERNALS.AD_ERROR.INTERNAL_ERROR"
        );
      }
    })
  }

  getUsersName(id: string) {
    const user = this.users.find(u => u.id === id);
    return user ? user.name : "Unknown";
  }

  getRoomName(id: string) {
    const room = this.rooms.find(r => r.id === id);
    return room ? room.name : "Unknown";
  }

  onSessionExportModal(roomId:string, sessionId: string) {
    this.sessionExportService.sessionExportRequest.next([roomId,sessionId, true]);
  }
}
