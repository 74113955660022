import { Compiler, Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';

import { PdfCollaborationComponent } from '../pdf-collaboration.component';

import { Frame } from '@models/Frame';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pdf-collaboration-wrapper',
  templateUrl: './pdf-collaboration-wrapper.component.html',
  styleUrls: ['./pdf-collaboration-wrapper.component.scss']
})
export class PdfCollaborationWrapperComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef;
  @Input('frame') frame: Frame;
  @Input('size') size: string;
  @Input('external') external: boolean = false;
  @Input('nohover') nohover: boolean = false;
  @Output() toggleFullscreen = new EventEmitter<boolean>();
  @Output() toggleDownloadButtonClicked = new EventEmitter<boolean>();

  pdfCollaborationComponent: PdfCollaborationComponent;
  fullscreenSub: Subscription = null;
  toggleDownloadButtonSub: Subscription = null;
  loadFailed = false;
  reloadCount = 0;

  constructor(
    private compiler: Compiler,
    private injector: Injector
  ) { }

  ngOnInit(): void {
    this.lazyLoadPdfCollaboration();
  }

  ngOnDestroy(): void {
    if (this.fullscreenSub) { this.fullscreenSub.unsubscribe() }
    if (this.toggleDownloadButtonSub) { this.toggleDownloadButtonSub.unsubscribe() }
  }

  lazyLoadPdfCollaboration() {
    this.loadFailed = false;
    import('../pdf-collaboration.module')
    .then(({ PdfCollaborationModule }) => this.compiler.compileModuleAsync(PdfCollaborationModule))
    .then(moduleFactory => {
      const moduleRef = moduleFactory.create(this.injector);
      const componentFactory = moduleRef.instance.resolveComponent();
      const { instance } = this.container.createComponent(componentFactory, undefined, moduleRef.injector);
      instance.frame = this.frame;
      instance.size = this.size;
      instance.external = this.external;
      instance.nohover = this.nohover;
      this.fullscreenSub = instance.toggleFullscreen.asObservable().subscribe(f => this.toggleFullscreen.emit(f));
      this.toggleDownloadButtonSub = instance.toggleDownloadButtonClicked.asObservable().subscribe(td => this.toggleDownloadButtonClicked.emit(td));
      this.pdfCollaborationComponent = instance;
    })
    .catch(error => {
      this.loadFailed = true;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.pdfCollaborationComponent) {
      this.pdfCollaborationComponent.ngOnChanges(changes);
    }
  }

  reload() {
    this.reloadCount = this.reloadCount + 1;
    if (this.reloadCount < 3) {
      this.lazyLoadPdfCollaboration();
    } else {
      document.location.reload();
    }
  }
}
