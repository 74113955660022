import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'objSize'})
export class ObjSizePipe implements PipeTransform {

  private units = [
    'bytes',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB'
  ];

  transform(files: any[], precision: number = 2 ) : string {
    let bytes = 0
    for (const file of files) {
        bytes += file.size
    }
    if ( isNaN( parseFloat( String(bytes) )) || ! isFinite( bytes ) ) return '?';

    let unit = 0;

    while ( bytes >= 1024 ) {
      bytes /= 1024;
      unit ++;
    }

    return bytes.toFixed( + precision ) + ' ' + this.units[ unit ];
  }
}