import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { User } from '@models/User';
import { AuthService } from '@services/core/auth.service';
import { RoomSessionService } from '@services/core/room-session.service';
import { FileShareService } from '@services/other/file-share.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { LoaderService } from '@services/support/loader.service';
import { ModalService } from '@services/support/modal.service';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-rooms-files',
  templateUrl: './rooms-files.component.html',
  styleUrls: ['./rooms-files.component.scss']
})
export class RoomsFilesComponent implements OnInit {

  @ViewChild("deleteFileTemplate", { static: true }) private deleteFileTemplate: TemplateRef<any>;

  dropzoneStateSource = new Subject<boolean>();
  chosenFilesSource = new Subject<FileList>();

  imageExtensions: string[] = ['jpg', 'jpeg', 'png'];
  pdfExtensions: string[] = ['pdf'];
  objectSpecificExtensions: string[] = ['obj', 'stl', 'gltf', 'glb', 'dae', 'scn'];

  features: { [key: string]: boolean } = {
    filesharing: false
  };
  featuresSub: Subscription = null;

  filesSub: Subscription = null;
  filesArray: any[] = [];

  isDropdownOpen: boolean = false;

  currentUser: User = null;

  constructor(
    private authService: AuthService,
    private roomSessionService: RoomSessionService,
    private fileShareService: FileShareService,
    private flashMessageService: FlashMessageService,
    private loaderService: LoaderService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.featuresSub = this.authService.features.subscribe(features => {
      this.features = features;
    });
    this.filesSub = this.roomSessionService.getRoomFiles().subscribe(files => {
      if (files) {
        this.filesArray = Object.values(files);
      } else  {
        this.filesArray = [];
      }
    })
    this.currentUser = this.authService.currentUser;
  }

  onChooseFile(files: FileList) {
    if (this.features.filesharing) {
      this.chosenFilesSource.next(files);
    } else {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE');
    }
  }

  onAttachment(fileinput) {
    if (this.features.filesharing) {
      fileinput.value = '';
      fileinput.click();
    } else {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE');
    }
  }

  onDropEnter() {
    if (this.features.filesharing) {
      this.dropzoneStateSource.next(true);
    } else {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE');
    }
  }

  onDownloadFile(file: any) {
    this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.FILES.DOWNLOAD_STARTED_SOON', { cssClass: 'alert-info', timeout: 2000 });
    if (this.objectSpecificExtensions.includes(file.extension)) {
        //@ts-ignore
        const files = file.files;
        for (let f of files) {
            this.fileShareService.downloadFile(f.url, f.name)
                .catch(error => {
                  this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.FILES.DOWNLOAD_FAILED'); 
                  console.log(error);
                });
        }
    } else {
        this.fileShareService.downloadFile(file.url, file.name)
            .catch(error => {
              this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.FILES.DOWNLOAD_FAILED');
              console.log(error);
             });
    }
  }

  onSendToSession(file: any) {
    this.loaderService.show();
    const isObj = this.objectSpecificExtensions.includes(file.extension);
    this.fileShareService.copyFileRoomToSession(this.roomSessionService.currentRoomId, this.roomSessionService.currentSessionId, file.key, isObj)
    .catch(error => {
      if(error.error.code == 'permission-denied') {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.TICKETS.ERRORS.PERMISSION_DENIED');
      }
      if(error.error.code == 'file-already-exists') {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.TICKETS.ERRORS.FILE_ALREADY_EXIST');
      }
      if(error.error.code == 'internal-error') {
        this.flashMessageService.showTranslated('APP.HELP_CENTER.FAILED');
      }
      console.log(error);
    })
    .finally(() => {
      this.loaderService.hide();
    })
  }

  onDeleteFile(file: any) {
    const modalId = this.modalService.show({
      template: this.deleteFileTemplate,
      context: {
        dataModel: null,
        callbacks: {
          no: () => {
            this.modalService.hide(modalId);
          },
          yes: () => {
            this.loaderService.show();
            this.modalService.hide(modalId);
            const isObj = this.objectSpecificExtensions.includes(file.extension);
            this.fileShareService.deleteFromRoomFiles(this.roomSessionService.currentRoomId, file.key, isObj)
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.loaderService.hide();
            })
          }
        }
      }
    })
  }
}
