import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CallService } from '@services/core/call.service';
import { DbService } from '@services/core/db.service';
import { OpentokService } from '@services/core/opentok.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { LoaderService } from '@services/support/loader.service';
import { ModalService } from '@services/support/modal.service';
import { interval, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-reconnect-room',
  templateUrl: './reconnect-room.component.html',
  styleUrls: ['./reconnect-room.component.scss']
})
export class ReconnectRoomComponent implements OnInit, OnDestroy {
  @ViewChild("archivePermissionModal", { static: true }) private archivePermissionModal: TemplateRef<any>;

  endCallResponse: { roomId: string, sessionId: string, kickRoomStatus: string, reconnectionRequired: boolean, sessionEnded: boolean };
  countdown: number;
  tryingToReconnect = false;
  counterSub: Subscription = null;
  cancelReconnectionLater = false;

  retryCount = 0;
  durations = [5, 5, 10, 10, 30];

  pAudio: boolean;
  pVideo: boolean;

  constructor(
    private dbService: DbService,
    private router: Router,
    private callService: CallService,
    private opentokService: OpentokService,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private flashMessageService: FlashMessageService
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (!(navigation && navigation.extras && navigation.extras.state)) {
      this.router.navigate(['/']);
    } else {
      this.endCallResponse = <any>navigation.extras.state;
    }
  }

  ngOnInit(): void {
    if (this.endCallResponse) {
      this.tryToReconnect(0, false);
      this.opentokService.publishAudio.pipe(first()).toPromise().then(a => { this.pAudio = a; });
      this.opentokService.publishVideo.pipe(first()).toPromise().then(v => { this.pVideo = v; });  
    }
  }

  ngOnDestroy() {
    if (this.counterSub) { this.counterSub.unsubscribe() }
  }

  tryToReconnect(retry: number, archiveAllowed: boolean) {
    if (!this.callService.evaluateReconnectionRequired(this.endCallResponse.kickRoomStatus)) {
      this.cancelReconnect();
      return;
    }

    this.tryingToReconnect = true;
    this.callService.joinRoom(this.endCallResponse.roomId, archiveAllowed)
    .then(() => {
      if (this.cancelReconnectionLater) {
        this.callService.endCall().finally(() => {
          this.loaderService.hide();
          this.cancelReconnect();
        });
      } else {
        this.router.navigate(['/room']);
      }
    })
    .catch(error => {
      if (this.cancelReconnectionLater) {
        this.loaderService.hide();
        this.cancelReconnect();
      } else {
        if (error.message === 'concurrent-limit-reached') {
          this.flashMessageService.showTranslated('APP.MAIN.LOBBY.JOIN_CONCURRENT_ERROR', { timeout: 10000 });
          this.cancelReconnect();
        } else if (error.message === 'expert-concurrent-limit-reached') {
          this.flashMessageService.showTranslated('APP.MAIN.LOBBY.JOIN_EXPERT_CONCURRENT_ERROR', { timeout: 10000 });
          this.cancelReconnect();
        } else if (error.message === 'no-training-license') {
          this.flashMessageService.showTranslated('APP.MAIN.LOBBY.NO_TRAINING_LICENSE');
          this.cancelReconnect();
        } else if (error.message === 'training-license-expired') {
          this.flashMessageService.showTranslated('APP.MAIN.LOBBY.TRAINING_LICENSE_EXPIRED');
          this.cancelReconnect();
        } else if (error.message === 'training-license-error') {
          this.flashMessageService.showTranslated('APP.MAIN.LOBBY.ASK_TRAINING_HOST');
          this.cancelReconnect();
        } else if (error.message === 'archive-permission-needed') {
          this.showArchivePermissionModal(retry);
        } else if (retry < this.durations.length) {
          this.resetCountdown(retry, archiveAllowed);
          return;
        } else {
          this.cancelReconnect();
        }
      }
    });
  }

  showArchivePermissionModal(retry: number) {
    const modalId = this.modalService.show({
      template: this.archivePermissionModal,
      context: {
        dataModel: null,
        callbacks: {
          deny: () => {
            this.cancelReconnect();
            this.modalService.hide(modalId);
          },
          allow: () => {
            this.resetCountdown(retry, true);
            this.modalService.hide(modalId);
          }
        }
      }
    });
  }

  resetCountdown(retry: number, archiveAllowed: boolean) {
    this.countdown = this.durations[retry];
    this.tryingToReconnect = false;

    let connected = this.dbService.connected();
    this.counterSub = interval(1000).subscribe(() => {
      this.countdown = this.countdown - 1;
      if (!connected && this.dbService.connected()) {
        this.countdown = 0;
      }
      connected = this.dbService.connected();

      if (this.countdown === 0) {
        this.counterSub.unsubscribe();
        this.tryToReconnect(retry + 1, archiveAllowed);
      }
    });
  }

  tryToCancelReconnect() {
    this.loaderService.show();
    if (this.tryingToReconnect) {
      this.cancelReconnectionLater = true;
    } else {
      this.loaderService.hide();
      this.cancelReconnect();
    }
  }

  cancelReconnect() {
    this.counterSub.unsubscribe();
    this.router.navigate(['/']);
  }
}
