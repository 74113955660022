            <div class="modal-header d-flex align-items-center justify-content-between">
                <h5 class="modal-title d-flex align-items-center"><i class="mi mi-style" style="font-size: 20px; padding-right: 4px;"></i>
                    {{ (dataModel.mode === 'create' ? 'APP.MAIN.LOBBY.TICKETS.CREATE_TICKET' : 'APP.MAIN.LOBBY.TICKETS.TICKET_DETAILS') | translate }}
                </h5>
                <button *ngIf="dataModel.mode === 'detail'" type="button" class="btn btn-dark d-flex align-items-center justify-content-between rounded-0 px-2 py-0" (click)="edit(ticketForm)">
                    {{ 'APP.MAIN.LOBBY.TICKETS.EDIT' | translate }}
                </button>
            </div>
            <div class="modal-body-ticket p-0">
                    <form #ticketForm="ngForm" class="ticket-form px-3 pt-3" autocomplete="off">
                        <ng-container *ngIf="!isLoadingDetail; else loadingTemplate">
                            <input autocomplete="false" name="hidden" type="text" style="display:none;">
                            <div class="form-row">
                                <div class="form-group col-12 col-md-6">
                                    <label for="type">{{ 'APP.MAIN.LOBBY.TICKETS.TICKET_TYPE' | translate }}</label>
                                    <ng-select [items]="ticketTypes" name="type" bindLabel="name" bindValue="id" [multiple]="false" [disabled]="dataModel.mode === 'detail'"
                                        placeholder="{{ 'APP.MAIN.LOBBY.TICKETS.TICKET_TYPE' | translate }}" [(ngModel)]="dataModel.ticket.type" [clearable]="false">
                                    </ng-select>
                                </div>
                            </div>
                            <hr class="mt-0">
                            <div class="form-row">
                                <ng-container *ngIf="dataModel.mode === 'detail'">
                                    <div class="col-12">
                                        <span class="font-weight-bold">{{ 'APP.MAIN.LOBBY.TICKETS.ROOM2' | translate }}</span><span>{{ dataModel.ticket.room_name }}</span>
                                    </div>
                                    <div class="col-12 mt-1">
                                        <span class="font-weight-bold">{{ 'APP.MAIN.LOBBY.TICKETS.CREATED_BY2' | translate }}</span><span>{{ dataModel.ticket.creator }}</span>
                                    </div>
                                    <div class="col-12 mt-1">
                                        <span class="font-weight-bold">{{ 'APP.MAIN.LOBBY.TICKETS.CREATED_ON2' | translate }}</span><span>{{ dataModel.ticket.create_time | utcCalcAccount:true | async }}</span>    
                                    </div>
                                </ng-container>
                                <div class="form-group col-12 col-md-6 mb-0" *ngIf="dataModel.mode !== 'detail' && !roomSession">
                                    <label for="room_id">{{ 'APP.MAIN.LOBBY.TICKETS.ROOM3' | translate }}</label>
                                    <ng-select [items]="rooms" name="room_id" bindLabel="name" bindValue="id" [multiple]="false" [disabled]="dataModel.mode === 'detail'"
                                        placeholder="{{ 'APP.MAIN.LOBBY.TICKETS.ROOM3' | translate }}" [(ngModel)]="dataModel.ticket.room_id ? dataModel.ticket.room_id : selectedTicketRoomId" [clearable]="false" (change)="roomChanged($event)">
                                    </ng-select>
                                </div>
                                <div class="col-12 col-md-6 mb-0" *ngIf="roomSession && dataModel.mode !== 'detail'">
                                        <span class="font-weight-bold">{{ 'APP.MAIN.LOBBY.TICKETS.ROOM2' | translate }}</span><span>{{currentRoom.room_data.name}}</span>
                                  
                                </div>
                            </div>
                            <hr>
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label for="title">{{ 'APP.MAIN.LOBBY.TICKETS.TICKET_TITLE2' | translate }}</label>
                                    <input #title="ngModel" class="form-control" type="text" name="title" placeholder="{{ 'APP.MAIN.LOBBY.TICKETS.TICKET_TITLE2' | translate }}" 
                                        [ngClass]="{'is-invalid':title.errors && title.touched}" [disabled]="dataModel.mode === 'detail'"
                                        [(ngModel)]="dataModel.ticket.title" maxlength="100" required>
                                    <small class="invalid-feedback mx-2" [hidden]="!title.errors?.required">{{ 'APP.MAIN.LOBBY.TICKETS.TITLE_REQUIRED' | translate }}</small>
                                </div>
                                <div class="form-group col-12">
                                    <label for="description">{{ 'APP.MAIN.LOBBY.TICKETS.DESCRIPTION' | translate }}</label>
                                    <textarea #description="ngModel" class="form-control" rows="3" placeholder="{{ 'APP.MAIN.LOBBY.TICKETS.DESCRIPTION' | translate }}" [(ngModel)]="dataModel.ticket.description"
                                        name="description" [ngClass]="{'is-invalid':description.errors && description.touched}" maxlength="1000" required [disabled]="dataModel.mode === 'detail'"></textarea>
                                    <small class="invalid-feedback mx-2" [hidden]="!description.errors?.required">{{ 'APP.MAIN.LOBBY.TICKETS.DESCRIPTION_REQUIRED' | translate }}</small>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="status">{{ 'APP.MAIN.LOBBY.TICKETS.STATUS2' | translate }}</label>
                                    <ng-select [items]="ticketStatuses" name="status" bindLabel="name" bindValue="id" [multiple]="false" [disabled]="dataModel.mode === 'detail'"
                                        placeholder="{{ 'APP.MAIN.LOBBY.TICKETS.STATUS2' | translate }}" [(ngModel)]="dataModel.ticket.status" [clearable]="false">
                                    </ng-select>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="priority">{{ 'APP.MAIN.LOBBY.TICKETS.PRIORITY' | translate }}</label>
                                    <ng-select [items]="ticketPriorities" name="priority" bindLabel="name" bindValue="id" [multiple]="false" [disabled]="dataModel.mode === 'detail'"
                                        placeholder="{{ 'APP.MAIN.LOBBY.TICKETS.PRIORITY' | translate }}" [(ngModel)]="dataModel.ticket.priority" [clearable]="false">
                                    </ng-select>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="assignee">{{ 'APP.MAIN.LOBBY.TICKETS.ASSIGNEE' | translate }}</label>
                                    <ng-select [items]="selectedTicketRoomUsers" name="assignee" bindLabel="name" bindValue="id" [multiple]="false" [disabled]="dataModel.mode === 'detail'"
                                        placeholder="{{ 'APP.MAIN.LOBBY.TICKETS.ASSIGNEE' | translate }}" [(ngModel)]="dataModel.ticket.assignee" [clearable]="true">
                                    </ng-select>
                                </div>
                                <div class="form-group datepicker-form-group col-12 col-md-6">
                                    <label for="followup">{{ 'APP.MAIN.LOBBY.TICKETS.FOLLOW_UP' | translate }}</label>
                                    <input #followup="bsDatepicker" name="followup" [(ngModel)]="dataModel.ticket.followup" class="form-control" bsDatepicker [disabled]="dataModel.mode === 'detail'"
                                        placeholder="{{ 'APP.MAIN.LOBBY.TICKETS.FOLLOW_UP' | translate }}" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue', customTodayClass: 'datepicker-today' }" onkeypress="return false;">
                                    <a *ngIf="dataModel.mode !== 'detail'" class="datepicker-clear" (click)="dataModel.ticket.followup = null;"><i class="mi mi-close"></i></a>
                                </div>
                                <div class="form-group col-12">
                                    <label for="resolution">{{ 'APP.MAIN.LOBBY.TICKETS.RESOLUTION' | translate }}</label>
                                    <textarea #resolution="ngModel" class="form-control" rows="3" placeholder="{{ 'APP.MAIN.LOBBY.TICKETS.RESOLUTION' | translate }}" [(ngModel)]="dataModel.ticket.resolution"
                                        [ngClass]="{'is-invalid':resolution.errors && resolution.touched}" name="resolution" maxlength="1000" [disabled]="dataModel.mode === 'detail'"></textarea>
                                </div>
                            </div>
                        </ng-container>
                    </form>

                    <hr *ngIf="!isLoadingDetail">

                    <div class="mt-5 mb-5" *ngIf="dataModel.mode !== 'create' && !isLoadingFiles && !isLoadingDetail">
                        <p class="mb-3 files-title text-center">{{ 'APP.MAIN.LOBBY.TICKETS.FILES' | translate }}</p>
                        <div class="d-flex mb-3">
                            <div class="col-12">
                                <input class="d-none" type="file" (change)="onChooseFile(fileinput.files)" multiple #fileinput>
                                <button class="btn btn-sm btn-info px-4 upload-button" (click)="onAttachment(fileinput)">{{ 'APP.MAIN.LOBBY.TICKETS.UPLOAD_FILE' | translate }}</button>
                            </div>
                        </div>
                        <app-file-share [dropzoneState]="dropzoneStateSource.asObservable()" 
                        [chosenFiles]="chosenFilesSource.asObservable()" [uploadSource]="'ticket'" [ticketId]="ticketId"></app-file-share>

                        <div class="d-flex flex-wrap files-gap">
                            <div class="col-4 pr-1" *ngFor="let file of files; let i = index" [popover]="popTemplate" containerClass="popover-body" (mouseenter)="!isDropdownOpen && pop.show()" (mouseleave)="pop.hide()" triggers="" [popoverContext]="{file:files[i]}" container="body" placement="left" #pop="bs-popover">
                                <div class="room-file mb-2">
                                    <div class="file-image">
                                        <img *ngIf="imageExtensions.includes(file.extension)" [src]="file.url">
                                        <img *ngIf="pdfExtensions.includes(file.extension)" src="assets/img/pdf.png">
                                        <img *ngIf="objectSpecificExtensions.includes(file.extension)" src="assets/img/obj.png">
                                        <img *ngIf="!imageExtensions.includes(file.extension) && !pdfExtensions.includes(file.extension) && !objectSpecificExtensions.includes(file.extension)" src="assets/img/other.png">
                                    </div>
                                    <div class="file-info px-1">
                                        <p class="file-name"> {{ file.name }} </p>
                                        <p *ngIf="!objectSpecificExtensions.includes(file.extension)" class="file-size"> {{ file.size | fileSize }} </p>
                                        <p *ngIf="objectSpecificExtensions.includes(file.extension)" class="file-size"> {{ file.files | objSize }} </p>
                                    </div>
                                    <div class="file-dropdown" (onShown)="isDropdownOpen = !isDropdownOpen" (onHidden)="isDropdownOpen = !isDropdownOpen" dropdown #dropdown="bs-dropdown">
                                        <button id="file-toggle" class="btn dropdown-button px-2 py-3" dropdownToggle aria-controls="file-menu" (click)="pop.toggle()">
                                            <i class="mi mi-more-vert"></i>
                                        </button>
                                        <ul id="file-menu" *dropdownMenu class="dropdown-menu" role="menu" aria-expanded="false" aria-labelledby="file-toggle"
                                               [ngStyle]="{'margin-top': isExpert ? (currentRoom ? '3rem' : '20px') : '-6px'}">
                                            <li role="menuitem"><a class="dropdown-item" (click)="onDownloadFile(file)">
                                                <i class="mi mi-cloud-download mr-2"></i><span>{{ 'APP.MAIN.ROOM.SIDEBAR.FILES.DOWNLOAD' | translate }}</span>
                                            </a></li>
                                            <ng-container *ngIf="isExpert">
                                                <li role="menuitem"><a class="dropdown-item" (click)="onSendToSession(file)" *ngIf="currentRoom">
                                                    <i class="mi mi-send mr-2"></i><span>{{ 'APP.MAIN.ROOM.SIDEBAR.FILES.SEND_TO_SESSION' | translate }}</span>
                                                </a></li>
                                                <li role="menuitem"><a class="dropdown-item" (click)="onDeleteFile(file)">
                                                    <i class="mi mi-delete mr-2"></i><span>{{ 'APP.MAIN.ROOM.SIDEBAR.FILES.DELETE' | translate }}</span>
                                                </a></li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <div class="cumulative-data py-3 mb-3 mx-3 bg-white" *ngIf="!isLoadingDetail && dataModel.mode === 'detail' && dataModel.sessionStats">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12 mb-3 cumulative-title text-center">{{ 'APP.MAIN.LOBBY.TICKETS.ATTACHED_SESSIONS' | translate }}</div>
                                <div class="col-md-6 mt-2"><span class="key">{{ 'APP.MAIN.LOBBY.TICKETS.SESSION_COUNT' | translate }}</span><span class="value">{{ dataModel.sessionStats.count }}</span></div>
                                <div class="col-md-6 mt-2"><span class="key">{{ 'APP.MAIN.LOBBY.TICKETS.TOTAL_DURATION' | translate }}</span><span class="value">{{ dataModel.sessionStats.duration }}</span></div>
                                <div class="col-12 my-2">
                                    <div class="key my-2">{{ 'APP.MAIN.LOBBY.TICKETS.PARTICIPANTS2' | translate }}</div>
                                    <div class="row">
                                     <div class="col-md-6 mt-1" *ngFor="let user of (dataModel.sessionStats.users | objectToArray)"><span class="key">{{ getUsersName(user.id) }}</span><span>{{ 'APP.MAIN.LOBBY.TICKETS.COUNT_SESSIONS' | translate:user }}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="px-3 mb-2" *ngIf="!isLoadingDetail && dataModel.mode === 'detail'">
                        <div class="t-container t-no-hover container-fluid">
                            <div class="t-head row sm-hide">
                                <div class="t-cell col-1 font-weight-bold">#</div>
                                <div class="t-cell col-2 font-weight-bold">{{ 'APP.MAIN.LOBBY.TICKETS.DATE' | translate }} ({{ timezone }})</div>
                                <div class="t-cell font-weight-bold" [ngClass]="{'col-3': isExpert, 'col-4': !isExpert}">{{ 'APP.MAIN.LOBBY.TICKETS.ROOM_NAME' | translate }}</div>
                                <div class="t-cell col-3 font-weight-bold">{{ 'APP.MAIN.LOBBY.TICKETS.PARTICIPANTS' | translate }}</div>
                                <div class="t-cell col-1 font-weight-bold text-center">{{ 'APP.MAIN.LOBBY.TICKETS.DURATION' | translate }}</div>
                                <div *ngIf="isExpert" class="t-cell col-2 font-weight-bold text-center">{{ 'APP.MAIN.LOBBY.SESSIONS.EXPORT' | translate }}</div>
                            </div>
                            <div class="t-row row bg-white" *ngIf="dataModel.sessions && dataModel.sessions.length === 0">
                                <div class="no-tickets-message text-muted py-2">{{ 'APP.MAIN.LOBBY.TICKETS.NO_ATTACHED_SESSIONS' | translate }}</div>    
                            </div>
                            <div class="t-row row" *ngFor="let s of dataModel.sessions; index as i;">
                                <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">#</div>
                                <div class="t-cell col-6 col-md-4 col-lg-1">{{ i+1 }}</div>
                                <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'APP.MAIN.LOBBY.TICKETS.DATE' | translate }} ({{ timezone }})</div>
                                <div class="t-cell col-6 col-md-4 col-lg-2">{{ s.create_time | utcCalcAccount | async }}</div>
                                <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'APP.MAIN.LOBBY.TICKETS.ROOM_NAME' | translate }}</div>
                               <div class="t-cell col-6 col-md-4" [ngClass]="{'col-lg-3': isExpert, 'col-lg-4': !isExpert}">{{ getRoomName(s.room_id) }}</div>
                                <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'APP.MAIN.LOBBY.TICKETS.PARTICIPANTS' | translate }}</div>
                                <div class="t-cell col-6 col-md-4 col-lg-3">
                                  <div *ngFor="let uid of s.users | getIds | filterGuests:s.guest_users" class="mt-1">{{ getUsersName(uid) }}</div>
                                    <div *ngFor="let guest of s.guest_users | objectToArray" class="mt-1">{{ guest.name }} <span class="text-info">{{ 'APP.MAIN.LOBBY.TICKETS.GUEST' | translate }}</span></div>
                                </div>
                                <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'APP.MAIN.LOBBY.TICKETS.DURATION' | translate }}</div>
                                <div class="t-cell col-6 col-md-4 col-lg-1 text-center">{{ s | sessionDuration }}</div>
                                <div *ngIf="isExpert" class="t-cell sm-show col-6 col-md-8 font-weight-bold"></div>
                                <div *ngIf="isExpert" class="t-cell col-6 col-md-4 col-lg-2 text-center">
                                    <a *ngIf="sessionExportEnabled && !webdavEnabled && !s.session_files_deleted" (click)="onSessionExportModal(s.room_id, s.id);" style="cursor: pointer;">
                                        <img src="assets/export_session.svg">
                                    </a>
                                </div>                  
                            </div>
                        </div>
                    </div>
    
                    <div class="px-3 mb-2" *ngIf="!isLoadingDetail && mailToUrl && dataModel.mode === 'detail'">
                        <a class="btn btn-outline-info btn-block" style="font-size: 0.875rem;" [href]="mailToUrl" target="_blank">{{ 'APP.MAIN.LOBBY.TICKETS.SEND_VIA_EMAIL' | translate }}</a>
                    </div>
    
                    <div class="px-3 mb-2" *ngIf="dataModel.mode === 'edit'">
                        <button class="btn btn-outline-danger btn-block" style="font-size: 0.875rem;" (click)="deleteTicket(dataModel.ticket)">{{ 'APP.MAIN.LOBBY.TICKETS.DELETE_TICKET' | translate }}</button>
                    </div>
            </div>
            <div class="modal-footer">
                <button *ngIf="dataModel.mode !== 'edit'" class="btn btn-dark rounded-0 px-4 py-1 mr-2" style="font-size: 0.875rem;" type="button" (click)="close()">
                    {{ (dataModel.mode === 'create' ? 'APP.MAIN.LOBBY.TICKETS.CANCEL' : 'APP.MAIN.LOBBY.TICKETS.CLOSE') | translate }}
                </button>
                <button *ngIf="dataModel.mode === 'edit'" class="btn btn-dark rounded-0 px-4 py-1 mr-2" style="font-size: 0.875rem;" type="button" (click)="cancelEdit()">
                    {{ 'APP.MAIN.LOBBY.TICKETS.CANCEL' | translate }}
                </button>
                <button *ngIf="dataModel.mode === 'edit'" class="btn btn-info px-4 py-1 rounded-0" style="font-size: 0.875rem;" type="button" [disabled]="!ticketForm.valid || dataModel.pristine || isLoadingUpdate" (click)="save(ticketForm)">
                    {{ 'APP.MAIN.LOBBY.TICKETS.SAVE' | translate }}
                </button>    
                <button *ngIf="dataModel.mode === 'create'" class="btn btn-info px-4 py-1 rounded-0" style="font-size: 0.875rem;" type="button" [disabled]="!ticketForm.valid || isLoadingCreate" (click)="create(ticketForm)">
                    {{ 'APP.MAIN.LOBBY.TICKETS.CREATE' | translate }}
                </button>
            </div>

    <ng-template #loadingTemplate>
        <div class="d-flex justify-content-center mt-5">
            <div class="loader loader-sm la-dark">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
            </div>
        </div>
    </ng-template>

    <ng-template #deleteFileTemplate let-callbacks="callbacks">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ 'APP.MAIN.ROOM.SIDEBAR.FILES.DELETE_MODAL.TITLE_DELETE' | translate }}</h5>
                </div>
                <div class="modal-body px-0 py-4">
                    <p class="mx-3">{{ 'APP.MAIN.ROOM.SIDEBAR.FILES.DELETE_MODAL.MESSAGE_DELETE' | translate }}</p>
                </div>
        
                <div class="modal-footer d-block text-right py-2 px-3">
                    <button class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">
                        {{ 'APP.MAIN.ROOM.SIDEBAR.FILES.DELETE_MODAL.CANCEL' | translate }}
                    </button>
                    <button class="btn btn-sm btn-danger px-4" (click)="callbacks.yes()">
                        {{ 'APP.MAIN.ROOM.SIDEBAR.FILES.DELETE_MODAL.DELETE' | translate }}
                    </button>
                </div>
            </div>
        </div>
      </ng-template>