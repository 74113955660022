import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';

import { DbService } from '@services/core/db.service';
import { AuthService } from '@services/core/auth.service';
import { CollaborationService } from '@services/core/collaboration.service';
import { ImageCollaborationService } from '@services/other/image-collaboration.service';
import { PdfCollaborationService } from '@services/other/pdf-collaboration.service';

import { ActivatedRoute } from '@angular/router';

import { first } from 'rxjs/operators';
import { ResizedEvent } from 'angular-resize-event';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-collaboration',
  templateUrl: './collaboration.component.html',
  styleUrls: ['./collaboration.component.scss']
})
export class CollaborationComponent implements OnInit, OnDestroy {

  frame = null;
  collaborationSub: Subscription = null;

  constructor(
    private dbService: DbService,
    private authService: AuthService,
    private collaborationService: CollaborationService,
    private route: ActivatedRoute,
    private imageCollaborationService: ImageCollaborationService,
    private pdfCollaborationService: PdfCollaborationService
  ) { }

  ngOnInit() {
    this.route.queryParams.pipe(first()).toPromise()
    .then(params => {
      console.log(params);
      const keys = Object.keys(params);
      if (keys.every(k => ['token', 'account', 'room', 'session', 'newcoll', 'china'].indexOf(k) > -1) &&
          ['token', 'account', 'room', 'session'].every(k => keys.indexOf(k) > -1)) {

        const token = atob(decodeURIComponent(params.token));
        const ps = {
          account_id: atob(decodeURIComponent(params.account)),
          room_id: atob(decodeURIComponent(params.room)),
          session_id: atob(decodeURIComponent(params.session))
        }

        if (params.china) {
          if (this.dbService.getSourceType() === "firebase") {
            this.dbService.setSourceType("socket");
          }
        } else {
          if (this.dbService.getSourceType() === "socket") {
            this.dbService.setSourceType("firebase");
          }
        }

        this.authService.externalLogin(token, ps)
        .then(() => {
          let w: any = window;
          if (w.webkit && w.webkit.messageHandlers) {
            try {
              w.webkit.messageHandlers.collaborationready.postMessage("COLLABORATION_READY");
              console.log("collaborationready Message Sent");
            } catch(error) {
              console.log("Cannot Send collaborationready Message");
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.collaborationSub) { this.collaborationSub.unsubscribe() }
  }

  onResized(event: ResizedEvent) {
    if (!this.frame) {
      return;
    }
    let top = 0;
    let left = 0;
    let width = 0;
    let height = 0;
    if (event.newWidth*0.75 > event.newHeight) {
      height = event.newHeight;
      width = event.newHeight*4.0/3.0;
      left = (event.newWidth - width) / 2.0;
    } else {
      width = event.newWidth;
      height = event.newWidth*0.75;
      top = (event.newHeight - height) / 2.0;
    }
    this.frame.style2 = {
      "margin-top":top+"px",
      width: width+"px",
      height: height+"px",
      "margin-left": left+"px"
    };

    setTimeout(() => {
      if (this.frame) {
        if (this.frame.type === 'image') {
          this.imageCollaborationService.triggerResize();
        } else if (this.frame.type === 'screenshot') {
          this.imageCollaborationService.triggerResize();
        } else if (this.frame.type === 'pdf') {
          this.pdfCollaborationService.triggerResize();
        }
      }
    }, 500);
  }

  @HostListener('window:iosmessage', ['$event'])
  iosmessage(event: CustomEvent) {
    const collaborationData = event.detail.data;
    if (event.detail.collaborationOpen) {
      this.collaborationService.externalCollaborationOpen(collaborationData);
      this.frame = { type: collaborationData.type, isCollaboration: true, collaborationData: collaborationData};

      setTimeout(() => {
        if (this.frame.type === 'image') {
          this.imageCollaborationService.triggerResize();
        } else if (this.frame.type === 'screenshot') {
          this.imageCollaborationService.triggerResize();
        } else if (this.frame.type === 'pdf') {
          this.pdfCollaborationService.triggerResize();
        }
      }, 1000);
    } else {
      this.collaborationService.externalCollaborationClose();
      this.frame = null;
    }
  }
}
