<div class="container-fluid">
    <div class="mx-auto my-auto text-center">
        <h6 class="mt-4 rating-message">{{ 'APP.MAIN.SESSION_RATING.RATING_MESSAGE' | translate }}</h6> 
        <div class="d-flex justify-content-center my-3">
            <a style="cursor: pointer;" (click)="onStarSelected(1)" (mouseenter)="onStarHover(1)" (mouseleave)="onClear()">
                <i class="mi" style="font-size: 2.5rem;"
            [ngClass]="{'text-warning mi-star': (chosenStar >= 1 && hoverCount === 0) || hoverCount >= 1, 'mi-star-border': !((chosenStar >= 1 && hoverCount === 0) || hoverCount >= 1)}"></i>
            </a>
            <a style="cursor: pointer;" (click)="onStarSelected(2)" (mouseenter)="onStarHover(2)" (mouseleave)="onClear()">
                <i class="mi" style="font-size: 2.5rem;"
            [ngClass]="{'text-warning mi-star': (chosenStar >= 2 && hoverCount === 0) || hoverCount >= 2, 'mi-star-border': !((chosenStar >= 2 && hoverCount === 0) || hoverCount >= 2)}"></i>
            </a>
            <a style="cursor: pointer;" (click)="onStarSelected(3);save()" (mouseenter)="onStarHover(3)" (mouseleave)="onClear()">
                <i class="mi" style="font-size: 2.5rem;"
            [ngClass]="{'text-warning mi-star': (chosenStar >= 3 && hoverCount === 0) || hoverCount >= 3, 'mi-star-border': !((chosenStar >= 3 && hoverCount === 0) || hoverCount >= 3)}"></i>
            </a>
            <a style="cursor: pointer;" (click)="onStarSelected(4);save()" (mouseenter)="onStarHover(4)" (mouseleave)="onClear()">
                <i class="mi" style="font-size: 2.5rem;"
            [ngClass]="{'text-warning mi-star': (chosenStar >= 4 && hoverCount === 0) || hoverCount >= 4, 'mi-star-border': !((chosenStar >= 4 && hoverCount === 0) || hoverCount >= 4)}"></i>  
            </a>
            <a style="cursor: pointer;" (click)="onStarSelected(5);save()" (mouseenter)="onStarHover(5)" (mouseleave)="onClear()">
                <i class="mi" style="font-size: 2.5rem;"
            [ngClass]="{'text-warning mi-star': (chosenStar >= 5 && hoverCount === 0) || hoverCount >= 5, 'mi-star-border': !((chosenStar >= 5 && hoverCount === 0) || hoverCount >= 5)}"></i>
            </a>
        </div>
        <div class="my-3" style="height: 24px;">
            <h6 class="rating-word" *ngIf="hoverCount !== 0"> {{ titles[hoverCount-1] | translate }} </h6>
            <h6 class="rating-word" *ngIf="hoverCount === 0 && chosenStar !== 0"> {{ titles[chosenStar-1] | translate }}</h6>
        </div>
        <div class="mb-4 mt-3" [style.display]="chosenStar >= 3 || !isRated ? 'none' : '' ">
            <p class="review-message">{{ 'APP.MAIN.SESSION_RATING.REVIEW_MESSAGE' | translate }}</p>
            <textarea class="review-area mt-2 p-2" [(ngModel)]="clientReview" name="" id=""  cols="" rows="5"></textarea>
        </div>
        <div class="d-flex justify-content-center">
            <div class="py-2 px-3 mb-2">
                <button *ngIf="!isRated" class="btn btn-dark rounded-0" (click)="close()">{{ 'APP.MAIN.SESSION_RATING.DISMISS' | translate }}</button>
                <button *ngIf="isRated" class="btn btn-dark rounded-0 mr-3" (click)="close()">{{ 'APP.MAIN.SESSION_RATING.CLOSE' | translate }}</button>
                <button *ngIf="isRated" class="btn btn-primary rounded-0" (click)="save()">{{ 'APP.MAIN.SESSION_RATING.SUBMIT' | translate }}</button>
            </div>
        </div>
    </div>
</div>