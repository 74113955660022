import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from "@angular/core";

@Directive({
  selector: "[handleResize]",
})
export class ResizeDirective {
  @Input()
  maxHeight: number;
  @Input()
  maxWidth: number;
  @Input()
  minHeight: number;
  @Input()
  minWidth: number;
  @Input()
  width: number;
  @Output()
  widthChange = new EventEmitter<number>();
  @Input()
  height: number;
  @Output()
  heightChange = new EventEmitter<number>();

  oldY = 0;
  oldX = 0;
  isGrabbing = false;

  @HostListener("document:mousemove", ["$event"])
  onMouseMove(event: MouseEvent) {
    let clientX = event.clientX
    let clientY = event.clientY
    this.moveListener(event, clientX, clientY)
  }

  @HostListener("document:touchmove", ["$event"])
  onTouchMove(event: TouchEvent) {
    let clientX = event.touches[0].clientX
    let clientY = event.touches[0].clientY
    this.moveListener(event, clientX, clientY)
  }

  @HostListener("touchstart", ["$event"])
  onTouchStart(event: TouchEvent) {
    if ((event.target as HTMLElement).className === 'handler') {
      this.isGrabbing = true
      this.oldY = event.touches[0].clientY
      this.oldX = event.touches[0].clientX
      return;
    }
    this.isGrabbing = false
  }

  @HostListener("touchend", ["$event"])
  onTouchEnd(event: TouchEvent) {
    this.isGrabbing = false
  }

  moveListener(event: MouseEvent | TouchEvent, clientX, clientY) {
    if (this.isGrabbing) {
      const xPositionAmount = clientX - this.oldX;
      
      if (xPositionAmount > this.width && this.width > 0) {
        const newWidth = this.width - xPositionAmount;
        this.width = newWidth > 0 ? newWidth : 0;
      } else {
        this.width += xPositionAmount;
      }

      if (clientY - this.oldY > this.height && this.height > 0) {
        this.height =
          this.height - (clientY - this.oldY) > 0
            ? this.height - (clientY - this.oldY)
            : 0;
      } else {
        this.height += clientY - this.oldY;
      }
      if(this.width >= this.minWidth) {
        this.widthChange.emit(this.width);
      }else{
        this.width = this.minWidth+1
      }
      if(this.height >= this.minHeight) {
        this.heightChange.emit(this.height);
      }else{
        this.height = this.minHeight+1
      }
      
      this.oldY = clientY;
      this.oldX = clientX;
    }
  }

  @HostListener("mouseup", ["$event"])
  onMouseUp(event: MouseEvent) {
    this.isGrabbing = false;
  }

  @HostListener("mousedown", ["$event"])
  onMouseDown(event: MouseEvent) {
    if ((event.target as HTMLElement).className === 'handler') {
      this.isGrabbing = true
      this.oldY = event.clientY
      this.oldX = event.clientX
      return;
    }
    this.isGrabbing = false
  }
}
