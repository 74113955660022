<div class="main-wrapper container-fluid py-5" *ngIf="!isModalView; else ticketListTemplate">
    <div class="row justify-content-center">
        <div class="col-xl-9 col-lg-10 col-md-11 col-12">
            <div class="main-header text-muted d-flex align-items-center justify-content-between" *ngIf="isExpert">
                <span class="py-2">{{ 'APP.MAIN.LOBBY.TICKETS.TITLE' | translate }}</span>
                <button class="btn btn-sm btn-success rounded-0 ml-2 py-1" (click)="onCreateTicket();">{{ 'APP.MAIN.LOBBY.TICKETS.NEW_TICKET' | translate }}</button>
            </div>
            <div class="filters container-fluid bg-white mt-1 pb-2"  style="background-color: white; border-bottom: 3px solid #ececec;">
                <div class="row">
                    <div class="col-sm-6 col-md-12 col-lg-6 mt-2">
                        <ng-select [items]="rooms" name="rooms" bindLabel="name" bindValue="id" [clearSearchOnAdd] = "true" [closeOnSelect]="false"
                            placeholder="{{ 'APP.MAIN.LOBBY.TICKETS.ALL_ROOMS' | translate }}" [multiple]="true" [(ngModel)]="filterRooms" (change)="onFilterChanged()">
                        </ng-select>
                    </div>
                    <div class="col-sm-6 col-md-12 col-lg-6 mt-2">
                        <ng-select [items]="creatorFilterList" name="users" bindLabel="name" bindValue="id" [clearSearchOnAdd] = "true" [closeOnSelect]="false"
                            placeholder="{{ 'APP.MAIN.LOBBY.TICKETS.ALL_CREATORS' | translate }}" [multiple]="true" [(ngModel)]="filterCreators" (change)="onFilterChanged()">
                        </ng-select>
                    </div>
                    <div class="col-sm-6 col-md-12 col-lg-6 mt-2 datepicker-form-group">
                        <input #filterDates="bsDaterangepicker" name="filterDates" [(ngModel)]="filterStartEnd" class="form-control datepicker-input" bsDaterangepicker
                            placeholder="{{ 'APP.MAIN.LOBBY.TICKETS.CREATED_BETWEEN' | translate }}" [bsConfig]="{ rangeInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue', customTodayClass: 'datepicker-today' }" onkeypress="return false;"
                            (ngModelChange)="onFilterChanged()" style="cursor: default;">
                        <a class="datepicker-clear" (click)="filterStartEnd = null; onFilterChanged();" style="right: 1.5rem;"><i style="color: #777;" class="mi mi-close"></i></a>
                    </div>
                    <div class="col-sm-6 col-md-12 col-lg-6 mt-2">
                        <ng-select [items]="ticketStatuses" name="statuses" bindLabel="name" bindValue="id" [clearSearchOnAdd] = "true" [closeOnSelect]="false"
                            placeholder="{{ 'APP.MAIN.LOBBY.TICKETS.ALL_STATUSES' | translate }}" [multiple]="true" [(ngModel)]="filterStatuses" (change)="onFilterChanged()">
                        </ng-select>
                    </div>
                </div>
            </div>
            <ng-container [ngTemplateOutlet]="ticketListTemplate"></ng-container>
        </div>
    </div>
</div>

<ng-template #ticketListTemplate>
    <div>
        <table mat-table matSort matTableResponsive [dataSource]="dataSource">

          <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef> # </th>
            <td mat-cell data-column-name="#" *matCellDef="let i = index"> {{ i+1 }} </td>
          </ng-container>
      
          <ng-container matColumnDef="title">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'APP.MAIN.LOBBY.TICKETS.TICKET_TITLE' | translate }}</th>
            <td mat-cell data-column-name="Title" *matCellDef="let element"> {{ element.title }} </td>
          </ng-container>

          <ng-container matColumnDef="room.name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by name">{{ 'APP.MAIN.LOBBY.TICKETS.ROOM' | translate }} </th>
            <td mat-cell data-column-name="Room" *matCellDef="let element"> {{ element.room.name}} </td>
          </ng-container>
      
          <ng-container matColumnDef="creator.name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> {{ 'APP.MAIN.LOBBY.TICKETS.CREATED_BY' | translate }}</th>
            <td mat-cell data-column-name="Created By" *matCellDef="let element"> {{ element.creator.name }} </td>
          </ng-container>
      
          <ng-container matColumnDef="create_time">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'APP.MAIN.LOBBY.TICKETS.CREATED_ON' | translate }} ({{ timezone }})</th>
            <td mat-cell data-column-name="Created On" *matCellDef="let element"> {{ element.create_time | utcCalcAccount | async }} </td>
          </ng-container>
      
          <ng-container matColumnDef="status">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'APP.MAIN.LOBBY.TICKETS.STATUS' | translate }}</th>
            <td mat-cell data-column-name="Status" *matCellDef="let element">
                 <div *ngIf="element.status === 'open'" class="badge badge-success">{{ 'APP.MAIN.LOBBY.TICKETS.STATUS_OPEN' | translate }} </div>
                 <div *ngIf="element.status === 'in-progress'" class="badge badge-warning">{{ 'APP.MAIN.LOBBY.TICKETS.STATUS_IN_PROGRESS' | translate }} </div>
                 <div *ngIf="element.status === 'closed'" class="badge badge-secondary">{{ 'APP.MAIN.LOBBY.TICKETS.STATUS_CLOSED' | translate }} </div>
                </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <ng-container *ngIf="!isLoading ">
              <tr mat-row (click)="onRowClick(row);"  *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'selected': selectedTicket?.id === row.id && isModalView}"></tr>
          </ng-container>
        </table>
    </div>
    <ng-container *ngIf="isLoading" [ngTemplateOutlet]="loadingTemplate"></ng-container>

    <div class="bg-white empty-list" *ngIf="filteredTickets && filteredTickets?.length === 0 && !isLoading">
      <div class="ticket-list-empty py-2"> {{ 'APP.MAIN.LOBBY.TICKETS.NO_TICKETS' | translate }}</div>
  </div>
</ng-template>

<ng-template #loadingTemplate>
    <div class="loading-template">
      <div class="d-flex justify-content-center">
        <div class="loader loader-sm la-dark">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </ng-template>
