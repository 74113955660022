import {AbstractControl} from '@angular/forms';
export class EmailValidation {

    static MatchEmail(AC: AbstractControl) {
       let newEmail = AC.get('newEmail').value; // to get value in input tag
       let repeatEmail = AC.get('repeatEmail').value; // to get value in input tag
        if(newEmail != repeatEmail) {
            AC.get('repeatEmail').setErrors( { MatchEmail: true } );
        } else {
            AC.get('repeatEmail').setErrors( null );
            return null;
        }
    }
}