import { Compiler, Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';

import { ImageCollaborationComponent } from '../image-collaboration.component';

import { Frame } from '@models/Frame';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-image-collaboration-wrapper',
  templateUrl: './image-collaboration-wrapper.component.html',
  styleUrls: ['./image-collaboration-wrapper.component.scss']
})
export class ImageCollaborationWrapperComponent implements OnInit, OnDestroy {

  @ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef;
  @Input('frame') frame: Frame;
  @Input('size') size: string;
  @Input('external') external: boolean = false;
  @Input('nohover') nohover: boolean = false;
  @Output() toggleFullscreen = new EventEmitter<boolean>();

  imageCollaborationComponent: ImageCollaborationComponent;
  fullscreenSub: Subscription = null;
  loadFailed = false;
  reloadCount = 0;

  constructor(
    private compiler: Compiler,
    private injector: Injector
  ) { }

  ngOnInit(): void {
    this.lazyLoadImageCollaboration();
  }

  ngOnDestroy(): void {
    if (this.fullscreenSub) { this.fullscreenSub.unsubscribe() }
  }

  lazyLoadImageCollaboration() {
    this.loadFailed = false;
    import('../image-collaboration.module')
    .then(({ ImageCollaborationModule }) => this.compiler.compileModuleAsync(ImageCollaborationModule))
    .then(moduleFactory => {
      const moduleRef = moduleFactory.create(this.injector);
      const componentFactory = moduleRef.instance.resolveComponent();
      const { instance } = this.container.createComponent(componentFactory, undefined, moduleRef.injector);
      instance.frame = this.frame;
      instance.size = this.size;
      instance.external = this.external;
      instance.nohover = this.nohover;
      this.fullscreenSub = instance.toggleFullscreen.asObservable().subscribe(f => this.toggleFullscreen.emit(f));
      this.imageCollaborationComponent = instance;
    })
    .catch(error => {
      this.loadFailed = true;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.imageCollaborationComponent) {
      this.imageCollaborationComponent.ngOnChanges(changes);
    }
  }

  reload() {
    this.reloadCount = this.reloadCount + 1;
    if (this.reloadCount < 3) {
      this.lazyLoadImageCollaboration();
    } else {
      document.location.reload();
    }
  }
}
