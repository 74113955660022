import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { AuthService } from '@services/core/auth.service';
import { DbService } from '@services/core/db.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Language } from '@models/Language';

@Injectable({
  providedIn: 'root'
})
export class MultilanguageService {

  currentLang: Language = { code: 'en', name: 'English' };
  allLangs: Language[] = environment.translationLanguagesOverride ? environment.translationLanguagesOverride : [
    { code: 'en', name: 'English' },
    { code: 'de', name: 'Deutsch' },
    { code: 'fr', name: 'Français' },
    { code: 'cz', name: 'Čeština' },
    { code: 'pl', name: 'Polski' },
    { code: 'pt', name: 'Português' },
    { code: 'ru', name: 'русский' },
    { code: 'es', name: 'Español' },
    { code: 'ja', name: '日本語' },
    { code: 'sk', name: 'Slovenčina' },
    { code: 'tr', name: 'Türkçe' },
    { code: 'zhs', name: '简体字' },
    { code: 'zht', name: '繁体字' }
  ];

  onLangChange: Observable<{ lang: Language, translations: any }>;
  private onLangChangeSource = new BehaviorSubject<{ lang: Language, translations: any }>(null);

  translationLanguage: Observable<string>;
  private translationLanguageSource = new BehaviorSubject<string>('en');

  translationLanguage2: Observable<string>;
  private translationLanguageSource2 = new BehaviorSubject<string>('en');

  private userPrefersTranslationLang: boolean = false;
  private userPrefersTranslationLang2: boolean = false;

  constructor(
    private authService: AuthService,
    private dbService: DbService,
    private translateService: TranslateService
  ) {
    this.onLangChange = this.onLangChangeSource.asObservable().pipe(filter(l => l !== null));
    this.translationLanguage = this.translationLanguageSource.asObservable();
    this.translationLanguage2 = this.translationLanguageSource2.asObservable();

    // Set default language as English
    this.translateService.setDefaultLang('en');
    const languages = [];
    this.allLangs.forEach((lang) => {
      translateService.setTranslation(lang.code, require(`i18n/${lang.code}.json`));
      languages.push(lang.code);
    })
    // Add current languages to translate service
    this.translateService.addLangs(languages);
    const localLang = localStorage.getItem('language');
    const browserLang = this.translateService.getBrowserLang();
    let localLanguageItem;
    let browserLangItem;


    this.allLangs.forEach((lang) => {

      translateService.setTranslation(lang.code, require(`i18n/${lang.code}.json`));

      languages.push(lang.code);

    })

    // Add current languages to translate service

    this.translateService.addLangs(languages);

    // Look for language key in local storage
    if (localLang && (localLanguageItem = this.allLangs.find(l => l.code === localLang))) {
      this.translateService.use(localLang);
      this.currentLang = localLanguageItem;
      // If there is no config saved in local storage, Look for browser language
    } else if (browserLang && (browserLangItem = this.allLangs.find(l => l.code === browserLang))) {
      this.translateService.use(browserLang);
      this.currentLang = browserLangItem
      // Else, use English
    } else {
      this.translateService.use('en');
      this.currentLang = { code: 'en', name: 'English' }
    }

    this.authService.user.subscribe(user => {
      if (user && user.translation_lang) {
        this.userPrefersTranslationLang = true;
        this.translationLanguageSource.next(user.translation_lang);
      } else {
        this.userPrefersTranslationLang = false;
        this.translationLanguageSource.next(this.translateService.currentLang);
      }

      if (user && user.translation_lang2) {
        this.userPrefersTranslationLang2 = true;
        this.translationLanguageSource2.next(user.translation_lang2);
      } else {
        this.userPrefersTranslationLang2 = false;
        this.translationLanguageSource2.next(this.translateService.currentLang);
      }
    });

    // Change currentLang and notify subscribers when lang changed
    this.translateService.onLangChange.subscribe((change: LangChangeEvent) => {
      const lng = this.translateService.currentLang
      this.currentLang = this.allLangs.find(l => l.code === lng);
      this.onLangChangeSource.next({ lang: this.currentLang, translations: change.translations });

      if (!this.userPrefersTranslationLang) {
        this.translationLanguageSource.next(lng);
      }
      if (!this.userPrefersTranslationLang2) {
        this.translationLanguageSource2.next(lng);
      }
    });
  }

  setCurrentLanguage(code: string) {
    if (code && this.allLangs.findIndex(l => l.code == code) > -1) {
      this.translateService.use(code);
      localStorage.setItem('language', code);
    }
  }

  getTranslation(key: string,interpolateParams:any = null) {
    if(interpolateParams) {
      return this.translateService.get(key,interpolateParams).toPromise();
    }
    return this.translateService.get(key).toPromise();
  }

  getInstantTranslation(key: string,interpolateParams:any = null) {
    if(interpolateParams) {
      return this.translateService.instant(key,interpolateParams);
    }
    return this.translateService.instant(key);
  }

  changePreferedTranslationLang(languageCode: string) {
    const user = this.authService.currentUser;
    this.dbService.set(`accounts/${user.account_id}/users/${user.id}/translation_lang`,languageCode);  
  }

  changePreferedTranslationLang2(languageCode: string) {
    const user = this.authService.currentUser;
    this.dbService.set(`accounts/${user.account_id}/users/${user.id}/translation_lang2`,languageCode);  
  }

  changePreferedTranslationLangEmail(languageCode: string) {
    const user = this.authService.currentUser;
    this.dbService.set(`accounts/${user.account_id}/users/${user.id}/translation_lang_email`,languageCode);  
  }
}
