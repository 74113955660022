<div class="container-fluid py-5">
    <div class="row justify-content-center">
        <div class="col-xl-9 col-lg-10 col-md-11 col-12">
            <div class="d-flex justify-content-between">
                <div class="d-inline own-rooms-header text-muted py-2">{{ 'APP.MAIN.LOBBY.LOBBY_SHARED.ROOMS_TITLE' | translate }}</div>
            </div>

            <div class="d-flex justify-content-center" *ngIf="roomsLoading">
                <div class="loader loader-sm la-dark"><div></div><div></div><div></div><div></div><div></div></div>
            </div>

            <div class="no-room-message text-muted mt-2" *ngIf="!roomsLoading && rooms.length === 0">{{ 'APP.MAIN.LOBBY.LOBBY_SHARED.NO_ROOM' | translate }}</div>

            <div class="room rounded-0 card-1 mt-2 py-0 px-2" *ngFor="let room of rooms; let i = index">
                <div class="room-color" [ngStyle]="{'background-color': (i % 2) === 0 ? ('design' | env).primaryColor : ('design' | env).accentColor}"></div>

                <div class="row px-2">
                    <div class="col-md-6 col-7">
                        <div class="d-flex flex-column h-100 justify-content-center py-md-3 py-2">
                            <div class="room-header d-flex align-items-center flex-row">
                                <div class="room-icon border border-light">
                                    <img src="{{ room.room_data.icon }}" alt="Room Icon">
                                </div>
                                <div class="room-name ml-2">{{ room.room_data.name }}</div>
                            </div>
                            <div class="room-description mt-3">{{ room.room_data.description }}</div>
                        </div>
                    </div>
                    <div class="col-md-4 col-5 divided">
                        <div class="vertical-divider"></div>
                        <div class="d-flex flex-column h-100 justify-content-center py-md-3 py-2">
                            <div class="room-no-user text-muted" *ngIf="room.room_data.users.length === 0">{{ 'APP.MAIN.LOBBY.LOBBY_SHARED.NO_OTHER_USERS' | translate }}</div>
                            <ng-container *ngFor="let user of room.room_data.users; let ui = index">
                                <div *ngIf="showRoomUsers[room.id] || ui < 3" class="room-user pt-1" [ngClass]="{'in': user.in_room, 'out': !user.in_room}">{{ user.name }}</div>
                            </ng-container>
                            <ng-container *ngIf="room.room_data.users.length > 3">
                                <a *ngIf="!showRoomUsers[room.id]" (click)="showRoomUsers[room.id] = true" class="text-muted pt-1" style="font-size: 12px; font-weight: 600; cursor: pointer;">{{ 'APP.MAIN.LOBBY.LOBBY_SHARED.SHOW_MORE' | translate:{count:room.room_data.users.length-3} }}</a>
                                <a *ngIf="showRoomUsers[room.id]" (click)="showRoomUsers[room.id] = false" class="text-muted pt-1" style="font-size: 12px; font-weight: 600; cursor: pointer;">{{ 'APP.MAIN.LOBBY.LOBBY_SHARED.SHOW_LESS' | translate }}</a>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-md-2 col-12 divided">
                        <div class="vertical-divider d-md-block d-none"></div>
                        <div class="horizontal-divider d-md-none d-block"></div>
                        <div class="d-flex h-100 flex-column justify-content-center align-items-center py-md-3 py-2">
                            <button (click)="onJoin(room)" class="btn rounded-0 btn-outline-dark btn-sm btn-block px-2">{{ 'APP.MAIN.LOBBY.LOBBY_SHARED.JOIN' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loader>
</ng-template>

<ng-template #notSupportedTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.LOBBY.NOT_SUPPORTED_TITLE' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p *ngIf="!dataModel.ios" class="mx-3">{{ 'APP.MAIN.LOBBY.NOT_SUPPORTED_MESSAGE' | translate }}</p>
                <ng-container *ngIf="dataModel.ios">
                    <p *ngIf="!dataModel.ios_version_low" class="mx-3 mb-3">{{ 'APP.MAIN.LOBBY.NO_IOS_MESSAGE' | translate:('design' | env) }}</p>
                    <p *ngIf="dataModel.ios_version_low" class="mx-3 mb-3">{{ 'APP.MAIN.LOBBY.IOS_VERSION_MESSAGE' | translate:('design' | env) }}</p>
                    <div class="d-flex justify-content-center">
                        <a [href]="('design' | env).appStoreLink" target="_blank" rel="noopener">
                            <img class="d-block mx-auto" style="width: 80%; max-width: 200px; object-fit: contain;" src="assets/badges/applestore.png">
                        </a>
                    </div>
                </ng-container>
            </div>

            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.close()">{{ 'APP.MAIN.LOBBY.NOT_SUPPORTED_CLOSE' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #joinTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog join-room-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div class="video-container">

                    <div *ngIf="dataModel.status === 'permission-denied'">
                        <div class="redbox d-flex justify-content-center">
                            <i class="mi mi-videocam-off" style="color: white; font-size: 50px; opacity: 0.9;"></i>
                        </div>
                    </div>
                    <video [ngStyle]="{'transform': cameraMirror ? 'scaleX(-1)' : 'scaleX(1)'}" #videoElement autoplay="true" playsinline="true" muted></video>
                    
                    <div *ngIf="dataModel.status === 'running' && !dataModel.videoEnabled">
                        <div class="blackbox d-flex justify-content-center">
                            <i class="mi mi-videocam-off" style="color: white; font-size: 50px; opacity: 0.9;"></i>
                        </div>
                    </div>
                    <video [ngStyle]="{'transform': cameraMirror ? 'scaleX(-1)' : 'scaleX(1)'}" #videoElement autoplay="true" playsinline="true" muted></video>
                    
                    <div class="video-controls">
                        <a class="control" (enabledClick)="callbacks.toggleVideo();" [fakeDisabled]="dataModel.status !== 'running' || dataModel.videos.length === 0" [ngClass]="{'video-disabled': !dataModel.videoEnabled}">
                            <i class="mi" [ngClass]="{'mi-videocam': dataModel.videoEnabled, 'mi-videocam-off': !dataModel.videoEnabled}"></i>
                        </a>
                        <a class="control ml-2" (enabledClick)="callbacks.toggleAudio();" [fakeDisabled]="dataModel.status !== 'running' || dataModel.audios.length === 0" [ngClass]="{'audio-disabled': !dataModel.audioEnabled}">
                            <div class="audio-level" [style.height.%]="dataModel.audioEnabled ? dataModel.audioLevel : 0"></div>
                            <i class="mi" [ngClass]="{'mi-mic': dataModel.audioEnabled, 'mi-mic-off': !dataModel.audioEnabled}"></i>
                        </a>
                    </div>
                    
                    <div class="video-settings">
                        <a class="control" (enabledClick)="dataModel.settingsOn = !dataModel.settingsOn" [fakeDisabled]="dataModel.status !== 'running'">
                            <i class="mi mi-settings"></i>
                        </a>
                    </div>
                </div>
                <div class="settings-container py-3" [ngClass]="{'show': dataModel.settingsOn}">
                    <form #settingsForm="ngForm">
                        <div class="form-row justify-content-center" *ngIf="('design' | env).showPreferredResolution">
                            <div class="form-group col-11 col-sm-10 mb-1">
                                <label class="mb-1" for="publishResolution">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.PREFERRED_RESOLUTION' | translate }}</label>
                                <ng-select name="publishResolution" [items]="dataModel.publishResolutions" [clearable]="false" [searchable]="false" [dropdownPosition]="'top'"
                                    [(ngModel)]="opentokService.selectedPublishResolution"></ng-select>
                            </div>
                            <div class="text-info col-11 col-sm-10 mb-3">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.RESOLUTION_NOTE' | translate }}</div>
                        </div>
                        <div class="form-row justify-content-center">
                            <div class="form-group col-11 col-sm-5 mb-2 mb-sm-0">
                                <ng-select [items]="dataModel.videos" [clearable]="false" name="videoSetting" bindLabel="label" bindValue="deviceId" [searchable]="false" [disabled]="!dataModel.videoEnabled"
                                    placeholder="Video Device" [(ngModel)]="dataModel.selectedVideo" (change)="callbacks.changeVideoSource($event)" [dropdownPosition]="'top'"></ng-select>
                            </div>
                            <div class="form-group col-11 col-sm-5 mb-0">
                                <ng-select [items]="dataModel.audios" [clearable]="false" name="audioSetting" bindLabel="label" bindValue="deviceId" [searchable]="false" [disabled]="!dataModel.audioEnabled"
                                    placeholder="Audio Device" [(ngModel)]="dataModel.selectedAudio" (change)="callbacks.changeAudioSource($event)" [dropdownPosition]="'top'"></ng-select>
                            </div>
                        </div>
                        <div class="form-row justify-content-center">
                            <div class="form-group col-11 col-sm-10 mb-0">
                                <div class="custom-control custom-checkbox d-flex align-items-center">
                                    <input type="checkbox" class="custom-control-input" id="mirror" name="mirror" [(ngModel)]="cameraMirror" (ngModelChange)="cameraMirrorChanged($event)">
                                    <label class="custom-control-label" for="mirror">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.MIRROR_VIDEO' | translate }}</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="message-container" [ngSwitch]="dataModel.status">
                    <span *ngSwitchCase="'permission-required'" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.CAM_MIC_PERM_REQUIRED' | translate }}</span>
                    <span *ngSwitchCase="'permission-denied'" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.CAM_MIC_PERM_DENIED' | translate }}</span>
                    <span *ngSwitchCase="'cant-access-devices'" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.CAM_MIC_CANT_ACCESS' | translate }}</span>
                    <span *ngSwitchCase="'no-devices-found'" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN_NO_DEVICE' | translate }}</span>
                    <ng-container *ngSwitchCase="'running'">
                        <span *ngIf="!dataModel.audioEnabled && dataModel.videoEnabled" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN_MIC_OFF' | translate }}</span>
                        <span *ngIf="!dataModel.videoEnabled && dataModel.audioEnabled" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN_CAM_OFF' | translate }}</span>
                        <span *ngIf="!dataModel.audioEnabled && !dataModel.videoEnabled" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN_CAM_MIC_OFF' | translate }}</span>    
                    </ng-container>
                </div>
            </div>
            <div *ngIf="dataModel.status === 'permission-denied'" class="prompt-text px-3 py-4">
                <p class="px-1 py-2 mt-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.ALLOW_ACCESS' | translate }}</p>
                <p class="font-weight-bold px-3 py-2 mb-1">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.TRY_AGAIN' | translate }}</p>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-responsive btn-sm btn-outline-dark px-3" (click)="callbacks.close()" [disabled]="!dataModel.enableCancel">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.CANCEL' | translate }}</button>
                <button class="btn btn-responsive btn-sm btn-dark px-4" (click)="callbacks.join()" [disabled]="!dataModel.enableJoin">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #noDeviceTemplate let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.LOBBY.NO_DEVICE_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p class="mx-3">{{ 'APP.MAIN.LOBBY.NO_DEVICE_MODAL.JOIN_BODY' | translate }}</p>
            </div>
    
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-outline-dark px-4" (click)="callbacks.cancel()">
                    {{ 'APP.MAIN.LOBBY.NO_DEVICE_MODAL.CANCEL' | translate }}
                </button>
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.join()">
                    {{ 'APP.MAIN.LOBBY.NO_DEVICE_MODAL.JOIN' | translate }}
                </button>
            </div>
        </div>
    </div>
  </ng-template>

<ng-template #archivePermissionModal let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body">
                <p>{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.MESSAGE' | translate }}</p>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.deny()">{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.DENY' | translate }}</button>
                <button class="btn btn-sm btn-success px-4" (click)="callbacks.allow()">{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.ALLOW' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>