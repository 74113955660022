import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { Modal } from '@models/Modal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  modals: Observable<Modal[]>;
  private modalsValue: Modal[] = [];
  private modalsSource = new BehaviorSubject<Modal[]>(this.modalsValue);

  nextModalId: number = 1;

  constructor() {
    this.modals = this.modalsSource.asObservable();
  }

  show( modal: Modal ) {
    modal.id = this.nextModalId;
    this.nextModalId++;

    this.modalsValue.push(modal);
    this.modalsSource.next(this.modalsValue);

    return modal.id;
  }

  hide(modalId: number) {

    this.modalsValue.forEach((modal, index) => {
      if (modal.id === modalId) {
        this.modalsValue.splice(index, 1);
      }
    });

    this.modalsSource.next(this.modalsValue);
  }
}
