<div class="chat-container" filedropenter (dropEnter)="onDropEnter()" [class.translation-enabled]="translationEnabled">
    <div class="tutorial-container" #showTutorialTutorial="tutorial" tutorial="showTutorial" (change)="$event ? showTutorialPopover.show() : showTutorialPopover.hide()"
            #showTutorialPopover="bs-popover" [popover]="showTutorialTmp" triggers="" placement="bottom" containerClass="tutorial-popover">
        <a class="tutorial-link" (enabledClick)="showTutorial()" [fakeDisabled]="!sessionActive">{{ 'APP.TUTORIALS.TUTORIAL_LINK' | translate }}</a>
    </div>
    <div class="chat-list-area" #list>
        <div class="chat-message mt-1" *ngFor="let message of logMessages; let i = index;">

            <div class="text-message" *ngIf="message.type === 'text'" [ngClass]="{'left': !message.details.own_message, 'right': message.details.own_message}">
                <div class="owner-info my-1" *ngIf="message.details.show_owner">
                    {{ message.owner_info }}
                </div>
                <div class="chat-bubble">
                    <div class="chat-text text-left" [class.text-muted]="message.translation.visible" [innerHTML]="message.content | linkify:{target: '_blank'}"></div>
                    <div class="chat-translation text-left mt-2" *ngIf="message.translation.visible">{{ message.translation.text }}</div>
                    <div class="chat-translation text-left mt-2" *ngIf="message.original_content && message.details.own_message">{{ message.original_content }}</div>
                    <div class="chat-time text-right text-muted">{{ message.create_time | date:'HH:mm' }}</div>
                </div>
                <div class="translation-link mt-1" *ngIf="message.translation.has_link">
                    <a *ngIf="message.translation.loading;" class="loading">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.TRANSLATING' | translate }}</a>
                    <ng-container *ngIf="!message.translation.loading">
                        <a *ngIf="!message.translation.visible" (enabledClick)="seeTranslation(i, message);" [fakeDisabled]="!features.chattranslation"
                                [dynamicTooltip]="'APP.SHARED.FEATURE_UNAVAILABLE'" [tooltipDisabled]="features.chattranslation" [tooltipDelay]="200">
                            {{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.SEE_TRANSLATION' | translate }}
                        </a>
                        <a *ngIf="message.translation.visible" (click)="seeOriginal(i, message);">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.SEE_ORIGINAL' | translate }}</a>
                    </ng-container>
                </div>
            </div>

            <div class="other-message" *ngIf="message.type !== 'text'">
                <div class="message-circle" [ngClass]="{ 'wide': message.details.has_file }">
                    <div>{{ message.details.translation_key | translate:message }}</div>
                    <i *ngIf="message.type2 === 'reaction'" class="mi reaction" [ngClass]="'mi-'+message.content"></i>
                    <div class="btn-group w-100" dropdown *ngIf="message.details.has_file">
                        <ng-container *ngIf="!message.deleted">
                            <div class="file-box mt-2">
                                <div #collaborateTutorial="tutorial" tutorial="collaborate" [ignored]="!(message.type2 === 'screenshot' || message.type2 === 'image')" (change)="$event ? collaboratePopover.show() : collaboratePopover.hide()"
                                        #collaboratePopover="bs-popover" [popover]="collaborateTmp" triggers="" placement="top" containerClass="tutorial-popover"
                                        class="file-info" [class.collaboratable]="message.details.collaboratable" [class.collaborating]="collaborationKey === message.content">
                                    <div class="file-icon">
                                        <i class="mi mi-insert-drive-file"></i>
                                        <label class="file-extension">{{ message.details.extension }}</label>
                                    </div>
                                    <span class="file-name">{{ message.extra }}</span>
    
                                    <a class="file-collaboration" [fakeDisabled]="!sessionActive || collDebounce || message.details.coll_unavailable || trainingDisable" [dynamicTooltip]="'APP.SHARED.FEATURE_UNAVAILABLE'" [tooltipDisabled]="!message.details.coll_unavailable" [tooltipDelay]="200"
                                            (enabledClick)="(collaborationKey === message.content ? endCollaboration(message) : startCollaboration(message)); collaborateTutorial.next({collaboration: true, annotate: true, noCloseColl: true})"
                                            (disabledClick)="showCollaborationDisableReason()">
                                        <i class="mi mi-gesture mr-1"></i>
                                        <span class="collaborate">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.COLLABORATE' | translate }}</span>
                                        <span class="end-collaboration">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.CLOSE_COLLABORATION' | translate }}</span>
                                    </a>
                                </div>
                                <!--<a class="file-button">
                                    <i class="mi mi-gesture"></i>
                                </a>-->
                                <a class="file-button dropdown-toggle" dropdownToggle aria-controls="dropdown-more">
                                    <i class="mi mi-more-vert"></i>
                                </a>
                            </div>
                            <ul id="dropdown-more" *dropdownMenu class="dropdown-menu dropdown-menu-right py-1" role="menu" aria-labelledby="button-more">
                                <li role="menuitem"><a class="dropdown-item px-2" (click)="onDownloadFile(message)">
                                    <i class="mi mi-cloud-download mr-3"></i><span>{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.DOWNLOAD' | translate }}</span>
                                </a></li>
                                <li *ngIf="false && (message.type2 === 'screenshot' || message.details.own_message)" role="menuitem"><a class="dropdown-item px-2" (click)="onDeleteFile(message)">
                                    <i class="mi mi-delete mr-3"></i><span>{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.DELETE' | translate }}</span>
                                </a></li>
                                <li role="menuitem"><a class="dropdown-item px-2" (click)="message.type === 'file' ? onFileDetails(message) : onObjectDetails(message)">
                                    <i class="mi mi-info mr-3"></i><span>{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.DETAILS' | translate }}</span>
                                </a></li>
                                <li *ngIf="features.roomfiles && (currentUser.role === 'admin' || currentUser.role === 'coadmin' || currentUser.role === 'expert')" role="menuitem"><a class="dropdown-item px-2" (click)="onSendToRoomFiles(message)">
                                    <i class="mi mi-send mr-3"></i><span>{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.SEND_TO_FILES' | translate }}</span>
                                </a></li>
                            </ul>
                        </ng-container>
                        <div class="deleted-file-box mt-2" *ngIf="message.deleted">
                            <div class="deleted-icon">
                                <i class="mi mi-block"></i>
                            </div>
                            <span class="deleted-message">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_DELETED' | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="chat-type-area">
        <form *ngIf="translationEnabled" class="translation-form rounded" (ngSubmit)="sendTranslation(lastTranslationOrj, translatedMessage)">
            <input class="send-input rounded" type="text" autocomplete="off" name="translatedMessage" style="color: gray;"
                [(ngModel)]="translatedMessage" [disabled]="!sessionActive && !roomUserStatus?.training_room" [class.disabled]="!sessionActive && !roomUserStatus?.training_room"
                placeholder="{{ (translating ? 'translating...' : '') }}">

            <input class="d-none" type="file" (change)="onChooseFile(fileinput.files)" multiple #fileinput>

            <button class="form-button reload-button" (click)="reloadTranslation()" type="button" [disabled]="(!sessionActive  && !roomUserStatus?.training_room) || logMessage.length === 0 || logMessage === lastTranslationOrj || translating">
                <i class="mi mi-refresh"></i>
            </button>
            <button class="form-button send-button" (click)="sendTranslation(lastTranslationOrj, translatedMessage)" type="submit" [disabled]="(!sessionActive && !roomUserStatus?.training_room) || translating">
                <i class="mi mi-send"></i>
            </button>
        </form>

        <form class="send-form" (ngSubmit)="translationEnabled ? reloadTranslation() : sendMessage(logMessage)">
            <input class="send-input" type="text" autocomplete="off" name="logMessage" (paste)= "onPaste($event)"
                [(ngModel)]="logMessage" [disabled]="!sessionActive && !roomUserStatus?.training_room" [class.disabled]="!sessionActive && !roomUserStatus?.training_room"
                placeholder="{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.TYPE_HERE' | translate }}">

            <input class="d-none" type="file" (change)="onChooseFile(fileinput.files)" multiple #fileinput>

            <a class="form-button attachment-button" (enabledClick)="onAttachment(fileinput)" [fakeDisabled]="!features.filesharing || (!sessionActive && !roomUserStatus?.training_room)"
                    [dynamicTooltip]="'APP.SHARED.FEATURE_UNAVAILABLE'" [tooltipDisabled]="features.filesharing" [tooltipDelay]="200">
                <i class="mi mi-attach-file"></i>
            </a>
            <a class="form-button translation-button" (enabledClick)="toggleTranslation()" [fakeDisabled]="!features.chattranslation || (!sessionActive && !roomUserStatus?.training_room) || (logMessage.length === 0 && !translationEnabled)"
                [dynamicTooltip]="'APP.SHARED.FEATURE_UNAVAILABLE'" [tooltipDisabled]="features.chattranslation" [tooltipDelay]="200">
                <i class="mi mi-g-translate"></i>
            </a>
            <button class="form-button send-button" *ngIf="!translationEnabled" (click)="sendMessage(logMessage)" type="submit" [disabled]="!sessionActive && !roomUserStatus?.training_room">
                <i class="mi mi-send"></i>
            </button>
        </form>
    </div>

    <a class="unread-button bg-white border border-light rounded px-2 py-1" *ngIf="unreadNewMessage" (click)="scrollChatToBottom()">
        <span class="text-center pr-1">{{ unreadMessageCount }}</span>
        <i class="mi mi-play-circle-outline align-middle" style="font-size: 24px; transform: rotate(90deg); color: #4582b3;"></i>
    </a>

    <app-file-share [dropzoneState]="dropzoneStateSource.asObservable()"
        [chosenFiles]="chosenFilesSource.asObservable()" (uploadedFile)="uploadedFile($event)"
        (uploadedObject)="uploadedObject($event)" [uploadSource]="'chat'"></app-file-share>
</div>

<ng-template #collaborateTmp>
    <div>{{ 'APP.TUTORIALS.COLLABORATE' | translate }}</div>
</ng-template>

<ng-template #showTutorialTmp>
    <div>{{ 'APP.TUTORIALS.END_TUTORIAL' | translate }}</div>
    <a (click)="showTutorialTutorial.end()">{{ 'APP.TUTORIALS.END' | translate }}</a>
</ng-template>

<ng-template #fileDetailsTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <span class="mr-2">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_DETAILS_MODAL.TITLE' | translate }}</span>
                </h5>
            </div>
            <div class="modal-body py-3 px-2">
                <div class="file-details container-fluid" [class.file-visible]="dataModel.file">
                    <div class="row">
                        <div class="col-4 py-1">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_DETAILS_MODAL.FILENAME' | translate }}</div>
                        <div class="col-8 py-1">{{ dataModel.file?.name }}</div>
                    </div>
                    <div class="row">
                        <!-- Shows the total size if there are multiple files. -->
                        <div class="col-4 py-1">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_DETAILS_MODAL.SIZE' | translate }}</div>
                        <div class="col-8 py-1" *ngIf="dataModel.type === 'file'">{{ dataModel.file?.size | fileSize }}</div>
                        <div class="col-8 py-1" *ngIf="dataModel.type === 'object'">{{ dataModel.totalSize | fileSize }}</div>
                    </div>
                    <div class="row">
                        <!-- if the file is not an object, shows the file type. Else, shows the file count. -->
                        <div class="col-4 py-1" *ngIf="dataModel.type === 'file'">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_DETAILS_MODAL.TYPE' | translate }}</div>
                        <div class="col-4 py-1" *ngIf="dataModel.type === 'object'">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_DETAILS_MODAL.FILECOUNT' | translate }}</div>
                        <div class="col-8 py-1">{{ dataModel.type === 'file' ? dataModel.file?.type : dataModel.fileCount }}</div>
                    </div>
                    <div class="row">
                        <div class="col-4 py-1">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_DETAILS_MODAL.UPLOAD_TIME' | translate }}</div>
                        <div class="col-8 py-1">{{ dataModel.file?.create_time | date: 'MMM d, y HH:mm' }}</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-info px-4" (click)="callbacks.download()">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_DETAILS_MODAL.DOWNLOAD' | translate }}</button>
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.close()">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.FILE_DETAILS_MODAL.CLOSE' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #deleteFileTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.DELETE_FILE_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body px-3 py-4">
                <div>{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.DELETE_FILE_MODAL.MESSAGE' | translate }}</div>
                <!--<div class="text-danger mt-2 font-weight-bold">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.DELETE_FILE_MODAL.CAUTION' | translate }}</div>-->
            </div>

            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.cancel()">
                    {{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.DELETE_FILE_MODAL.CLOSE' | translate }}
                </button>
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.delete()">
                    {{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.DELETE_FILE_MODAL.DELETE' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #closeCollaborationTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{ (dataModel.previous ? 'APP.MAIN.ROOM.SIDEBAR.CHAT.CLOSE_PREV_COLLABORATION_TITLE' : 'APP.MAIN.ROOM.SIDEBAR.CHAT.CLOSE_COLLABORATION_TITLE') | translate }}
                </h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p class="mx-3">
                    {{ (dataModel.previous ? 'APP.MAIN.ROOM.SIDEBAR.CHAT.CLOSE_PREV_COLLABORATION_MESSAGE' : 'APP.MAIN.ROOM.SIDEBAR.CHAT.CLOSE_COLLABORATION_MESSAGE') | translate }}
                </p>
            </div>

            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">
                    {{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.CLOSE_PREV_COLLABORATION_NO' | translate }}
                </button>
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.yes()">
                    {{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.CLOSE_PREV_COLLABORATION_YES' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #disableFocusTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.DISABLE_FOCUS_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p class="mx-3" *ngIf="dataModel.authorized">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.DISABLE_FOCUS_MODAL.MESSAGE_AUTHORIZED' | translate }}</p>
                <p class="mx-3" *ngIf="!dataModel.authorized">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.DISABLE_FOCUS_MODAL.MESSAGE_UNAUTHORIZED' | translate }}</p>
            </div>

            <div class="modal-footer d-block text-right py-2 px-3">
                <ng-container *ngIf="dataModel.authorized">
                    <button class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.DISABLE_FOCUS_MODAL.CANCEL' | translate }}</button>
                    <button class="btn btn-sm btn-danger px-4" (click)="callbacks.yes()">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.DISABLE_FOCUS_MODAL.PROCEED' | translate }}</button>    
                </ng-container>
                <button *ngIf="!dataModel.authorized" class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.DISABLE_FOCUS_MODAL.CLOSE' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #closeArPlusTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.CLOSE_ARPLUS_BEFORE_COLL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p class="mx-3">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.CLOSE_ARPLUS_BEFORE_COLL.MESSAGE' | translate }}</p>
            </div>

            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.CLOSE_ARPLUS_BEFORE_COLL.NO' | translate }}</button>
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.yes()">{{ 'APP.MAIN.ROOM.SIDEBAR.CHAT.CLOSE_ARPLUS_BEFORE_COLL.YES' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>