import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "@services/core/auth.service";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { SessionExportService } from "@services/core/session-export.service";
import { RoomSessionService } from "@services/core/room-session.service";
import { SessionDurationPipe } from "app/pipes/session-duration.pipe";
import { Session } from "@models/Session";
import { Subscription } from "rxjs";
import { FlashMessageService } from "@services/support/flash-message.service";
import { distinctUntilChanged } from "rxjs/operators";
import { SessionDetailComponent } from "./session-detail/session-detail.component";
import { DetailPageService } from "@services/core/detail-page.service";

@Component({
  selector: "app-sessions",
  templateUrl: "./sessions.component.html",
  styleUrls: ["./sessions.component.scss"],
})
export class SessionsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort, { static: false })
  set sort(sorter: MatSort) {
    this.dataSource.sort = sorter;
  }

  @ViewChild(MatPaginator, { static: false })
  set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
    setTimeout(() => {
        this.pageIndex = this.dataSource.paginator.pageIndex
        this.pageSize = this.dataSource.paginator.pageSize
    });
  }
  dataSource = new MatTableDataSource<Session>();
  displayedColumns: string[] = [
    "index",
    "create_time",
    "room.name",
    "participants",
    "duration",
    "status"
  ];
  isUserExpert: boolean = false;
  sessions: Session[];
  isLoading: boolean = true;
  timezone = "UTC"
  pageIndex: number;
  pageSize: number;
  subscriptions: Subscription[] = []

  webdavEnabled: boolean = false;
  sessionExportEnabled: boolean = false;

  constructor(
    private authService: AuthService,
    private durationPipe: SessionDurationPipe,
    private sessionExportService: SessionExportService,
    private roomSessionService: RoomSessionService,
    private flashMessageService: FlashMessageService,
    private detailPageService: DetailPageService
  ) {}

  ngAfterViewInit(): void {}
  ngOnInit(): void {
    this.subscriptions.push(this.authService.timezone.pipe(
      distinctUntilChanged()
    ).subscribe(res=>{
       this.timezone = res;
    }))
    this.roomSessionService
      .getUserSessions()
      .then((res) => {
        this.isLoading = false;
        this.sessions = res.sessions.sort((a, b) => a.create_time > b.create_time ? -1 : 1);
        this.dataSource = new MatTableDataSource(this.sessions);
        this.dataSource.sortingDataAccessor = (item: Session, property) => {
          switch (property) {
            case "room.name":
              return item.room.name;
            case "duration":
              return this.durationPipe.transform(item);
            default:
              return item[property];
          }
        };
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      })
      .catch((error) => {
        this.isLoading = false;
        if (error.message === "permission-denied") {
          this.flashMessageService.showTranslated(
            "APP.EXTERNALS.AD_ERROR.PERMISSION_DENIED"
          );
        }
        if (error.message === "internal-error") {
          this.flashMessageService.showTranslated(
            "APP.EXTERNALS.AD_ERROR.INTERNAL_ERROR"
          );
        }
      });
      this.subscriptions.push(this.authService.addOns.subscribe(addOns => {
        this.sessionExportEnabled = addOns.sessionexport;
        this.webdavEnabled = addOns.webdav;
        this.subscriptions.push(this.authService.user.subscribe((userData) => {
          this.isUserExpert = userData && userData.role === "expert";
          if (this.isUserExpert && !this.displayedColumns.includes("export") && this.sessionExportEnabled && !this.webdavEnabled) {
            this.displayedColumns.push("export");
          }
        }));
      }));
    
  }

  onSessionExportModal(roomId: string, sessionId: string, event): void {
    event.stopPropagation();
    this.sessionExportService.sessionExportRequest.next([
      roomId,
      sessionId,
      true,
    ]);
  }

  onSessionDetails(session: Session) {
    const detail = this.detailPageService.loadComponent2(SessionDetailComponent).instance;
    detail.sessionApi = session;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
