<div class="login-wrapper">
    <div class="login-container">
        <div class="logo-container">
            <img *ngIf="!('design' | env).loginLogo" class="logo-image" [ngStyle]="('design' | env).logoStyle" [src]="('design' | env).logo">
            <img *ngIf="('design' | env).loginLogo" class="logo-image" [ngStyle]="('design' | env).loginLogoStyle" [src]="('design' | env).loginLogo">
            <div *ngIf="('design' | env).logoText" class="logo-text ml-2">{{ ('design' | env).logoText }}</div>
        </div>
        <div class="card">
            <div class="card-header pt-1 pb-0" [style.background-color]="('design' | env).loginColor"></div>
            <div class="card-body px-5 pt-1 pb-2">
                <div class="login-primary-title mt-5" *ngIf="('design' | env).addRemoteToName">{{ 'APP.LOGIN.APP_NAME' | translate:('design' | env) }}</div>
                <div class="login-primary-title mt-5" *ngIf="!('design' | env).addRemoteToName">{{ ('design' | env).appName }}</div>
                <div class="login-secondary-title mt-2 pb-2">{{ 'APP.LOGIN.TITLE' | translate }}</div>

                <div class="login-form-wrapper pb-0" [ngClass]="{'d-none': verification.open}">
                    <form #loginForm="ngForm" id="loginForm" (ngSubmit)="onLogin(loginForm.value)" class="mt-4">
                        <div class="form-group">
                            <input
                                class="field form-control py-2"
                                placeholder="{{ 'APP.LOGIN.ACCOUNT_PLACEHOLDER' | translate }}"
                                type="text"
                                name="account"
                                #loginAccount="ngModel"
                                [ngClass]="{'is-invalid':loginAccount.errors && loginAccount.touched}"
                                [(ngModel)]="loginUser.account"
                                minlength="2"
                                required
                                autocomplete="organization">
                            <small class="invalid-feedback mx-2" [hidden]="!loginAccount.errors?.required">{{ 'APP.LOGIN.ACCOUNT_REQUIRED' | translate }}</small>
                            <small class="invalid-feedback mx-2" [hidden]="!loginAccount.errors?.minlength">{{ 'APP.LOGIN.ACCOUNT_SHORT' | translate }}</small>
                        </div>
            
                        <div class="form-group" *ngIf="!msLoginMode">
                            <input
                                class="field form-control py-2"
                                placeholder="{{ 'APP.LOGIN.USERNAME_PLACEHOLDER' | translate }}"
                                type="text"
                                name="username"
                                #loginUsername="ngModel"
                                [ngClass]="{'is-invalid':loginUsername.errors && loginUsername.touched}"
                                [(ngModel)]="loginUser.username"
                                minlength="2"
                                required>
                            <small class="invalid-feedback mx-2" [hidden]="!loginUsername.errors?.required">{{ 'APP.LOGIN.USERNAME_REQUIRED' | translate }}</small>
                            <small class="invalid-feedback mx-2" [hidden]="!loginUsername.errors?.minlength">{{ 'APP.LOGIN.USERNAME_SHORT' | translate }}</small>
                        </div>
            
                        <div class="form-group mb-2" *ngIf="!msLoginMode">
                            <input
                                class="field form-control py-2"
                                placeholder="{{ 'APP.LOGIN.PASSWORD_PLACEHOLDER' | translate }}"
                                type="password"
                                name="password"
                                #loginPassword="ngModel"
                                [ngClass]="{'is-invalid':loginPassword.errors && loginPassword.touched}"
                                [(ngModel)]="loginUser.password"
                                minlength="6"
                                required>
                            <small class="invalid-feedback mx-2" [hidden]="!loginPassword.errors?.required">{{ 'APP.LOGIN.PASSWORD_REQUIRED' | translate }}</small>
                            <small class="invalid-feedback mx-2" [hidden]="!loginPassword.errors?.minlength">{{ 'APP.LOGIN.PASSWORD_SHORT' | translate }}</small>
                        </div>        
                    </form>

                    <div class="custom-control custom-checkbox d-flex align-items-center">
                        <input type="checkbox" class="custom-control-input" id="remember" name="remember" [(ngModel)]="accountNameRemeber">
                        <label class="custom-control-label" for="remember">{{ 'APP.LOGIN.REMEMBER_ACCOUNT_NAME' | translate }}</label>
                    </div>
                    <div class="custom-control custom-checkbox d-flex align-items-center" *ngIf="('design' | env).showLoggingInFromChina">
                        <input type="checkbox" class="custom-control-input" id="chinaserver" name="chinaserver" [(ngModel)]="useChinaServer" (ngModelChange)="chinaServerChange($event)">
                        <label class="custom-control-label" for="chinaserver">{{ 'APP.LOGIN.USE_CHINA_SERVERS' | translate }}</label>
                    </div>
                    <small class="text-danger" style="font-size: 12px;" [hidden]="!useChinaServer">{{ 'APP.LOGIN.CHINA_SERVERS_CAUTION' | translate }}</small>

                </div>
                <button class="btn btn-sm d-flex align-items-center justify-content-center mx-auto" (click)="loginWithMicrosoft(loginForm)" *ngIf="('design' | env).showMsLogin">
                    <img [src]="('design' | env).msLoginSquareLogo ? 'assets/img/square_logo.png' : 'assets/ms-logo.svg'" style="width: 24px; height: 24px;">
                    <span *ngIf="currentLang.code !== 'de'" class="ml-2 font-weight-bold" style="color: #5e5e5e;">{{ ('design' | env).msLoginText }}</span>
                    <span *ngIf="currentLang.code === 'de'" class="ml-2 font-weight-bold" style="color: #5e5e5e;">{{ ('design' | env).msLoginTextDE }}</span>
                </button>

            </div>
            <div class="card-footer d-flex flex-row justify-content-between align-items-center">
                <div class="language-dropdown" dropdown [dropup]="true" #dropdown="bs-dropdown">
                    <button id="language-toggle" class="language-link d-flex flex-row align-items-center" dropdownToggle aria-controls="language-menu">
                        <i class="mi mi-language mr-2"></i>
                        <span>{{ currentLang.code | uppercase }}</span>
                        <i class="mi mi-keyboard-arrow-down ml-2"></i>
                    </button>
                    <ul id="language-menu" *dropdownMenu class="dropdown-menu py-0" role="menu" aria-expanded="false" aria-labelledby="language-toggle">
                        <ng-container *ngFor="let lang of allLangs">
                            <li class="language-item" role="menuitem" *ngIf="currentLang !== lang">
                                <a class="dropdown-item language-link px-2" (click)="onLanguageSelected(lang)">{{ lang.code | uppercase }}</a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
                <button *ngIf="verification.open" form="verificationForm" class="btn btn-dark px-4" [disabled]="!verificationForm.valid">{{ 'APP.LOGIN.VERIFY_BUTTON' | translate }}</button>
                <button *ngIf="!verification.open && !msLoginMode" form="loginForm" class="btn btn-dark px-4" [disabled]="isLoginProgress || !isFormValid">{{ 'APP.LOGIN.LOGIN_BUTTON' | translate }}</button>
                <button *ngIf="msLoginMode" class="btn btn-dark px-4" (click)="msLoginMode = false">{{ 'APP.LOGIN.BACK_BUTTON' | translate }}</button>
            </div>
        </div>
        <label *ngIf="!('design' | env).unclickableTerms" class="agree-text w-100 mt-2">{{ 'APP.LOGIN.BEFORE_TERMS' | translate }}<ng-container *ngIf="!('design' | env).hideTermsOfService"><a (click)="onTermsofService()" [style.color]="('design' | env).linkColor">{{ 'APP.LOGIN.TERMS' | translate }}</a>{{ 'APP.LOGIN.CONJUNCTION' | translate }}</ng-container><a (click)="onPrivacyPolicy()" [style.color]="('design' | env).linkColor">{{ 'APP.LOGIN.PRIVACY' | translate }}</a>{{ 'APP.LOGIN.AFTER_PRIVACY' | translate }}</label>
        <label *ngIf="('design' | env).unclickableTerms" class="agree-text w-100 mt-2">{{ 'APP.LOGIN.BEFORE_TERMS' | translate }}<a [style.color]="('design' | env).linkColor">{{ 'APP.LOGIN.TERMS' | translate }}</a>{{ 'APP.LOGIN.CONJUNCTION' | translate }}<a [style.color]="('design' | env).linkColor">{{ 'APP.LOGIN.PRIVACY' | translate }}</a>{{ 'APP.LOGIN.AFTER_PRIVACY' | translate }}</label>

        <label class="agree-text w-100 mt-3" *ngIf="('design' | env).showPoweredBy">Powered by <a href="https://vsight.io" target="_blank" [style.color]="('design' | env).linkColor">VSight</a></label>
        <label class="agree-text w-100 mt-3" *ngIf="('design' | env).customPoweredBy">Powered by <a [href]="('design' | env).customPoweredByURL" target="_blank" [style.color]="('design' | env).linkColor">{{ ('design' | env).customPoweredBy }}</a></label>
    </div>
</div>

<ng-template #multiLoginWarningModal let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.LOGIN.MULTI_LOGIN_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body">
                <p>{{ 'APP.LOGIN.MULTI_LOGIN_MODAL.MESSAGE' | translate }}</p>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.cancel()">{{ 'APP.LOGIN.MULTI_LOGIN_MODAL.CANCEL' | translate }}</button>
                <button class="btn btn-sm btn-info px-4" (click)="callbacks.proceed()">{{ 'APP.LOGIN.MULTI_LOGIN_MODAL.PROCEED' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>