<app-loader></app-loader>
<app-modal></app-modal>
<app-tutorial></app-tutorial>
<app-flash-message></app-flash-message>

<!--
    #LoginComponent
    #MainComponent
    #NotFoundComponent
    #PrivacyComponent
    #TermsComponent
    #VsGuideComponent
-->
<router-outlet></router-outlet>


<ng-template #updateTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.UPDATE_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body p-3">{{ 'APP.UPDATE_MODAL.MESSAGE' | translate }}</div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-info px-4" (click)="callbacks.update()">{{ 'APP.UPDATE_MODAL.UPDATE' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>