<div class="settings-container pt-2">
    <div class="setting-wrapper">
        <div class="setting py-2 px-4" style="border-bottom: none;">
            <div>{{ 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.MICROPHONE' | translate }}</div>
            <div class="switch pt-1">
                <input type="checkbox" id="audio-switch" name="switch" [checked]="pAudio" (change)="onToggleAudio()">
                <label for="audio-switch"></label>
            </div>
        </div>
        <div class="setting2 pb-3 px-4" style="border-top: none;">
            <form #audioForm="ngForm">
                <div class="form-group m-0">
                    <ng-select [items]="audios" [clearable]="false" [searchable]="false" name="audios" bindLabel="label" bindValue="deviceId" placeholder="" [(ngModel)]="selectedAudioDeviceId" (change)="onAudioDeviceSelected($event)"></ng-select>
                </div>
            </form>
        </div>
        <div class="setting py-2 px-4" style="border: none;">
            <div>{{ 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.CAMERA' | translate }}</div>
            <div class="switch pt-1">
                <input type="checkbox" id="video-switch" name="switch" [checked]="pVideo" [disabled]="screenPublishing" (change)="onToggleVideo()">
                <label for="video-switch"></label>
            </div>
        </div>
        <div class="setting2 pb-1 px-4" style="border-top: none;">
            <form #videoForm="ngForm">
                <div class="form-group m-0">
                    <ng-select [items]="videos" [clearable]="false" [searchable]="false" name="videos" bindLabel="label" bindValue="deviceId" placeholder="" [(ngModel)]="selectedVideoDeviceId" (change)="onVideoDeviceSelected($event)" [disabled]="screenPublishing"></ng-select>
                    <small class="invalid-feedback mt-1" [hidden]="!screenPublishing" style="font-size: 12px;">You can't change camera when screen sharing is enabled</small>
                </div>
                <div class="form-group m-0">
                    <div class="custom-control custom-checkbox d-flex align-items-center">
                        <input type="checkbox" class="custom-control-input" id="mirror" name="mirror" [(ngModel)]="cameraMirror" (ngModelChange)="cameraMirrorChanged($event)">
                        <label class="custom-control-label" for="mirror">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.MIRROR_VIDEO' | translate }}</label>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="setting-wrapper mt-2">
        <div class="setting2 py-3 px-4">
            <div class="mb-2">{{ 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.TRANSLATE_OTHERS_MSG_TO' | translate }}</div>
            <form #translationForm="ngForm">
                <div class="form-group m-0">
                    <ng-select [items]="languages" [clearable]="false" name="languages" bindLabel="displayName" bindValue="languageCode" placeholder="" [(ngModel)]="selectedLanguageCode" (change)="onLangChange($event)"></ng-select>
                </div>
            </form>
        </div>

        <div class="setting2 py-3 px-4" style="border-top: none;">
            <div class="mb-2">{{ 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.TRANSLATE_MY_MSG_TO' | translate }}</div>
            <form #translationForm2="ngForm">
                <div class="form-group m-0">
                    <ng-select [items]="languages" [clearable]="false" name="languages" bindLabel="displayName" bindValue="languageCode" placeholder="" [(ngModel)]="selectedLanguageCode2" (change)="onLangChange2($event)"></ng-select>
                </div>
            </form>
        </div>
    </div>

    <div class="setting-wrapper mt-2">
        <div class="join-setting py-3 px-4">

            <form #inviteFieldCodeForm="ngForm">
                <p class="mb-2" [ngStyle]="{'color': (!joinLinkAccountAllowed || !joinLinkUserAllowed) ? 'grey' : 'black'}">{{ 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.CONNECT_VIA_FIELD_CODE' | translate }}</p>
                <div class="form-row field-code">
                    <div class="form-group col-6 field-code-textbox mb-0">
                        <input class="form-control py-2" type="text" autocomplete="off" maxlength="6" [ngModelOptions]='{ standalone: true }' (keypress)="numberOnly($event)" 
                            [(ngModel)]="fieldCode" placeholder="{{ 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.FIELD_CODE' | translate }}" (ngModelChange)="onFieldCodeChange()" 
                            [disabled]='invitingViaFieldCode || !joinLinkAccountAllowed || !joinLinkUserAllowed' (keyup.enter)="inviteFieldUserToSession()"
                            style="font-size: 12px;">
                    </div>
                    <div class="form-group col-6 field-code-button mb-0">
                        <button class="link-generate-button btn btn-block border"
                            [ngStyle]="{'color': enableFieldInviteButton ? 'black' : '#b4b4b4', 'disabled': !joinLinkAccountAllowed || !joinLinkUserAllowed}"
                            [ngClass]="{'disabled': !joinLinkAccountAllowed || !joinLinkUserAllowed || !enableFieldInviteButton}" (click)='inviteFieldUserToSession();'>
                            {{ 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.CONNECT' | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="setting-wrapper mt-1">
        <div class="join-setting py-3 px-4">

            <div class="mb-2">{{ 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.GUEST_ACCESS' | translate }}</div>

            <ng-container *ngIf='!creatingGuestAccess'>
                <button (click)="onCreateGuestAccess()" class="link-generate-button btn btn-sm btn-block border p-2"
                        [ngClass]="{'disabled': !joinLinkAccountAllowed || !joinLinkUserAllowed || invitingViaFieldCode || creatingGuestAccessOnGoing}"
                        [dynamicTooltip]="'APP.SHARED.FEATURE_UNAVAILABLE'" [tooltipDisabled]="joinLinkAccountAllowed" [tooltipDelay]="200">
                    {{ ((creatingGuestAccessOnGoing || invitingViaFieldCode) && (joinLinkAccountAllowed && joinLinkUserAllowed) ? 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.GENERATING' : 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.CREATE_GUEST_ACCESS') | translate }}
                </button>
            </ng-container>

            <ng-container *ngIf="creatingGuestAccess">
                <div class="join-link-wrapper d-flex align-items-center mb-2">
                    <a #link class="join-link mr-2" [copy-clipboard]="joinLink" [select-text]="link" (copied)="joinLinkCopied($event)">{{ joinLink }}</a>
                    <button class="copy-button btn btn-sm border" [copy-clipboard]="joinLink" [select-text]="link" (copied)="joinLinkCopied($event)">{{ 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.COPY_LINK' | translate }}</button>
                </div>
                <div class="text-center">
                    <img *ngIf="qrcode" class="img-fluid" [src]="qrcode" alt="QR" style="cursor: pointer;" style="width: 150px;">
                </div>
                <div class="btn-group text-center d-flex align-items-center justify-content-center mt-2" dropdown style="width: 100%;">
                    <button id="button-1" dropdownToggle type="button" class="btn btn-sm dropdown-toggle text-center d-flex align-items-center justify-content-center" aria-controls="dropdown-1" style="width: 100%; background: #3a7cb3; color: white">
                        <span>{{ 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.SHARE' | translate }}</span>
                    </button>
                    <ul id="dropdown-1" *dropdownMenu class="join-dropdown-menu dropdown-menu dropdown-menu-right py-1" role="menu" aria-labelledby="button-1" style="width: 100%;">
                        <li role="menuitem" style="cursor: pointer">
                            <a (click)="onMailGenerating()" class="dropdown-item d-flex justify-content-start" style="font-size: 15px;" [href]="mailToUrl" target="_blank">         <i class="mi mi-mail mr-3 d-flex justify-content-start" style="font-size: 15px;">                 </i>E-mail</a>
                        </li>
                        <li role="menuitem" style="cursor: pointer">
                            <a (click)="onWhatsappGenerating()" class="dropdown-item d-flex justify-content-start" style="font-size: 15px;" [href]="whatsappToUrl" target="_blank"> <i class="mi mi-sms mr-3 d-flex justify-content-start" style="font-size: 15px;">                  </i>Whatsapp</a>
                        </li>
                        <li role="menuitem" style="cursor: pointer">
                            <a [ngClass]="{'disabled': !smsAccountEnabled}" (click)="onSMSGenerating()" class="dropdown-item d-flex justify-content-start" style="font-size: 15px;"><i class="mi mi-stay-current-portrait mr-3 d-flex justify-content-start" style="font-size: 15px;"></i>SMS</a>
                        </li>
                    </ul>
                </div>
            </ng-container>

            <ng-container *ngIf="joinSMSForm && smsAccountEnabled">
                <div class="join-via-sms mt-3">
                    <div class="mb-2 mt-2" style="font-size: 12px;">{{ 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.SHARE_LINK_VIA_SMS' | translate }}</div>
                    <form #smsForm="ngForm" id="smsForm" (ngSubmit)="onSendSMS(smsForm.value)">
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <ng-container>
                                    <ng-select 
                                        [items]="countryCodes" 
                                        [clearable]="false" 
                                        name="countrycodes" 
                                        bindLabel="number" 
                                        bindValue="number" 
                                        placeholder="+93" 
                                        [(ngModel)]="mobilePhone.selectedCountry"
                                        (change)="onMobilePhoneChange()" 
                                    >
                                        <ng-template ng-option-tmp let-item="item" let-index="index">
                                            <img height="10" width="15" [src]="item.flag"/>&nbsp;<b>{{item.number}}</b>
                                        </ng-template>
                                    </ng-select>
                                </ng-container>
                            </div>
                            <input 
                                class="field form-control py-2" 
                                type="number"
                                autocomplete="off" 
                                name="mobilephoneNumber"
                                #mobilephoneAccount="ngModel" 
                                [ngClass]="{'is-invalid':mobilephoneAccount.errors && mobilephoneAccount.touched}"
                                [(ngModel)]="mobilePhone.number"
                                placeholder=""
                                (ngModelChange)="onMobilePhoneChange()"
                                (keypress)="numberOnly($event)"
                            >
                        </div>
                        <div *ngIf="savedPhoneNums[0]" class="mb-2">{{ 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.PHONE_NUMBERS' | translate }}</div>
                        <div *ngFor="let phoneNum of savedPhoneNums">
                            <div class="text-info mb-2" style="cursor: pointer;"><a (click)=onPhoneNumClick(phoneNum)> {{ phoneNum.selectedCountry }} {{ phoneNum.number }} </a> </div>
                        </div>
                        <button [disabled]="!sendEnabled" class="btn btn-sm border" style="font-size: 12px; display: block; width: 100%;" type="submit">{{ 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.SEND' | translate }}</button>
                        <div *ngIf="sendButtonClicked" class="mb-2 mt-3" style="font-size: 12px;">{{ 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.DIDNT_GET' | translate }} <a #link class="share-link mr-2" style="cursor: pointer;" (click)="onSMSRegenerating()">{{ 'APP.MAIN.ROOM.SIDEBAR.SETTINGS.RESEND' | translate }}</a></div>
                    </form>   
                </div>
            </ng-container>
        </div>
    </div>
</div>