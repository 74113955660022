<div class="main-wrapper container-fluid py-5">
  <div class="row justify-content-center">
    <div class="col-xl-9 col-lg-10 col-md-11 col-12">
      <div class="main-header text-muted d-flex">
        <span class="py-2">{{
          "APP.MAIN.LOBBY.SESSIONS.TITLE" | translate
        }}</span>
      </div>
      <div>
        <table mat-table [dataSource]="dataSource" matSort  matTableResponsive>
          <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell data-column-name="#" *matCellDef="let i = index">
            {{ pageIndex * pageSize + i + 1}}
            </td>
          </ng-container>
          <ng-container matColumnDef="create_time">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ 'APP.MAIN.LOBBY.SESSIONS.DATE' | translate }} ({{ timezone }})
            </th>
            <td
              mat-cell
              data-column-name="Created On"
              *matCellDef="let element"
            >
            {{ element.create_time | utcCalcAccount | async }}
            </td>
          </ng-container>
          <ng-container matColumnDef="room.name">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by name"
            >
            {{ 'APP.MAIN.LOBBY.SESSIONS.ROOM_NAME' | translate }}
            </th>
            <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.ROOM_NAME' | translate" *matCellDef="let element">{{ element.room.name }}</td>
          </ng-container>
          <ng-container matColumnDef="participants">
            <th mat-header-cell *matHeaderCellDef>{{ 'APP.MAIN.LOBBY.SESSIONS.PARTICIPANTS' | translate }}</th>
            <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.PARTICIPANTS' | translate" *matCellDef="let element">
              <div *ngFor="let user of element.users" class="participant">
                {{ user.name }}
              </div>
              <div *ngFor="let guestUser of element.guest_users" class="participant">
                {{ guestUser.name }} <span class="text-info">{{ 'APP.MAIN.LOBBY.TICKETS.GUEST' | translate }}</span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="duration">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by duration"
            >
            {{ 'APP.MAIN.LOBBY.SESSIONS.DURATION' | translate }}
            </th>
            <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.DURATION' | translate" *matCellDef="let element">
              {{ element | sessionDuration }}
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>{{ 'APP.MAIN.LOBBY.SESSIONS.STATUS' | translate }}</th>
            <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.STATUS' | translate" *matCellDef="let element">
              <span class="badge" [ngClass]="{'badge-success': !element.end_time, 'badge-secondary': element.end_time}">{{ (element.end_time ? 'APP.MAIN.LOBBY.SESSIONS.ENDED' : 'APP.MAIN.LOBBY.SESSIONS.ON_GOING') | translate }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="export" *ngIf="isUserExpert && sessionExportEnabled && !webdavEnabled">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'APP.MAIN.LOBBY.SESSIONS.EXPORT' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              (click)="onSessionExportModal(element.room.id, element.id, $event)"
            >
              <img *ngIf="!element.session_files_deleted" src="assets/export_session.svg" alt="" />
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <div *ngIf="!isLoading && isUserExpert">
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onSessionDetails(row)"></tr>
          </div>
          <div *ngIf="!isLoading && !isUserExpert">
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </div>
        </table>
        <ng-container *ngIf="isLoading" [ngTemplateOutlet]="loadingTemplate"></ng-container>
        <div class="bg-white empty-list" *ngIf="dataSource && (!dataSource.filteredData || dataSource.filteredData.length === 0) && !isLoading">
          <div class="ticket-list-empty py-2"> {{ "APP.MAIN.LOBBY.SESSIONS.NO_SESSIONS" | translate }}</div>
      </div>
        <mat-paginator
          [pageSizeOptions]="[10, 5, 20]"
          showFirstLastButtons
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div style="background-color: white; padding: 50px;">
    <div class="d-flex justify-content-center">
      <div class="loader loader-sm la-dark">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</ng-template>
