<div class="modal-header d-flex align-items-center justify-content-between">
    <h5 class="modal-title d-flex align-items-center"><i class="mi mi-style" style="font-size: 20px; padding-right: 4px;"></i>
        {{ title ? title : ('APP.MAIN.ROOM.SIDEBAR.ATTACH_SESSION_TO_TICKET' | translate) }}
    </h5>
</div>
<div class="modal-body p-0">
       <app-tickets
        [showFilter]= "false" 
        [isModalView]="true" 
        (onTicketSelection)="setSelectedTicket($event)" 
        [filter]="'room'" 
        [roomId]="currentRoom?.id"></app-tickets>
</div>
<div class="modal-footer">
    <button class="btn btn-sm btn-dark px-4" style="font-size: 0.875rem;" type="button" (click)="close()" *ngIf="!disableClose">
        {{ 'APP.MAIN.LOBBY.TICKETS.CLOSE_PICK_TICKET' | translate }}
    </button>
    <button  class="btn btn-sm btn-info px-4" style="font-size: 0.875rem;" type="button" (click)="create()" *ngIf="canCreate">
        {{ 'APP.MAIN.LOBBY.TICKETS.CREATE_NEW' | translate }}
    </button>
    <button  class="btn btn-sm btn-info px-4" style="font-size: 0.875rem;" [disabled]="!selectedTicket" type="button" (click)="attach()">
        {{ confirmButtonText ? confirmButtonText : ('APP.MAIN.LOBBY.TICKETS.ATTACH' | translate) }}
     </button>
</div>