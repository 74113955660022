<div class="files-container" filedropenter (dropEnter)="onDropEnter()">
    <div class="files-area">
        <div class="card mb-3 mx-auto">
            <div class="card body d-flex flex-row p-2">
                <i class="mi mi-info d-flex align-items-center info-icon ml-2"></i>
                <p class="info-text">{{ 'APP.MAIN.ROOM.SIDEBAR.FILES.INFO_TEXT' | translate }}</p>
            </div>
        </div>
        <div class="file-container" *ngFor="let file of filesArray; index as i"
            [popover]="popTemplate" containerClass="popover-body" (mouseenter)="!isDropdownOpen && pop.show()" (mouseleave)="pop.hide()" triggers="" [popoverContext]="{file:filesArray[i]}" container="body" placement="left" #pop="bs-popover">
            <div class="room-file mb-2">
                <div class="file-image">
                    <img *ngIf="imageExtensions.includes(file.extension)" [src]="file.url">
                    <img *ngIf="pdfExtensions.includes(file.extension)" src="assets/img/pdf.png">
                    <img *ngIf="objectSpecificExtensions.includes(file.extension)" src="assets/img/obj.png">
                    <img *ngIf="!imageExtensions.includes(file.extension) && !pdfExtensions.includes(file.extension) && !objectSpecificExtensions.includes(file.extension)" src="assets/img/other.png">
                </div>
                <div class="file-info px-1">
                    <p class="file-name"> {{ file.name }} </p>
                    <p *ngIf="!objectSpecificExtensions.includes(file.extension)" class="file-size"> {{ file.size | fileSize }} </p>
                    <p *ngIf="objectSpecificExtensions.includes(file.extension)" class="file-size"> {{ file.files | objSize }} </p>
                </div>
                <div class="file-dropdown" (onShown)="isDropdownOpen = !isDropdownOpen" (onHidden)="isDropdownOpen = !isDropdownOpen" dropdown #dropdown="bs-dropdown">
                    <button id="file-toggle" class="btn dropdown-button px-2 py-3" dropdownToggle aria-controls="file-menu" (click)="pop.toggle()">
                        <i class="mi mi-more-vert"></i>
                    </button>
                    <ul id="file-menu" *dropdownMenu class="dropdown-menu" role="menu" aria-expanded="false" aria-labelledby="file-toggle"
                            [ngStyle]="{'margin-top': (currentUser.role === 'admin' || currentUser.role === 'coadmin' || currentUser.role === 'expert') ? '3rem' : '0' }">
                        <li role="menuitem"><a class="dropdown-item" (click)="onDownloadFile(file)">
                            <i class="mi mi-cloud-download mr-2"></i><span>{{ 'APP.MAIN.ROOM.SIDEBAR.FILES.DOWNLOAD' | translate }}</span>
                        </a></li>
                        <ng-container *ngIf="currentUser.role === 'admin' || currentUser.role === 'coadmin' || currentUser.role === 'expert'">
                            <li role="menuitem"><a class="dropdown-item" (click)="onSendToSession(file)">
                                <i class="mi mi-send mr-2"></i><span>{{ 'APP.MAIN.ROOM.SIDEBAR.FILES.SEND_TO_SESSION' | translate }}</span>
                            </a></li>
                            <li role="menuitem"><a class="dropdown-item" (click)="onDeleteFile(file)">
                                <i class="mi mi-delete mr-2"></i><span>{{ 'APP.MAIN.ROOM.SIDEBAR.FILES.DELETE' | translate }}</span>
                            </a></li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="upload-area">
        <input class="d-none" type="file" (change)="onChooseFile(fileinput.files)" multiple #fileinput>
        <button (click)="onAttachment(fileinput)" class="btn upload-button">{{ 'APP.MAIN.ROOM.SIDEBAR.FILES.UPLOAD_FILE' | translate }}</button>
    </div>
    <app-file-share [dropzoneState]="dropzoneStateSource.asObservable()" 
    [chosenFiles]="chosenFilesSource.asObservable()" [uploadSource]="'files'"></app-file-share>
</div>

<ng-template #popTemplate style="background-color: rgba(0, 0, 0, 0.1);" let-file="file">
    <div *ngIf="file.files">
        <div><b>{{ 'APP.MAIN.ROOM.SIDEBAR.FILES.FILE_CONTENTS' | translate }}</b></div>
        <li *ngFor="let f of file.files">
            {{ f.name }}
        </li>
        <div><b>{{ 'APP.MAIN.ROOM.SIDEBAR.FILES.TOTAL_SIZE' | translate }}</b> {{ file.files | objSize }} </div>
    </div>
    <div *ngIf="!(file.files)">
        <div><b>{{ 'APP.MAIN.ROOM.SIDEBAR.FILES.FILE_NAME' | translate }}</b> {{ file.name }} </div>
        <div><b>{{ 'APP.MAIN.ROOM.SIDEBAR.FILES.FILE_SIZE' | translate }}</b> {{ file.size | fileSize }} </div>
    </div>
</ng-template>

<ng-template #deleteFileTemplate let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.ROOM.SIDEBAR.FILES.DELETE_MODAL.TITLE_DELETE' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p class="mx-3">{{ 'APP.MAIN.ROOM.SIDEBAR.FILES.DELETE_MODAL.MESSAGE_DELETE' | translate }}</p>
            </div>
    
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">
                    {{ 'APP.MAIN.ROOM.SIDEBAR.FILES.DELETE_MODAL.CANCEL' | translate }}
                </button>
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.yes()">
                    {{ 'APP.MAIN.ROOM.SIDEBAR.FILES.DELETE_MODAL.DELETE' | translate }}
                </button>
            </div>
        </div>
    </div>
  </ng-template>