import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { WorkflowService } from '@services/other/workflow.service';
import { LoaderService } from '@services/support/loader.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-workflow-list',
  templateUrl: './workflow-list.component.html',
  styleUrls: ['./workflow-list.component.scss']
})
export class WorkflowListComponent implements OnInit {
  onClose: EventEmitter<any> = new EventEmitter();
  dataSource : any
  displayedColumns: string[] = ['index', 'title'];

  isLoading = false;

  filter = new FormControl()
  workflows

  constructor(public modalService:BsModalRef, private workflowService: WorkflowService,private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.isLoading = true
    this.workflowService.getAllWorkflows().then(res => {
      this.workflows = res.data
      res.data.forEach(workflow => {
        if(workflow.translations) {
          workflow['listName'] = workflow.defaultLanguage ? workflow.translations[workflow.defaultLanguage][workflow.name] : workflow.translations['en'][workflow.name]
        }else {
          if(workflow) {
            workflow['listName'] = workflow.name
          }
        }
      })
      this.dataSource = new MatTableDataSource(res.data);

      this.filter.valueChanges.pipe(debounceTime(300)).subscribe(res => {
        if (res) {
          this.filterWorkflows(res.toLowerCase())
        } else {
          this.dataSource = new MatTableDataSource(this.workflows)
        }
      })
      this.isLoading = false
    })
    
  }
  selectWorkflow(workflow){
    
    this.onClose.next(workflow)
    this.modalService.hide()

  }

  filterWorkflows(filterData?){
    let filteredData =[]
    filteredData = this.workflows.filter(w => {
      if(w){
        return w['listName'].toLowerCase().includes(filterData.toLowerCase())
      }
      else{
       return filteredData
      }
    })
   
    this.dataSource = new MatTableDataSource(filteredData);
  }
  
}
