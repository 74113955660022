import { Directive, OnInit, OnDestroy, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

declare var $: any;

@Directive({
    selector: '[dynamicTooltip]'
})
export class DynamicTooltipDirective implements OnInit, OnDestroy, OnChanges {
    @Input('dynamicTooltip') translationKey: string;
    @Input('tooltipDisabled') tooltipDisabled: boolean = false;
    @Input('tooltipDelay') tooltipDelay: number = 1000;
    @Input('tooltipPosition') tooltipPosition: "left" | "top" | "right" | "buttom" = "top";

    private langSub: Subscription = null;
    private tooltipAdded: boolean = false;
    private jqueryObject: any;

    constructor(
        private el: ElementRef,
        private translateService: TranslateService,
        private multilanguageService: MultilanguageService
    ) {}

    ngOnInit() {
        if (this.translationKey) {
            this.jqueryObject = $(this.el.nativeElement);
            this.langSub = this.multilanguageService.onLangChange.pipe(startWith(null)).subscribe(change => {
                this.addTooltip(this.translateService.instant(this.translationKey));
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.tooltipDisabled && !changes.tooltipDisabled.firstChange && this.tooltipAdded) {
            this.tooltipDisabled = changes.tooltipDisabled.currentValue;
            this.jqueryObject.tooltip(this.tooltipDisabled ? "disable" : "enable");
        }
        if (changes.tooltipDelay && !changes.tooltipDelay.firstChange && this.tooltipAdded) {
            this.tooltipDelay = changes.tooltipDelay.currentValue;
            this.addTooltip(this.translateService.instant(this.translationKey));
        }
        if (changes.tooltipPosition && !changes.tooltipPosition.firstChange && this.tooltipAdded) {
            this.tooltipPosition = changes.tooltipPosition.currentValue;
            this.addTooltip(this.translateService.instant(this.translationKey));
        }
        if (changes.translationKey && !changes.translationKey.firstChange) {
            this.translationKey = changes.translationKey.currentValue;
            this.addTooltip(this.translateService.instant(this.translationKey));
        }
    }

    ngOnDestroy() {
        if (this.langSub) { this.langSub.unsubscribe() }
        if (this.translationKey && this.tooltipAdded) { this.jqueryObject.tooltip('dispose') }
    }

    private addTooltip(tooltip: string) {
        if (this.tooltipAdded) {
            this.jqueryObject.tooltip('dispose');
        }
        this.jqueryObject.tooltip({ title: tooltip, trigger: "hover", animation: false, placement: this.tooltipPosition, delay: {show: this.tooltipDelay, hide: 100} });
        if (this.tooltipDisabled) { this.jqueryObject.tooltip("disable") }
        this.tooltipAdded = true;
    }
}