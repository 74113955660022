import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionExportService {
  
  sessionExportRequest: Subject<[string, string, boolean]> = new Subject<[string, string, boolean]>();

  constructor() { }
}
