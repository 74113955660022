import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JoinRoomResponse } from '@models/JoinRoomResponse';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/core/auth.service';
import { CallService } from '@services/core/call.service';
import { DbService } from '@services/core/db.service';
import { OpentokService } from '@services/core/opentok.service';
import { RoomService } from '@services/other/room.service';
import { WorkflowService } from '@services/other/workflow.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { LoaderService } from '@services/support/loader.service';
import { ModalService } from '@services/support/modal.service';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { UtilityService } from '@services/support/utility.service';
import { environment } from 'environments/environment';
import { first } from 'rxjs/operators';
import { LogService } from '@services/core/log.service';
import { CollaborationService } from '@services/core/collaboration.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-connect-experts',
  templateUrl: './connect-experts.component.html',
  styleUrls: ['./connect-experts.component.scss']
})
export class ConnectExpertsComponent implements OnInit, OnDestroy {

  currentRoomId
  currentRoomPath
  inRoom

  callResponse
  loginResponse

  @ViewChild("archivePermissionModal", { static: true }) private archivePermissionModal: TemplateRef<any>;
  @ViewChild("notSupportedTemplate", { static: true }) private notSupportedTemplate: TemplateRef<any>;
  @ViewChild("joinTemplate", { static: true }) private joinTemplate: TemplateRef<any>;
  @ViewChild("noDeviceTemplate", {static: true}) private noDeviceTemplate: TemplateRef<any>;

  @ViewChild('videoElement') videoElement: ElementRef;
  @ViewChild("multiLoginWarningModal", { static: true }) private multiLoginWarningModal: TemplateRef<any>;

   id;
   accountName
   username
   subscriptions: Subscription[] = []

  constructor(
    private route: ActivatedRoute, 
    private workflowService: WorkflowService, 
    private authService: AuthService, 
    private router: Router,
    private callService:CallService,
    private loaderService: LoaderService,
    private modalService: ModalService,
    private opentokService: OpentokService,
    private flashMessageService: FlashMessageService,
    private utilityService: UtilityService,
    private multilanguageService: MultilanguageService,
    private translateService: TranslateService,
    private roomService: RoomService,
    private dbService: DbService, 
    private logService: LogService,
    private collaborationService: CollaborationService) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit(): void {
    this.loaderService.show();
    this.route.queryParams.pipe(first()).toPromise().then(res => {
      this.id = res['id']
      this.accountName = res['account_name']
      this.username = res['username']
      this.callExpert(this.accountName, this.username, this.id, false)
    })
  }

  private callExpert(accountName, username, id, kickOther) {
    this.authService.sendLoginCredentialsForRemoteIntegration(accountName,username, id, kickOther)
    .then((res) => {
      return this.authService.login(res.token)
    })
    .then((response) => new Promise<any>(resolve => setTimeout(() => resolve(response), 5000)))
    .then(response => {
      if(response) {
        return this.workflowService.workflowRemoteIntegrationCall(this.id, environment.version, 'web', response.token)
      }
    })
    .then((res) => {
      this.loaderService.hide();
      this.callResponse = res.data.callResponse
      this.showJoinModal()
    })
    .catch(error => {
        if (error.error === 'user-already-online') {
        const modalId = this.modalService.show({
          template: this.multiLoginWarningModal,
          context: {
            dataModel: null,
            callbacks: {
              cancel: () => this.modalService.hide(modalId),
              proceed: () => {
                this.modalService.hide(modalId);
                this.callExpert(this.accountName, this.username, this.id, true)
                // this.authService.sendLoginCredentialsForRemoteIntegration(this.accountName, this.username, this.id, true)
              }
            }
          }
        });
      }
      if(error.error.message == 'integration-already-used') {
        this.flashMessageService.showTranslated("APP.MAIN.WORKFLOW_REMOTE.ERRORS.INTEGRATION_ALREADY_USED");
      }
      if(error.error.message == 'integration-not-found') {
        this.flashMessageService.showTranslated("APP.MAIN.WORKFLOW_REMOTE.ERRORS.INTEGRATION_ALREADY_USED");
      }
      if(error.error.message == 'missing-parameters') {
        this.flashMessageService.showTranslated("APP.MAIN.WORKFLOW_REMOTE.ERRORS.INTEGRATION_ALREADY_USED");
      }
      if (error.error.message === 'account-disabled') {
        this.flashMessageService.showTranslatedWithData('APP.LOGIN.ACCOUNT_DISABLED', environment.design);
      }
      if(error.error.message == 'call-in-progress') {
        this.flashMessageService.showTranslated("APP.MAIN.WORKFLOW_REMOTE.ERRORS.CALL_IN_PROGRESS");
      }
      if (error.error.message === 'concurrent-limit-reached') {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.JOIN_CONCURRENT_ERROR', { timeout: 10000 });
      }
      if (error.error.message === 'user-in-another-room') {
        this.flashMessageService.showTranslated("APP.MAIN.WORKFLOW_REMOTE.ERRORS.USER_IN_ANOTHER_ROOM");
      }
      if (error.error.message === 'archive-permission-needed') {
        this.showArchivePermissionModal();
      }
      if(error.error.message == 'internal-error') {
        this.flashMessageService.showTranslated("APP.EXTERNALS.AD_ERROR.INTERNAL_ERROR");
      }
    })
    .finally(() => this.loaderService.hide())
  }

  private onJoinRoom(joinResponse: JoinRoomResponse) {
    this.subscriptions.push(this.roomService.getCurrentRoom(joinResponse.room_id).subscribe(room => {}))
    this.callService.onJoinRoom(joinResponse)
    setTimeout(() => {
      console.log('')
    }, 15000);

  }

  showNotSupportedModal(dataModel: any) {
    const modalId = this.modalService.show({
      template: this.notSupportedTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          close: () => this.modalService.hide(modalId)
        }
      }
    });
  }

  showJoinModal() {
    const AContext = window.AudioContext || window.webkitAudioContext;
    const model = {
      publishResolutions: ["320x180", "320x240", "640x360", "640x480", "1280x720", "1280x960"],
      currentStream: null,
      audios: [],
      videos: [],
      selectedVideo: null,
      selectedAudio: null,
      audioEnabled: true,
      videoEnabled: false,
            //@ts-ignore
      audioContext: new AContext(),
      audioLevel: 0,
      audioLevelSub: null,
      settingsOn: false,
      status: "waiting",
      enableJoin: false,
      enableCancel: false
    };
    const modalId = this.modalService.show({
      template: this.joinTemplate,
      context: {
        dataModel: model,
        callbacks: {
          toggleAudio: () => this.opentokService.togglePreviewAudio(model),
          toggleVideo: () => this.opentokService.togglePreviewVideo(model),
          changeAudioSource: (device: OT.Device) => this.opentokService.changePreviewSource("audio", model, this.videoElement),
          changeVideoSource: (device: OT.Device) => this.opentokService.changePreviewSource("video", model, this.videoElement),
          close: () => {
            this.opentokService.destroyPreview(model, this.videoElement);
            this.modalService.hide(modalId);
          },
          join: () => {
            this.opentokService.destroyPreview(model, this.videoElement);
            if (model.status === 'no-devices-found') {
              this.showNoDeviceModal(modalId);
            } else {
              this.modalService.hide(modalId);
              this.onJoinRoom(this.callResponse);
              this.router.navigate(['/room'])
            }
          }
        }
      }
    });

    setTimeout(() => {
      this.opentokService.startPreview(model, this.videoElement);
    }, 1000);
  }

  showNoDeviceModal(joinModalId: number, users?: any) {
    const modalId = this.modalService.show({
      template: this.noDeviceTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () => {
            this.modalService.hide(modalId);
          },
          join: () => {
            this.modalService.hide(modalId);
            this.modalService.hide(joinModalId);
            this.onJoinRoom(this.callResponse);
            this.router.navigate(['/room']);
          }
        }
      }
    });
  }

  showArchivePermissionModal() {
    const modalId = this.modalService.show({
      template: this.archivePermissionModal,
      context: {
        dataModel: null,
        callbacks: {
          deny: () => this.modalService.hide(modalId),
          allow: () => {
            this.onJoinRoom(this.callResponse);
            this.router.navigate(['/room']);
            this.modalService.hide(modalId);
          }
        }
      }
    });
  }

}
