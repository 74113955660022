import { Directive, OnInit, AfterViewInit, OnChanges, OnDestroy, ElementRef, Renderer2, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
    selector: '[animatedGroupBtn]',
    exportAs: 'animatedGroupBtn'
})
export class AnimatedGroupBtnDirective implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @Input('animatedGroupBtn') id: string;
    @Input() active: boolean = false;
    @Output() activeChange = new EventEmitter<boolean>();
    @Output() onActivated = new EventEmitter<void>();
    @Output() onDeactivated = new EventEmitter<void>();

    @Input() activeGroup: string;

    private subscription: Subscription = null;

    constructor(
        private renderer: Renderer2,
        private el: ElementRef
    ) {}

    ngOnInit() {
        if (!this.id) { console.log("Please give id to AnimatedGroupBtn") }
    }

    ngAfterViewInit() {
        this.subscription = fromEvent(this.el.nativeElement, "click")
        .subscribe(() => {
            this.changeActive(!this.active)
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.active) {
            this.changeActive(changes.active.currentValue);
        }
    }

    ngOnDestroy() {
        if (this.subscription) { this.subscription.unsubscribe() }
    }

    deactivate() {
        if (this.active) {
            this.changeActive(false);
        }
    }

    private changeActive(act: boolean) {
        this.active = act;
        if (act) {
            this.onActivated.emit();
            this.renderer.addClass(this.el.nativeElement, "active");
        } else {
            this.onDeactivated.emit();
            this.renderer.removeClass(this.el.nativeElement, "active");
        }
        this.activeChange.emit(act);
    }
}