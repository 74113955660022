import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@services/core/auth.service';
import { DbService } from '@services/core/db.service';
import { RoomSessionService } from '@services/core/room-session.service';
import { first } from 'rxjs/operators';
import { environment } from 'environments/environment'

declare var hbspt: any;
declare var $: any;

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})

export class FeedbackComponent implements OnInit {
  @ViewChild('feedbackContainer', { static: true }) feedbackContainer: ElementRef;

  submittedForm: boolean = false;
  feedbackiFrame: any = null;
  feedbackId: string = null;

  constructor(
    private dbService: DbService,
    private authService: AuthService,
    private roomSessionService: RoomSessionService
  ) { }

  formReadyFn = async () => {
    this.feedbackId = this.dbService.createPushId();
    const currentUser = this.authService.currentUser;
    const currentRoom = await this.roomSessionService.currentRoom.pipe(first()).toPromise();
    const feedbackForm = environment["feedbackForm"];

    this.feedbackiFrame = $(this.feedbackContainer.nativeElement).find('iframe').contents();
    const feedbackInput = this.feedbackiFrame.find("input[name='feedback_id']");
    feedbackInput.val(this.feedbackId).change();

    const emailInput = this.feedbackiFrame.find("#email-" + feedbackForm.formId);
    emailInput.val(currentUser.email).change();

    const companyInput = this.feedbackiFrame.find("#company-" + feedbackForm.formId);
    companyInput.val(currentUser.auth.account_name).change();

    if (currentRoom) {
      const roomInput = this.feedbackiFrame.find("#vsight_room_name-" + feedbackForm.formId);
      roomInput.val(currentRoom.room_data.name).change();
    }

    const now = new Date();
    const formattedDate = now.getFullYear() + '-' + ("0"+(now.getMonth()+1)).slice(-2) + '-' + ("0" + now.getDate()).slice(-2);

    const dateInput = this.feedbackiFrame.find("#incident_date-" + feedbackForm.formId);
    dateInput.val(formattedDate).change();

    const hourInput = this.feedbackiFrame.find("#hout-" + feedbackForm.formId);
    hourInput.val(now.getHours()).change();

    const minuteInput = this.feedbackiFrame.find("#minute-" + feedbackForm.formId);
    minuteInput.val(now.getMinutes()).change();
  }

  formSubmitFn = async (form) => {
    if (this.feedbackId) {
      const currentUser = this.authService.currentUser;
      const currentRoom = await this.roomSessionService.currentRoom.pipe(first()).toPromise();

      const feedbackData: any = {
        account_id: currentUser.account_id,
        company_name: currentUser.company_name,
        user: {
          name: currentUser.name,
          role: currentUser.role,
          username: currentUser.user_principal_name ? currentUser.user_principal_name : currentUser.auth.username,
          allow_archiving: currentUser.allow_archiving,
          allow_calling: currentUser.allow_calling,
          allow_join_link: currentUser.allow_join_link,
          call_archive_perm_needed: currentUser.call_archive_perm_needed
        }
      };
      if (currentUser.ad_user) {
        feedbackData.user.ad_user = true;
      }
      if (currentUser.email) {
        feedbackData.user.email = currentUser.email;
      }
      if (currentRoom) {
        feedbackData.session = {
          room_name: currentRoom.room_data.name,
          vs: currentRoom.room_data.session.vs,
          ot: currentRoom.room_data.session.ot,
          peer_to_peer: currentRoom.room_data.session.peer_to_peer,
          training_room: currentRoom.room_data.session.training_room,
          auto_archive: currentRoom.room_data.session.auto_archive,
          full_hd: currentRoom.room_data.full_hd ? true : false,
          create_time: currentRoom.room_data.session.create_time,
          archive_perm_needed: currentRoom.room_data.archive_perm_needed
        };
      }
      this.authService.sendFeedbackData(this.feedbackId, feedbackData);
    }
  }

  formSubmittedFn = () => {
    this.submittedForm = true;
  }

  ngOnInit(): void {
    const feedbackForm = environment["feedbackForm"];
    if (feedbackForm){
      hbspt.forms.create({
        region: feedbackForm.region,
        portalId: feedbackForm.portalId,
        formId: feedbackForm.formId,
        target: "#feedback-modal",
        onFormReady: this.formReadyFn,
        onFormSubmit: this.formSubmitFn,
        onFormSubmitted: this.formSubmittedFn
      });
    }
  }

  ngOnDestroy() {}
}
