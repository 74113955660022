import { Pipe, PipeTransform } from "@angular/core";

import { UtilityService } from "@services/support/utility.service";

@Pipe({name: 'utcCalc'})
export class UtcCalcPipe implements PipeTransform {
    constructor() {}

    transform(timestamp: number, timezone: string): string {
        const d = new Date(timestamp+UtilityService.timezoneOffset+UtilityService.timezones[timezone]);
        return ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
    }
}