<div class="modal-content">
<div class="modal-header">
    <h5 class="modal-title" [innerHTML]="title"></h5>
</div>
<div class="modal-body">
    <div [innerHTML]="description"></div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn px-4 py-1 rounded-0" style="font-size: 0.875rem;"
      (click)="confirm(false)"
      [ngClass]="cancelClass"
    >
      {{ cancelTitle }}
    </button>
    <button
      type="button"
      class="btn px-4 py-1 rounded-0" style="font-size: 0.875rem;"
      [disabled]="showLoading"
      (click)="confirm(true)"
      [ngClass]="approveClass"
    >
    {{ approveTitle }}
    </button>
  </div>
</div>
  