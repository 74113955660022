import { Component, OnInit } from '@angular/core';
import { DetailPageService } from '@services/core/detail-page.service';
import { RoomSessionService } from '@services/core/room-session.service';
import { Session } from '@models/Session';
import { Subscription } from 'rxjs';
import { TicketService } from '@services/other/ticket.service';
import { AuthService } from '@services/core/auth.service';
import { SessionExportService } from '@services/core/session-export.service';
import { SessionDurationPipe } from 'app/pipes/session-duration.pipe';
import { TicketsModalComponent } from '../../tickets-modal/tickets-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Ticket } from '@models/Ticket';
import { User } from '@models/User';
import { TitleCasePipe } from '@angular/common';
import { SessionDataMore } from '@models/SessionData';
import { LoaderService } from '@services/support/loader.service';
import { FlashMessageService } from '@services/support/flash-message.service';

@Component({
  selector: 'app-session-detail',
  templateUrl: './session-detail.component.html',
  styleUrls: ['./session-detail.component.scss']
})
export class SessionDetailComponent implements OnInit {

  sessionApi: Session;
  sessionFirebase: SessionDataMore;

  isInitialized = false;
  webdavEnabled = false;
  sessionExportEnabled = false;
  ticketAvailable = false;

  attachedTicket: Ticket = null;
  licenseType = "expert_user_based";
  users: User[] = [];
  userEmailsById: Record<string, string> = {};
  duration: string;

  showCheckboxes = false;
  downloadSelectedFiles = false;
  showDownloadButton: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private ticketService: TicketService,
    private sessionService: RoomSessionService,
    private detailPageService: DetailPageService,
    private authService: AuthService,
    private sessionExportService: SessionExportService,
    private titleCasePipe: TitleCasePipe,
    private durationPipe: SessionDurationPipe,
    private modal: BsModalService,
    private loaderService: LoaderService,
    private flashMessageService: FlashMessageService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit(): void {
    this.subscriptions.push(this.authService.license.subscribe(license => {
      this.licenseType = license.type;
    }))
    this.subscriptions.push(this.authService.addOns.subscribe(addOns => {
      this.ticketAvailable = addOns.ticket;
      this.sessionExportEnabled = addOns.sessionexport;
      this.webdavEnabled = addOns.webdav;
    })) 
    this.subscriptions.push( this.authService.getAllUsers().subscribe(usersResponse => {
      this.users = usersResponse as User[];
      this.users.forEach((item) => this.userEmailsById[item.id] = item.email);
      this.sessionApi.users.forEach(user=>{
        let detail=this.users.find(username => username.id == user.id);
        user['username' ] = detail?.auth?.username;
        user['role'] = detail?.role ? this.titleCasePipe.transform(detail?.role) : '';
      })
    }))

    this.subscriptions.push( this.sessionService.getSession(this.sessionApi.id).subscribe(res=>{
      this.sessionFirebase = res;
      this.duration = this.durationPipe.transform(res);
      if (res.attached_ticket) {
        this.ticketService.getTicketDetail(res.attached_ticket).then(ticketDetail => {
          this.attachedTicket = ticketDetail.ticket;
          this.isInitialized = true;
        }, () => {
          this.isInitialized = true;
        })
        return;
      }
      this.isInitialized = true;
    }, () => {
      this.isInitialized = true;
    }))
  };

  onSessionExportModal(roomId: string, sessionId: string): void {
    this.sessionExportService.sessionExportRequest.next([
      roomId,
      sessionId,
      true,
    ]);
  };

  onBack(): void {
    this.detailPageService.removeComponent2();
  };

  openTicketListModal(): void {
    let currentRoom = {
      id:this.sessionApi.room.id,
      room_data:{
        session:{
          vs:this.sessionApi.id
        }
      }
    }
      const initialState= {
        currentRoom: currentRoom,
        canCreate:false
      };
      const modalRef = this.modal.show(TicketsModalComponent,  {initialState, backdrop:'static', class: 'white modal-lg', animated:false});
      modalRef.content.onClose.subscribe(() => {
        this.isInitialized = true;
      });
  };

  toggleSelection() {
    this.showCheckboxes = !this.showCheckboxes;
    this.showDownloadButton = !this.showDownloadButton;
  }

  onDownloadStarted() {
    if (this.downloadSelectedFiles) {
      this.downloadSelectedFiles = false;
    } else {
      setTimeout(() => {
        this.downloadSelectedFiles = true;
        setTimeout(() => {
          this.downloadSelectedFiles = false;
        }, 0);
      }, 0);
    }
  }
}
