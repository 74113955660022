import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

import { AuthService } from './auth.service';
import { RoomService } from '@services/other/room.service';
import { CallService } from '../core/call.service';
import { DbService } from './db.service';

import { JoinRoomResponse } from '@models/JoinRoomResponse';
import { Room } from '@models/Room';
import { SessionData, SessionDataMore } from '@models/SessionData';
import { Frame } from '@models/Frame';
import { User } from '@models/User';
import { RoomData } from '@models/RoomData';

import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';
import { SessionResponse } from '@models/Session';
import { UserFile } from '@models/UserFile';
import { Archive } from '@models/Archive';

@Injectable({
  providedIn: 'root'
})
export class RoomSessionService {

  sessionCreated: boolean = false;

  sessionActive: Observable<boolean>;
  private sessionActiveSource: BehaviorSubject<boolean> = new BehaviorSubject(false);

  currentRoomId: string = null;
  currentSessionId: string = null;
  currentSessionPath: string = null;

  private listeners: {
    "sessionCreated": any[],
    "sessionStarted": any[],
    "sessionEnded": any[],
    "sessionDestroyed": any[]
  };

  currentRoom: Observable<Room>;
  private currentRoomSource = new BehaviorSubject<Room>(null);
  private roomSub: Subscription = null;

  sessionData: Observable<SessionData>;
  private sessionDataSource = new BehaviorSubject<SessionData>(null);
  private sessionSub: Subscription = null;

  roomUserStatus: Observable<{ training_room: boolean, session_active: boolean, user_status: string }>;
  roomUserStatusSource = new BehaviorSubject<{ training_room: boolean, session_active: boolean, user_status: string }>(null);
  private roomUserStatusSub: Subscription = null;

  constructor(
    private http: HttpClient,
    private callService: CallService,
    private roomService: RoomService,
    private authService: AuthService,
    private dbService: DbService
  ) {
    this.sessionActive = this.sessionActiveSource.asObservable();
    this.currentRoom = this.currentRoomSource.asObservable();
    this.sessionData = this.sessionDataSource.asObservable().pipe(filter( data => data !== null ));
    this.roomUserStatus = this.roomUserStatusSource.pipe(
      filter(d => d !== null),
      distinctUntilChanged((prev, cur) => prev.session_active === cur.session_active && prev.user_status === cur.user_status)
    );
    this.listeners = {
      "sessionCreated": [],
      "sessionStarted": [],
      "sessionEnded": [],
      "sessionDestroyed": []
    };

    this.authService.on("externalLogin", this.onExternalLogin);

    this.callService.on("joinRoom", this.onJoinRoom);
    this.callService.on("beforeEndCall", this.onBeforeEndCall);
    this.callService.on("endCall", this.onEndCall);
  }

  on( event: "sessionCreated" | "sessionStarted" | "sessionEnded" | "sessionDestroyed", callback) {
    this.listeners[event].push(callback);
  }

  off(event: "sessionCreated" | "sessionStarted" | "sessionEnded" | "sessionDestroyed", callback?) {
    if (callback == undefined) {
      this.listeners[event] = [];
    } else {
      const index = this.listeners[event].indexOf(callback);
      this.listeners[event].splice(index, 1);
    }
  }

  // On external login fills currentRoomId, currentSessionId, currentSessionPath variables
  // currentRoom and roomUserStatus observables for usage on collaborations
  onExternalLogin = (user: User, params: any) => {
    if (this.roomUserStatusSub) { this.roomUserStatusSub.unsubscribe() }
    this.currentRoomId = params.room_id;
    this.currentSessionId = params.session_id;
    this.currentSessionPath = 'accounts/'+params.account_id+'/sessions/'+params.room_id+'/'+params.session_id;

    this.roomUserStatusSub = this.dbService.listen<RoomData>(`accounts/${params.account_id}/rooms/${params.room_id}/room_data`)
    .subscribe(roomData => {
      this.currentRoomSource.next(this.roomService.getRoomFromRoomData(roomData, params.room_id, false));
      if (roomData) {
        const ru = roomData.users[user.id];
        if (ru) {
          this.roomUserStatusSource.next({
            training_room: roomData.training_room ? true : false,
            session_active: roomData.session.active,
            user_status: ru.status ? ru.status : null
          });
        } else {
          this.roomUserStatusSource.next(null);
        }
      } else {
        this.roomUserStatusSource.next(null);
      }
    });
  }
 
  /**
   * LISTENERS FROM CALL SERVICE
   */
  private onJoinRoom = (joinResponse: JoinRoomResponse, currentRoomPath: string) => {
    const now = (new Date()).getTime();

    if (this.roomSub) { this.roomSub.unsubscribe(); }
    this.currentRoomId = joinResponse.room_id;
    this.currentSessionId = joinResponse.vs_session;

    this.currentSessionPath = 'accounts/'+this.authService.currentUser.account_id+'/sessions/'+joinResponse.room_id+'/'+this.currentSessionId;

    this.onSessionCreated(joinResponse, this.currentSessionPath);

    this.roomSub = this.roomService.getCurrentRoom(joinResponse.room_id).subscribe(room => {
      this.currentRoomSource.next(room);
      const roomUserInfo = room.room_data.users.find(user => user.user_id === this.authService.currentUser.id);
      // User not in room anymore
      if (!roomUserInfo || !roomUserInfo.in_room && (new Date).getTime() - now > 10000) {
        const status = roomUserInfo && roomUserInfo.in_room_status ? roomUserInfo.in_room_status : "frontend/no-status"
        this.callService.simulateEndcallForFrontend(joinResponse.room_id, joinResponse.vs_session, status);
      } else {
        if (room.room_data.session.active && !this.sessionActiveSource.value) {
          this.onSessionStarted(room, roomUserInfo);
        }
        if (!room.room_data.session.active && this.sessionActiveSource.value) {
          this.onSessionEnded(!this.sessionActiveSource.value, false);
        }
      }

      if (roomUserInfo) {
        this.roomUserStatusSource.next({
          training_room: room.room_data.training_room ? true : false,
          session_active: room.room_data.session.active,
          user_status: roomUserInfo.status ? roomUserInfo.status : null
        });
      } else {
        this.roomUserStatusSource.next(null);
      }
    }, err => {
      console.log(err)
      this.callService.simulateEndcallForFrontend(joinResponse.room_id, joinResponse.vs_session, "frontend/connection-problem")
    });
  }

  private onBeforeEndCall = () => {
    if (this.roomSub) { this.roomSub.unsubscribe(); }
    this.currentRoomSource.next(null);
    this.roomUserStatusSource.next(null);

    if (this.sessionActiveSource.value) {
      this.onSessionEnded(!this.sessionActiveSource.value, true)
    }
  }

  private onEndCall = (roomId: string, sessionId: string, kickRoomStatus: string, reconnectionRequired: boolean, sessionEnded: boolean) => {
    this.currentRoomId = null;
    this.currentSessionId = null;

    this.onSessionDestroyed()
  }

  generateJoinRoomLink(): Promise<any> {
    const url = this.dbService.getEndPoint("joinwithlink");
    let base = this.dbService.getEndPoint("base");
    return this.http.post<any>(url, { action: 'generate', token: this.authService.currentUser.token, room: this.currentRoomId, session: this.currentSessionId, base_url: base }).toPromise()
  }

  sendJoinRoomLinkSMS(countryCode: string, phoneCode: string, linkId: string, smsContent: string) {
    const url = this.dbService.getEndPoint("joinwithlink");
    const phoneNumber = countryCode+phoneCode;
    return this.http.post<any>(url, {
      action: 'sms',
      token: this.authService.currentUser.token,
      country_code: countryCode,
      phone_code: phoneCode,
      phone_number: phoneNumber,
      link_id: linkId,
      sms_content: smsContent
    }).toPromise()
  }

  generateSMSLink(): Promise<string> {
    //TODO
    const url = this.dbService.getEndPoint("joinwithlink");
    return this.http.post<{link_id: string}>(url, { action: 'generate', token: this.authService.currentUser.token, room: this.currentRoomId, session: this.currentSessionId }).toPromise()
    .then(result => result.link_id)
  }

  getSessionExportDownloadLink(id: string) {
    console.log("getSessionExportDownloadLink");
    const url = this.dbService.getEndPoint("downloadexport2");
    return this.http.post<{url: string}>(url, { id: id }).toPromise()
    .then(result => result.url)
  }

  /**
   * ROOM SESSION LIFECYCLE EVENTS
   */
  private onSessionCreated(joinResponse: JoinRoomResponse, currentSessionPath: string) {
    this.sessionCreated = true;
    this.listeners["sessionCreated"].forEach(callback => {
      callback(joinResponse, this.currentSessionPath);
    });

    this.sessionSub = this.dbService.listen<SessionData>(currentSessionPath+'/session_data')
    .subscribe(sessionData => {
      this.sessionDataSource.next(sessionData);
    });
  }

  private onSessionStarted(room: Room, roomUserInfo: any) {
    this.sessionActiveSource.next(true);
    this.listeners.sessionStarted.forEach(callback => callback(room, roomUserInfo));
  }

  private onSessionEnded(endedBeforeStart: boolean, willDestroy: boolean) {
    this.sessionActiveSource.next(false);
    this.listeners.sessionEnded.forEach(callback => callback(endedBeforeStart, willDestroy));
  }

  private onSessionDestroyed() {
    this.sessionCreated = false;
    this.listeners.sessionDestroyed.forEach(callback => callback());

    if (this.sessionSub) { this.sessionSub.unsubscribe() }
    this.sessionDataSource.next(null);
  }

  isSessionActive(): boolean {
    return this.sessionActiveSource.value;
  }

  async getSessionStartTime(start_time: number) {
    // TODO This is wrong time offset, fix this in the future
    const offset: number = (await this.dbService.get<number>('.info/serverTimeOffset', 'value'));
    return Math.floor((Date.now() - (start_time - offset)) / 1000);
  }

  getArSourceColor(): Observable<string> {
    return this.currentRoom.pipe(
      filter(room => !!room),
      map(room => {
        const userData = room.room_data.users.filter(user => !!user.ar_color).find(u => u.user_id === this.authService.currentUser.id);
        return userData ? userData.ar_color : null;
      }),
      distinctUntilChanged()
    )
  }

  startTrainingSession() {
    const url = this.dbService.getEndPoint("trainingactions");
    const body = {
      master: true,
      action: "start-session",
      room_id: this.currentRoomId,
      token: this.authService.currentUser.token
    };
    return this.http.post<any>(url, body).toPromise();
  }

  enablePublish(user_id: string) {
    const url = this.dbService.getEndPoint("trainingactions");
    const body = {
      master: true,
      action: "enable-publish",
      room_id: this.currentRoomId,
      data: { uid: user_id },
      token: this.authService.currentUser.token
    };
    return this.http.post<any>(url, body).toPromise();
  }

  disablePublish(user_id: string) {
    const url = this.dbService.getEndPoint("trainingactions");
    const body = {
      master: true,
      action: "disable-publish",
      room_id: this.currentRoomId,
      data: { uid: user_id },
      token: this.authService.currentUser.token
    };
    return this.http.post<any>(url, body).toPromise();
  }

  lowerSubscriberHand(user_id: string) {
    const url = this.dbService.getEndPoint("trainingactions");
    const body = {
      master: true,
      action: "lower-sub-hand",
      room_id: this.currentRoomId,
      data: { uid: user_id },
      token: this.authService.currentUser.token
    };
    return this.http.post<any>(url, body).toPromise();
  }

  lowerAllHands() {
    const url = this.dbService.getEndPoint("trainingactions");
    const body = {
      master: true,
      action: "lower-all-hands",
      room_id: this.currentRoomId,
      token: this.authService.currentUser.token
    };
    return this.http.post<any>(url, body).toPromise();
  }

  raiseHand() {
    const url = this.dbService.getEndPoint("trainingactions");
    const body = {
      master: false,
      action: "raise-hand",
      room_id: this.currentRoomId,
      token: this.authService.currentUser.token
    };
    return this.http.post<any>(url, body).toPromise();
  }

  lowerHand() {
    const url = this.dbService.getEndPoint("trainingactions");
    const body = {
      master: false,
      action: "lower-hand",
      room_id: this.currentRoomId,
      token: this.authService.currentUser.token
    };
    return this.http.post<any>(url, body).toPromise();
  }

  acceptPublish() {
    const url = this.dbService.getEndPoint("trainingactions");
    const body = {
      master: false,
      action: "accept-publish",
      room_id: this.currentRoomId,
      token: this.authService.currentUser.token
    };
    return this.http.post<any>(url, body).toPromise();
  }

  denyPublish() {
    const url = this.dbService.getEndPoint("trainingactions");
    const body = {
      master: false,
      action: "deny-publish",
      room_id: this.currentRoomId,
      token: this.authService.currentUser.token
    };
    return this.http.post<any>(url, body).toPromise();
  }

  enableFrameFocus(frame: Frame) {
    if (frame.isCollaboration) {
      return this.dbService.transaction(this.currentSessionPath+'/session_data', 'enableFocus', { type: "collaboration", id: frame.collaborationData.id });
    } else {
      return this.dbService.transaction(this.currentSessionPath+'/session_data', 'enableFocus', { type: "video", id: frame.uid });
    }
  }

  disableFrameFocus() {
    return this.dbService.transaction(this.currentSessionPath+'/session_data', 'disableFocus');
  }

  getSessionData(roomId: string, sessionId: string) {
    return this.dbService.listen<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${roomId}/${sessionId}/session_data`);
  }
  
  getSession(sessionId: string): Observable<any> {
  return this.dbService.listen<any>(`accounts/${this.authService.currentUser.account_id}/sessions_meta/${sessionId}`)
  }

  getRoomFiles() {
    return this.dbService.listen<any>(`accounts/${this.authService.currentUser.account_id}/rooms_files/${this.currentRoomId}`);
  }

  getSessionExport(room_id: string, session_id: string, recipients: string[]) {
    const url = this.dbService.getEndPoint("exportsession");
    const body = {
      room: room_id,
      session: session_id,
      recipients: recipients,
      app_name: environment.design.appName,
      token: this.authService.currentUser.token
    };
    return this.http.post<any>(url, body).toPromise();
  }

  setExportName(roomId: string, sessionId: string, text: string) {
    this.dbService.update(`accounts/${this.authService.currentUser.account_id}/sessions/${roomId}/${sessionId}/session_data/export_name`, {name: text, author: this.authService.currentUser.name});
  }

  lockExportName(roomId: string, sessionId: string) {
    this.dbService.set(`accounts/${this.authService.currentUser.account_id}/sessions/${roomId}/${sessionId}/session_data/export_name/locked`, true);
  }

  getSessionFileUrl(key: string, extension: string, storageUrl: string) {
    if (this.dbService.getSourceType() === "socket") {
      return `${this.dbService.getEndPoint("base")}/sessionfile?token=${this.authService.currentUser.token}&room=${this.currentRoomId}&session=${this.currentSessionId}&file=${key}.${extension}`;
    } else {
      return storageUrl;
    }
  }

  getSessionArchivesWithLink(accountId: string, accountName: string, roomId: string, sessionId: string, id: string) {
    const url = this.dbService.getEndPoint("changearchive");
    const body = {
      action: "checkandget",
      account: accountId,
      name: accountName,
      room: roomId,
      session: sessionId,
      id: id
    };
    return this.http.post<any>(url, body).toPromise();
  }
  checkSessionArchives(roomId: string, sessionId: string) {
    const url = this.dbService.getEndPoint("changearchive");
    return this.http
      .post<SessionResponse>(url, { token: this.authService.currentUser.token, action: "check", room: roomId, session: sessionId })
      .toPromise();
  }

  getArchiveDownloadUrl(accountId: string, accountName: string, roomId: string, sessionId: string, id: string, archiveId: string) {
    const url = this.dbService.getEndPoint("changearchive");
    const body = {
      action: "get2",
      account: accountId,
      name: accountName,
      room: roomId,
      session: sessionId,
      id: id,
      archive: archiveId
    };
    return this.http.post<any>(url, body).toPromise();
  }

  getUserSessions(): Promise<SessionResponse> {
    const url = this.dbService.getEndPoint("getUserSessions");
    return this.http
      .post<SessionResponse>(url, { token: this.authService.currentUser.token })
      .toPromise();
  }


  getSessionObjects(session: SessionDataMore, session_id:string): Observable<UserFile[]> {
    return new Observable<UserFile[]>(subscriber => {
      const logs: any[] = [];
      const sub1 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${session.room_id}/${session_id}/objects`, "child_added")
        .subscribe(snp => {
          const ticket = snp.data;
          ticket.id = snp.key;
          logs.push(ticket);
          subscriber.next(logs);
        });

      const sub2 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${session.room_id}/${session.id}/objects`, "child_changed")
        .subscribe(snp => {
          const i = logs.findIndex(t => t.id === snp.key);
          const ticket = snp.data;
          ticket.id = snp.key;
          if (i > -1) {
            logs[i] = ticket;
          } else {
            logs.push(ticket);
          }
          subscriber.next(logs);
        });

      const sub3 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${session.room_id}/${session.id}/objects`, "child_removed")
        .subscribe(snp => {
          const i = logs.findIndex(t => t.id === snp.key);
          if (i > -1) {
            logs.splice(i, 1);
            subscriber.next(logs);
          }
        });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
        sub3.unsubscribe();
      }
    });
  }

  getSessionChat(session: SessionDataMore,session_id: string): Observable<any[]>{
    return new Observable<any[]>(subscriber => {
      const logs: any[] = [];
      const sub1 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${session.room_id}/${session_id}/logs`, "child_added")
        .subscribe(snp => {
          const ticket = snp.data;
          ticket.id = snp.key;
          logs.push(ticket);
          subscriber.next(logs);
        });

      const sub2 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${session.room_id}/${session_id}/logs`, "child_changed")
        .subscribe(snp => {
          const i = logs.findIndex(t => t.id === snp.key);
          const ticket = snp.data;
          ticket.id = snp.key;
          if (i > -1) {
            logs[i] = ticket;
          } else {
            logs.push(ticket);
          }
          subscriber.next(logs);
        });

      const sub3 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${session.room_id}/${session_id}/logs`, "child_removed")
        .subscribe(snp => {
          const i = logs.findIndex(t => t.id === snp.key);
          if (i > -1) {
            logs.splice(i, 1);
            subscriber.next(logs);
          }
        });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
        sub3.unsubscribe();
      }
    });
  }

  getSessionFiles(session: SessionDataMore,session_id: string): Observable<any[]>{
    return new Observable<any[]>(subscriber => {
      const files: any[] = [];
      const sub1 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${session.room_id}/${session_id}/files`, "child_added")
        .subscribe(snp => {
          const ticket = snp.data;
          ticket.id = snp.key;
          files.push(ticket);
          subscriber.next(files);
        });

      const sub2 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${session.room_id}/${session_id}/files`, "child_changed")
        .subscribe(snp => {
          const i = files.findIndex(t => t.id === snp.key);
          const ticket = snp.data;
          ticket.id = snp.key;
          if (i > -1) {
            files[i] = ticket;
          } else {
            files.push(ticket);
          }
          subscriber.next(files);
        });

      const sub3 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${session.room_id}/${session_id}/files`, "child_removed")
        .subscribe(snp => {
          const i = files.findIndex(t => t.id === snp.key);
          if (i > -1) {
            files.splice(i, 1);
            subscriber.next(files);
          }
        });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
        sub3.unsubscribe();
      }
    });
  }

  getArchive(room_id,session_id): Observable<any[]> {
    return new Observable<any[]>(subscriber => {
      const tickets: any[] = [];
      const sub1 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${room_id}/${session_id}/archives`, "child_added")
        .subscribe(snp => {
          const ticket = snp.data;
          ticket.id = snp.key;
          tickets.push(ticket);
          subscriber.next(tickets);
        });

      const sub2 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${room_id}/${session_id}/archives`, "child_changed")
        .subscribe(snp => {
          const i = tickets.findIndex(t => t.id === snp.key);
          const ticket = snp.data;
          ticket.id = snp.key;
          if (i > -1) {
            tickets[i] = ticket;
          } else {
            tickets.push(ticket);
          }
          subscriber.next(tickets);
        });

      const sub3 = this.dbService.listenSnap<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${room_id}/${session_id}/archives`, "child_removed")
        .subscribe(snp => {
          const i = tickets.findIndex(t => t.id === snp.key);
          if (i > -1) {
            tickets.splice(i, 1);
            subscriber.next(tickets);
          }
        });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
        sub3.unsubscribe();
      }
    });
  }

  deleteArchive(accountId: string, accountName: string, roomId: string, sessionId: string, id: string, archiveId: string){
    const url = this.dbService.getEndPoint("changearchive");
    const body = {
      action: "delete",
      account: accountId,
      name: accountName,
      room: roomId,
      session: sessionId,
      id: id,
      archive: archiveId
    };
    return this.http.post<any>(url, body).toPromise();
  }

  downloadArchive(roomId: string, sessionId: string, archive: Archive) {
    return this.http.post<any>(environment.endPoints.changearchive, { token: this.authService.currentUser.token, action: "get", room: roomId, session: sessionId, archive: archive.id }).toPromise()
    .then(response => response.url);

  }

}
