import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

// rxjs imports
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';

// Data model imports
import { User } from '@models/User';
import { FlashMessageService } from '@services/support/flash-message.service';
import { DbService } from './db.service';
import { AuthUser } from '@models/AuthUser';
import { Contact } from '@models/Contact';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // Current user -> filled when user data loaded, directly accessable
  currentUser: User = null;

  // Current user observable -> can subscribable for real time updates
  user: Observable<User>;
  private currentUserSource: BehaviorSubject<User> = new BehaviorSubject(null);

  loggedInOnce: boolean = false;

  features: Observable<{[key: string]: boolean}>;
  private featuresSource: BehaviorSubject<{[key: string]: boolean}> = new BehaviorSubject(null);
  private featuresSub: Subscription = null;

  addOns: Observable<{[key: string]: boolean}>;
  private addOnsSource: BehaviorSubject<{[key: string]: boolean}> = new BehaviorSubject(null);
  private addOnsSub: Subscription = null;

  license: Observable<any>;
  private licenseSource: BehaviorSubject<any> = new BehaviorSubject(null);
  private licenseSub: Subscription = null;

  timezone: Observable<string>;
  private timezoneSource: BehaviorSubject<string> = new BehaviorSubject('UTC');
  private timezoneSub: Subscription = null;

  private loginIdSub: Subscription = null;

  private listeners: {
    "externalLogin": any[],
    "multipleLogin": any[]
  };

  constructor(
    private dbService: DbService,
    private flashMessageService: FlashMessageService,
    private http: HttpClient
  ) {
    this.user = this.currentUserSource.asObservable();
    this.features = this.featuresSource.asObservable().pipe(filter(d => d !== null));
    this.addOns = this.addOnsSource.asObservable().pipe(filter(d => d !== null));
    this.license = this.licenseSource.asObservable().pipe(filter(d => d !== null));
    this.timezone = this.timezoneSource.asObservable();

    this.listeners = {
      "externalLogin": [],
      "multipleLogin": []
    };

    this.dbService.authState
    .subscribe(user => {
      if (user) {
        if (this.featuresSub) { this.featuresSub.unsubscribe(); }
        this.featuresSub = this.dbService.listen<{[key: string]: boolean}>(`accounts/${user.account_id}/account_data/features`, 'value')
        .subscribe(f => { this.featuresSource.next(f) });

        if (this.addOnsSub) { this.addOnsSub.unsubscribe(); }
        this.addOnsSub = this.dbService.listen<{[key: string]: boolean}>(`accounts/${user.account_id}/account_data/add_ons`, 'value')
        .subscribe(f => { this.addOnsSource.next(f) });

        if (this.licenseSub) { this.licenseSub.unsubscribe(); }
        this.licenseSub = this.dbService.listen<any>(`accounts/${user.account_id}/account_data/license`, 'value')
        .subscribe(l => { this.licenseSource.next(l) });

        if (this.timezoneSub) { this.timezoneSub.unsubscribe(); }
        this.timezoneSub = this.dbService.listen<string>(`accounts/${user.account_id}/account_data/timezone`, 'value')
        .subscribe(t => { this.timezoneSource.next(t) });

        if (this.dbService.getManageUserStatus()) {
          this.listenLoginId(user);
        }
      } else {
        if (this.featuresSub) { this.featuresSub.unsubscribe(); }
        this.featuresSource.next(null);
        if (this.addOnsSub) { this.addOnsSub.unsubscribe(); }
        this.addOnsSource.next(null);
        if (this.licenseSub) { this.licenseSub.unsubscribe(); }
        this.licenseSource.next(null);
        if (this.timezoneSub) { this.timezoneSub.unsubscribe(); }
        this.timezoneSource.next(null);

        if (this.loginIdSub) { this.loginIdSub.unsubscribe() }
      }
    });

    this.dbService.currentUser
    .subscribe(user => {
      this.currentUser = user;
      this.currentUserSource.next(user);
    });
  }

  isAddOnAvailable(addOn: string): boolean {
    return this.addOnsSource.value ? this.addOnsSource.value[addOn] : false;
  }

  isFeatureAvailable(feature: string): boolean {
    return this.featuresSource.value ? this.featuresSource.value[feature] : false;
  }

  on( event: "externalLogin" | "multipleLogin", callback) {
    this.listeners[event].push(callback);
  }

  off(event: "externalLogin" | "multipleLogin", callback?) {
    if (callback == undefined) {
      this.listeners[event] = [];
    } else {
      const index = this.listeners[event].indexOf(callback);
      this.listeners[event].splice(index, 1);
    }
  }

  getJoinLinkData(linkId: string): Promise<any> {
    const url = this.dbService.getEndPoint("joinwithlink");
    return this.http.post<any>(url, { action: 'info', link_id: linkId }).toPromise()
    .then(result => result.data);
  }

  joinWithLink(linkId: string, name: string): Promise<any> {
    const url = this.dbService.getEndPoint("joinwithlink");
    return this.http.post<any>(url, { action: 'credentials', link_id: linkId, name: name }).toPromise()
      .catch(error => {
        if (error.error) {
          if (error.error === 'session-not-found') {
            throw new Error('session-not-found');
          } else if (error.error === 'session-expired') {
            throw new Error('session-expired');
          } else if (error.error === 'concurrent-limit-reached') {
            throw new Error('concurrent-limit-reached');
          } else if (error.error === 'expert-concurrent-limit-reached') {
            throw new Error('expert-concurrent-limit-reached');
          } else if (error.error === 'invalid-parameters') {
            throw new Error('invalid-parameters');
          } else if (error.error === 'internal-error') {
            throw new Error('internal-error');
          }
        }
        throw new Error('internal-error');
      });
  }

  listenLoginId(user: AuthUser) {
    if (this.loginIdSub) { this.loginIdSub.unsubscribe(); }

    this.loginIdSub = this.dbService.listen<string>(`accounts/${user.account_id}/users/${user.uid}/logout_reason`)
    .subscribe(logoutReason => {
      if (!logoutReason) {
        return;
      }
      this.showLogoutReason(logoutReason);

      this.loginIdSub.unsubscribe();
      this.listeners.multipleLogin.forEach(callback => callback());
      this.dbService.logout()
      .then(() => new Promise<void>(resolve => setTimeout(() => resolve(), 1000)))
      .then(() => location.reload());
      localStorage.setItem('logoutReason', logoutReason);
    });
  }

  showLogoutReason(reason: string) {
    let message: string;
    switch (reason) {
      case "guest-deleted":
        message = "APP.LOGIN.GUEST_DELETED";
        break;
      case "user-deleted":
        message = "APP.LOGIN.USER_DELETED";
        break;
      case "user-disabled":
        message = "APP.LOGIN.USER_DISABLED";
        break;
      case "login-another-session":
        message = "APP.LOGIN.LOGGED_IN_ANOTHER_SESSION"
        break;
      default:
        message = "APP.LOGIN.LOGGED_OUT_UNKNOWN";
        break;
    }
    this.flashMessageService.showTranslated(message);
  }

  isAuthenticated(): Observable<boolean> {
    return this.dbService.isAuthenticated;
  }

/*
  manageUserStatus(userId: string, account_id: string) {

    this.userStatusRef = this.afdb.database.ref(`accounts/${account_id}/users/${userId}/status`);

    // Make user online in firebase database
    this.userStatusRef.update(this.online);
    // This method trigger when user close the window
    this.afdb.database.ref(`.info/connected`).on('value', this.onConnected);
  }

  onConnected = (connSnap) => {
    if (connSnap.val() === true) {
      this.afdb.database.ref(`.info/connected`).off('value', this.onConnected);
      this.onFirebaseDisconnect = this.userStatusRef.onDisconnect();
      this.onFirebaseDisconnect.update(this.offline);
    }
  }
*/

  externalLogin(token: string, params: any) {
    return this.dbService.login(token, false, true)
    .then(user => {
      this.listeners.externalLogin.forEach(callback => callback(user, params));
      return user;
    });
  }

  async loginWithLink(token: string) {
    return (this.dbService.login(token, true).then(user => {
      this.currentUser = user; // Assign it as soon as posible for socketsource
      return user;
    }));
  }

  getCustomTokenFromSsoLinkId(linkId: string) {
    const url = this.dbService.getEndPoint("getssolinkdata");
    return this.http.post<any>(url, { link_id: linkId }).toPromise().then(r => { console.log(r); return r; });
  }

  sendLoginCredentials(account_name: string, username: string, password: string, kick_other: boolean): Promise<any> {
    const url = this.dbService.getEndPoint("login");
    return this.http.post<any>(url, { account_name: account_name, username: username, password: password, kick_other: kick_other }).toPromise();
  }

  sendLoginCredentialsForRemoteIntegration(account_name: string, username: string, password: string, kick_other: boolean): Promise<any> {
    const url = this.dbService.getEndPoint("login");
    return this.http.post<any>(url, { account_name: account_name, username: username, integration_id: password, kick_other: kick_other }).toPromise();
  }

  verifyLoginCode(account_name: string, username: string, password: string, code: string) {
    const url = this.dbService.getEndPoint("login");
    return this.http.post<any>(url, { account_name: account_name, username: username, password: password, verification: code }).toPromise();
  }

  login(token: string) {
    return this.dbService.login(token, true)
    .then(user => {
      this.loggedInOnce = true;
      return user;
    });
  }

  logout() {
    return this.dbService.logout();
    /*
    if (this.isExternalLogin) {
      return this.afAuth.auth.signOut();
    } else {
      return this.userStatusRef.update(this.offline)
      .then(() => {
        this.onFirebaseDisconnect.cancel();
        return this.afAuth.auth.signOut();
      });
    }
    */
  }

  changePassword(password: string, newPassword: string) {
    const url = this.dbService.getEndPoint("changepsd");
    return this.http.post<any>(url, {
      token: this.currentUser.token,
      uid: this.currentUser.id,
      username: this.currentUser.auth.username,
      password: newPassword,
      old_password: password
    }).toPromise()
    .then(result => { return; });
  }

  changeEmail(newEmail: string) {
    const url = this.dbService.getEndPoint("changeemail");
    return this.http.post<any>(url, {
      token: this.currentUser.token,
      email: newEmail
    }).toPromise()
    .then(result => { 
      return; 
    });
  }

  switchToAdmin() {
    const url = this.dbService.getEndPoint('switchToAdmin');
    const adminBaseUrl = this.dbService.getEndPoint('adminBase');

    return this.http.post<any>(url, { token: this.currentUser.token }).toPromise()
    .then(result => {
      const encodedToken = encodeURIComponent(btoa(result.token));
      const navUrl = `${adminBaseUrl}/login-from-remote?token=${encodedToken}`;
      window.location.href = navUrl;
    });
  }

  inviteFieldUser(fieldCode: string, currentRoomId: string, sessionId: string) {
    const url = this.dbService.getEndPoint('invitefielduser');
    const base = this.dbService.getEndPoint("base");

    return this.http.post<any>(url, {
      token: this.currentUser.token,
      field_code: fieldCode,
      room: currentRoomId,
      session: sessionId,
      base_url: base
    }).toPromise();
  }

  getAllUsers(icludeDeletedUsers: boolean = false): Observable<User[]> {
    return new Observable<User[]>(subscriber => {
      const users: User[] = [];
      const sub1 = this.dbService.listenSnap<User>(`accounts/${this.currentUser.account_id}/users`, "child_added")
        .subscribe(snp => {
          if (!snp.data.guest && (!snp.data.auth.deleted || icludeDeletedUsers)) {
            const user = snp.data;
            user.id = snp.key;
            users.push(user);
            subscriber.next(users);
          }
        });

      const sub2 = this.dbService.listenSnap<User>(`accounts/${this.currentUser.account_id}/users`, "child_changed")
        .subscribe(snp => {
          const i = users.findIndex(u => u.id === snp.key);
          if (snp.data.auth.deleted && !icludeDeletedUsers) {
            if (i>-1) {
              users.splice(i, 1);
              subscriber.next(users);
            }
          } else {
            const user = snp.data;
            user.id = snp.key;
            if (i>-1) {
              users[i] = user;
            } else {
              users.push(user);
            }
            subscriber.next(users);
          }
        });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
      }
    });
  }

  getRoommateContacts(): Observable<Contact[]> {
    return new Observable<Contact[]>(subscriber => {
      const contacts: Contact[] = [];
      const sub1 = this.dbService.listenSnap<any>(`accounts/${this.currentUser.account_id}/roommates/${this.currentUser.id}`, "child_added")
        .subscribe(snp => {
          snp.data.meta.id = snp.key;
          contacts.push(snp.data.meta);
          subscriber.next(contacts);
        });

      const sub2 = this.dbService.listenSnap<any>(`accounts/${this.currentUser.account_id}/roommates/${this.currentUser.id}`, "child_changed")
        .subscribe(snp => {
          const i = contacts.findIndex(u => u.id === snp.key);
          snp.data.meta.id = snp.key;
          if (i>-1) {
            contacts[i] = snp.data.meta;
          } else {
            contacts.push(snp.data.meta);
          }
          subscriber.next(contacts);
        });

      const sub3 = this.dbService.listenSnap<any>(`accounts/${this.currentUser.account_id}/roommates/${this.currentUser.id}`, "child_removed")
        .subscribe(snp => {
          const i = contacts.findIndex(u => u.id === snp.key);
          if (i>-1) {
            contacts.splice(i, 1);
            subscriber.next(contacts);
          }
        });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
        sub3.unsubscribe();
      }
    });
  }

  setUserStatus(status: string, lock: boolean) {
    const accountId = this.currentUser.account_id;
    const currentUserId = this.currentUser.id;
    return this.dbService.update(`accounts/${accountId}/users/${currentUserId}/status`, { web_status: status, web_status_lock: lock });
  }

  getUserContacts(): Promise<User[]> {
    return this.http.post<any>(environment.endPoints.getUserContacts, { token: this.currentUser.token })
    .toPromise()
    .then(result => result.data.users);
  }

  getAllContacts(): Observable<Contact[]> {
    return new Observable<Contact[]>(subscriber => {
      const contacts: Contact[] = [];
      const sub1 = this.dbService.listenSnap<User>(`accounts/${this.currentUser.account_id}/users`, "child_added")
        .subscribe(snp => {
          if (!snp.data.guest && !snp.data.auth.deleted) {
            const contact: Contact = {
              id: snp.key,
              name: snp.data.name,
              username: snp.data.auth.username,
              role: snp.data.role
            };
            if (snp.data.ad_user) {
              contact.ad_user = true;
            }
            if (snp.data.user_principal_name) {
              contact.user_principal_name = snp.data.user_principal_name;
            }
            contacts.push(contact);
            subscriber.next(contacts);
          }
        });

      const sub2 = this.dbService.listenSnap<User>(`accounts/${this.currentUser.account_id}/users`, "child_changed")
        .subscribe(snp => {
          const i = contacts.findIndex(u => u.id === snp.key);
          if (snp.data.auth.deleted) {
            if (i>-1) {
              contacts.splice(i, 1);
              subscriber.next(contacts);
            }
          } else {
            const contact: Contact = {
              id: snp.key,
              name: snp.data.name,
              username: snp.data.auth.username,
              role: snp.data.role
            };
            if (snp.data.ad_user) {
              contact.ad_user = true;
            }
            if (snp.data.user_principal_name) {
              contact.user_principal_name = snp.data.user_principal_name;
            }
            if (i>-1) {
              contacts[i] = contact;
            } else {
              contacts.push(contact);
            }
            subscriber.next(contacts);
          }
        });

      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
      }
    });
  }

  authenticateSSO(id: string) {
    return this.http.post<any>(environment.endPoints.remote_sso, { sso_id: id }).toPromise();
  }

  acceptAgreement() {
    return this.http.post<any>(this.dbService.getEndPoint("accountagreement"), {
      token: this.currentUser.token
    }).toPromise();
  }

  sendFeedbackData(feedbackId: string, feedbackData: any) {
    return this.dbService.set(`feedbacks/${feedbackId}`, feedbackData);
  }

  getWebUpdates() {
    return this.dbService.get<any>(`updates/web`);
  }

  getLastWebsiteVersion() {
    return this.dbService.get<string>(`updates/web/currentLastVersion`);
  }

  setLastSeenWebsiteVersion(webLastSeenVersion: string): Promise<void> {
    return this.dbService.set(`accounts/${this.currentUser.account_id}/users/${this.currentUser.id}/web_last_seen_version`, webLastSeenVersion);
  }

  sendRatingData(ratingData: any) {
    return this.dbService.push('session_ratings', ratingData);
  }

  getSecondLogo() {
    return this.dbService.authState.pipe(first()).toPromise()
      .then(authState => this.dbService.get<any>(`accounts/${authState.account_id}/account_data/second_logo`));
  }

  getPasswordPolicy() {
    return this.dbService.authState.pipe(first()).toPromise()
      .then(authState => this.dbService.get<any>(`accounts/${authState.account_id}/account_data/password_policy`));
  }
}
