import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/core/auth.service';
import { DbService } from '@services/core/db.service';
import { LoaderService } from '@services/support/loader.service';

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss']
})
export class AuthenticateComponent implements OnInit, OnDestroy {

  loading: boolean = true;

  authSubscription: Subscription = null;

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private dbService: DbService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // Navigate main if user already authenticated
    this.authSubscription = this.dbService.authState.subscribe(auth => {
      if (auth) {
        this.router.navigate(['/']);
      }
    });
    this.authenticate();
  }

  ngOnDestroy() {
    if (this.authSubscription) { this.authSubscription.unsubscribe() }
  }

  async authenticate() {
    const params = await this.route.queryParams.pipe(first()).toPromise();
    if (!(params && params.id)) {
      this.loading = false;
      return;
    }

    this.loaderService.show();
    this.authService.authenticateSSO(params.id)
    .then(result => this.authService.login(result.token))
    .catch(error => { this.loading = false; })
    .finally(() => this.loaderService.hide());
  }
}
