<div class="file-container">
  <div class="card-deck" *ngIf="logFiles.length > 0">
    <div class="file-log border border-secondary mb-1 ml-1" *ngFor="let file of logFiles; index as i" 
      [popover]="popTemplate" [popoverContext]="{file:filesAndObjects[i]}" triggers="mouseenter:mouseleave" container="body">
      <div class="image-container d-flex justify-content-center mt-2" *ngIf="filesAndObjects.length>0">
        <img class="card-img-top" [src]="!filesAndObjects[i].src ? filesAndObjects[i].url : filesAndObjects[i].src">
      </div>
      <div class="file-info d-flex justify-content-center my-2">
        <div class="d-flex align-items-center pl-2">
          <div class="file-download bd-highlight">
            <span>
              <input type="checkbox" *ngIf="showCheckboxes" [(ngModel)]="file.isChecked" (change)="onFileCheckboxChange(file)">
            </span>
            <span class="file-name">{{ file.name }}</span>
          </div>
          
        </div>
        <a class="download-icon d-flex align-items-center pr-1" (click)="onDownloadFile(file)"><i class="mi mi-get-app"></i></a>
      </div>
    </div>
  </div>

  <div class="error-message" *ngIf="logFiles.length === 0">
    <p class="ticket-list-empty">{{'APP.MAIN.LOBBY.SESSIONS.SESSION_FILES.NO_FILE_IS_ADDED' | translate}}</p>
  </div>
</div>

<ng-template #popTemplate let-file="file">
  <div class="file-name"><b>File Name:</b> {{file.name}}</div>
  <div class="file-name" *ngIf="file.subfiles"><b>File Contents:</b></div>
  <li class="file-name" *ngFor="let f of file.subfiles">
    {{ f }}
  </li>
  <div class="file-size"><b>File Size:</b> {{ file.size | fileSize}}</div>
</ng-template>