<div class="session-details-container">
  <div class="container-fluid">
    <div class="main-wrapper">
      <div class="row justify-content-center">
        <div class="col-xl-9 col-lg-10 col-md-11 col-12 main-body">
            <a class="back-link" (click)="onBack()">
              <i class="mi mi-arrow-back"></i><span>{{ 'APP.MAIN.LOBBY.SESSIONS.BACK' | translate }}</span>
            </a>
            <div class="card">
              <div class="card-header d-flex justify-content-between align-items-center">
                <span class="card-header-title">{{ 'APP.MAIN.LOBBY.SESSIONS.SESSION_DETAILS' | translate }}</span>
              </div>
              <div class="card-body px-lg-5" *ngIf="isInitialized; else loadingTemplate">
                <div class="row">
                  <div class="col-12 mt-2 mb-1">
                    <span class="room-name">{{sessionApi.room.name}}</span>
                  </div>
                  <div class="col-md-6 mt-3">
                    <span class="key">{{ 'APP.MAIN.LOBBY.SESSIONS.DATE' | translate }} : </span>
                    <span class="value ml-2">{{ sessionApi.create_time | utcCalcAccount | async }}</span>
                  </div>
                  <div class="col-md-6 mt-3">
                    <span class="key">{{ 'APP.MAIN.LOBBY.SESSIONS.DURATION' | translate }} : </span>
                    <span class="value ml-2">{{ duration }}</span>
                  </div>
      
                  <div class="col-md-6 mt-3 d-flex align-items-baseline">
                    <div class="key">
                      {{ 'APP.MAIN.LOBBY.SESSIONS.PARTICIPANTS' | translate }}:
                    </div>
                    <div class="value ml-2 participants">
                      <div class="mb-1" *ngFor="let user of sessionApi.users"> 
                        {{user.name}} ({{user.username}} / {{user.role}})
                        <div class="mb-2" *ngIf="userEmailsById[user.id]">
                          <a class="email-text text-info" href="mailto:{{ userEmailsById[user.id] }}">{{ userEmailsById[user.id] }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <div *ngFor="let user of sessionApi.users">
                      <div class="mb-1" *ngIf="sessionFirebase?.user_durations">
                        {{ sessionFirebase.user_durations[user.id] | durationFormat: 'ms':'hhmmss' }}
                        <div class="mb-4" *ngIf="userEmailsById[user.id]">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" >
                  <div class="col-12" [ngClass]="{'col-md-6': ticketAvailable}" *ngIf="sessionExportEnabled && !webdavEnabled && !sessionFirebase?.session_files_deleted">
                    <button class="btn btn-outline-info detail-button mt-3" (click)="onSessionExportModal(sessionApi.room.id, sessionApi.id);">
                      <img class="button-icon mr-2" src="assets/export_session.svg"><span>{{ 'APP.MAIN.LOBBY.SESSIONS.GET_SESSION_EXPORT' | translate }}</span>   
                    </button>
                  </div>
                  <ng-container *ngIf="isInitialized && ticketAvailable">
      
                    <div class="col-12" [ngClass]="{'col-md-6': sessionExportEnabled && !webdavEnabled && !sessionFirebase?.session_files_deleted}">
                      <button *ngIf="!attachedTicket" class="btn btn-outline-primary detail-button mt-3" (click)="openTicketListModal()">
                        <i class="mi mi-style dark-blue-color button-icon mr-2"></i><span>{{ 'APP.MAIN.LOBBY.SESSIONS.ATTACH_TO_TICKET' | translate }}</span>   
                      </button>
                      <div *ngIf="attachedTicket" class="mt-4"><span class="key">{{ 'APP.MAIN.LOBBY.SESSIONS.ATTACHED_TICKET' | translate }}: </span><span class="values">{{ attachedTicket.title }}</span></div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

            <app-archives [room_id]="sessionApi.room.id" [session_id]="sessionApi.id"></app-archives>

            <div class="row files-chat">
              <div class="col-lg-6 mb-3" *ngIf="!webdavEnabled && !sessionFirebase?.session_files_deleted">
                <div class="card">
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <span class="card-header-title">{{ 'APP.MAIN.LOBBY.SESSIONS.FILES' | translate }}</span>
                   <div>
                    <button class="btn btn-outline-info detail-button btn-sm"  (click)="toggleSelection()">{{ 'APP.MAIN.LOBBY.SESSIONS.SESSION_FILES.MULTIPLE_SELECTION' | translate }}</button>
                    &nbsp;
                    <button *ngIf="showDownloadButton" class="btn btn-outline-info detail-button btn-sm" (click)="onDownloadStarted()">{{ 'APP.MAIN.LOBBY.SESSIONS.SESSION_FILES.DOWNLOAD' | translate }}</button>
                  </div>                  
                  </div>
                  <div class="card-body">
                    <app-session-file
                      *ngIf="sessionFirebase"
                      [session]="sessionFirebase"
                      [session_id]="sessionApi.id"
                      [showCheckboxes]="showCheckboxes"
                      [downloadSelectedFiles]="downloadSelectedFiles"
                    ></app-session-file>
                  </div>
                </div>
              </div>
              
              <div class="col-lg-6" *ngIf="!webdavEnabled && !sessionFirebase?.session_files_deleted">
                <div class="card">
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <span class="card-header-title">{{ 'APP.MAIN.LOBBY.SESSIONS.CHAT' | translate }}</span>
                  </div>
                  <div class="card-body">
                    <app-session-chat [session]="sessionFirebase" [session_id]="sessionApi.id"></app-session-chat>
                  </div>
                </div>      
              </div>
            </div>
       </div>
    </div>
  </div>
</div>
</div>
<ng-template #loadingTemplate>
<div class="loading-template">
  <div class="d-flex justify-content-center">
    <div class="loader loader-sm la-dark">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
</ng-template>