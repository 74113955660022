import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-get-file',
  templateUrl: './get-file.component.html',
  styleUrls: ['./get-file.component.scss']
})
export class GetFileComponent implements OnInit {
  url: string

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.url = this.router.url
  }
}