import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter, OnDestroy } from '@angular/core';

import { environment } from 'environments/environment';

import { FileShareService } from '@services/other/file-share.service';
import { ModalService } from '@services/support/modal.service';

import { Observable, Subscription } from 'rxjs';

import { UploadModel } from '@models/UploadModel';
import { UploadFile } from '@models/UploadFile';
import { UserFile } from '@models/UserFile';
import { AuthService } from '@services/core/auth.service';

@Component({
  selector: 'app-file-share',
  templateUrl: './file-share.component.html',
  styleUrls: ['./file-share.component.scss']
})
export class FileShareComponent implements OnInit, OnDestroy {

  @ViewChild("uploadModalTemplate", { static: true }) private uploadModalTemplate: TemplateRef<any>;
  @ViewChild("cancelUploadTemplate", { static: true }) private cancelUploadTemplate: TemplateRef<any>;

  @Input() dropzoneState: Observable<boolean>;
  @Input() chosenFiles: Observable<FileList>;
  @Input() uploadSource: string;
  @Output() uploadedFile = new EventEmitter<UserFile>();
  @Output() uploadedObject = new EventEmitter<Object>();

  @Input() ticketId: string;

  dropzoneOpen: boolean = false;
  dropzoneSub: Subscription = null;
  chosenFilesSub: Subscription = null;

  uploadModel: UploadModel = {
    uploadFiles: [],
    uploading: false,
    completed: false,
    totalBytes: 0,
    transferredBytes: 0,
    isFirebaseSDK: true,
    types: {
      includesImage: false,
      includesPdf: false,
      includesObject: false,
      includesOther: false
    }
  };

  sizeLimitExceeded: boolean = false;

  restrictedExtensions = environment.design.restrictedExtensions
  restrictedExtensionDetected: boolean = false;

  uploadModalId: number;
  uploadSub: Subscription = null;

  fileSizeLimit = {
    bytes: 104857600,
    megabytes: 100
  }
  licenseSub: Subscription = null;

  constructor(
    private authService: AuthService,
    private fileShareService: FileShareService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.licenseSub = this.authService.license.subscribe(license => {
      if (license.file_size_limit_mb) {
        this.fileSizeLimit = {
          bytes: license.file_size_limit_mb * 1024 * 1024,
          megabytes: license.file_size_limit_mb
        }
      } else if (license.model === "ultimate") {
        this.fileSizeLimit = {
          bytes: 524288000,
          megabytes: 500
        }
      } // else use default value => 100mb
    });

    this.dropzoneSub = this.dropzoneState.subscribe(state => {
      this.dropzoneOpen = state;
    });

    this.chosenFilesSub = this.chosenFiles.subscribe(files => {
      this.filesSelected(files);
    });
  }

  ngOnDestroy() {
    if (this.licenseSub) { this.licenseSub.unsubscribe() }
    if (this.dropzoneSub) { this.dropzoneSub.unsubscribe() }
    if (this.chosenFilesSub) { this.chosenFilesSub.unsubscribe() }

    if (this.uploadModel && this.uploadModel.uploading) {
      this.uploadModel.uploadFiles.forEach(upload => {
        if (upload.uploadTask.snapshot.state === 'running') {
          upload.uploadTask.cancel();
        }
      });
    }
    this.modalService.hide(this.uploadModalId);
  }

  onHoverFile(hover: boolean) {
    if (!hover) {
      this.dropzoneOpen = false;
    }
  }
  onDropFile(files: FileList) {
    this.filesSelected(files);
  }

  /* SELECTED FILES WITH DROP OR CHOOSE */
  filesSelected(files: FileList) {
    if (files.length > 0) {
      this.uploadModel.uploadFiles = [];

      for (let i = 0; i < files.length; i++) {
        this.uploadModel.uploadFiles.push({
          file: files[i],
          extension: files[i].name.split('.').pop().toLowerCase(),
          progress: 0
        });
      }

      this.detectFileTypes();

      this.uploadModel.uploading = false;
      this.uploadModel.completed = false;
      this.uploadModel.totalBytes = 0,
      this.uploadModel.transferredBytes = 0,

      this.uploadModalId = this.modalService.show({
        template: this.uploadModalTemplate,
        context: {
          dataModel: this.uploadModel,
          callbacks: {
            close: this.closeModal,
            cancel: this.openCancelDialog,
            upload: this.upload,
            remove: this.removeFile,
          }
        }
      });
    }
  }

  detectFileTypes() {
    this.uploadModel.types = {
      includesImage: false,
      includesPdf: false,
      includesObject: false,
      includesOther: false
    }

    this.uploadModel.multipleObjects = false;
    let objectSpecificExtensionFound = false;
    this.sizeLimitExceeded = false;
    this.restrictedExtensionDetected = false;
    this.uploadModel.uploadFiles.forEach(upFile => {
      if (upFile.file.size > this.fileSizeLimit.bytes && !this.ticketId) {
        this.sizeLimitExceeded = true;
      }
      if (this.restrictedExtensions.includes(upFile.extension)) {
        this.restrictedExtensionDetected = true;
      }
      if (FileShareService.objectSpecificExtensions.includes(upFile.extension)) {
        upFile.main_object_file = true;
        if (objectSpecificExtensionFound) {
          this.uploadModel.multipleObjects = true;
        }
        objectSpecificExtensionFound = true;
        this.uploadModel.types.includesObject = true;
      }
    });

    this.uploadModel.uploadFiles.forEach(upFile => {

      if (this.uploadModel.types.includesObject) {
        upFile.highlighted = !FileShareService.objectAllExtensions.includes(upFile.extension);
        upFile.file_type = 'object';
      } else {
        upFile.highlighted = false;

        if (FileShareService.imageExtensions.includes(upFile.extension)) {
          this.uploadModel.types.includesImage = true;
          upFile.file_type = 'image';
        } else if (FileShareService.pdfExtensions.includes(upFile.extension)) {
          this.uploadModel.types.includesPdf = true;
          upFile.file_type = 'pdf';
        } else {
          this.uploadModel.types.includesOther = true;
          upFile.file_type = 'other';
        }
      }
    });
  }

  /* MODAL WINDOW CALLBACKS */
  closeModal = () => {
    this.modalService.hide(this.uploadModalId);
  }
  removeFile = (index) => {
    this.uploadModel.uploadFiles.splice(index, 1);

    this.detectFileTypes();

    if (this.uploadModel.uploadFiles.length === 0) {
      this.modalService.hide(this.uploadModalId);
    }
  }
  upload = () => {
    if (this.uploadSource === 'ticket') {
      this.uploadModel.uploading = true;
      let formData = new FormData();
    
      if(this.uploadModel.uploadFiles.length){
        for (let i = 0; i < this.uploadModel.uploadFiles.length; i++) {
          formData.append(this.uploadModel.uploadFiles[i].file.name, this.uploadModel.uploadFiles[i].file)
        }
      }
      this.fileShareService.uploadTicketFiles(formData, this.ticketId).subscribe((res) => {
          this.uploadModel.uploadFiles.forEach(file => {
            if(res) {
              file.progress = 100
              file.uploadTask = {snapshot: {state: ''}}
              file.uploadTask.snapshot.state = 'success'
              this.uploadModel.completed = true;
            }else{
              file.uploadTask.snapshot.state = 'canceled'
            }
          })
        this.uploadModel.uploading = false;
      })

    }else{
      let obs, isFirebaseSDK;
      if (this.uploadSource === 'chat'){ [obs, isFirebaseSDK] = this.fileShareService.uploadFiles(this.uploadModel) }
      else if (this.uploadSource === 'files') { [obs, isFirebaseSDK] = this.fileShareService.uploadRoomFiles(this.uploadModel) }
  
      this.uploadModel.isFirebaseSDK = isFirebaseSDK;
      if (!isFirebaseSDK) {
        this.uploadModel.uploading = true;
        this.uploadSub = obs.subscribe(result => {
          const files: {done: boolean, filedata: any}[] = result.files;
          if (this.uploadModel.types.includesObject) {
            if (files.every(f => f.done)) {
              files.forEach(file => {
                const rf = this.uploadModel.uploadFiles.find(f => f.key == file.filedata.key);
                rf.progress = 100;
                rf.url = file.filedata.url;
                rf.uploadTask.snapshot.state = 'success';
              });
            } else {
              files.forEach(file => {
                const rf = this.uploadModel.uploadFiles.find(f => f.key == file.filedata.key);
                rf.progress = 100;
                rf.uploadTask.snapshot.state = 'canceled';
              });
            }
            if (this.uploadSource === 'chat') { this.onObjectUploadedToChat() }
            else if (this.uploadSource === 'files') { this.onObjectUploadedToFiles() }
  
          } else {
            files.forEach(file => {
              const rf = this.uploadModel.uploadFiles.find(f => f.key == file.filedata.key);
              rf.progress = 100;
              if (file.done) {
                rf.url = file.filedata.url;
                rf.uploadTask.snapshot.state = 'success';
                if (this.uploadSource === 'chat') { this.onFileUploadedToChat(rf) }
                else if (this.uploadSource === 'files') { this.onFileUploadedToFiles(rf) }
              } else {
                rf.uploadTask.snapshot.state = 'canceled';
              }
            });
          }
        }, error => {
          this.uploadModel.uploadFiles.forEach(f => {
            f.progress = 100;
            f.uploadTask.snapshot.state = 'canceled';
          });
          this.uploadModel.uploading = false;
          this.uploadModel.completed = true;
        }, () => {
          this.uploadModel.uploading = false;
          this.uploadModel.completed = true;
        });
      } else {
        const sub = obs.subscribe(
          upload => {
            if (!this.uploadModel.types.includesObject) {
              if (this.uploadSource === 'chat') { this.onFileUploadedToChat(upload) }
              else if (this.uploadSource === 'files') { this.onFileUploadedToFiles(upload) }
            }
          },
          error => {},// This error handled as canceled in html
          () => {
            if (this.uploadModel.types.includesObject) {
              if (this.uploadSource === 'chat') { this.onObjectUploadedToChat() }
              else if (this.uploadSource === 'files') { this.onObjectUploadedToFiles() }
  
            }
            sub.unsubscribe();
          }
        );
      }
    }
    
  }

  openCancelDialog = () => {
    const modalId = this.modalService.show({
      template: this.cancelUploadTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () => {
            if (this.uploadModel.isFirebaseSDK) {
              this.uploadModel.uploadFiles.forEach(upload => {
                if (upload.uploadTask.snapshot.state === 'running') {
                  upload.uploadTask.cancel();
                }
              });
            } else {
              if (this.uploadSub) { this.uploadSub.unsubscribe() }
              this.uploadModel.uploading = false;
              this.uploadModel.completed = true;
            }
            this.modalService.hide(modalId);
          },
          close: () => {
            this.modalService.hide(modalId);
          }
        }
      }
    });
  }

  onFileUploadedToChat(upload: UploadFile) {
    const userFile = this.fileShareService.createDatabaseUserFile(upload);
    this.fileShareService.saveUserFileToChatDb(userFile)
    .then(file => { this.uploadedFile.emit(file) })
  }

  onFileUploadedToFiles(upload: UploadFile) {
    const userFile = this.fileShareService.createDatabaseUserFile(upload);
    this.fileShareService.saveUserFileToFilesDb(userFile)
    .then(file => { this.uploadedFile.emit(file) })
  }

  onObjectUploadedToChat(){
    const userObject = this.fileShareService.createDatabaseUserObject(this.uploadModel.uploadFiles);
    this.fileShareService.saveUserObjectToChatDb(userObject)
    .then(object => {
      this.uploadedObject.emit(object);
    });
  }

  onObjectUploadedToFiles(){
    const userObject = this.fileShareService.createDatabaseUserObject(this.uploadModel.uploadFiles);
    this.fileShareService.saveUserObjectToFilesDb(userObject)
    .then(object => {
      this.uploadedObject.emit(object);
    });
  }

}
