import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { SessionFile } from '@models/Session';
import { User } from '@models/User';
import { UserFile } from '@models/UserFile';
import { RoomSessionService } from '@services/core/room-session.service';
import { FileShareService } from '@services/other/file-share.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { LoaderService } from '@services/support/loader.service';

@Component({
  selector: 'app-session-file',
  templateUrl: './session-file.component.html',
  styleUrls: ['./session-file.component.scss']
})
export class SessionFileComponent implements OnInit, OnChanges {

  @Input('session') session: any;
  @Input('session_id') session_id: string;
  @Input() showCheckboxes: boolean;
  @Input() downloadSelectedFiles: boolean;
 
  currentUser: User = null;

  logFiles: UserFile[] = [];
  filesAndObjects: SessionFile[] = [];
  selectedFiles: any[] = [];
  chatexport: any;

  private filesAndObjectsMap = new Set();
  private subscriptions: Subscription[] = [];

  constructor(
      private sessionService: RoomSessionService,
      private flashMessageService: FlashMessageService,
      private fileShareService: FileShareService,
      private http: HttpClient,
      private loaderService: LoaderService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.downloadSelectedFiles && changes.downloadSelectedFiles.currentValue) {
      this.onDownloadSelectedFiles();
    }
    if (changes.showCheckboxes) {
      this.resetCheckboxSelections();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  };

  ngOnInit(): void {
    this.filesAndObjects = [];
    this.logFiles = [];
    this.subscriptions.push(this.sessionService.getSessionFiles(this.session,this.session_id).subscribe((files: UserFile[]) => {
      files.forEach(file => {
        if (!this.filesAndObjectsMap.has(file.id)) {
          this.filesAndObjectsMap.add(file.id);
          if (file.file_type === 'image') {
            this.filesAndObjects.push({name: file.name, size: file.size, src: file.url});
          } else if (file.file_type === 'pdf') {
            this.filesAndObjects.push({name: file.name, size: file.size, src: 'assets/img/pdf.png'});
          } else if (file.file_type === 'other') {
            this.filesAndObjects.push({name: file.name, size: file.size, src: 'assets/img/other.png'});
          }
          this.logFiles.push(file);
        }
    });
    }));
    this.subscriptions.push(this.sessionService.getSessionObjects(this.session,this.session_id).pipe(first()).subscribe(objects => {
      objects.forEach(object => {
        if (!this.filesAndObjectsMap.has(object.id)) {
          this.filesAndObjectsMap.add(object.id);
          let sum = 0;
          let subfiles = [];
          //@ts-ignore
          object.files.forEach(file => { 
              sum = sum + file.size;
              subfiles.push(file.name);
          });
          this.filesAndObjects.push({name: object.name, size: sum, src: 'assets/img/obj.png', subfiles: subfiles});
          this.logFiles.push(object);
        }
      });
    }));
    this.chatexport = {
      name: "export.pdf",
      size: 0,
      src: "assets/img/pdf.png",
      exportPdf: true,
      room_id: this.session.room_id,
      session_id: this.session_id
    };
    
    this.filesAndObjects.push(this.chatexport);
    this.logFiles.push(this.chatexport);
  }

  onDownloadFile(file: any) {
    this.flashMessageService.showTranslated('APP.MAIN.LOBBY.SESSIONS.SESSION_FILES.DOWNLOAD_STARTED_SOON', { cssClass: 'alert-info', timeout: 2000 });
    if (file.extension === 'obj') {
      const files = file.files;
      for (let f of files) {
        this.fileShareService.downloadFile(f.url, f.name)
          .catch(error => { this.flashMessageService.showTranslated('APP.MAIN.LOBBY.SESSIONS.SESSION_FILES.DOWNLOAD_FAILED'); });
      }
    } else if (file.exportPdf) {
      this.fileShareService.getSessionExportPDF(file.room_id, file.session_id)
        .catch(error => { this.flashMessageService.showTranslated('APP.MAIN.LOBBY.SESSIONS.SESSION_FILES.DOWNLOAD_FAILED'); });
    }
    else {
      this.fileShareService.downloadFile(file.url, file.name)
          .catch(error => {this.flashMessageService.showTranslated('APP.MAIN.LOBBY.SESSIONS.SESSION_FILES.DOWNLOAD_FAILED'); });
    }
  }
  
onFileCheckboxChange(file: any) {
  if (file === this.chatexport) {
    if (file.isChecked) {
      this.selectedFiles.push(file);
    } else {
      const index = this.selectedFiles.findIndex(selectedFile => selectedFile === file);
      if (index !== -1) {
        this.selectedFiles.splice(index, 1);
      }
    }
  } else {
    if (file.isChecked) {
      this.selectedFiles.push(file);
    } else {
      const index = this.selectedFiles.findIndex(selectedFile => selectedFile.id === file.id);
      if (index !== -1) {
        this.selectedFiles.splice(index, 1);
      }
    }
  }
}

  resetCheckboxSelections() {
    for (const file of this.selectedFiles) {
      file.isChecked = false;
    }
    this.selectedFiles = []
  }

  async onDownloadSelectedFiles() {
  this.loaderService.show();

  if (this.selectedFiles.length > 0) {
    await this.fileShareService.downloadSelectedFilesAsZip(this.selectedFiles, 'selectedFiles.zip')
    .then(() => { this.flashMessageService.showTranslated('APP.MAIN.LOBBY.SESSIONS.SESSION_FILES.SUCCESS_MESSAGE');})
    .catch(error => { this.flashMessageService.showTranslated('APP.MAIN.LOBBY.SESSIONS.SESSION_FILES.DOWNLOAD_FAILED');})
    .finally(() => this.loaderService.hide());
  }
}

  /*onFileLogClick(file: any) {
    console.log(file)

    file.isChecked = !file.isChecked;
  }*/
}