import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

import * as bowser from 'bowser';

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss']
})
export class DownloadAppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const parser = bowser.getParser(window.navigator.userAgent)
    const isTouchDevice = 'ontouchstart' in window;

    if (parser.is('iOS') || (parser.is("macOS") && isTouchDevice)) {
      window.location.href = environment.design.appStoreLink
    } else if (parser.is('Android')) {
      window.location.href = environment.design.googlePlayLink
    }
  }
}
