import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { CanActivate } from '@angular/router';

@Injectable({providedIn: 'root'})
export class HelpCenterGuard implements CanActivate {

    constructor() { }

    canActivate() {
        return environment.design.showHelpCenterLink;
    }
}