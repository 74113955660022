import { Component, OnInit, ViewChild, TemplateRef, OnDestroy, ViewContainerRef } from '@angular/core';

import { environment } from '../../../../environments/environment';

import { Validators, NgForm } from '@angular/forms';

import { fromEvent, Subscription, merge, combineLatest, interval } from 'rxjs';
import { auditTime, first } from 'rxjs/operators';

import { AuthService } from '@services/core/auth.service';
import { CallService } from '@services/core/call.service';
import { RoomService } from '@services/other/room.service';
import { LoaderService } from '@services/support/loader.service';
import { OpentokService } from '@services/core/opentok.service';
import { ModalService } from '@services/support/modal.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { DbService } from '@services/core/db.service';
import { NavigationService } from '@services/support/navigation.service';
import { TranslateService } from '@ngx-translate/core';

import * as semver from 'semver';

import { Language } from '@models/Language';
import { Room } from '@models/Room';
import { Contact } from '@models/Contact';

import { Howl } from 'howler';
import { DetailPageService } from '@services/core/detail-page.service';

@Component({
  selector: 'app-lobby',
  templateUrl: './lobby.component.html',
  styleUrls: ['./lobby.component.scss']
})
export class LobbyComponent implements OnInit, OnDestroy {

  @ViewChild("logoutTemplate", { static: true }) private logoutTemplate: TemplateRef<any>;
  @ViewChild("idleTemplate", { static: true }) private idleTemplate: TemplateRef<any>;
  @ViewChild("agreementModel", {static: true}) private agreementTemplate: TemplateRef<any>;
  @ViewChild("checkAgreementModel", {static: true}) private checkAgreementTemplate: TemplateRef<any>;
  @ViewChild("scheduleMeetingTemplate", { static: true }) private scheduleMeetingTemplate: TemplateRef<any>;
  @ViewChild("cancelMeetingTemplate", { static: true }) private cancelMeetingTemplate: TemplateRef<any>;
  @ViewChild("openWhatsnewTemplate", { static: true }) private openWhatsnewTemplate: TemplateRef<any>;
  @ViewChild('changePassForm') changePassForm: NgForm;
  @ViewChild("detailContainer", { read: ViewContainerRef, static: true }) detailContainer: ViewContainerRef;
  @ViewChild("detailContainer2", { read: ViewContainerRef, static: true }) detailContainer2: ViewContainerRef;

  idleDuration: number = 600000;
  lastUserEventTime = (new Date()).getTime();
  userEventSub: Subscription = null;
  userEventTimerSub: Subscription = null;
  idleCountdownTimerSub: Subscription = null;

  sidebarOpen: boolean = window.innerWidth < 768 ? false : true;
  sidebarTogglerSub: Subscription = null;

  appVersion: string = "";

  contactsAvailable: boolean = false;
  featuresSub: Subscription = null;

  ticketsAvailable: boolean = false;
  addOnsSub: Subscription = null;

  reloadPageTimes: number = 0;
  cards = [];

  roommates = [];

  licenseType: string = "concurrent_user_based";
  licenseSub: Subscription = null;

  scheduleMeetingSub: Subscription = null;
  cancelMeetingSub: Subscription = null;

  notificationSub: Subscription = null;

  validMailValidator = Validators.pattern(/^$|^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/);
  invalidEmailError = {pattern: ''};

  constructor(
    public opentokService: OpentokService,
    private authService: AuthService,
    private callService: CallService,
    private roomService: RoomService,
    private flashMessageService: FlashMessageService,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private modalService: ModalService,
    private navigationService: NavigationService,
    private dbService: DbService,
    private multilanguageService: MultilanguageService,
    private detailPageService: DetailPageService
  ) { }

  ngOnInit() {
    this.appVersion = environment.version;

    this.licenseSub = this.authService.license.subscribe(license => {
      this.licenseType = license.type;
    });

    this.detailPageService.setDetailContainer(this.detailContainer);
    this.detailPageService.setDetailContainer2(this.detailContainer2);
    this.roomService.startListeningOwnRooms();

    this.scheduleMeetingSub = this.callService.scheduleMeetingRequest.subscribe(() => this.scheduleMeeting());
    this.cancelMeetingSub = this.callService.cancelMeetingRequest.subscribe(r => this.cancelMeeting(r[0], r[1]));

    this.authService.getUserContacts().then(users => {
      this.roommates = users
    })

    combineLatest([this.authService.addOns, this.authService.user]).pipe(first()).toPromise()
    .then(([addOns, userData]) => {
      if (addOns.agreement && !userData.agreement_accepted) {
        this.showAgreement();
      }
      if (!addOns.dontkickinactives) {
        this.userEventSub = merge(fromEvent(document, "keydown"), fromEvent(document, "mousedown"), fromEvent(document, "mousemove"))
        .pipe(auditTime(1000)).subscribe(e => {
          this.lastUserEventTime = (new Date()).getTime();
        });
        this.startWatching();
      }
    });

    this.navigationService.setSidebarTogglerStatus(true);
    this.sidebarTogglerSub = this.navigationService.onSidebarTogglerClicked.subscribe(() => {
      this.sidebarOpen = !this.sidebarOpen;
    });
    this.addOnsSub = this.authService.addOns.subscribe(addOns => { this.ticketsAvailable = addOns.ticket; });
    this.featuresSub = this.authService.features.subscribe(features => { this.contactsAvailable = features.calling; });

    this.notificationSub = this.roomService.getInRoomNotification()
    .subscribe(() => {
      const sound = new Howl({
        src: ['assets/lobby-notification-sound.wav'],
      });
      sound.play();
    })
  }

  async scheduleMeeting() {

    this.translateService.get('APP.MAIN.EMAIL_RECIPIENTS.INVALID_EMAIL').toPromise().then(tr => {
      this.invalidEmailError.pattern = tr;
    });

    const timeSlotMinutes = 15;
    const now = new Date();
    
    const startD = new Date(now);
    startD.setHours(startD.getHours(), startD.getMinutes() + timeSlotMinutes - (startD.getMinutes() % timeSlotMinutes), 0, 0);

    const endD = new Date(startD);
    endD.setHours(endD.getHours(), endD.getMinutes() + timeSlotMinutes, 0, 0);

    const emailLangList = ['en', 'de', 'tr']
    let emailLang = 'en'
    if (this.authService.currentUser?.translation_lang_email) {
      emailLang = this.authService.currentUser.translation_lang_email
    } else if (emailLangList.includes(this.multilanguageService.currentLang.code)) {
      emailLang = this.multilanguageService.currentLang.code
    }
    const dataModel = {
      title: "",
      start: { date: startD, time: startD.getHours()*60+startD.getMinutes() }, startSlots: [],
      end: { date: endD, time: endD.getHours()*60+endD.getMinutes() }, endSlots: [],
      duration: `0h ${timeSlotMinutes}m`,
      users: [],
      emailRecipients: [],
      emailLang: emailLang,
      emailLangList: emailLangList
    }; 

    this.roommates.forEach(user => {
      user['username'] = user.ad_user ? user.user_principal_name :  user.auth?.username
    })

    dataModel.users = this.roommates
      .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))
      .map(roommate => {
        const username = roommate.ad_user ? roommate.user_principal_name : roommate.username;
        switch (roommate.role) {
          case "admin":
            return {name: `${roommate.name} (${username} / Admin)`, id: roommate.id};
          case "coadmin":
            return {name: `${roommate.name} (${username} / Co-Admin)`, id: roommate.id};
          case "expert":
            return {name: `${roommate.name} (${username} / Expert)`, id: roommate.id};
          default:
            return {name: `${roommate.name} (${username} / ${this.licenseType === "concurrent_user_based" ? "User" : "Subscriber"})`, id: roommate.id};
        }
      });

    for (let i = 0; i < 1440; i=i+timeSlotMinutes) {
      const h1 = Math.floor(i/60);
      const m1 = i % 60;
      dataModel.startSlots.push({time: i, label: `${("0"+h1).slice(-2)}:${("0"+m1).slice(-2)}`});

      const i2 = (i+dataModel.start.time+timeSlotMinutes);
      const h2 = Math.floor(i2/60) % 24;
      const m2 = i2 % 60;

      const i3 = i2 - dataModel.start.time;
      const h3 = Math.floor(i3/60);
      const m3 = i3 % 60;

      dataModel.endSlots.push({time: i2, label: `${("0"+h2).slice(-2)}:${("0"+m2).slice(-2)} - ${h3}h ${m3}m`});
    }

    let userList: string[] = [];
    const modalId = this.modalService.show({
      template: this.scheduleMeetingTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          startDateChanged: (d: Date) => {
            dataModel.start.time = dataModel.start.date.getHours()*60+dataModel.start.date.getMinutes();
            const e = new Date(dataModel.start.date);
            e.setHours(e.getHours(), e.getMinutes() + timeSlotMinutes, 0, 0);
            dataModel.end.date = e;
            dataModel.end.time = dataModel.start.time+timeSlotMinutes;
            dataModel.endSlots = [];
            for (let i = dataModel.end.time; i < dataModel.end.time+1440; i=i+timeSlotMinutes) {
              const h = Math.floor(i/60) % 24;
              const m = i % 60;

              const i3 = i - dataModel.start.time;
              const h3 = Math.floor(i3/60);
              const m3 = i3 % 60;

              dataModel.endSlots.push({time: i, label: `${("0"+h).slice(-2)}:${("0"+m).slice(-2)} - ${h3}h ${m3}m`});
            }
            const i4 = dataModel.end.time - dataModel.start.time;
            const h4 = Math.floor(i4/60);
            const m4 = i4 % 60;
            dataModel.duration = `${h4}h ${m4}m`;
          },
          endDateChanged: (d: Date) => {
            const difference = d.getTime() - dataModel.start.date.getTime();

            if (difference <= 0) {
              const e = new Date(dataModel.start.date);
              e.setHours(e.getHours(), e.getMinutes() + timeSlotMinutes, 0, 0);
              dataModel.end.date = e;
              dataModel.end.time = dataModel.start.time+timeSlotMinutes;

              dataModel.endSlots = [];
              for (let i = dataModel.end.time; i < dataModel.end.time+1440; i=i+timeSlotMinutes) {
                const h = Math.floor(i/60) % 24;
                const m = i % 60;

                const i3 = i - dataModel.start.time;
                const h3 = Math.floor(i3/60);
                const m3 = i3 % 60;
  
                dataModel.endSlots.push({time: i, label: `${("0"+h).slice(-2)}:${("0"+m).slice(-2)} - ${h3}h ${m3}m`});
              }
            } else {
              const diffInMinutes = Math.round(difference / 60000);
              dataModel.end.time = dataModel.start.time + diffInMinutes;
              let days = Math.round(dataModel.end.time/1440);

              dataModel.endSlots = [];
              if (days === 0) {
                for (let i = dataModel.start.time+timeSlotMinutes; i < dataModel.start.time+timeSlotMinutes+1440; i=i+timeSlotMinutes) {
                  const h = Math.floor(i/60) % 24;
                  const m = i % 60;

                  const i3 = i - dataModel.start.time;
                  const h3 = Math.floor(i3/60);
                  const m3 = i3 % 60;
    
                  dataModel.endSlots.push({time: i, label: `${("0"+h).slice(-2)}:${("0"+m).slice(-2)} - ${h3}h ${m3}m`});
                }
              } else {
                if ((dataModel.start.time % 1440) < (dataModel.end.time % 1440)) {
                  days = days - 1;
                }
                for (let i = days*1440; i < (days+1)*1440; i=i+timeSlotMinutes) {
                  const h = Math.floor(i/60) % 24;
                  const m = i % 60;

                  const i3 = i - dataModel.start.time;
                  const h3 = Math.floor(i3/60);
                  const m3 = i3 % 60;
    
                  dataModel.endSlots.push({time: i, label: `${("0"+h).slice(-2)}:${("0"+m).slice(-2)} - ${h3}h ${m3}m`});
                }
              }
            }
            const i4 = dataModel.end.time - dataModel.start.time;
            const h4 = Math.floor(i4/60);
            const m4 = i4 % 60;
            dataModel.duration = `${h4}h ${m4}m`;
          },
          startTimeChanged: (t: any) => {
            const h1 = Math.floor(t.time/60);
            const m1 = t.time % 60;
            dataModel.start.date = new Date(dataModel.start.date.setHours(h1, m1, 0, 0));
            const tmp = new Date(dataModel.start.date);
            tmp.setHours(h1, m1+timeSlotMinutes, 0, 0);
            dataModel.end.date = tmp;
            dataModel.end.time = t.time+timeSlotMinutes;

            dataModel.endSlots = [];
            for (let i = dataModel.end.time; i < dataModel.end.time+1440; i=i+timeSlotMinutes) {
              const h = Math.floor(i/60) % 24;
              const m = i % 60;

              const i3 = i - dataModel.start.time;
              const h3 = Math.floor(i3/60);
              const m3 = i3 % 60;

              dataModel.endSlots.push({time: i, label: `${("0"+h).slice(-2)}:${("0"+m).slice(-2)} - ${h3}h ${m3}m`});
            }
            const i4 = dataModel.end.time - dataModel.start.time;
            const h4 = Math.floor(i4/60);
            const m4 = i4 % 60;
            dataModel.duration = `${h4}h ${m4}m`;
          },
          endTimeChanged: (t: any) => {
            const t2 = t.time % 1440;
            const h1 = Math.floor(t2/60);
            const m1 = t2 % 60;
            dataModel.end.date = new Date(dataModel.end.date.setHours(h1, m1, 0, 0));

            if (dataModel.start.date.getDate() === dataModel.end.date.getDate() && t.time >= 1440 && t.time <= dataModel.start.time+1440) {
              dataModel.end.date.setDate(dataModel.end.date.getDate() + 1);
              dataModel.endSlots = [];
              for (let i = 1440; i < 2880; i=i+timeSlotMinutes) {
                const h = Math.floor(i/60) % 24;
                const m = i % 60;

                const i3 = i - dataModel.start.time;
                const h3 = Math.floor(i3/60);
                const m3 = i3 % 60;

                dataModel.endSlots.push({time: i, label: `${("0"+h).slice(-2)}:${("0"+m).slice(-2)} - ${h3}h ${m3}m`});
              }
            }
            const i4 = dataModel.end.time - dataModel.start.time;
            const h4 = Math.floor(i4/60);
            const m4 = i4 % 60;
            dataModel.duration = `${h4}h ${m4}m`;
          },
          schedule: () => {
            this.loaderService.show();
            setTimeout(() => {
              const emails = dataModel.emailRecipients.map(email => email.value);
              this.callService.scheduleMeeting(userList, emails, dataModel.title, dataModel.start.date.getTime(), dataModel.end.date.getTime(), dataModel.emailLang)
              .then(() => {
                this.modalService.hide(modalId);
                dataModel.emailRecipients = [];
              })
              .catch(error => this.flashMessageService.showTranslated('APP.MAIN.LOBBY.SCHEDULE_MEETING_MODAL.CANNOT_SCHEDULE'))
              .then(() => {
                if (!this.authService.currentUser?.translation_lang_email) {
                  return this.multilanguageService.changePreferedTranslationLangEmail(dataModel.emailLang)
                }
              })
              .finally(() => this.loaderService.hide());
            }, 1000);
          },
          close: () => {
            this.modalService.hide(modalId)
            dataModel.emailRecipients = [];
          },
          userListChanged: (users: any) => {
            userList = [];
            users.forEach(u => userList.push(u.id));
          },
          onLanguageSelected: (lang: string) => {
            dataModel.emailLang = lang
            this.multilanguageService.changePreferedTranslationLangEmail(lang)
          }
        }
      }
    });
  }

  cancelMeeting(room: Room, previous: boolean) {
    const modalId = this.modalService.show({
      template: this.cancelMeetingTemplate,
      context: {
        dataModel: { previous: previous },
        callbacks: {
          close: () => this.modalService.hide(modalId),
          proceed: () => {
            this.loaderService.show();
            this.callService.cancelMeeting(room)
            .then((result) => this.modalService.hide(modalId))
            .catch(error => this.flashMessageService.showTranslated('APP.MAIN.LOBBY.SCHEDULE_MEETING_MODAL.CANNOT_CANCEL_MEETING'))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  showAgreement() {
    const modalId = this.modalService.show({
      template: this.agreementTemplate,
      context: {
        dataModel: null,
        callbacks: {
          reject: () => {
            this.showCheckAgreement(modalId);
          },
          accept: () => {
            this.loaderService.show();
            this.authService.acceptAgreement()
            .then(() => this.modalService.hide(modalId))
            .catch(error => this.flashMessageService.showTranslated('APP.MAIN.LOBBY.CHECK_AGREEMENT_MODAL.ERROR')) //There is an error occured
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  showCheckAgreement(agreementModalId: number) {
    const modalId = this.modalService.show({
      template: this.checkAgreementTemplate,
      context: {
        dataModel: null,
        callbacks: {
          close: () => {
            this.modalService.hide(modalId);
          },
          proceed: () => {
            this.loaderService.show();
            this.logout()
            .then(() => {
              this.modalService.hide(modalId)
              this.modalService.hide(agreementModalId)
            })
            .catch(error => this.flashMessageService.showTranslated('APP.MAIN.NAVBAR.LOGOUT_FAILED'))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  onWhatsNewModal() {
    //this.utilityService.whatsNewRequest.next();
  }

  setLastSeenVersionNumber(lastVersionSeen: string): Promise<void> {
    const accountId = this.authService.currentUser.account_id;
    const currentUserId = this.authService.currentUser.id;
    return this.dbService.set(`accounts/${accountId}/users/${currentUserId}/web_last_seen_version`, lastVersionSeen);
  }

  getAllCards(currentLang: Language, updateList: Object, websiteLatestVer: string, lastVersionSeen: string): Array<Object> {
    this.cards = [];

    let isWebsiteUpdated = semver.gt(websiteLatestVer, lastVersionSeen);
    if (isWebsiteUpdated) {
      const updateIds = updateList ? Object.keys(updateList) : [];
      const unseenUpdatesIds = updateIds.filter(upId => semver.lte(updateList[upId].v, websiteLatestVer) && semver.gt(updateList[upId].v, lastVersionSeen));
      
      const envName = environment.design.environmentName;
      const curlang = currentLang.code;

      unseenUpdatesIds.forEach(unseenUpdateId => { 
        let unseenUpdatesDetails = updateList[unseenUpdateId];
        let cardwEnv = unseenUpdatesDetails[envName];

        let cardwEnvLang;
        if (!cardwEnv[curlang]) {
          cardwEnvLang = cardwEnv["en"];
        } else {
          cardwEnvLang = cardwEnv[curlang];
        }

        Object.keys(cardwEnvLang).forEach(id => {
          this.cards.push({
            ...Object.values(cardwEnvLang[id])
          });
        });
      });

      for (let i=0; i<this.cards.length; i++) { this.cards[i] = {desc:  this.cards[i][0], image: this.cards[i][1], title: this.cards[i][2]} }
      return this.cards;
    }
  }

  openWhatsnewModal(updateList: Object, websiteLatestVer: string) {
    let currentLang = this.multilanguageService.currentLang; 
    let lastVersionSeen = this.authService.currentUser.web_last_seen_version;
    let localVersion = environment.version.toString();

    this.getAllCards(currentLang, updateList, websiteLatestVer, lastVersionSeen);    
    let isLatestVersionSeen = semver.eq(websiteLatestVer, lastVersionSeen);
    if (!isLatestVersionSeen) {                                             
      let isUserOnline = semver.gt(websiteLatestVer, localVersion);
      if (isUserOnline) {                                           
        //Show what's new page + Reload
        const modalId = this.modalService.show({
          template: this.openWhatsnewTemplate,
          context: {
            dataModel: null,
            callbacks: {
              close: () => {
                this.modalService.hide(modalId);
                this.setLastSeenVersionNumber(websiteLatestVer)
                .then(() => {
                  var refresh = window.sessionStorage.getItem('refresh');
                  if (refresh === null) {
                    document.location.reload();
                    window.sessionStorage.setItem('refresh', "1");
                  }
                });
              }
            }
          }
        });
      } 
      else { //I am not online on website, I came in later.
        //Did user see the modal?
        const modalId = this.modalService.show({
          template: this.openWhatsnewTemplate,
          context: {
            dataModel: null,
            callbacks: {
              close: async () => {
                this.modalService.hide(modalId);
                await this.setLastSeenVersionNumber(websiteLatestVer);
              }
            }
          }
        });
      } 
    }    
  }

  onSidebarLinkClicked() {
    // Toggle sidebar if width is smaller than 768px
    if (this.sidebarOpen && window.innerWidth < 768) {
      this.sidebarOpen = !this.sidebarOpen;
    }
    this.detailPageService.removeComponent();
    this.detailPageService.removeComponent2();
  }

  onLogout() {
    const modalId = this.modalService.show({
      template: this.logoutTemplate,
      context: {
        dataModel: { inRoom: this.callService.inRoom ? true : false },
        callbacks: {
          no: () => {
            this.modalService.hide(modalId);
          },
          yes: () => {
            this.modalService.hide(modalId);
            this.loaderService.show();
            this.logout()
            .then(() => {
              this.loaderService.hide();
            })
            .catch(error => {
              this.modalService.hide(modalId);
              this.loaderService.hide();
              this.flashMessageService.showTranslated('APP.MAIN.NAVBAR.LOGOUT_FAILED');
            });
          }
        }
      }
    });
  }

  logout(): Promise<void> {
    if (this.callService.inRoom) {
      return this.callService.endCall()
      .then(() => {
        return this.authService.logout();
      });
    } else {

      // For prevent loader flashing
      return new Promise(resolve => setTimeout(resolve, 500))
      .then(() => {
        return this.authService.logout();
      });
    }
  }

  startWatching() {
    this.userEventTimerSub = interval(1000).subscribe(() => {
      const now = (new Date()).getTime();
      if ((now - this.lastUserEventTime) > (this.idleDuration + 30000)) {
        this.authService.logout()
          .then(() => window.location.reload());
      } else if ((now - this.lastUserEventTime) > this.idleDuration) {
        this.onIdle();
      }
    });
  }

  onIdle = () => {
    if (this.userEventTimerSub) { this.userEventTimerSub.unsubscribe() }
    const dataModel = {
      count: 30,
      countMessage: ""
    };
    this.translateService.get('APP.MAIN.LOBBY.IDLE_MODAL_COUNT', dataModel).toPromise()
    .then(result => {
      dataModel.countMessage = result;
    });

    const modalId = this.modalService.show({
      template: this.idleTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          ok: () => {
            if (this.idleCountdownTimerSub) { this.idleCountdownTimerSub.unsubscribe() }
            this.startWatching();
            this.modalService.hide(modalId);
          }
        }
      }
    });

    this.idleCountdownTimerSub = interval(1000).subscribe(() => {
      if (dataModel.count > 0) {
        dataModel.count--;
        this.translateService.get('APP.MAIN.LOBBY.IDLE_MODAL_COUNT', dataModel).toPromise()
        .then(result => {
          dataModel.countMessage = result;
        });
      } else {
        if (this.idleCountdownTimerSub) { this.idleCountdownTimerSub.unsubscribe() }
        this.modalService.hide(modalId);
        this.authService.logout()
          .then(() => window.location.reload());
      }
    });
  }

  ngOnDestroy() {
    this.roomService.stopListeningOwnRooms();

    this.navigationService.setSidebarTogglerStatus(false);

    if (this.featuresSub) { this.featuresSub.unsubscribe() }
    if (this.addOnsSub) { this.addOnsSub.unsubscribe() }
    if (this.sidebarTogglerSub) { this.sidebarTogglerSub.unsubscribe() }
    if (this.scheduleMeetingSub) { this.scheduleMeetingSub.unsubscribe() }
    if (this.cancelMeetingSub) { this.cancelMeetingSub.unsubscribe() }
    if (this.licenseSub) { this.licenseSub.unsubscribe() }
    if (this.notificationSub) { this.notificationSub.unsubscribe() }

    if (this.idleCountdownTimerSub) { this.idleCountdownTimerSub.unsubscribe() }
    if (this.userEventSub) { this.userEventSub.unsubscribe() }
    if (this.userEventTimerSub) { this.userEventTimerSub.unsubscribe() }
  }
/*
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 576 && this.sidebarOpen) {
      this.sidebarOpen = false;
    }
  }
*/
}