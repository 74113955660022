import { Injectable } from '@angular/core';
import * as bowser from 'bowser';

import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

import { DBSource } from '@models/DBSource';
import { FirebaseSource } from '@models/FirebaseSource';
import { SocketioSource } from '@models/SocketioSource';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { User } from '@models/User';
import { AuthUser } from '@models/AuthUser';
import { UploadModel } from '@models/UploadModel';
import { UploadFile } from '@models/UploadFile';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  private source: DBSource = null;

  isAuthenticated: Observable<boolean>;
  private isAuthenticatedSource = new BehaviorSubject<boolean>(null);
  authState: Observable<AuthUser>;
  private authStateSource = new BehaviorSubject<AuthUser>(null);
  currentUser: Observable<User>;
  private currentUserSource = new BehaviorSubject<User>(null);
  isConnected: Observable<boolean>;
  private isConnectedSource = new BehaviorSubject<boolean>(null);

  private stateSubscriptions: Subscription = null;

  private isFirefox: boolean;

  constructor(
    private http: HttpClient
  ) {
    this.isFirefox = bowser.getParser(window.navigator.userAgent).is("Firefox");

    // Create all Observable sources in DBService,
    // In this way we can toggle db source in runtime
    this.isAuthenticated = this.isAuthenticatedSource.asObservable().pipe(filter(c => c !== null));
    this.authState = this.authStateSource.asObservable();
    this.currentUser = this.currentUserSource.asObservable();
    this.isConnected = this.isConnectedSource.asObservable().pipe(filter(c => c !== null));

    const type = sessionStorage.getItem("sourceType");
    this.setSourceType(type ? <"firebase"|"socket">type : "firebase");
  }

  setSourceType(sourceType: "firebase" | "socket") {
    if (this.stateSubscriptions) { this.stateSubscriptions.unsubscribe() }

    if (this.source) {
      this.source.destroy();
    }

    this.source = sourceType === "socket" ? new SocketioSource(this.http) : new FirebaseSource(this.http, this.isFirefox);

    this.stateSubscriptions = this.source.isAuthenticated.subscribe(ia => this.isAuthenticatedSource.next(ia));
    this.stateSubscriptions.add(this.source.authState.subscribe(as => this.authStateSource.next(as)));
    this.stateSubscriptions.add(this.source.currentUser.subscribe(cu => this.currentUserSource.next(cu)));
    this.stateSubscriptions.add(this.source.isConnected.subscribe(ic => this.isConnectedSource.next(ic)));
  }

  getSourceType(): "firebase" | "socket" {
    return this.source ? this.source.type : null;
  }

  authecticated(): boolean {
    return this.source.authecticated();
  }

  connected(): boolean {
    return this.source.connected();
  }

  getEndPoint(name: string): string {
    return this.source.getEndPoint(name);
  }
  login(token: string, manageUserStatus: boolean, reconnect: boolean = false): Promise<User> {
    return this.source.login(token, manageUserStatus, reconnect);
  }
  logout(): Promise<void> {
    return this.source.logout();
  }
  getManageUserStatus(): boolean {
    return this.source.manageUserStatus;
  }
  setManageUserStatus(manageUserStatus: boolean): void {
    this.source.manageUserStatus = manageUserStatus;
  }
  listen<T>(path: string, event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Observable<T> {
    return this.source.listen<T>(path, event);
  }
  listenSnap<T>(path: string, event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Observable<{ data: T; key: string; }> {
    return this.source.listenSnap<T>(path, event);
  }
  query<T>(path: string, queries: {key:"orderByChild"|"orderByKey"|"orderByValue"|"equalTo"|"startAt"|"endAt"|"limitToFirst"|"limitToLast", value:string|number|boolean}[], event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Observable<T> {
    return this.source.query<T>(path, queries, event);
  }
  querySnap<T>(path: string, queries: {key:"orderByChild"|"orderByKey"|"orderByValue"|"equalTo"|"startAt"|"endAt"|"limitToFirst"|"limitToLast", value:string|number|boolean}[], event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Observable<{ data: T; key: string; }> {
    return this.source.querySnap<T>(path, queries, event);
  }
  get<T>(path: string, event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Promise<T> {
    return this.source.get<T>(path, event);
  }
  set(path: string, data: any): Promise<void> {
    return this.source.set(path, data);
  }
  push(path: string, data: any): Promise<string> {
    return this.source.push(path, data);
  }
  update(path: string, data: any): Promise<void> {
    return this.source.update(path, data);
  }
  remove(path: string): Promise<void> {
    return this.source.remove(path);
  }
  transaction(path: string, name: string, data: any = null): Promise<{ committed: boolean; data: any; }> {
    return this.source.transaction(path, name, data);
  }
  createPushId(): string {
    return this.source.createPushId();
  }
  timestamp(): any {
    return this.source.timestamp();
  }

  uploadFiles(accountId: string, currentRoomId: string, currentSessionId: string, uploadModel: UploadModel, token: string): [Observable<any>, boolean] {
    return this.source.uploadFiles(accountId, currentRoomId, currentSessionId, uploadModel, token);
  }

  uploadRoomFiles(accountId: string, currentRoomId: string, uploadModel: UploadModel, token: string): [Observable<any>, boolean] {
    return this.source.uploadRoomFiles(accountId, currentRoomId, uploadModel, token);
  }

  uploadSnapshot(currentSessionPath: string, upFile: UploadFile, token: string): Promise<UploadFile> {
    return this.source.uploadSnapshot(currentSessionPath, upFile, token);
  }

  uploadTicketFiles(formData: any, ticketId: string, token: string){
    const url = `https://cdn.vsight.link/upload-ticket-files/${ticketId}?env=${environment.name}`
    return this.http.post(url, formData, {headers: {'authorization': `Bearer ${token}`}})
  }
}
