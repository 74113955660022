import { Directive, OnInit, AfterViewInit, OnChanges, OnDestroy, ElementRef, Renderer2, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
    selector: '[animatedGroupToggle]',
    exportAs: 'animatedGroupToggle'
})
export class AnimatedGroupToggleDirective implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @Input('animatedGroupToggle') id: string;
    @Input() expanded: boolean = false;
    @Input('groupToggleDisabled') groupToggleDisabled: boolean = false;

    @Output() expandedChange = new EventEmitter<boolean>();
    @Output() onExpanded = new EventEmitter<void>();
    @Output() onCollapsed = new EventEmitter<void>();

    @Input() active: boolean = false;
    @Output() activeChange = new EventEmitter<boolean>();
    @Output() onActivated = new EventEmitter<void>();
    @Output() onDeactivated = new EventEmitter<void>();

    @Input() activeGroup: string;

    private subscription: Subscription = null;

    constructor(
        private renderer: Renderer2,
        private el: ElementRef
    ) {}

    ngOnInit() {
        if (!this.id) { console.log("Please give id to AnimatedGroupToggle") }
    }

    ngAfterViewInit() {
        this.subscription = fromEvent(this.el.nativeElement, "click")
        .subscribe(() => {
            this.changeExpanded(!this.expanded);
            if (this.activeGroup && !this.active) {
                this.changeActive(true);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.expanded) {
            this.changeExpanded(changes.expanded.currentValue);
        }
        if (this.activeGroup && changes.active) {
            this.changeActive(changes.active.currentValue);
        }
        if (changes.groupToggleDisabled) {
            if (changes.groupToggleDisabled.currentValue) {
                if (this.expanded) {
                    this.changeExpanded(false);
                }
                if (this.active) {
                    this.changeActive(false);
                }
            }
        }
    }

    ngOnDestroy() {
        if (this.subscription) { this.subscription.unsubscribe() }
    }

    collapse() {
        if (this.expanded) {
            this.changeExpanded(false);
        }
    }

    deactivate() {
        if (this.activeGroup && this.active) {
            this.changeActive(false);
        }
    }

    private changeExpanded(exp: boolean) {
        if (this.groupToggleDisabled && exp) {
            return;
        }

        this.expanded = exp;
        if (exp) {
            this.onExpanded.emit();
            this.renderer.addClass(this.el.nativeElement.parentNode, "expand");
        } else {
            this.onCollapsed.emit();
            this.renderer.removeClass(this.el.nativeElement.parentNode, "expand");
        }
        this.expandedChange.emit(exp);
    }

    private changeActive(act: boolean) {
        if (this.groupToggleDisabled && act) {
            return;
        }

        this.active = act;
        if (act) {
            this.onActivated.emit();
            this.renderer.addClass(this.el.nativeElement, "active");
        } else {
            this.onDeactivated.emit();
            this.renderer.removeClass(this.el.nativeElement, "active");
        }
        this.activeChange.emit(act);
    }
}