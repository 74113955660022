import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';

import { ModalService } from '@services/support/modal.service';
import { TutorialService } from '@services/support/tutorial.service';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements OnInit, OnDestroy {

  @ViewChild("skipTutTemplate", { static: true }) private skipTutTemplate: TemplateRef<any>;

  tutorialOpen: boolean = false;
  initialTutorialModalOpen: boolean = false;
  isDontShowChecked: boolean = false;

  private tutorialSub: Subscription = null;

  constructor(
    private tutorialService: TutorialService,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    this.tutorialSub = this.tutorialService.tutorial.subscribe(t => {
      this.tutorialOpen = t ? true : false;
      this.initialTutorialModalOpen = this.tutorialService.initialTutorialModalOpen;
    });
  }

  ngOnDestroy() {
    if (this.tutorialSub) { this.tutorialSub.unsubscribe() }
  }

  skipTutorial() {
    this.tutorialService.currentController.skip();
  }

  continueTutorial() {
    this.tutorialService.continueAfterInitialTutorial();
  }

  endTutorial() {
    const modalId = this.modalService.show({
      template: this.skipTutTemplate,
      context: {
        dataModel: null,
        callbacks: {
          no: () => {
            this.modalService.hide(modalId);
          },
          yes: () => {
            this.tutorialService.currentController.end();
            this.modalService.hide(modalId);
          }
        }
      }
    });
  }
}
