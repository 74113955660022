import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { DbService } from './db.service';
import { RoomSessionService } from './room-session.service';

import { map, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {

  constructor(
    private authService: AuthService,
    private roomSessionService: RoomSessionService,
    private dbService: DbService
  ) { }

  getPlaneDetection(uid: string) {
    return this.dbService.listen<any>(this.roomSessionService.currentSessionPath + `/session_data/devices/${uid}/device_status/plane_detection`);
  }

  setPlaneDetectionTimestamp(uid: string) {
    return this.dbService.update(this.roomSessionService.currentSessionPath + `/session_data/devices/${uid}/device_status/plane_detection`, { available: true, last_refresh: this.dbService.timestamp() });
  }

  setPublisherStats(uid: string, stats: any) {
    return this.dbService.set(this.roomSessionService.currentSessionPath+'/connection_stats/'+uid, stats);
  }
  
  getSubscriberStats(uid: string) {
    return this.dbService.listen<any>(this.roomSessionService.currentSessionPath+'/connection_stats/'+uid); 
  }
  
  getSubscriberControls(userId: string) {
    return this.roomSessionService.sessionData.pipe(
      map(sessionData => sessionData.devices[userId]),
      distinctUntilChanged()
    )
  }

  getArPlusSelectNode(userId: string) {
    return this.dbService.listen<any>(this.roomSessionService.currentSessionPath+'/ar_plus/'+userId+'/operations/select/'+this.authService.currentUser.id);
  }

  getArPlusLockStatus(userId: string) {
    return this.dbService.listen<boolean>(this.roomSessionService.currentSessionPath+'/session_data/devices/'+userId+'/device_status/arkit_lock/locked');
  }

  setArPlusLockStatus(userId: string, lock: boolean) {
    const sessionRoot: any = {};
    sessionRoot[`session_data/devices/${userId}/device_status/arkit_lock/locked`] = lock;
    if (lock) {
      sessionRoot[`ar_plus/${userId}/operations`] = null;
    }

    return this.dbService.update(this.roomSessionService.currentSessionPath, sessionRoot);
  }

  setSelectPosition(targetId: string, leftNu: number, topNu: number, isFirst: boolean = false) {
    this.dbService.update(this.roomSessionService.currentSessionPath+'/ar_plus/'+targetId+'/operations/select/'+this.authService.currentUser.id, { left: leftNu, top: topNu, first: (isFirst ? true : null) });
  }

  /*unmarkSelectFirst(targetId: string) {
    this.dbService.remove(this.roomSessionService.currentSessionPath+'/ar_plus/'+targetId+'/operations/select/'+this.authService.currentUser.id+'/first');
  }*/

  scaleUp(userId: string, selectId: string){
    return this.dbService.transaction(this.roomSessionService.currentSessionPath+'/ar_plus/'+userId+'/operations/select/'+selectId, 'scaleUp');
  }

  scaleDown(userId: string, selectId: string){
    return this.dbService.transaction(this.roomSessionService.currentSessionPath+'/ar_plus/'+userId+'/operations/select/'+selectId, 'scaleDown');
  }

  deleteArPlusObject(userId: string, objectId: string) {
    return this.dbService.transaction(this.roomSessionService.currentSessionPath+'/ar_plus/'+userId+'/arrays/', 'deleteArPlus', objectId);
  }

  deselectARObject(userId: string) {
    this.dbService.remove(this.roomSessionService.currentSessionPath+'/ar_plus/'+userId+'/operations/select/'+this.authService.currentUser.id);
  }

  zoomIn(userId: string) {
    return this.dbService.transaction(this.roomSessionService.currentSessionPath+'/session_data/devices/'+userId+'/camera_controls/zoom', 'zoomIn');
  }

  zoomOut(userId: string) {
    return this.dbService.transaction(this.roomSessionService.currentSessionPath+'/session_data/devices/'+userId+'/camera_controls/zoom', 'zoomOut');
  }

  toggleFlash(userId: string) {
    return this.dbService.transaction(this.roomSessionService.currentSessionPath+'/session_data/devices/'+userId+'/camera_controls/flash', 'toggleFlash');
  }
}
