<div class="card">
    <div class="modal-header d-flex align-items-center justify-content-between">
        <h5 class="modal-title d-flex align-items-center"><i class="mi mi-style" style="font-size: 20px; padding-right: 4px;"></i>
            Workflows
        </h5>
    </div>

    <div class="card-body pe-4 ps-4 pb-0 filter-area">
      <div class="d-flex justify-content-end" style="padding-top: 2px;">
        <div class="search-box">
          <input type="text" [formControl]="filter" placeholder="Search" class="filter-input">
          <div class="search-icon"> <img src="assets/img/search.svg" alt=""></div>
        </div>
       
      </div>
    </div>

    <hr style="margin-bottom: 1px;">

    <div class="modal-body" style="padding: 0 !important;">
        <div>
            <table mat-table matSort matTableResponsive [dataSource]="dataSource">
    
              <ng-container matColumnDef="index">
                <th mat-header-cell *matHeaderCellDef> # </th>
                <td mat-cell data-column-name="#" *matCellDef="let i = index"> {{ i+1 }} </td>
              </ng-container>
          
              <ng-container matColumnDef="title">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
                <td mat-cell data-column-name="Title" *matCellDef="let element"> {{ element['listName'] }} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <ng-container *ngIf="!isLoading ">
                  <tr mat-row (click)="selectWorkflow(row)"  *matRowDef="let row; columns: displayedColumns;"></tr>
              </ng-container>
            </table>
        </div>
        <ng-container *ngIf="isLoading" [ngTemplateOutlet]="loadingTemplate"></ng-container>
    </div>
    <div class="modal-footer">
        <button class="btn btn-sm btn-dark px-4" style="font-size: 0.875rem;" type="button" (click)="modalService.hide()">
            {{ 'APP.MAIN.LOBBY.TICKETS.CLOSE_PICK_TICKET' | translate }}
        </button>
    </div>
</div>

<ng-template #loadingTemplate>
    <div class="loading-template">
      <div class="d-flex justify-content-center">
        <div class="loader loader-sm la-dark">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </ng-template>