<div class="flash-message alert" [class.show-close-btn]="message.showCloseBtn"  [class.close-on-click]="message.closeOnClick"
        [ngClass]="message.cssClass" (click)="message.closeOnClick ? message.close(message) : null;"
        *ngFor="let message of flashMessageService.messages | async as messages; index as i;" [@flash]="'in'">
    <div class="flash-message-content">
        <div class="flash-message-text" *ngIf="!message.insertHtml">{{ message.text }}</div>
        <div class="flash-message-text" *ngIf="message.insertHtml" [innerHTML]="message.text"></div>

        <div class="flash-message-buttons" *ngIf="message.buttons">
            <div class="buttons-container">
                <button class="btn btn-sm rounded-0" *ngFor="let button of message.buttons" [ngClass]="button.cssClass" (click)="button.callback();">{{button.title}}</button>
            </div>
        </div>
    </div>
    <a class="close-btn" (click)="$event.stopPropagation(); message.close(message);"><i class="mi mi-close"></i></a>
</div>
