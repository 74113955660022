import { Component, OnInit } from '@angular/core';

import { RoomSessionService } from '@services/core/room-session.service';
import { FlashMessageService } from '@services/support/flash-message.service';

import { Subscription } from 'rxjs';

import { Room } from '@models/Room';
import { AuthService } from '@services/core/auth.service';
import { CallService } from '@services/core/call.service';

@Component({
  selector: 'app-participants',
  templateUrl: './participants.component.html',
  styleUrls: ['./participants.component.scss']
})
export class ParticipantsComponent implements OnInit {

  isMaster: boolean = true;
  sessionActive: boolean = false;

  roomUsers: any[] = [];
  private roomUsersObj: any = {};
  private roomSub: Subscription = null;

  runningTransactions: any = {};
  runningLowerHands: boolean = false;

  outgoingCall: any = null;
  callSub: Subscription = null;
  callingMessageId: number = null;

  constructor(
    private authService: AuthService,
    private callService: CallService,
    private roomSessionService: RoomSessionService,
    private flashMessageService: FlashMessageService
  ) { }

  ngOnInit() {
    this.roomSub = this.roomSessionService.currentRoom
    .subscribe(room => {
      this.roomUsers = room ? room.room_data.users.sort((a, b) => a.status === "master" ? -1 : (b.status === "master" ? 1 : 0)) : [];
      this.detectStatusChanges(room);
    });

    this.callSub = this.callService.getOutgoingCall()
    .subscribe(outgoing => {
      this.outgoingCall = outgoing;

      if (!this.callingMessageId && outgoing && outgoing.status !== "call-ended") {
        const participants = outgoing.calling ? Object.keys(outgoing.calling).map(uid => outgoing.calling[uid].name) : [];
        let participantNames = participants.length > 0 ? participants[0] : "";
        for (let i = 1; i < participants.length; i++) {
          participantNames = participantNames+`, ${participants[i]}`;
        }
        this.flashMessageService.showTranslatedWithData('APP.MAIN.ROOM.SIDEBAR.PARTICIPANTS.CALLING', { participants: participants }, { cssClass: "alert-success", noTimeout: true, closeOnClick: true })
        .then(id => { this.callingMessageId = id; });
      }
      if (this.callingMessageId && (!outgoing || (outgoing && outgoing.status === "call-ended"))) {
        this.flashMessageService.hide(this.callingMessageId);
        this.callingMessageId = null;
      }
    });
  }

  detectStatusChanges(room: Room) {
    if (room) {
      const current = room.room_data.users.find(u => u.user_id === this.authService.currentUser.id);
      this.isMaster = current ? current.status === "master" : false;
      this.sessionActive = room.room_data.session.active;

      if (this.isMaster) {
        room.room_data.users.forEach(roomUser => {
          if (this.roomUsersObj[roomUser.user_id]) {
            if (this.roomUsersObj[roomUser.user_id] !== roomUser.status) {
              this.userStatusChanged(roomUser);
            }
          } else {
            this.userStatusChanged(roomUser);
          }
          this.roomUsersObj[roomUser.user_id] = roomUser.status;
        });
      }
    } else {
      this.sessionActive = false;
      this.roomUsersObj = {};
    }
  }

  userStatusChanged(roomUser: any) {
    if (roomUser.status === "hand-raised") {
      this.flashMessageService.showTranslatedWithData('APP.MAIN.ROOM.SIDEBAR.PARTICIPANTS.RAISED_HAND', {name: roomUser.name}, { cssClass: "alert-info", timeout: 6000 });
    } else if (roomUser.status === "publish-denied") {
      this.flashMessageService.showTranslatedWithData('APP.MAIN.ROOM.SIDEBAR.PARTICIPANTS.DENIED_PUBLISH', {name: roomUser.name}, { timeout: 6000 });
    }
  }

  ngOnDestroy() {
    if (this.roomSub) { this.roomSub.unsubscribe(); }
    if (this.callSub) { this.callSub.unsubscribe(); }
    if (this.callingMessageId) { this.flashMessageService.hide(this.callingMessageId); }
  }

  onToggleParticipant(roomUser: any) {
    if (roomUser.status === "waiting" || roomUser.status === "hand-raised") {
      this.runningTransactions[roomUser.user_id] = true;
      this.roomSessionService.enablePublish(roomUser.user_id)
      .catch(error => {
        if (error.error === "publish-limit-reached") {
          this.flashMessageService.showTranslated('APP.MAIN.ROOM.SIDEBAR.PARTICIPANTS.PARTICIPANT_LIMIT_REACHED', { timeout: 6000 });
        }
      })
      .finally(() => { this.runningTransactions[roomUser.user_id] = false; });
    } else if (roomUser.status === "publishing" || roomUser.status === "try-to-publish") {
      this.runningTransactions[roomUser.user_id] = true;
      this.roomSessionService.disablePublish(roomUser.user_id)
      .finally(() => { this.runningTransactions[roomUser.user_id] = false; });
    }
  }

  onLowerHand(roomUser: any) {
    this.runningTransactions[roomUser.user_id] = true;
    this.roomSessionService.lowerSubscriberHand(roomUser.user_id)
    .finally(() => { this.runningTransactions[roomUser.user_id] = false; });
  }

  lowerAllHands() {
    this.runningLowerHands = true;
    this.roomSessionService.lowerAllHands()
    .finally(() => { this.runningLowerHands = false; });
  }
}
