import { Injectable, ElementRef } from '@angular/core';

import * as bowser from 'bowser';
import * as OT from '@opentok/client';
import { environment } from 'environments/environment';

import { AuthService } from './auth.service';
import { CallService } from './call.service';
import { RoomSessionService } from './room-session.service';
import { DbService } from './db.service';

import { JoinRoomResponse } from '@models/JoinRoomResponse';
import { SoundMeter } from '@models/SoundMeter';

import { HttpClient } from '@angular/common/http';

import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { Room } from '@models/Room';

interface OpentokCredentialsResponse {
  api_key: string,
  session_id: string,
  token: string
}

@Injectable({
  providedIn: 'root'
})
export class OpentokService {

  cameraMirror: Observable<boolean>;
  private cameraMirrorSource = new BehaviorSubject<boolean>(false);

  sessionStarted: boolean = false;

  currentSession: OT.Session;
  currentCameraPublisher: OT.Publisher;
  currentScreenPublisher: OT.Publisher;

  selectedAudioDevice: Observable<string>;
  private selectedAudioDeviceSource = new BehaviorSubject<string>(null);
  selectedVideoDevice: Observable<string>;
  private selectedVideoDeviceSource = new BehaviorSubject<string>(null);

  selectedPublishResolution: any = "640x480";

  publishAudio: Observable<boolean>;
  private publishAudioSource = new BehaviorSubject<boolean>(true);
  publishVideo: Observable<boolean>;
  private publishVideoSource = new BehaviorSubject<boolean>(true);

  private subscriberStatusList: { [uid:string]: {audio: boolean, video: boolean} } = {};

  cameraPublishRequest: Observable<void>;
  cameraPublishRequestSource = new BehaviorSubject<void>(null);

  screenPublishState: Observable<boolean>;
  screenPublishStateSource = new BehaviorSubject<boolean>(null);

  private connectObservable: Observable<OT.Session>;
  private connectSubscribtion: Subscription = null;

  devices: any[] = [];

  private listeners: {
    "sessionStarted": any[],
    "sessionEnded": any[],
    "streamCreated": any[],
    "streamDestroyed": any[],
    "sessionArchiving": any[]
  };

  constructor(
    private authService: AuthService,
    private callService: CallService,
    private roomSessionService: RoomSessionService,
    private http: HttpClient,
    private dbService: DbService
  ) {
    this.publishAudio = this.publishAudioSource.asObservable();
    this.publishVideo = this.publishVideoSource.asObservable();
    this.selectedAudioDevice = this.selectedAudioDeviceSource.asObservable();
    this.selectedVideoDevice = this.selectedVideoDeviceSource.asObservable();
    this.cameraMirror = this.cameraMirrorSource.asObservable();
    this.cameraPublishRequest = this.cameraPublishRequestSource.asObservable();
    this.screenPublishState = this.screenPublishStateSource.asObservable();

    this.listeners = {
      "sessionStarted": [],
      "sessionEnded": [],
      "streamCreated": [],
      "streamDestroyed": [],
      "sessionArchiving": []
    };

    this.roomSessionService.on("sessionCreated", this.onRoomSessionCreated);
    this.roomSessionService.on("sessionStarted", this.onRoomSessionStarted);
    this.roomSessionService.on("sessionEnded", this.onRoomSessionEnded);
    this.roomSessionService.on("sessionDestroyed", this.onRoomSessionDestroyed);
    const browser = bowser.getParser(window.navigator.userAgent);
    const isTouchDevice = 'ontouchstart' in window;

    if (browser.is("mobile") || browser.is("tablet") || (browser.is('macOS') && isTouchDevice)) {
      this.selectedPublishResolution = "1280x720"
    }


    // OT.NONE will hide verbose logs in opentok
    // OT.NONE not found in OPENTOK type definitions,
    // but it exists, asked typescript to ignore it
    if (environment.production) {
      // @ts-ignore
      OT.setLogLevel(OT.NONE);
    }

    this.roomSessionService.roomUserStatus.subscribe(status => {
      if (status && status.training_room) {
        if (status.user_status === "publishing") {
          if (status.session_active) {
            this.screenPublishStateSource.next(false);
          }
        } else if (status.user_status !== "master") {
          this.screenPublishStateSource.next(null);
        }
      }
    });
  }

  on( event: "sessionStarted" | "sessionEnded" | "streamCreated" | "streamDestroyed" | "sessionArchiving", callback ) {
    this.listeners[event].push(callback);
  }

  off( event: "sessionStarted" | "sessionEnded" | "streamCreated" | "streamDestroyed" | "sessionArchiving", callback? ) {
    if (callback == undefined) {
      this.listeners[event] = [];
    } else {
      const index = this.listeners[event].indexOf(callback);
      this.listeners[event].splice(index, 1);
    }
  }

  private onRoomSessionCreated = (joinResponse: JoinRoomResponse, sessionPath: string) => {
    this.subscriberStatusList = {};
    const source = new BehaviorSubject<OT.Session>(null);
    this.connectObservable = source.asObservable();

    this.currentSession = OT.initSession(joinResponse.ot_api_key, joinResponse.ot_session);

    this.currentSession.on("streamCreated", this.onOpentokStreamCreated);
    this.currentSession.on("streamDestroyed", this.onOpentokStreamDestroyed);
    this.currentSession.on("archiveStarted", this.onOpentokArchiveStarted);
    this.currentSession.on("archiveStopped", this.onOpentokArchiveStopped);

    this.currentSession.connect(joinResponse.ot_token, error => {
      if (error) {
        this.currentSession.off("streamCreated", this.onOpentokStreamCreated);
        this.currentSession.off("streamDestroyed", this.onOpentokStreamDestroyed);
        this.currentSession.off("archiveStarted", this.onOpentokArchiveStarted);
        this.currentSession.off("archiveStopped", this.onOpentokArchiveStopped);

        source.error(error);
        this.callService.otSessionConnectFailed(error.name, (<any>error).code);
        this.saveOtError("session", error);
      } else {
        source.next(this.currentSession);
      }
    });
  }

  private onRoomSessionStarted = (room: Room, roomUserInfo: any) => {
    this.connectSubscribtion = this.connectObservable.pipe( filter(session => session != null), first() )
    .subscribe(opentokSession => {
      this.sessionStarted = true;
      this.listeners.sessionStarted.forEach(callback => {
        callback(opentokSession);
      });

      if (!room.room_data.training_room || (room.room_data.training_room && roomUserInfo.status === "master")) {
        this.screenPublishStateSource.next(false);
      }
    });
  }

  private onRoomSessionEnded = (endedBeforeStart: boolean, willDestroy: boolean) => {

    if (this.connectSubscribtion) { this.connectSubscribtion.unsubscribe() }
    if (this.sessionStarted) {
      this.sessionStarted = false;

      if (this.currentCameraPublisher) {
        this.currentCameraPublisher.destroy();
        this.currentCameraPublisher = null;
      }
      if (this.currentScreenPublisher) {
        this.currentScreenPublisher.destroy();
        this.currentScreenPublisher = null;
      }
      this.screenPublishStateSource.next(null);

      this.listeners.sessionEnded.forEach(callback => {
        callback();
      });
    }

    if (willDestroy) {

      this.currentSession.off("streamCreated", this.onOpentokStreamCreated);
      this.currentSession.off("streamDestroyed", this.onOpentokStreamDestroyed);
      this.currentSession.off("archiveStarted", this.onOpentokArchiveStarted);
      this.currentSession.off("archiveStopped", this.onOpentokArchiveStopped);

      if (this.currentCameraPublisher) {
        this.currentCameraPublisher.destroy();
        this.currentCameraPublisher = null;
      }
    }
  }

  private onRoomSessionDestroyed = () => {
    this.currentSession.disconnect();
    this.currentSession = null;
  }

  onOpentokStreamCreated = event => {
    this.listeners.streamCreated.forEach(callback => {
      callback(event.stream);
    });
  }

  onOpentokStreamDestroyed = event => {
    this.listeners.streamDestroyed.forEach(callback => {
      callback(event.stream);
    });
  }

  onOpentokArchiveStarted = event => {
    this.listeners.sessionArchiving.forEach(callback => {
      callback(true, event.archive);
    });
  }

  onOpentokArchiveStopped = event => {
    this.listeners.sessionArchiving.forEach(callback => {
      callback(false, event.archive);
    });
  }

  startArchive() {
    return this.http.post<any>(this.dbService.getEndPoint("togglearchive"), {
      action: "start-archive",
      token: this.authService.currentUser.token,
      room: this.callService.currentRoomId,
      vs_session: this.roomSessionService.currentSessionId,
      ot_session: this.currentSession.sessionId }).toPromise();
  }

  stopArchive(archiveId: string) {
    return this.http.post<any>(this.dbService.getEndPoint("togglearchive"), {
      action: "stop-archive",
      token: this.authService.currentUser.token,
      room: this.callService.currentRoomId,
      vs_session: this.roomSessionService.currentSessionId,
      archive: archiveId }).toPromise();
  }

  allowArchive() {
    return this.http.post<any>(this.dbService.getEndPoint("togglearchive"), {
      action: "allow-archive",
      token: this.authService.currentUser.token,
      room: this.callService.currentRoomId
    }).toPromise();
  }

  denyArchive() {
    return this.http.post<any>(this.dbService.getEndPoint("togglearchive"), {
      action: "deny-archive",
      token: this.authService.currentUser.token,
      room: this.callService.currentRoomId
    }).toPromise();
  }

  cancelArchiveRequest() {
    return this.http.post<any>(this.dbService.getEndPoint("togglearchive"), {
      action: "cancel-archive-request",
      token: this.authService.currentUser.token,
      room: this.callService.currentRoomId
    }).toPromise();
  }

  getTestCredentials() {
    return this.http.post<any>(this.dbService.getEndPoint("gettestcredentials"), { token: this.authService.currentUser.token }).toPromise();
  }

  async startPreview(dataModel: any, videoRef: ElementRef) {
    this.selectedPublishResolution = "640x480";
    this.publishAudioSource.next(dataModel.audioEnabled);
    this.publishVideoSource.next(dataModel.videoEnabled);
    this.cameraMirrorSource.next(true);

    try {
      const properties: any = {};
      const browser = bowser.getParser(window.navigator.userAgent);
      const isTouchDevice = 'ontouchstart' in window;
      if (browser.is("mobile") || browser.is("tablet") || (browser.is('macOS') && isTouchDevice)) {
        this.roomSessionService.currentRoom.subscribe(room => {
          if(room?.room_data?.full_hd) {
            this.selectedPublishResolution = "1920x1080"
          }else{
            this.selectedPublishResolution = "1280x720"
          }
        })
        properties.facingMode = "environment";
        this.cameraMirrorSource.next(false);
      }
      const timeout = setTimeout(() => { dataModel.status = "permission-required" }, 500);
      const video: HTMLVideoElement = videoRef.nativeElement;

      this.devices = await (new Promise<OT.Device[]>((resolve, reject) => {
        OT.getDevices((error, devices) => {
          if (error) {
            reject(error);
          } else {
            resolve(devices);
          }
        });
      }));

      if (this.devices.length !== 0) {
        dataModel.currentStream = (await OT.getUserMedia(properties)
        .then(stream => {
          clearTimeout(timeout);
          dataModel.enableJoin = true;
          dataModel.enableCancel = true;
          video.muted = true;
          video.srcObject = stream;
          dataModel.status = "running";
          return stream;
        })
        .catch(error => {
          clearTimeout(timeout);
          dataModel.enableJoin = this.authService.isAddOnAvailable("cammicbypass");
          dataModel.enableCancel = true;
          if (error.name === "OT_USER_MEDIA_ACCESS_DENIED") {
            dataModel.status = "permission-denied";
          } else if (error.name === "OT_HARDWARE_UNAVAILABLE") {
            dataModel.status = "cant-access-devices";
          } else {
            dataModel.status = "cant-access-devices";
          }
          return null;
        }));
      } else {
        clearTimeout(timeout);
        dataModel.enableJoin = true;
        dataModel.enableCancel = true;
        dataModel.status = "no-devices-found";
      }
      if (dataModel.currentStream) {
        this.startPreviewSoundMeter(dataModel);

/*         const devices = await (new Promise<OT.Device[]>((resolve, reject) => {
          OT.getDevices((error, devices) => {
            if (error) {
              reject(error);
            } else {
              resolve(devices);
            }
          });
        })); */
        
        /*
        const devices = await navigator.mediaDevices.enumerateDevices()
        .then(devices => devices.map(d => {
          return {
            deviceId: d.deviceId,
            label: d.label,
            kind: (d.kind === "audioinput" ? "audioInput" : (d.kind === "videoinput" ? "videoInput" : "audioOutput"))
          }
        }));

        const stream = await OT.getUserMedia().catch(error => console.error(error));
        if (stream) {
          const [audioSource] = stream.getAudioTracks();
          const [videoSource] = stream.getVideoTracks();
          let vSource = [videoSource]
          let vSourceOT : OT.Device = {
            deviceId: vSource[0].id,
            label: vSource[0].label,
            kind: "videoInput"
          }
          dataModel.videos = [vSourceOT];
        }

        let streams = null;
        try {
          streams = await navigator.mediaDevices.getUserMedia({audio: false, video: true});
          await navigator.mediaDevices.getUserMedia({audio: false, video: true})
          .then(function(stream) {
            var videoTracks = stream.getVideoTracks();
            console.log("video tracks: ", videoTracks);
          })
          .catch(error => console.error(error))
        } catch(err) {}
        */

        dataModel.audios = this.devices.filter(d => d.kind === "audioInput");
        dataModel.videos = this.devices.filter(d => d.kind === "videoInput");

        const audioTracks: MediaStreamTrack[] = dataModel.currentStream.getAudioTracks();
        const videoTracks: MediaStreamTrack[] = dataModel.currentStream.getVideoTracks();
        
        if (audioTracks.length > 0) {
          dataModel.selectedAudio = audioTracks[0].getSettings().deviceId;
          this.selectedAudioDeviceSource.next(dataModel.selectedAudio);
        }
        if (videoTracks.length > 0) {
          dataModel.selectedVideo = videoTracks[0].getSettings().deviceId;
          this.selectedVideoDeviceSource.next(dataModel.selectedVideo);
        }
      }
    } catch (error) {}
  }

  getAvailableDevices() {
    return this.devices;
  }

  async changePreviewSource(type: "audio"|"video", dataModel: any, videoRef: ElementRef) {
    if (type === "video") {
      this.cameraMirrorSource.next(!this.cameraMirrorSource.value);
    }

    this.stopPreviewSoundMeter(dataModel);
    if (dataModel.currentStream) {
      dataModel.currentStream.getTracks().forEach(t => t.stop())
    }

    const video: HTMLVideoElement = videoRef.nativeElement;
    const props: any = {};
    if (dataModel.selectedAudio) {
      props.audioSource = dataModel.selectedAudio;
      this.selectedAudioDeviceSource.next(dataModel.selectedAudio);
    }
    if (dataModel.selectedVideo) {
      props.videoSource = dataModel.selectedVideo;
      this.selectedVideoDeviceSource.next(dataModel.selectedVideo);
    }
    dataModel.currentStream = await OT.getUserMedia(props);
    video.srcObject = dataModel.currentStream;

    if (dataModel.audioEnabled) {
      this.startPreviewSoundMeter(dataModel);
    }
  }

  changeAudioDevice(deviceId: string) {
    if (this.roomSessionService.isSessionActive()) {
      const isScreenSharingEnabled = this.screenPublishStateSource.value;
      let publisher: OT.Publisher;

      if (isScreenSharingEnabled && this.currentScreenPublisher) {
        publisher = this.currentScreenPublisher;
      } else if (this.currentCameraPublisher) {
        publisher = this.currentCameraPublisher;
      }

      const oldDeviceId = this.selectedAudioDeviceSource.value;
      if (!publisher) {
        this.selectedAudioDeviceSource.next(oldDeviceId);
        return;
      }
      publisher.setAudioSource(deviceId)
      .then(() => this.selectedAudioDeviceSource.next(deviceId))
      .catch(error => this.selectedAudioDeviceSource.next(oldDeviceId));
    } else {
      this.selectedAudioDeviceSource.next(deviceId);
    }
  }

  changeVideoDevice(deviceId: string) {
    if (this.roomSessionService.isSessionActive()) {
      const isScreenSharingEnabled = this.screenPublishStateSource.value;
      if (isScreenSharingEnabled && !this.currentCameraPublisher) {
        return;
      }

      const oldDeviceId = this.selectedVideoDeviceSource.value;
      this.currentCameraPublisher.setVideoSource(deviceId)
      .then(() => this.selectedVideoDeviceSource.next(deviceId))
      .catch(error => {this.selectedVideoDeviceSource.next(oldDeviceId)});
    } else {
      this.selectedVideoDeviceSource.next(deviceId);
    }
  }

  togglePreviewAudio(dataModel: any) {
    dataModel.audioEnabled = !dataModel.audioEnabled;
    this.publishAudioSource.next(dataModel.audioEnabled);
  }

  togglePreviewVideo(dataModel: any) {
    dataModel.videoEnabled = !dataModel.videoEnabled;
    this.publishVideoSource.next(dataModel.videoEnabled);
  }

  startPreviewSoundMeter(dataModel: any) {
    if (dataModel.currentStream && dataModel.audioContext) {
      const smeter = new SoundMeter(dataModel.audioContext);
      if (this.devices.filter(d => d.kind === "audioInput").length !== 0){
        dataModel.audioLevelSub = smeter.connectToSource(dataModel.currentStream)
        .subscribe(level => { dataModel.audioLevel = Math.round(level * 200) }, error => console.log(error));
      }
    }
  }

  stopPreviewSoundMeter(dataModel: any) {
    if (dataModel.audioLevelSub) {
      dataModel.audioLevel = 0;
      dataModel.audioLevelSub.unsubscribe();
      dataModel.audioLevelSub = null;
    }
  }

  destroyPreview(dataModel: any, videoRef: ElementRef) {
    if (videoRef) {
      const video: HTMLVideoElement = videoRef.nativeElement;
      video.pause();
      video.src = "";
    }
    this.stopPreviewSoundMeter(dataModel);
    if (dataModel.currentStream) { dataModel.currentStream.getTracks().forEach(t => t.stop()) }
  }

  destroyCurrentPublisherIfExists() {
    if (this.currentCameraPublisher) {
      this.currentCameraPublisher.destroy();
      this.currentCameraPublisher = null;
    }
    if (this.currentScreenPublisher) {
      this.currentScreenPublisher.destroy();
      this.currentScreenPublisher = null;
    }
  }

  checkSystemRequirements(): boolean {
    return !!OT.checkSystemRequirements();
  }

  changeCameraMirror(mirror: boolean) {
    if (mirror !== this.cameraMirrorSource.value) {
      if (this.currentCameraPublisher) {
        this.currentCameraPublisher.destroy();
        this.currentCameraPublisher = null;
        this.cameraPublishRequestSource.next(null);
      }
      this.cameraMirrorSource.next(mirror);
    }
  }

  async startCameraPublish(container: ElementRef) {
    if (this.devices.length !== 0) {
      const audio = await this.publishAudio.pipe(first()).toPromise();
      const video = await this.publishVideo.pipe(first()).toPromise();

      const selectedAudioDeviceId = this.selectedAudioDeviceSource.value;
      const selectedVideoDeviceId = this.selectedVideoDeviceSource.value;

      let pProperties: OT.PublisherProperties = {
        showControls: false,
        publishAudio: audio,
        publishVideo: video,
        width: '100%',
        height: '100%',
        fitMode: "contain",
        insertMode: "append",
        resolution: this.selectedPublishResolution,
        mirror: this.cameraMirrorSource.value
      };

      if (selectedAudioDeviceId && selectedVideoDeviceId) {
        /*
          // try to get media with selected devices
          const stream = await OT.getUserMedia({
            audioSource: selectedAudioDeviceId,
            videoSource: selectedVideoDeviceId
          }).catch(error => null);
          // if succeeded, assign that sources to publisher
          if (stream) {
            const [audioSource] = stream.getAudioTracks();
            const [videoSource] = stream.getVideoTracks();
            pProperties.audioSource = audioSource;
            pProperties.videoSource = videoSource;
          }
        */
        pProperties.audioSource = selectedAudioDeviceId;
        pProperties.videoSource = selectedVideoDeviceId;
      } else {
        const browser = bowser.getParser(window.navigator.userAgent);
        const isTouchDevice = 'ontouchstart' in window;
        if (browser.is("mobile") || browser.is("tablet") || (browser.is('macOS') && isTouchDevice)) {
          pProperties.facingMode = "environment";
          pProperties.mirror = false;
          this.cameraMirrorSource.next(false);
        } else {
          pProperties.mirror = true;
          this.cameraMirrorSource.next(true);
        }
      }
      return new Promise<OT.Publisher>((resolve, reject) => {
        this.currentCameraPublisher = OT.initPublisher(container.nativeElement, pProperties);
        const e = <HTMLElement>this.currentCameraPublisher.element.getElementsByClassName('OT_widget-container')[0];
        if (e) { e.style.backgroundColor="rgb(141, 141, 141)" }
  
        this.currentSession.publish(this.currentCameraPublisher, error => {
          if (error) {
            this.currentCameraPublisher = null;
            reject(error);
          } else {
            resolve(this.currentCameraPublisher);
            this.publishAudio.pipe(first()).toPromise().then(a =>  this.currentCameraPublisher.publishAudio(a) );
            this.publishVideo.pipe(first()).toPromise().then(v => this.currentCameraPublisher.publishVideo(v) );
          }
        });
      });
    }
  }

  checkScreenSharingCapability(): Promise<OT.ScreenSharingCapabilityResponse> {
    return new Promise<OT.ScreenSharingCapabilityResponse>((resolve, reject) => {
      OT.checkScreenSharingCapability(response => {
        resolve(response);
      });
    });
  }

  async startScreenSharing() {
    const audio = await this.publishAudio.pipe(first()).toPromise();
    const selectedAudioDeviceId = this.selectedAudioDeviceSource.value;

    const pProperties: any = {
      videoSource : 'screen',
      publishAudio: audio,
      insertDefaultUI: false,
      maxResolution: { width: 1280, height: 720 }
    };
  
    let mProperties: OT.PublisherProperties = { videoSource: null };
    if (selectedAudioDeviceId) {
      mProperties.audioSource = selectedAudioDeviceId;
    }
    // try to get media with selected devices
    if (this.devices.length !== 0) {
      const stream = await OT.getUserMedia(mProperties).catch(error => null);
      if (stream) {
        const [audioSource] = stream.getAudioTracks();
        pProperties.audioSource = audioSource;
      }
    }
    // if succeeded, assign that sources to publisher
    return new Promise<any>((resolve, reject) => {
      const screenPublisher = OT.initPublisher('screen-publisher', pProperties,
      error => {
        if (error) {
          reject(error);
        } else {
          this.currentScreenPublisher = screenPublisher;
          this.currentSession.publish(screenPublisher, error => {
            if (error) {
              this.currentScreenPublisher = null;
              reject(error);
            } else {
              this.screenPublishStateSource.next(true);
              
              const screenPublisherDestroyed = event => {
                screenPublisher.off("destroyed", screenPublisherDestroyed);
                this.roomSessionService.roomUserStatus.pipe(first()).toPromise()
                .then(s => {
                  this.screenPublishStateSource.next(false);
                  if (s.training_room && (!s.session_active || !(s.user_status === "publishing" || s.user_status === "master"))) {
                    this.screenPublishStateSource.next(null);
                  }
                });
              }
              screenPublisher.on("destroyed", screenPublisherDestroyed);

              const screenPublisherMediaStopped = event => {
                screenPublisher.off("mediaStopped", screenPublisherMediaStopped);
                this.endScreenSharing();
              }
              screenPublisher.on("mediaStopped", screenPublisherMediaStopped);

              if (this.currentCameraPublisher) {
                this.currentCameraPublisher.destroy();
                this.currentCameraPublisher = null;
              }
              resolve(screenPublisher);
              this.publishAudio.pipe(first()).toPromise().then(a =>  this.currentScreenPublisher.publishAudio(a) );
            }
          });
        }
      });
    });
  }

  endScreenSharing() {
    if (this.currentScreenPublisher) {
      this.currentScreenPublisher.destroy();
      this.currentScreenPublisher = null;
      this.cameraPublishRequestSource.next(null);
    }
  }

  startSubscribe(container: ElementRef, stream: OT.Stream, subscribeToAudio: boolean, subscribeToVideo: boolean) {
    return new Promise<OT.Subscriber>((resolve, reject) => {
      const subscriber = this.currentSession.subscribe(stream, container.nativeElement,
        { showControls: true,
          style: {
            audioLevelDisplayMode: 'off',
            audioBlockedDisplayMode: 'off',
            buttonDisplayMode: 'off',
            nameDisplayMode: 'off',
            videoDisabledDisplayMode: 'on'
          },
          subscribeToVideo: subscribeToVideo,
          subscribeToAudio: subscribeToAudio,
          width: '100%',
          height: '100%',
          insertMode: 'append',
          fitMode: "contain"
        },
        error => {
          if (error) {
            reject(error);
          } else {
            resolve(subscriber);
          }
        }
      );
      const e = <HTMLElement>subscriber.element.getElementsByClassName('OT_widget-container')[0];
      if (e) { e.style.backgroundColor="rgb(141, 141, 141)" }
    });
  }

  saveOtError(type: "session" | "publisher" | "subscriber", error: any) {
    const browser = bowser.getParser(window.navigator.userAgent);
    return this.dbService.push(`ot_errors/${type}`, {
      account_name: this.authService.currentUser.auth.account_name,
      username: this.authService.currentUser.auth.username,
      name: error.name,
      OSName: browser.getOSName(),
      OSVersion: browser.getOSVersion(),
      browserName: browser.getBrowserName(),
      browserVersion: browser.getBrowserVersion()
    }).catch(error => {});
  }

  setSubscriberAudioVideoStatus(uid: string, audio: boolean, video: boolean) {
    this.subscriberStatusList[uid] = { audio: audio, video: video };
  }

  getSubscriberAudioVideoStatus(uid: string) {
    return this.subscriberStatusList[uid];
  }

  async toggleAudio() {
    const pState = await this.screenPublishState.pipe(first()).toPromise();
    const a = await this.publishAudio.pipe(first()).toPromise();

    if (pState) {
      if (this.currentScreenPublisher) { this.currentScreenPublisher.publishAudio(!a) }
    } else {
      if (this.currentCameraPublisher) { this.currentCameraPublisher.publishAudio(!a) }
    }
    this.publishAudioSource.next(!a);
  }

  async toggleVideo() {
    const v = await this.publishVideo.pipe(first()).toPromise();
    if (this.currentCameraPublisher) {
      this.currentCameraPublisher.publishVideo(!v);
    }
    this.publishVideoSource.next(!v);
  }
}