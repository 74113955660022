import { DBSource } from './DBSource';

import { environment } from 'environments/environment';

import * as io from 'socket.io-client';
import { v4 as uuidV4 } from 'uuid';

import { Observable, BehaviorSubject, of } from 'rxjs';
import { share, first, filter, switchMap, map } from 'rxjs/operators';
import { User } from '@models/User';
import { HttpClient } from '@angular/common/http';
import { UploadFile } from './UploadFile';
import { UploadModel } from './UploadModel';
import { AuthUser } from './AuthUser';

export class SocketioSource implements DBSource {

    readonly type: "firebase" | "socket" = "socket";

    private socket: SocketIOClient.Socket;
    private listeners: {
        [key: string]: {
            path: string,
            callback: any,
            snap: boolean,
            event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved"
        }
    } = {};

    isConnected: Observable<boolean>
    private isConnectedSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private trackDisconnection: boolean = false;

    isAuthenticated: Observable<boolean>;
    private isAuthenticatedSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    authState: Observable<AuthUser>;
    private authStateSource: BehaviorSubject<AuthUser> = new BehaviorSubject<AuthUser>(null);

    currentUser: Observable<User>;
    //private currentUserSource: BehaviorSubject<User> = new BehaviorSubject<User>(null);

    manageUserStatus: boolean = true;
    private tryReconnect: boolean = false;

    private http: HttpClient;

    constructor(http: HttpClient) {
        this.http = http;
        this.isAuthenticated = this.isAuthenticatedSource.asObservable().pipe(filter(a => a !== null));
        this.authState = this.authStateSource.asObservable();
        //this.currentUser = this.currentUserSource.asObservable();
        this.isConnected = this.isConnectedSource.asObservable().pipe(filter(c => c !== null));

        this.currentUser = this.authStateSource.pipe(
            switchMap(user => {
                if (user) {
                    return this.listen<User>(`accounts/${user.account_id}/users/${user.uid}`)
                    .pipe(map(u => {
                        u.id = user.uid;
                        u.token = user.token;
                        return u;
                    }));
                } else {
                    return of(null);
                }
            })
        );

        const socketSessionString = sessionStorage.getItem("socketSession");
        if (socketSessionString) {
            const socketSession: {token:string, manage:boolean, reconnect:boolean} = JSON.parse(socketSessionString);
            this.loginWithIdToken(socketSession.token, socketSession.manage, socketSession.reconnect);
        } else {
            this.isAuthenticatedSource.next(false);
        }
    }

    authecticated(): boolean {
        return this.isAuthenticatedSource.value ? true : false;
    }

    connected(): boolean {
        return this.isConnectedSource.value ? true : false;
    }

    getEndPoint(name: string): string {
        return environment.endPoints[name];
    }

    async login(customToken: string, manageUserStatus: boolean, reconnect: boolean): Promise<User> {
        const idToken = await (this.http.post<any>(environment.cnEndPoints.getidtoken, { token: customToken }).toPromise().then(result => result.id_token));
        sessionStorage.setItem("socketSession", JSON.stringify({token: idToken, manage: manageUserStatus, reconnect: reconnect}));
        sessionStorage.setItem("sourceType", "socket");
        return this.loginWithIdToken(idToken, manageUserStatus, reconnect);
    }

    private loginWithIdToken(idToken: string, manageUserStatus: boolean, reconnect: boolean): Promise<User> {
        this.trackDisconnection = true;
        this.manageUserStatus = manageUserStatus;
        this.tryReconnect = reconnect;

        let options: SocketIOClient.ConnectOpts = { secure: true, query: `manage=${manageUserStatus}&token=${idToken}`, autoConnect: true };
        options.reconnection = reconnect;
        if (reconnect) {
            options.reconnectionAttempts = 5;
        }
        this.socket = io(environment.cnEndPoints.socketserver, options);

        this.socket.on('on2', (uuid: string, err: any, data: any) => {
            if (this.listeners[uuid]) { this.listeners[uuid].callback(err, data) }
        });
        this.socket.on('onsnap2', (uuid: string, err: any, snap: any) => {
            if (this.listeners[uuid]) { this.listeners[uuid].callback(err, snap) }
        });

        this.socket.on('connect', () => {
            this.isConnectedSource.next(true);
        });

        this.socket.on("reconnect", () => {
            for (const lid of Object.keys(this.listeners)) {
                if (this.listeners[lid].snap) {
                    this.socket.emit('onsnap2', lid, this.listeners[lid].path, this.listeners[lid].event);
                } else {
                    this.socket.emit('on2', lid, this.listeners[lid].path, this.listeners[lid].event);
                }
            }
        });

        this.socket.on("reconnect_failed", () => {
            if (this.connected()) {
                this.isConnectedSource.next(false);
            }
            if (this.authecticated()) {
                // We will kick user to login, clear session
                sessionStorage.removeItem("socketSession");
                this.isAuthenticatedSource.next(false);
            }
            if (this.authStateSource.value) {
                this.authStateSource.next(null);
            }
            this.listeners = {};
        });

        this.socket.on('disconnect', () => {
            if (this.trackDisconnection) {
                this.isConnectedSource.next(false);

                // No reconnect, cleanup
                if (!this.tryReconnect) {
                    // We will kick user to login, clear session
                    sessionStorage.removeItem("socketSession");

                    this.isAuthenticatedSource.next(false);
                    this.authStateSource.next(null);
                    this.listeners = {};
                }
            } else {
                // Logout called, disconnected on purpose, cleanup
                this.isAuthenticatedSource.next(false);
                this.authStateSource.next(null);
                this.listeners = {};
            }
        });

        return new Promise<User>((resolve, reject) => {
            this.socket.on('userdata', (data: User) => {
                data.token = idToken;
                this.authStateSource.next({
                    uid: data.id,
                    account_id: data.account_id,
                    role: data.role,
                    token: data.token
                });
                this.isAuthenticatedSource.next(true);
                resolve(data);
            });
            this.socket.on('connect_error', () => {
                if (!this.tryReconnect) {
                    this.isAuthenticatedSource.next(false);
                }
                reject(new Error('socket-connection-failed'));
            });
            this.socket.on('error', (err) => {
                if (!this.tryReconnect) {
                    this.isAuthenticatedSource.next(false);
                }
                reject(new Error(err));
            });
        });
    }

    async logout(): Promise<void> {
        sessionStorage.removeItem("socketSession");

        this.trackDisconnection = false;
        this.socket.disconnect();
    }

    destroy(): Promise<void> {
        if (this.authecticated()) {
            return this.logout();
        }
        return Promise.resolve();
    }

    listen<T>(path: string, event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Observable<T> {
        const listenerId = uuidV4();
        //console.log(`LISTEN STARTED PATH: ${path} UUID: ${listenerId}`);

        this.socket.emit('on2', listenerId, path, event);
        return new Observable<T>(subscriber => {
            const callback = (err: any, data: T) => err ? subscriber.error(err) : subscriber.next(data);
            this.listeners[listenerId] = { path: path, event: event, snap: false, callback: callback };
            return () => {
                if (this.socket.connected) {
                    this.socket.emit('off', listenerId, path, event);
                    delete this.listeners[listenerId];
                }
            }
        }).pipe(share());
    }

    listenSnap<T>(path: string, event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Observable<{data: T, key: string}> {
        const listenerId = uuidV4();
        //console.log(`LISTEN STARTED PATH: ${path} UUID: ${listenerId}`);

        this.socket.emit('onsnap2', listenerId, path, event);
        return new Observable<{data: T, key: string}>(subscriber => {
            const callback = (err: any, snap: {data: T, key: string}) => err ? subscriber.error(err) : subscriber.next(snap);
            this.listeners[listenerId] = { path: path, event: event, snap: false, callback: callback };
            return () => {
                if (this.socket.connected) {
                    this.socket.emit('off', listenerId, path, event);
                    delete this.listeners[listenerId];
                }
            }
        }).pipe(share());
    }

    query<T>(path: string, queries: {key:"orderByChild"|"orderByKey"|"orderByValue"|"equalTo"|"startAt"|"endAt"|"limitToFirst"|"limitToLast", value:string|number|boolean}[],
            event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Observable<T> {
        const listenerId = uuidV4();
        //console.log(`LISTEN STARTED PATH: ${path} UUID: ${listenerId}`);

        this.socket.emit('onquery', listenerId, path, queries, event);
        return new Observable<T>(subscriber => {
            const callback = (err: any, data: T) => err ? subscriber.error(err) : subscriber.next(data);
            this.listeners[listenerId] = { path: path, event: event, snap: false, callback: callback };
            return () => {
                if (this.socket.connected) {
                    this.socket.emit('off', listenerId, path, event);
                    delete this.listeners[listenerId];
                }
            }
        }).pipe(share());
    }

    querySnap<T>(path: string, queries: {key:"orderByChild"|"orderByKey"|"orderByValue"|"equalTo"|"startAt"|"endAt"|"limitToFirst"|"limitToLast", value:string|number|boolean}[],
            event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Observable<{data: T, key: string}> {
        const listenerId = uuidV4();
        //console.log(`LISTEN STARTED PATH: ${path} UUID: ${listenerId}`);

        this.socket.emit('onquerysnap', listenerId, path, queries, event);
        return new Observable<{data: T, key: string}>(subscriber => {
            const callback = (err: any, snap: {data: T, key: string}) => err ? subscriber.error(err) : subscriber.next(snap);
            this.listeners[listenerId] = { path: path, event: event, snap: false, callback: callback };
            return () => {
                if (this.socket.connected) {
                    this.socket.emit('off', listenerId, path, event);
                    delete this.listeners[listenerId];
                }
            }
        }).pipe(share());
    }

    get<T>(path: string, event: "value" | "child_added" | "child_changed" | "child_removed" | "child_moved" = "value"): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            this.socket.emit('get', path, event, (err, data: T) => {
                if (err) { reject(err) }
                else { resolve(data) }
            });
        });
    }

    set(path: string, data: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.socket.emit('set', path, data, (err) => {
                if (err) { reject(err) }
                else { resolve() }
            });
        });
    }

    push(path: string, data: any): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.socket.emit('push', path, data, (err, key: string) => {
                if (err) { reject(err) }
                else { resolve(key) }
            });
        });
    }

    update(path: string, data: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.socket.emit('update', path, data, (err) => {
                if (err) { reject(err) }
                else { resolve() }
            });
        });
    }

    remove(path: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.socket.emit('remove', path, (err) => {
                if (err) { reject(err) }
                else { resolve() }
            });
        });
    }

    transaction(path: string, name: string, data: any): Promise<{ committed: boolean; data: any;}> {
        return new Promise<{ committed: boolean; data: any;}>((resolve, reject) => {
            this.socket.emit('transaction', path, name, data, (err, tResult: any) => {
                if (err) { reject(err) }
                else { resolve(tResult) }
            });
        });
    }

    createPushId(): string {
        return uuidV4();
    }

    timestamp(): any {
        return {".sv":"timestamp"};
    }

    uploadFiles(accountId: string, currentRoomId: string, currentSessionId: string, uploadModel: UploadModel, token: string): [Observable<any>, boolean] {
        const url = `${this.getEndPoint("uploadfiles")}?room=${currentRoomId}&session=${currentSessionId}&token=${token}`;
        const formData = new FormData();
        uploadModel.uploadFiles.forEach(upFile => {
            upFile.uploadTask = { snapshot: { state: 'inprogress' } };
            upFile.key = this.createPushId();
            formData.append(upFile.key, upFile.file, upFile.file.name);
        });
        return [this.http.post<any>(url, formData, { reportProgress: false }), false];
    }

    uploadRoomFiles(accountId: string, currentRoomId: string, uploadModel: UploadModel, token: string): [Observable<any>, boolean] {
        const url = `${this.getEndPoint("uploadRoomFiles")}?room=${currentRoomId}&token=${token}`;
        const formData = new FormData();
        uploadModel.uploadFiles.forEach(upFile => {
            upFile.uploadTask = { snapshot: { state: 'inprogress' } };
            upFile.key = this.createPushId();
            formData.append(upFile.key, upFile.file, upFile.file.name);
        });
        return [this.http.post<any>(url, formData, { reportProgress: false }), false];
    }

    uploadTicketFiles(accountId: string, ticketId: string, uploadModel: UploadModel, token: string): [Observable<any>, boolean] {
        const url = `${this.getEndPoint("uploadTicketFiles")}?ticket=${ticketId}&token=${token}`;
        const formData = new FormData();
        uploadModel.uploadFiles.forEach(upFile => {
            upFile.uploadTask = { snapshot: { state: 'inprogress' } };
            upFile.key = this.createPushId();
            formData.append(upFile.key, upFile.file, upFile.file.name);
        });
        return [this.http.post<any>(url, formData, { reportProgress: false }), false];
    }

    uploadSnapshot(currentSessionPath: string, upFile: UploadFile, token: string): Promise<UploadFile> {
        const path = `${currentSessionPath}/${upFile.extension}`;
        const url = `${this.getEndPoint("upload")}?file_type=image&size=${upFile.file.size}&token=${token}`;
        const formData = new FormData();
        formData.append(path, upFile.file, upFile.file.name);
        return this.http.post<any>(url, formData).pipe(first()).toPromise()
        .then(result => {
            upFile.key = result.files[0].key;
            upFile.url = result.files[0].url;
            return upFile;
        });
    }
}