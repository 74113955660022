<div class="legal-page-container px-3">
  <div class="bar-container">
    <div class="logo-container" *ngIf="('design' | env).legalLogoText"><img class="logo-image" [src]="('design' | env).legalLogo"><span>{{ ('design' | env).legalLogoText }}</span></div>
    <div class="logo-container" *ngIf="!('design' | env).legalLogoText"><img class="logo-only-image" [class.wide]="('design' | env).wideLegalLogo" [src]="('design' | env).legalLogo"></div>
    <a routerLink="/login" class="back-link text-info mr-3"></a>
  </div>
  <div class="legal-page-content">
    <div class="legal-page-title">Technical and Organizational Security Measures</div>

    <p class="my-4">Last updated: September 10th, 2019</p>

    <p>VSight provides a cloud communications platform for a wide range of customer and business needs. Recognizing the importance of information security, we have invested considerable time and effort into ensuring our platform’s security.</p>
    <p>This document summarizes various technical and organizational security measures we have implemented to protect our customers’ data from malicious or accidental destruction, alteration, loss, unauthorized access or disclosure.</p>

    <div class="legal-page-title-secondary">1. Physical Access Control</div>
    <p>VSight’s data processing environment is built on the Google Cloud platform with geographically located Frankfurt data centers. VSight Cloud platform complies with various security standards - including ISO/IEC 27001:2013, ISO/IEC 27017:2015, ISO/IEC 27018:2014, ISO 22301:2012, ISO 31000:2009, HITRUST CSF v8.1, SOC 2, SOC 3 - and guarantees protection of physical infrastructure and facilities.</p>
    <p>VSight stores all production data in physically secure data centers, including Google Cloud, Microsoft Azure facilities. VSight’s cloud storage vendor (Google Cloud Datastore), are compliant with ISO/IEC 27001:2013, ISO/IEC 27017:2015, ISO/IEC 27018:2014, SOC 2, and SOC 3, NIST 800-171.</p>
    <p><strong>Certificates can be reached from the links:</strong></p>
    <ul class="mb-3">
      <li><a href="https://cloud.google.com/security/compliance/">Google Cloud</a></li>
      <li><a href="https://azure.microsoft.com/en-us/overview/trusted-cloud/compliance/">Microsoft Azure</a></li>
    </ul>

    <p>VSight’s office facilities are secured by 24/7 guards, interior and exterior video surveillance, alarm systems, security gates, and doors equipped with access card readers or locks.</p>

    <div class="legal-page-title-secondary">2. System Access Control</div>
    <p>VSight data processing systems are designed to ensure only authorized access and processing of customers’ data.</p>
    <p>The granting or modification of access rights follows an established workflow with a mandatory approval from the line management. Workflow tools provide accountability through recordkeeping.</p>
    <p>All account actions can be traced to the particular user taking action on the account. The time, date, and type of action are recorded for all privileged account actions.</p>
  
    <div class="legal-page-title-secondary">3. Data Access and Personnel Security</div>
    <p>Only properly authorized personnel are allowed to access and manage customer data. Team-wide security roles covering critical tools and applications are applied.</p>
    <p>VSight’s onboarding process mandates that domain credentials for each employee are requested by the HR function in a formal, accountable manner. Employment termination triggers revocation of issued credentials.</p>
    <p>VSight ensures that personnel are notified of significant requirements as well as personal and corporate consequences of engaging in improper activities. All employees complete a periodic mandatory security training and a Code of Conduct training covering business ethics and professional standards, each at least annually.</p>

    <div class="legal-page-title-secondary">4. Customer Access Control & Account Management</div>
    <p>Customers can manage their accounts through VSight Admin Panel - a dedicated web page which supports two-factor authentication and IP address verification security mechanisms. If enabled, VSight Customer Dashboard will in addition to customer’s password require a one-time verification code - an SMS sent to the phone registered on the customer’s account when the customer’s IP address differs from the one used previously.</p>
    <p>VSight Customer Dashbord password-based authentication utilizes secure hashing and salting to protect against impersonation and brute-force attacks.</p>

    <div class="legal-page-title-secondary">5. Transmission Control</div>
    <p>VSight supports HTTPS and SMPP over TLSv1.2 as main protocols for encrypted communication.</p>

    <div class="legal-page-title-secondary">6. Network Security and Segmentation</div>
    <p>VSight’s data processing environment is separated from the outside world and from the test environment with firewalls. Fine-grained segmentation inside production and test environments is achieved with the help of VLANs.</p>

    <div class="legal-page-title-secondary">7. Vulnerability Management</div>
    <p>VSight employs a three-fold vulnerability management strategy which includes proactive updates of 3rd-party applications, internal monthly vulnerability scans, and external penetration tests. VSight keeps itself up to date with patches/upgrades and updates 3rd-party applications promptly as new versions are released.</p>

    <div class="legal-page-title-secondary">8. Change Management</div>
    <p>VSight's development process is built on the principle of segregation of duties and employs mandatory reviews and approvals. Each change to production environment is submitted by Development, tested by Quality Assurance, and reviewed by Operations before deployment.</p>

    <div class="legal-page-title-secondary">9. Logging</div>
    <p>Apart from system level logging to ensure traceability of account actions, VSight commits to logging of all API requests to recognize, investigate, and protect customers from fraudulent activity. Among other information, logs contain: source IP, account Id, type of activity and timestamp. All successful/unsuccessful authentication attempts are logged and investigated, as appropriate.</p>
    <p>Customers control and configure VSight services through a portal (VSight Admin Panel). To provide an audit trail, all changes and actions performed using the customer dashboard are recorded.</p>
    <p>Internal administration activities are performed via tools accessible only by authorized VSight personnel. All activities including provisioning of VSight services are logged.</p>

    <div class="legal-page-title-secondary">10. Business Continuity</div>
    <p>VSight’s business continuity planning incorporates procedures to sustain critical functions, backup and recover data, and protect company assets.</p>
    <p>Single points of failure are eliminated for critical services with multi-node and multi-channel network design and load-balancing strategy.</p>
    <p>VSight follows a Data Backup Policy which mandates regular backups of configuration and account data required for continuous service operation and usage of off-site storage, and daily data restoration tests where appropriate.</p>

    <div class="legal-page-title-secondary">11. Media Protection and End-User Security</div>
    <p>VSight recognizes a potential internal attack surface originating from compromised end-user machines used by VSight employees, and to mitigate this threat implements a set of security measures including hard drive encryption, secure data erasure upon laptop decommissioning, virus/malware protection with automated updates, browsing/traffic control, and centralized domain-based authentication.</p>

    <div class="legal-page-title-secondary">12. Data Encryption and Secure Data Redaction</div>
    <p>VSight utilizes two main strategies to protect customer’s data: data encryption for long-term data and limited data retention for short-lived data.</p>
    <p>VSight retains data processing logs for a minimum of three days.</p>
    <p>VSight provides, upon customer’s request and subject to applicable legal requirements, a true data anonymization by means of data redaction. Data redaction is a one-way process that substitutes original data with a predefined set of characters that reveals no information on the original data except that it was anonymized.</p>

    <div class="legal-page-title-secondary">13. Report a Security Vulnerability</div>
    <p>If you believe that you have found a VSight security vulnerability, please contact us at security@vsight.io for further investigation.</p>
  </div>
</div>