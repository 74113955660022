import { Component, OnInit, Input, ElementRef, ViewChild, ChangeDetectorRef, OnDestroy, AfterViewInit, OnChanges, SimpleChanges, Renderer2, Output, EventEmitter, TemplateRef } from '@angular/core';
import * as semver from 'semver';

import { AnimatedBtnGroupDirective } from '../../../../../directives/animated-btn-group.directive';

import { AuthService } from '@services/core/auth.service';
import { CallService } from '@services/core/call.service';
import { FileShareService } from '@services/other/file-share.service';
import { RoomSessionService } from '@services/core/room-session.service';
import { OpentokService } from '@services/core/opentok.service';
import { CollaborationService } from '@services/core/collaboration.service';
import { ArCollaborationService } from '@services/core/ar-collaboration.service';
import { SubscriberService } from '@services/core/subscriber.service';
import { LogService } from '@services/core/log.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { ModalService } from '@services/support/modal.service';
import { FullscreenService } from '@services/support/fullscreen.service';

import { Subscription, fromEvent, merge, interval } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { ArDot } from '@models/ArDot';
import { Frame } from '@models/Frame';
import { UserObject } from '@models/UserObject';
import { DbService } from '@services/core/db.service';
import { environment } from 'environments/environment';

interface SubscriberStat {
  audio: number,
  audioPacketLossRatio: number,
  signalState: number,
  video: number,
  videoPacketLossRatio: number
}

@Component({
  selector: 'app-subscriber',
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.scss']
})
export class SubscriberComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @ViewChild("arCollBtnGroup", { static: true }) private arCollBtnGroup: AnimatedBtnGroupDirective;
  @ViewChild("arPlusBtnGroup", { static: true }) private arPlusBtnGroup: AnimatedBtnGroupDirective;

  @ViewChild("closeCollaborationTemplate", { static: true }) private closeCollaborationTemplate: TemplateRef<any>;
  @ViewChild("closeArPlusTemplate", { static: true }) private closeArPlusTemplate: TemplateRef<any>;
  @ViewChild("disableFocusTemplate", { static: true }) private disableFocusTemplate: TemplateRef<any>;

  @ViewChild('subscriberContainer', { static: true }) subscriberContainer: ElementRef;
  @Output() arPlusStatusChanged = new EventEmitter<boolean>();
  @Output() toggleFullscreen = new EventEmitter<boolean>();
  @Output() subscriberFailed = new EventEmitter<OT.OTError>();

  @Input('frame') frame: Frame;
  @Input('size') size: string;
  @Input('nohover') nohover: boolean = false;

  subscriber: OT.Subscriber;
  subscriberData: any = null;

  audioAvailable: boolean = false;
  videoAvailable: boolean = false;
  subscribeAudio: boolean = true;
  subscribeVideo: boolean = true;

  signalState: number = 0;
  audioKbps: number = 0;
  videoKbps: number = 0;

  screenWidthDynamic: number = 0;
  screenHeightDynamic: number = 0;

  //BP: Break Point
  minBPVideoLossRatio: number = 0;
  maxBPVideoLossRatio: number = 0;
  minBPAudioLossRatio: number = 0;
  maxBPAudioLossRatio: number = 0;

  audioBPMax: number = 0;
  audioBPMid: number = 0;
  audioBPMin: number = 0;

  differenceVideoPacketLossRatio: number = 0;
  differenceAudioPacketLossRatio: number = 0;

  videoPacketLossRatio: number = 0;
  audioPacketLossRatio: number = 0;

  connectedSub: Subscription = null;

  videoCanAvailable: boolean = true;

  fullscreenState: {fullscreen: boolean, supported: boolean} = {fullscreen: false, supported: true};
  fullscreenSub: Subscription = null;

  listenStatsSub: Subscription = null;
  screenWidth: number = 0;
  screenHeight: number = 0;

  listenStatsUnsubbed: boolean = false;

  arDots: ArDot[] = [];

  arColorCode: string = "dc3545";
  arColor: string = "red";
  arShape: string = "disc";
  arCollaborationOpen: boolean = false;
  arCollaborationActive: boolean = false;
  arCollSub: Subscription = null;
  arCollSourcesSub: Subscription = null;
  arCollTouchMouseSub: Subscription = null;
  arPlusTouchMouseSub: Subscription = null;

  arPlusDrawType: string = "line";
  arPlusArrowType: string = "arrow-down";

  arTextList = []
  currentArText = null

  arPlusWeight: string = "normal";
  arPlusOpen: boolean = false;
  arPlusActive: boolean = false;

  hdPhotoOpen: boolean = false;

  cameraControls: any = null;
  controlsSub: Subscription = null;
  showFlash: boolean = false;
  flashTimeout: any = null;
  showZoom: boolean = false;
  zoomTimeout: any = null;

  deviceStatus: any = null;

  pipEnabled: boolean = false;
  _document: any = document;

  showReaction: boolean = false;
  reactionTimeout: any = null;
  reactionSub: Subscription = null;
  reactionSource: string;
  reactionType: string;

  subscriberStats: SubscriberStat = {
    audio: 0,
    audioPacketLossRatio: 0,
    signalState: 0,
    video: 0,
    videoPacketLossRatio: 0
  };
  subscriberStatsSub: Subscription = null;
  showStatsDetails = false;

  currentArPlusLine: string = null;
  androidArSnapshotAvailable: boolean = false;
  arkitAvailable: boolean = false;
  arkitLockAvailable: boolean = false;

  features: { [key: string]: boolean } = {
    snapshot: false,
    arcollaboration: false,
    arplus: false,
    cameracontrols: false,
    batterystatus: false
  };
  featuresSub: Subscription = null;
  featureMessage: "Your licence doesn't contain this feature.";

  objects: any[] = [];
  customObject: any = null;
  objectsSub: Subscription = null;

  continueToTrySubscribe: boolean = true;

  isLocked: boolean = false;
  oldDrawType: string = null;

  objectSub: Subscription = null;
  objectScaleSub : Subscription = null;

  objSelected: boolean = false;
  currentScale: number = 1;
  selectedObjId: string = null;
  dragDebounceTime: number = 0;

  isTrainingMaster: boolean = false;
  trainingDisable: boolean = false;
  roomUserStatus: { training_room: boolean, session_active: boolean, user_status: string } = null;
  private roomUserStatusSub: Subscription = null;

  audioLevelIndicatorStyle = {width: "0px"};
  movingAvg = null;

  addOnsSub: Subscription = null;

  showPlaneSurfacesButton: boolean = false;
  planeDetectionSub: Subscription = null;

  sessionDataSub: Subscription = null;

  arTextAvailable: boolean = false;
  currentRoomSub: Subscription = null;

  constructor(
    private authService: AuthService,
    private callService: CallService,
    private opentokService: OpentokService,
    private logService: LogService,
    private arCollaborationService: ArCollaborationService,
    private roomSessionService: RoomSessionService,
    private subscriberService: SubscriberService,
    private changeDetector: ChangeDetectorRef,
    private fullscreenService: FullscreenService,
    private renderer: Renderer2,
    private flashMessageService: FlashMessageService,
    private collaborationService: CollaborationService,
    private modalService: ModalService,
    private dbService: DbService
  ) { }

  ngOnInit() {
    this.subscriberData = JSON.parse(this.frame.stream.connection.data);

    this.planeDetectionSub = this.subscriberService.getPlaneDetection(this.subscriberData.uid).subscribe(node => {
      this.showPlaneSurfacesButton = node?.available ? true : false;
      this.setDefaultArPlusDrawType();
    });

    this.currentRoomSub = this.roomSessionService.currentRoom.subscribe(room => {
      const roomUserData = room?.room_data?.users?.find(us => us.user_id === this.subscriberData.uid)
      if (roomUserData && roomUserData.platform && roomUserData.app_version) {
        if (roomUserData.platform === "ios") {
          this.arTextAvailable = semver.gte(roomUserData.app_version, environment.appFeatureMinVersions.ios.artext);
        } else if (roomUserData.platform === "android") {
          this.arTextAvailable = semver.gte(roomUserData.app_version, environment.appFeatureMinVersions.android.artext);
        } else {
          this.arTextAvailable = false;
        }
      } else {
        this.arTextAvailable = false;
      }
    });

    this.addOnsSub = this.authService.addOns.subscribe(addOns => {
      if (addOns) {
        this.showStatsDetails = addOns.detailedvideoquality;
      }
    });

    this.objectSub = this.subscriberService.getArPlusSelectNode(this.subscriberData.uid).subscribe(selectedObjectNode => {
      if (selectedObjectNode) {
        if (selectedObjectNode.object) {
          this.objSelected = true;
          this.currentScale = selectedObjectNode.object.scale;
          this.selectedObjId = selectedObjectNode.object.id;
        } else {
          this.objSelected = false;
        }
      }
      else {
        this.objSelected = false;
      }
    });

    const sub = this.subscriberService.getArPlusLockStatus(this.subscriberData.uid).subscribe(locked => {
      if (locked === true || locked === false) {
        this.isLocked = locked;
        if (this.isLocked) {
          this.oldDrawType = this.arPlusDrawType;
          this.arPlusDrawType = 'lock';
        } else {
          this.arPlusDrawType = this.oldDrawType;
        }
      }
    });
    this.objectSub.add(sub);

    this.subscriberStatsSub = this.subscriberService.getSubscriberStats(this.subscriberData.uid).subscribe(data => {
      if (data) {
        this.subscriberStats = data;
      }
    });

    let status = this.opentokService.getSubscriberAudioVideoStatus(this.subscriberData.uid);
    if (status) {
      this.subscribeAudio = status.audio;
      this.subscribeVideo = status.video;
    } else {
      // Set the default values in subscriber component
      this.opentokService.setSubscriberAudioVideoStatus(this.subscriberData.uid, this.subscribeAudio, this.subscribeVideo);
    }

    this.fullscreenSub = this.fullscreenService.isFullscreen.subscribe(state => {
      this.fullscreenState = state;
      this.changeDetector.detectChanges();
    });

    this.featuresSub = this.authService.features.subscribe(features => {
      this.features = features;
    });

    if (this._document.pictureInPictureEnabled) {
      this.pipEnabled = true;
    }

    const allObjects: UserObject[] = [];
    this.objectSub = this.arCollaborationService.getObjects().subscribe(object => {
      allObjects.push(object);
      if (this.subscriberData.platform === "ios") {
        this.objects = allObjects.filter(o => FileShareService.arkitExtensions.includes(o.extension));
      } else if (this.subscriberData.platform === "android") {
        this.objects = allObjects.filter(o => FileShareService.arcoreExtensions.includes(o.extension));
      } else if (this.subscriberData.platform === "android-glasses") {
        this.objects = allObjects.filter(o => FileShareService.wikitudeExtensions.includes(o.extension));
      }
    });

    this.roomUserStatusSub = this.roomSessionService.roomUserStatus
    .subscribe(roomUserStatus => {
      this.roomUserStatus = roomUserStatus;
      this.isTrainingMaster = roomUserStatus.training_room && roomUserStatus.user_status === "master";
      this.trainingDisable = roomUserStatus.training_room ? (roomUserStatus.user_status !== "master" && roomUserStatus.user_status !== "publishing") : false;

      if (this.trainingDisable) {
        if (this.arCollaborationOpen) {
          this.toggleArCollaboration();
        }
      }
    });

    this.sessionDataSub = this.roomSessionService.sessionData.pipe(
      map(d => d && d.focus ? d.focus : null),
      distinctUntilChanged((x,y) => (x === y) || (x && y && x.id === y.id && x.type === y.type))
    ).subscribe(focus => {
      if (focus){
        this.arCollaborationActive = false;
        this.subscriberContainer.nativeElement.style.cursor = "default";
        this.arCollaborationService.setDot(this.authService.currentUser.id, this.subscriberData.uid, '');
      }
    })
  }

  onArTextAdd(arTextInput: HTMLInputElement) {
    const value = arTextInput.value;
    arTextInput.value = "";

    if (value !== "" && !this.arTextList.includes(value)) {
      this.arTextList.push({ content: value, active: true });
      this.currentArText = value;
      this.arPlusDrawType = "artext"
    }
  }

  onArTextDelete(event, index: number) {
    event.stopPropagation();

    const contentToDelete = this.arTextList[index].content
    this.arTextList.splice(index, 1);

    if (this.arTextList.length === 0 || contentToDelete === this.currentArText) {
      this.setDefaultArPlusDrawType();
      this.currentArText = null;
    }
  }

  onRefreshSurfacesButtonClicked() {
    this.subscriberService.setPlaneDetectionTimestamp(this.subscriberData.uid);
  }

  endAr() {
    if (this.arCollaborationOpen) {
      this.onToggleArCollaboration();
    }
    if (this.arPlusOpen) {
      this.onToggleArPlus();
    }
  }

  onChangeArPlusArrowType(direction: "arrow-down" | "arrow-up" | "arrow-right" | "arrow-left") {
    this.arPlusDrawType = "arrow";
    this.arPlusArrowType = direction;
  }

  onLockClick() {
    this.subscriberService.setArPlusLockStatus(this.subscriberData.uid, !this.isLocked);
  }

  onLockedButtonClick() {
    this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.AR_PLUS_LOCKED');
  }

  scaleUp() {
    const targetId = this.subscriberData.uid;
    const selectId = this.authService.currentUser.id;

    this.subscriberService.scaleUp(targetId, selectId);
  }

  scaleDown() {
    const targetId = this.subscriberData.uid;
    const selectId = this.authService.currentUser.id;

    this.subscriberService.scaleDown(targetId, selectId);
  }

  onDelete() {
    this.objSelected = false;
    this.subscriberService.deleteArPlusObject(this.subscriberData.uid, this.selectedObjId);
    this.subscriberService.deselectARObject(this.subscriberData.uid);
  }

  onClose() {
    this.subscriberService.deselectARObject(this.subscriberData.uid);
  }

  onToggleFlash() {
    if (!this.features.cameracontrols) {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE');
      return;
    }
    if (!this.cameraControls.flash.available) {
      // No need to show message
      return;
    }
    if (this.trainingDisable) {
      this.flashMessageService.showTranslated('APP.SHARED.TRAINING_DISABLED');
      return;
    }
    this.subscriberService.toggleFlash(this.subscriberData.uid);
  }

  showToggleFlash() {
    if (this.flashTimeout) {
      clearTimeout(this.flashTimeout);
    }
    this.showFlash = true;
    this.showZoom = false;
    this.flashTimeout = setTimeout(() => {
      this.showFlash = false;
      this.flashTimeout = null;
    }, 1000);
  }

  onZoomIn() {
    if (!this.features.cameracontrols) {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE');
      return;
    }
    if (!this.cameraControls.zoom.available) {
      // No need to show message
      return;
    }
    if (this.trainingDisable) {
      this.flashMessageService.showTranslated('APP.SHARED.TRAINING_DISABLED');
      return;
    }
    this.subscriberService.zoomIn(this.subscriberData.uid);
  }

  onZoomOut() {
    if (!this.features.cameracontrols) {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE');
      return;
    }
    if (!this.cameraControls.zoom.available) {
      // No need to show message
      return;
    }
    if (this.trainingDisable) {
      this.flashMessageService.showTranslated('APP.SHARED.TRAINING_DISABLED');
      return;
    }
    this.subscriberService.zoomOut(this.subscriberData.uid);
  }

  showZoomLevel() {
    if (this.zoomTimeout) {
      clearTimeout(this.zoomTimeout);
    }
    this.showZoom = true;
    this.showFlash = false;
    this.zoomTimeout = setTimeout(() => {
      this.showZoom = false;
      this.zoomTimeout = null;
    }, 1000);
  }

  ngAfterViewInit() {
    this.tryToSubscribe(3)
    .catch(error => {
      // Catch opentok errors first
      this.callService.otSubscriberFailed(error.name, error.code);
      return null;
    })
    .then(subscriber => {
      if (subscriber) {
        this.subscriber = subscriber;
        this.initializeSubscriberListeners();
        this.connectedSub = this.dbService.isConnected.subscribe(connected => {
          if (!connected) {
            this.listenStatsSub.unsubscribe();
            this.listenStatsUnsubbed = true;

            this.subscriberStats = null;

            this.audioAvailable = false;
            this.videoAvailable = false;

            this.audioKbps = 0;
            this.videoKbps = 0;
            this.audioPacketLossRatio = 0;
            this.videoPacketLossRatio = 0;
          } else if (this.listenStatsUnsubbed){
            this.listenStatsUnsubbed = false;
            this.listenStats();
          }
        })
      }
    })
    .catch(error => {
      // Catch errors in then block
      console.log(error);
    });
  }

  async tryToSubscribe(maxTry: number) {
    let subscriber = null;
    let error = null;
    let retryCount = 0;
    while (this.continueToTrySubscribe && !subscriber && retryCount < maxTry) {
      subscriber = await (this.opentokService.startSubscribe(this.subscriberContainer, this.frame.stream, this.subscribeAudio, this.subscribeVideo)
      .catch((err: OT.OTError) => {
        this.opentokService.saveOtError("subscriber", err);
        error = err;
        if (!this.isRetryRequired(err.name)) {
          retryCount = maxTry;
        }
        return null;
      }));
      retryCount = retryCount + 1;
    }
    if (this.continueToTrySubscribe && !subscriber) {
      throw error;
    }
    return subscriber;
  }

  isRetryRequired(errorName: string) {
    return errorName === 'OT_CREATE_PEER_CONNECTION_FAILED' ||
      errorName === 'OT_INVALID_PARAMETER' ||
      errorName === 'OT_ICE_WORKFLOW_FAILED' ||
      errorName === 'OT_MEDIA_ERR_ABORTED' ||
      errorName === 'OT_MEDIA_ERR_DECODE' ||
      errorName === 'OT_MEDIA_ERR_NETWORK' ||
      errorName === 'OT_MEDIA_ERR_SRC_NOT_SUPPORTED' ||
      errorName === 'OT_NOT_CONNECTED' ||
      errorName === 'OT_SET_REMOTE_DESCRIPTION_FAILED'
  }

  initializeSubscriberListeners() {
    this.listenStats();

    this.videoAvailable = this.subscriber.stream.hasVideo;
    this.audioAvailable = this.subscriber.stream.hasAudio;

    this.subscriber.on("videoEnabled", this.videoEnabled);
    this.subscriber.on("videoDisabled", this.videoDisabled);
    //this.subscriber.on("videoDisableWarning", this.videoDisableWarning);
    //this.subscriber.on("videoDisableWarningLifted", this.videoDisableWarningLifted);
    this.subscriber.on("audioLevelUpdated", this.audioLevelUpdated);

    this.controlsSub = this.subscriberService.getSubscriberControls(this.subscriberData.uid)
    .subscribe(controls => {
      if (controls.camera_controls) {
        this.detectCameraControlChanges(controls.camera_controls);
      } else {
        this.cameraControls = null;
      }
      this.deviceStatus = controls.device_status;
      if (this.deviceStatus) {
        this.androidArSnapshotAvailable = this.deviceStatus.android_ar_snapshot ? this.deviceStatus.android_ar_snapshot.available : false;
        this.arkitAvailable = this.deviceStatus.arkit ? this.deviceStatus.arkit.available : false;
        this.arkitLockAvailable = this.deviceStatus.arkit_lock ? this.deviceStatus.arkit_lock.available : false;

        if (this.deviceStatus.arkit) {
          if (this.arPlusOpen && this.deviceStatus.arkit.status === 'closed') {
            this.toggleArPlus();
          }
          if (!this.arPlusOpen && this.deviceStatus.arkit.status === 'opened') {
            this.toggleArPlus();
            if (this.arCollaborationOpen) {
              this.toggleArCollaboration();
            }
          }
        }

        if (this.deviceStatus.photo) {
          this.hdPhotoOpen = this.deviceStatus.photo.status !== 'closed';
        }
      } else {
        this.arkitAvailable = false;
      }
    });

    this.arCollSourcesSub = this.roomSessionService.getArSourceColor()
    .subscribe(arColor => {
      if (arColor) {
        this.arColorCode = arColor;
        this.arColor = ArCollaborationService.arColorNames[arColor];
        if (this.arCollaborationOpen || this.arPlusOpen) {
          this.renderer.setStyle(this.frame.nativeElement, 'border', '2px solid #' + arColor);
        }
      }
    });
    this.arCollSub = this.arCollaborationService.getSubscriberDots(this.subscriberData.uid)
    .pipe(
      map(dots => {
        return !dots ? [] :
        Object.values(dots).filter(x => x !== '')
        .map((x: string) => {
          const values = x.split('!');
          let left = parseFloat(values[1]);
          let top = parseFloat(values[2]);
          let temp_left = left+"%";
          let temp_top = top+"%";
            /*
          if(this.isFullscreen) {
            const width = window.innerWidth;
            const height = window.innerHeight;
            let canvas_width;
            let canvas_height;

            // height fits the screen cropped from left and right
            if(width/this.frame.stream.videoDimensions.width >= height/this.frame.stream.videoDimensions.height){
              canvas_width = height*4/3;
              canvas_height = height;
            } else{
              canvas_width = width;
              canvas_height = canvas_width*3/4;
            }

            const distance_left = (width-canvas_width)/2
            left = canvas_width*left/100+distance_left;
            temp_left = left + "px";

            const distance_top = (height-canvas_height)/2
            top = canvas_height*top/100+distance_top;
            temp_top = top + "px";
            }*/
          const arDot: ArDot = {
            colorCode: values[0],
            left: temp_left,
            top: temp_top,
            name: values[3],
            shape: values[4]
          }
          return arDot;
        })
      })
    )
    .subscribe(dots => {
      this.arDots = dots.map(dot => {
        dot.color = ArCollaborationService.arColorNames[dot.colorCode]
        return dot;
      });
    });
    this.changeDetector.detectChanges();
  }

  listenStats() {
    let lastAudioBytesReceived = 0;
    let lastVideoBytesReceived = 0;
    let lastTimestamp = 0;

    this.minBPAudioLossRatio = 0.005;
    this.maxBPAudioLossRatio = 0.05;
    this.minBPVideoLossRatio = 0.005;
    this.maxBPVideoLossRatio = 0.03;

    this.signalState = 0; //0 --> bad, 1 --> ok, 2 --> good, 3 --> excellent

    let lastVideoPacketsSent = 0;
    let lastVideoPacketsLost = 0;

    let lastAudioPacketsSent = 0;
    let lastAudioPacketsLost = 0;

    this.listenStatsSub = interval(5000).subscribe(val => {
      this.subscriber.getStats((err, stats) => {

        this.screenWidth = this.subscriber.stream.videoDimensions.width;
        this.screenHeight = this.subscriber.stream.videoDimensions.height;
        this.screenWidthDynamic = this.subscriber.videoWidth();
        this.screenHeightDynamic = this.subscriber.videoHeight();

        this.videoAvailable = this.subscriber.stream.hasVideo;
        this.audioAvailable = this.subscriber.stream.hasAudio;

        const timestamp = stats.timestamp / 1000;
        if (lastTimestamp !== 0) {
          this.audioKbps = Math.floor(8 * (stats.audio?.bytesReceived - lastAudioBytesReceived) / (1024 * (timestamp - lastTimestamp)));
          this.videoKbps = Math.floor(8 * (stats.video?.bytesReceived - lastVideoBytesReceived) / (1024 * (timestamp - lastTimestamp)));

          let videoPacketsSent = stats.video?.packetsLost + stats.video?.packetsReceived;
          let videoPacketsLost = stats.video?.packetsLost;

          let videoPacketsSentDifference = videoPacketsSent - lastVideoPacketsSent;
          let videoPacketsLostDifference = videoPacketsLost - lastVideoPacketsLost;

          if (videoPacketsSentDifference > 0) {
            this.videoPacketLossRatio = parseFloat((videoPacketsLostDifference / videoPacketsSentDifference).toFixed(4))
          } else {
            this.videoPacketLossRatio = 0;
          }

          lastVideoPacketsSent = videoPacketsSent;
          lastVideoPacketsLost = videoPacketsLost;

          let audioPacketsSent = stats.audio?.packetsLost + stats.audio?.packetsReceived;
          let audioPacketsLost = stats.audio?.packetsLost;

          let audioPacketsSentDifference = audioPacketsSent - lastAudioPacketsSent;
          let audioPacketsLostDifference = audioPacketsLost - lastAudioPacketsLost;

          if (audioPacketsSentDifference > 0) {
            this.audioPacketLossRatio = parseFloat((audioPacketsLostDifference / audioPacketsSentDifference).toFixed(4))
          } else {
            this.audioPacketLossRatio = 0;
          }

          lastAudioPacketsSent = audioPacketsSent;
          lastAudioPacketsLost = audioPacketsLost;

          var breakpoints = {
            max: {
              res1920x1080: 1000,
              res1280x720: 1000,
              res640x480: 600,
              res352x288: 300,
              res320x240: 300
            },
            mid: {
              res1920x1080: 675,
              res1280x720: 675,
              res640x480: 425,
              res352x288: 225,
              res320x240: 225
            },
            min: {
              res1920x1080: 350,
              res1280x720: 350,
              res640x480: 250,
              res352x288: 150,
              res320x240: 150
            }
          }

          //Calculating signal state only
          if (this.videoAvailable) {
            if (this.screenWidth*this.screenHeight >= 1920*1080) {
              if (this.videoKbps > breakpoints.max.res1920x1080) {
                if (this.videoPacketLossRatio < this.minBPVideoLossRatio) {
                    this.signalState = 3;
                } else if (this.videoPacketLossRatio >= this.minBPVideoLossRatio) {
                    this.signalState = 2;
                }
              }
              else if (this.videoKbps > breakpoints.mid.res1920x1080 && this.videoKbps <= breakpoints.max.res1920x1080) {
                if (this.videoPacketLossRatio < this.maxBPVideoLossRatio) {
                  this.signalState = 2;
                } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                  this.signalState = 1;
                }
              }
              else if (this.videoKbps > breakpoints.min.res1920x1080 && this.videoKbps <= breakpoints.mid.res1920x1080) {
                if (this.videoPacketLossRatio < this.maxBPVideoLossRatio) {
                  this.signalState = 1;
                } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                  this.signalState = 0;
                }
              }
              else if (this.videoKbps <= breakpoints.min.res1920x1080) {
                this.signalState = 0;
              }
            }
            else if (this.screenWidth*this.screenHeight >= 1280*720 && this.screenWidth*this.screenHeight < 1920*1080) { //961.600
              if (this.videoKbps > breakpoints.max.res1280x720) {
                if (this.videoPacketLossRatio < this.minBPVideoLossRatio) {
                    this.signalState = 3;
                } else if (this.videoPacketLossRatio >= this.minBPVideoLossRatio) {
                    this.signalState = 2;
                }
              }
              else if (this.videoKbps > breakpoints.mid.res1280x720 && this.videoKbps <= breakpoints.max.res1280x720) {
                if (this.videoPacketLossRatio < this.maxBPVideoLossRatio) {
                  this.signalState = 2;
                } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                  this.signalState = 1;
                }
              }
              else if (this.videoKbps > breakpoints.min.res1280x720 && this.videoKbps <= breakpoints.mid.res1280x720) {
                if (this.videoPacketLossRatio < this.maxBPVideoLossRatio) {
                  this.signalState = 1;
                } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                  this.signalState = 0;
                }
              }
              else if (this.videoKbps <= breakpoints.min.res1280x720) {
                this.signalState = 0;
              }
            }
            else if (this.screenWidth*this.screenHeight >= 640*480 && this.screenWidth*this.screenHeight < 1280*720) { //307.200 - 961.600
              if (this.videoKbps > breakpoints.max.res640x480) {
                if (this.videoPacketLossRatio < this.minBPVideoLossRatio) {
                  this.signalState = 3;
                } else if (this.videoPacketLossRatio >= this.minBPVideoLossRatio) {
                  this.signalState = 2;
                }
              }
              else if (this.videoKbps > breakpoints.mid.res640x480 && this.videoKbps <= breakpoints.max.res640x480) {
                if (this.videoPacketLossRatio < this.maxBPVideoLossRatio) {
                  this.signalState = 2;
                } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                  this.signalState = 1;
                }
              }
              else if (this.videoKbps > breakpoints.min.res640x480 && this.videoKbps <= breakpoints.mid.res640x480) {
                if (this.videoPacketLossRatio < this.maxBPVideoLossRatio) {
                  this.signalState = 1;
                } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                  this.signalState = 0;
                }
              }
              else if (this.videoKbps <= breakpoints.min.res640x480) {
                this.signalState = 0;
              }
            }
            else if (this.screenWidth*this.screenHeight >= 352*288 && this.screenWidth*this.screenHeight < 640*480) { //101.376 - 307.200
              if (this.videoKbps > breakpoints.max.res352x288) {
                if (this.videoPacketLossRatio < this.minBPVideoLossRatio) {
                  this.signalState = 3;
                } else if (this.videoPacketLossRatio >= this.minBPVideoLossRatio) {
                  this.signalState = 2;
                }
              }
              else if (this.videoKbps > breakpoints.mid.res352x288 && this.videoKbps <= breakpoints.max.res352x288) {
                if (this.videoPacketLossRatio < this.maxBPVideoLossRatio) {
                  this.signalState = 2;
                } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                  this.signalState = 1;
                }
              }
              else if (this.videoKbps > breakpoints.min.res352x288 && this.videoKbps <= breakpoints.mid.res352x288) {
                if (this.videoPacketLossRatio < this.maxBPVideoLossRatio){
                  this.signalState = 1;
                } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                  this.signalState = 0;
                }
              }
              else if (this.videoKbps <= breakpoints.min.res352x288) {
                this.signalState = 0;
              }
            }
            else if (this.screenWidth*this.screenHeight >= 320*240 && this.screenWidth*this.screenHeight < 352*288) { //76.800 - 307.200
              if (this.videoKbps > breakpoints.max.res320x240) {
                if (this.videoPacketLossRatio < this.minBPVideoLossRatio) {
                  this.signalState = 3;
                } else if (this.videoPacketLossRatio >= this.minBPVideoLossRatio) {
                  this.signalState = 2;
                }
              }
              else if (this.videoKbps > breakpoints.mid.res320x240 && this.videoKbps <= breakpoints.max.res320x240) {
                if (this.videoPacketLossRatio < this.maxBPVideoLossRatio) {
                  this.signalState = 2;
                } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                  this.signalState = 1;
                }
              }
              else if (this.videoKbps > breakpoints.min.res320x240 && this.videoKbps <= breakpoints.mid.res320x240) {
                if (this.videoPacketLossRatio < this.maxBPVideoLossRatio) {
                  this.signalState = 1;
                } else if (this.videoPacketLossRatio >= this.maxBPVideoLossRatio) {
                  this.signalState = 0;
                }
              }
              else if (this.videoKbps <= breakpoints.min.res320x240) {
                this.signalState = 0;
              }
            }
          }
          else if (!this.videoAvailable) {
            this.videoPacketLossRatio = 0;
            this.videoKbps = 0;
          }

          if (!this.videoAvailable && this.audioAvailable) {
            this.audioBPMax = 20;
            this.audioBPMid = 15;
            this.audioBPMin = 10;
            if (this.audioKbps > this.audioBPMax) {
              if (this.audioPacketLossRatio < this.minBPAudioLossRatio) {
                this.signalState = 3;
              } else if (this.audioPacketLossRatio >= this.minBPAudioLossRatio) {
                this.signalState = 2;
              }
            }
            else if (this.audioKbps > this.audioBPMid && this.audioKbps <= this.audioBPMax) {
              if (this.audioPacketLossRatio < this.maxBPAudioLossRatio) {
                this.signalState = 2;
              } else if (this.audioPacketLossRatio >= this.maxBPAudioLossRatio) {
                this.signalState = 1;
              }
            }
            else if (this.audioKbps > this.audioBPMin && this.audioKbps <= this.audioBPMid) {
              if (this.audioPacketLossRatio < this.maxBPAudioLossRatio) {
                this.signalState = 1;
              } else if (this.audioPacketLossRatio >= this.maxBPAudioLossRatio) {
                this.signalState = 0;
              }
            }
            else if (this.audioKbps <= this.audioBPMin) {
              this.signalState = 0;
            }
          }
          else if (!this.audioAvailable) {
            this.audioPacketLossRatio = 0;
            this.audioKbps = 0;
          }

      }
      lastAudioBytesReceived = stats.audio?.bytesReceived;
      lastVideoBytesReceived = stats.video?.bytesReceived;
      lastTimestamp = timestamp;
      });
    })
  }

  showReactionMessage(source: string, reaction: string) {
    this.reactionSource = source;
    this.reactionType = reaction;

    if (this.reactionTimeout) {
      clearTimeout(this.reactionTimeout);
    }
    this.showReaction = true;
    this.reactionTimeout = setTimeout(() => {
      this.showReaction = false;
      this.reactionTimeout = null;
    }, 2000);
  }

  detectCameraControlChanges(controls: any) {
    if (this.cameraControls) {
      if (controls.flash.available && this.cameraControls.flash.status !== controls.flash.status) {
          this.showToggleFlash();
      }
      if (controls.zoom.available && this.cameraControls.zoom.status !== controls.zoom.status) {
        this.showZoomLevel();
      }
    }
    this.cameraControls = controls;
  }

  onToggleArCollaboration() {
    if (!this.features.arcollaboration) {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE');
      return;
    }
    if (!this.subscriberData || this.hdPhotoOpen || this.frame.stream.videoType === 'screen' || !this.videoAvailable || !this.subscribeVideo || !this.videoCanAvailable || this.arPlusOpen) {
      // No need to show message
      return;
    }
    if (this.trainingDisable) {
      this.flashMessageService.showTranslated('APP.SHARED.TRAINING_DISABLED');
      return;
    }
    this.toggleArCollaboration();
  }

  onToggleArPlus() {
    if (!this.features.arplus) {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE');
      return;
    }
    if (!this.arPlusOpen && !(this.subscriberData && !this.hdPhotoOpen && !(this.frame.stream.videoType === 'screen') && this.videoAvailable && this.subscribeVideo && this.videoCanAvailable)) {
      // No need to show message
      return;
    }
    if (!(this.deviceStatus && this.deviceStatus.arkit && this.deviceStatus.arkit.available)) {
      this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.AR_NOT_AVAILABLE', { timeout: 3000 });
      return;
    }
    if (this.trainingDisable) {
      this.flashMessageService.showTranslated('APP.SHARED.TRAINING_DISABLED');
      return;
    }

    if (this.arCollaborationOpen) { this.toggleArCollaboration() }
    if (this.arPlusOpen) {
      this.closeArPlus();
    } else {
      this.openArPlus();
    }
  }

  onToggleHdPhoto() {
    if (this.hdPhotoOpen) {
      this.flashMessageService.show('hd photo open, please wait', { timeout: 3000 });
      return;
    }
    if (!this.hdPhotoOpen && !(this.subscriberData && !(this.frame.stream.videoType === 'screen') && this.videoAvailable && this.subscribeVideo && this.videoCanAvailable)) {
      // No need to show message
      return;
    }
    if (!(this.deviceStatus && this.deviceStatus.photo && this.deviceStatus.photo.available)) {
      this.flashMessageService.show('hd photo not available', { timeout: 3000 });
      return;
    }
    if (this.trainingDisable) {
      this.flashMessageService.showTranslated('APP.SHARED.TRAINING_DISABLED');
      return;
    }

    this.arCollaborationService.openHdPhoto(this.subscriberData.uid)
    .then(tResult => {
      if (!tResult.committed && tResult.data?.collaboration?.data) {
        this.openCloseCollaborationModal("hd-photo")
      }
    });
  }

  openArPlus() {
    this.arCollaborationService.openArPlus(this.subscriberData.uid)
    .then(tResult => {
      if (!tResult.committed && tResult.data) {
        if (tResult.data.focus && (tResult.data.focus.type !== "video" || tResult.data.focus.id !== this.subscriberData.uid)) {
          this.showDisableFocusModal(this.roomUserStatus && this.roomUserStatus.user_status === "master");
        } else if (tResult.data.collaboration.data) {
          this.openCloseCollaborationModal("ar-plus");
        }
      }
    });
  }

  showDisableFocusModal(authorized: boolean) {
    const modalId = this.modalService.show({
      template: this.disableFocusTemplate,
      context: {
        dataModel: { authorized: authorized },
        callbacks: {
          no: () => this.modalService.hide(modalId),
          yes: () => {
            this.modalService.hide(modalId);
            this.roomSessionService.disableFrameFocus()
            .then(success => this.openArPlus());
          }
        }
      }
    });
  }

  closeArPlus() {
    if (this.fullscreenState.fullscreen && this.fullscreenState.supported) { this.onToggleFullscreen() }

    const modalId = this.modalService.show({
      template: this.closeArPlusTemplate,
      context: {
        dataModel: null,
        callbacks: {
          no: () => {
            this.modalService.hide(modalId);
          },
          yes: () => {
            this.modalService.hide(modalId);
            this.arCollaborationService.closeArPlus(this.subscriberData.uid);
          }
        }
      }
    });
  }

  openCloseCollaborationModal(type: "ar-plus" | "hd-photo") {
    const modalId = this.modalService.show({
      template: this.closeCollaborationTemplate,
      context: {
        dataModel: null,
        callbacks: {
          no: () => {
            this.modalService.hide(modalId);
          },
          yes: () => {
            this.modalService.hide(modalId);
            this.collaborationService.closeCollaboration().then(success => {
              setTimeout(() => {
                if (type === "ar-plus") {
                  this.openArPlus()
                } else if (type === "hd-photo") {
                  this.onToggleHdPhoto()
                }
              }, 500);
            });
          }
        }
      }
    });
  }

  getBatteryImageName(percent: number) {
    return 'assets/img/battery-'+Math.ceil(percent/20)+'.png';
  }

  getArColorCodeArray() {
    return Object.keys(ArCollaborationService.arColorNames);
  }

  onChangeArColor(color: string) {
    if (color !== this.arColorCode) {
      this.arCollaborationService.changeColor(color);
    }
  }

  showARPlusDisableReason() {
    this.flashMessageService.showTranslated('APP.SHARED.TRAINING_DISABLED');
  }

  onArPlusClear() {
    this.objSelected = false;
    this.arCollaborationService.clearArPlus(this.subscriberData.uid);
    this.subscriberService.deselectARObject(this.subscriberData.uid);
  }

  onArPlusUndo() {
    this.objSelected = false;
    this.arCollaborationService.undoArPlus(this.subscriberData.uid);
    this.subscriberService.deselectARObject(this.subscriberData.uid);
  }

  onArPlusRedo() {
    this.objSelected = false;
    this.arCollaborationService.redoArPlus(this.subscriberData.uid);
    this.subscriberService.deselectARObject(this.subscriberData.uid);
  }

  // Calculate left-top position (in 4:3 frame) with touch event
  // Both ar collaboration and ar plus uses this method
  calculateTouchLeftTopPosition(event: any) {
    const navbarHeight = 56;
    let mouseX: number;
    let mouseY: number;

    if(this.fullscreenState.fullscreen){
      mouseX = event.touches[0].clientX - parseFloat(this.frame.nativeElement.style.marginLeft);
      mouseY = event.touches[0].clientY - parseFloat(this.frame.nativeElement.style.marginTop) - (this.fullscreenState.supported ? 0 : navbarHeight);
    } else {
      mouseX = event.touches[0].clientX - parseFloat(this.frame.nativeElement.parentElement.style.left);
      mouseY = event.touches[0].clientY - parseFloat(this.frame.nativeElement.parentElement.style.top) - navbarHeight;
    }

    return { left: mouseX, top: mouseY };
  }

  // Calculate left-top position (in 4:3 frame) with mouse event
  calculateMouseLeftTopPosition(event: any) {
    let mouseX: number = (event.offsetX ? event.offsetX : event.layerX);
    let mouseY: number = (event.offsetY ? event.offsetY : event.layerY);

    return { left: mouseX, top: mouseY };
  }

  // Calculate left-top ratio (in 4:3 frame)
  calculateArCollLeftTopRatio(coord: { left: number, top: number }) {
    const width: number = this.subscriberContainer.nativeElement.clientWidth;
    const height: number = this.subscriberContainer.nativeElement.clientHeight;

    return { left: coord.left / width, top: coord.top / height };
  }

  // Calculate left-top ratio (in custom video frame)
  // removes padding, if video aspect ratio less than 4:3
  calculateArPlusLeftTopRatio(coord: { left: number, top: number }) {
    const aspectRatio = this.subscriber.stream.videoDimensions.width / this.subscriber.stream.videoDimensions.height;
    const width: number = this.subscriberContainer.nativeElement.clientWidth;
    const height: number = this.subscriberContainer.nativeElement.clientHeight;
    const videoHeight = width / aspectRatio;
    let topPadding = 0.0;

    if ((height - videoHeight) > 0) {
      topPadding = (height - videoHeight) / 2;
    }
    return { left: coord.left / width, top: (coord.top - topPadding) / videoHeight, topToFrame: coord.top / height };
  }

  toggleArCollaboration() {
    this.arCollaborationOpen = !this.arCollaborationOpen;
    if (this.arCollaborationOpen) {
      this.renderer.setStyle(this.frame.nativeElement, 'border', '2px solid #' + this.arColorCode);

      // Start listening events
      this.arCollTouchMouseSub = merge(
        // for touch events
        fromEvent(this.subscriberContainer.nativeElement, "touchmove").pipe(
          filter(e => this.arCollaborationActive),
          map(e => this.calculateTouchLeftTopPosition(e))
        ),
        // for mouse events
        fromEvent(this.subscriberContainer.nativeElement, "mousemove").pipe(
          filter(e => this.arCollaborationActive),
          map(e => this.calculateMouseLeftTopPosition(e))
        )
      ).pipe(map(coord => this.calculateArCollLeftTopRatio(coord)))
      .subscribe(ratioCoord => this.setArCollaborationDot(ratioCoord.left, ratioCoord.top));

    } else {
      if (this.arCollTouchMouseSub) { this.arCollTouchMouseSub.unsubscribe() }
      this.renderer.setStyle(this.frame.nativeElement, 'border', '2px solid white');
      this.arCollBtnGroup.collapseAll();
      this.arPlusBtnGroup.collapseAll();
    }
    this.changeDetector.detectChanges();
  }

  toggleArPlus() {
    this.arPlusOpen = !this.arPlusOpen;
    this.frame.isArPlusOpen = this.arPlusOpen;
    this.arPlusStatusChanged.emit(this.arPlusOpen);

    if (this.arPlusOpen) {
      this.renderer.setStyle(this.frame.nativeElement, 'border', '2px solid #' + this.arColorCode);

      // Start listening events
      this.arPlusTouchMouseSub = merge(
        // for touch events
        fromEvent(this.subscriberContainer.nativeElement, "touchmove").pipe(
          filter(e => this.arPlusActive),
          map(e => this.calculateTouchLeftTopPosition(e))
        ),
        // for mouse events
        fromEvent(this.subscriberContainer.nativeElement, "mousemove").pipe(
          filter(e => this.arPlusActive),
          map(e => this.calculateMouseLeftTopPosition(e))
        )
      // calculateArPlusLeftTopRatio removes padding, if video aspect ratio less than 4:3
      ).pipe(map(coord => this.calculateArPlusLeftTopRatio(coord)))
      .subscribe(ratioCoord => {
        if (this.arPlusDrawType === 'line') {
          this.arDots = [{ colorCode: this.arColorCode, color: this.arColor, shape: "disc", left: (ratioCoord.left*100).toFixed(2) + '%', top: (ratioCoord.topToFrame*100).toFixed(2) + '%', name: this.authService.currentUser.name }];
          this.arCollaborationService.addArPlusPointToLine(this.subscriberData.uid, this.currentArPlusLine, ratioCoord.left, ratioCoord.top);
        } else if (this.arPlusDrawType === 'move') {
          this.arDots = [{ colorCode: this.arColorCode, color: this.arColor, shape: "disc", left: (ratioCoord.left*100).toFixed(2) + '%', top: (ratioCoord.topToFrame*100).toFixed(2) + '%', name: this.authService.currentUser.name }];
          if (this.objSelected && Date.now() - this.dragDebounceTime > 100) {
            this.subscriberService.setSelectPosition(this.subscriberData.uid, ratioCoord.left, ratioCoord.top);
          }
        }
      });
      this.setDefaultArPlusDrawType();
    } else {
      if (this.arPlusTouchMouseSub) { this.arPlusTouchMouseSub.unsubscribe() }
      this.renderer.setStyle(this.frame.nativeElement, 'border', '2px solid white');
      this.arCollBtnGroup.collapseAll();
      this.arPlusBtnGroup.collapseAll();
    }
    this.changeDetector.detectChanges();
  }

  onDocumentMouseUp(event) {
    this.actionEnd(event);
  }

  onDocumentMouseDown(e) {
    this.actionStart(this.calculateMouseLeftTopPosition(e));
  }

  onDocumentTouchStart(e){
    e.preventDefault();
    this.actionStart(this.calculateTouchLeftTopPosition(e));
  }

  onDocumentTouchEnd(event){
    this.actionEnd(event);
  }

  onDocumentMouseLeave(event) {
    this.actionEnd(event);
  }

  setArCollaborationDot(x: number, y: number) {
    this.arCollaborationService.setDot(this.authService.currentUser.id, this.subscriberData.uid,
      this.arColorCode+'!'+
      (x * 100).toFixed(2)+'!'+
      (y * 100).toFixed(2)+'!'+
      this.authService.currentUser.name+'!'+this.arShape);
  }

  actionStart(coord: { left: number, top: number }) {
    if (this.arCollaborationOpen) {
      this.arCollBtnGroup.collapseAll();
      this.arPlusBtnGroup.collapseAll();
      this.arCollaborationActive = true;
      this.subscriberContainer.nativeElement.style.cursor = "none";

      let ratioCoord = this.calculateArCollLeftTopRatio({ left: coord.left, top: coord.top });
      this.setArCollaborationDot(ratioCoord.left, ratioCoord.top);
    }

    if (this.arPlusOpen) {
      this.arCollBtnGroup.collapseAll();
      this.arPlusBtnGroup.collapseAll();
      this.arPlusActive = true;
      this.subscriberContainer.nativeElement.style.cursor = "none";

      const ratioCoord = this.calculateArPlusLeftTopRatio({ left: coord.left, top: coord.top });

      // This code uses old ar collaboration dot, can be changed in future
      this.arDots = [{ colorCode: this.arColorCode, color: this.arColor, shape: "disc", left: (ratioCoord.left * 100).toFixed(2) + '%', top: (ratioCoord.topToFrame * 100).toFixed(2) + '%', name: this.authService.currentUser.name }];

      if (this.arPlusDrawType === 'line') {
        this.currentArPlusLine = this.arCollaborationService.createArPlusLine(this.subscriberData.uid, ratioCoord.left, ratioCoord.top, this.arColorCode, this.arPlusWeight);
      } else if (this.arPlusDrawType === 'arrow') {
        this.arCollaborationService.createArPlusArrow(this.subscriberData.uid, ratioCoord.left, ratioCoord.top, this.arColorCode, this.arPlusWeight, this.arPlusArrowType);
      } else if (this.arPlusDrawType === 'artext') {
        this.arCollaborationService.createArPlusText(this.subscriberData.uid, ratioCoord.left, ratioCoord.top, this.arColorCode, this.arPlusWeight, this.currentArText);
      } else if (this.arPlusDrawType === 'custom') {
        if (this.customObject && this.customObject.key) {
          this.arCollaborationService.createArPlusCustomObject(this.subscriberData.uid, ratioCoord.left, ratioCoord.top, this.arColorCode, this.arPlusWeight, this.customObject.key);
        }
      } else if (this.arPlusDrawType === 'move') {
        this.dragDebounceTime = Date.now();
        this.subscriberService.setSelectPosition(this.subscriberData.uid, ratioCoord.left, ratioCoord.top, true);
      }
    }
  }

  actionEnd(event) {
    if (this.arCollaborationOpen) {
      this.arCollaborationActive = false;
      this.subscriberContainer.nativeElement.style.cursor = "default";
      this.arCollaborationService.setDot(this.authService.currentUser.id, this.subscriberData.uid, '');
    }

    if (this.arPlusOpen && this.arPlusActive) {
      this.arPlusActive = false;
      this.subscriberContainer.nativeElement.style.cursor = "default";
      this.arDots = [];

      if (this.arPlusDrawType === 'line' && this.currentArPlusLine) {
        this.arCollaborationService.endArPlusLine(this.subscriberData.uid, this.currentArPlusLine);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.size) {
      this.size = changes.size.currentValue;
      if (this.size === 'small' && this.arCollaborationOpen) {
        this.toggleArCollaboration();
      }
    }
  }

  videoEnabled = event => {
    if (event.reason === "publishVideo") {
      this.videoAvailable = true;
    } else if (event.reason === "subscribeToVideo") {
      this.subscribeVideo = true;
      this.videoCanAvailable = true;
    } else if (event.reason === "quality") {
      this.videoCanAvailable = true;
    }
    this.removeIndicator(event.target);
    this.changeDetector.detectChanges();
  }

  videoDisabled = event => {
    if (event.reason === "publishVideo") {
      this.videoAvailable = false;
    } else if (event.reason === "subscribeToVideo") {
      this.subscribeVideo = false;
    } else if (event.reason === "quality") {
      this.videoCanAvailable = false;
      this.fixIndicator(event.target);
    }
    if (this.arCollaborationOpen) {
      this.toggleArCollaboration();
      this.arCollaborationActive = false;
      this.subscriberContainer.nativeElement.style.cursor = "default";
      this.arCollaborationService.setDot(this.authService.currentUser.id, this.subscriberData.uid, '');
    }
    this.changeDetector.detectChanges();
  }
/*
  videoDisableWarning = event => {
    this.blinkIndicator(event.target);
  }

  videoDisableWarningLifted = event => {
    this.removeIndicator(event.target);
  }
*/
  audioLevelUpdated = (event: OT.Event<"audioLevelUpdated", OT.Subscriber> & { audioLevel: number; }) => {
    if(this.frame.style2) {
      if (this.movingAvg === null || this.movingAvg <= event.audioLevel) {
        this.movingAvg = event.audioLevel;
      } else {
        this.movingAvg = 0.7 * this.movingAvg + 0.3 * event.audioLevel;
      }

      // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
      var logLevel = (Math.log(this.movingAvg) / Math.LN10) / 1.5 + 1;
      logLevel = Math.min(Math.max(logLevel, 0), 1);
      this.audioLevelIndicatorStyle.width = Math.floor(logLevel * 100) + "px";
      //this.frame.style2["box-shadow"] = `0px 0px 4px ${Math.floor(logLevel * 4)}px rgb(69 130 179)`;
      if (logLevel > 0.4) {
        this.frame.style2["box-shadow"] = `0px 0px 4px 4px rgb(69 130 179)`;
      } else if (logLevel > 0.2) {
        this.frame.style2["box-shadow"] = `0px 0px 4px 2px rgb(69 130 179)`;
      } else {
        this.frame.style2["box-shadow"] = '0px 3px 6px rgb(0, 0, 0)';
      }
    }
  }

  timer: any;
  indicatorOn: boolean = false;
  weakSignal: boolean = false;
  audioOnly: boolean = false;
/*
  blinkIndicator(s: OT.Subscriber) {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(() => {
      this.indicatorOn = !this.indicatorOn;
      this.changeDetector.detectChanges();
    }, 1000);
    this.weakSignal = true;
    this.indicatorOn = true;
    this.changeDetector.detectChanges();
  }
*/
  fixIndicator(s: OT.Subscriber) {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.weakSignal = true;
    this.audioOnly = true;
    this.indicatorOn = true;
    s.setStyle('videoDisabledDisplayMode', 'on');
    s.setStyle('audioLevelDisplayMode', 'on');
    this.changeDetector.detectChanges();
  }

  removeIndicator(s: OT.Subscriber) {
    if (this.timer) {
      clearInterval(this.timer);
    }
    s.setStyle('videoDisabledDisplayMode', 'off');
    s.setStyle('audioLevelDisplayMode', 'off');
    this.weakSignal = false;
    this.audioOnly = false;
    this.indicatorOn = false;
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    if (this.objectSub) { this.objectSub.unsubscribe() }
    this.continueToTrySubscribe = false;
    if (this.fullscreenSub) { this.fullscreenSub.unsubscribe() }
    if (this.arCollSub) { this.arCollSub.unsubscribe() }
    if (this.arCollTouchMouseSub) { this.arCollTouchMouseSub.unsubscribe() }
    if (this.arPlusTouchMouseSub) { this.arPlusTouchMouseSub.unsubscribe() }
    if (this.arCollSourcesSub) { this.arCollSourcesSub.unsubscribe() }
    if (this.reactionSub) { this.reactionSub.unsubscribe() }
    if (this.controlsSub) { this.controlsSub.unsubscribe() }
    if (this.featuresSub) { this.featuresSub.unsubscribe() }
    if (this.objectsSub) { this.objectsSub.unsubscribe() }
    if (this.roomUserStatusSub) { this.roomUserStatusSub.unsubscribe() }
    if (this.listenStatsSub) { this.listenStatsSub.unsubscribe() }
    if (this.subscriberStatsSub) { this.subscriberStatsSub.unsubscribe() }
    if (this.objectScaleSub) { this.objectScaleSub.unsubscribe() }
    if (this.addOnsSub) { this.addOnsSub.unsubscribe() }
    if (this.connectedSub) { this.connectedSub.unsubscribe() }
    if (this.planeDetectionSub) { this.planeDetectionSub.unsubscribe() }
    if (this.sessionDataSub) { this.sessionDataSub.unsubscribe() }
    if (this.currentRoomSub) { this.currentRoomSub.unsubscribe() }
    if (this.subscriber) {
      this.subscriber.off("videoEnabled", this.videoEnabled);
      this.subscriber.off("videoDisabled", this.videoDisabled);
      //this.subscriber.off("videoDisableWarning", this.videoDisableWarning);
      //this.subscriber.off("videoDisableWarningLifted", this.videoDisableWarningLifted);
      this.subscriber.off("audioLevelUpdated", this.audioLevelUpdated);
    }
    if (this.timer) {
      clearInterval(this.timer);
    }

    if (this._document.pictureInPictureElement && this._document.exitPictureInPicture) {
      this._document.exitPictureInPicture();
    }
  }

  onScreenshotRequest() {
    if (!this.features.snapshot) {
      this.flashMessageService.showTranslated('APP.SHARED.NO_FEATURE');
      return;
    }
    if (!this.subscriberData || (this.arPlusOpen && this.subscriberData && (this.subscriberData.platform === 'android' || this.subscriberData.platform === 'android-glasses') && !this.androidArSnapshotAvailable) || this.frame.stream.videoType === 'screen' || this.hdPhotoOpen || !this.videoAvailable) {
      // No need to show message
      return;
    }
    if (this.trainingDisable) {
      this.flashMessageService.showTranslated('APP.SHARED.TRAINING_DISABLED');
      return;
    }
    this.logService.sendScreenshotRequestLog(this.subscriberData.uid, this.subscriberData.name);
    this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.SCREENSHOT_REQUESTED', { cssClass: 'alert-info', timeout: 3000 });
  }

  onToggleAudio() {
    this.subscribeAudio = !this.subscribeAudio;
    this.subscriber.subscribeToAudio(this.subscribeAudio);
    this.opentokService.setSubscriberAudioVideoStatus(this.subscriberData.uid, this.subscribeAudio, this.subscribeVideo);
    this.changeDetector.detectChanges();
  }

  onToggleVideo() {
    const result = !this.subscribeVideo;
    if (result) {
      if (!this.videoAvailable) {
        this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.SUBSCRIBER.VIDEO_NOT_AVAILABLE', { cssClass: 'alert-danger', timeout: 1000 });
      }
    }
    this.subscriber.subscribeToVideo(result);
    this.opentokService.setSubscriberAudioVideoStatus(this.subscriberData.uid, this.subscribeAudio, result);
  }

  onToggleFullscreen() {
    if (this.fullscreenState.fullscreen) {
      this.toggleFullscreen.emit(false);
    } else {
      this.toggleFullscreen.emit(true);
    }
  }

  onTogglePictureInPicture() {
    if (this._document.pictureInPictureElement) {
      if (this._document.exitPictureInPicture) {
        this._document.exitPictureInPicture();
      }
    } else {
      const videoElement = this.subscriberContainer.nativeElement.getElementsByClassName("OT_video-element").item(0);
      if (videoElement) {
        if (videoElement.requestPictureInPicture) {
          videoElement.requestPictureInPicture()
          .catch(error => {
            // Video failed to enter Picture-in-Picture mode.
          });
        }
      }
    }
  }

  setDefaultArPlusDrawType() {
    this.arPlusArrowType = 'arrow-down';
    if (this.showPlaneSurfacesButton) {
      this.arPlusDrawType = 'arrow'
    } else {
      this.arPlusDrawType = 'line';
    }
  }
}
