import { Pipe, PipeTransform } from "@angular/core";
import { AuthService } from "@services/core/auth.service";

import { UtilityService } from "@services/support/utility.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Pipe({name: 'utcCalcAccount'})
export class UtcCalcAccountPipe implements PipeTransform {

    constructor(private authService: AuthService) {}

    transform(timestamp: number, appendTimezone?: boolean): Observable<string> {
        return this.authService.timezone.pipe(map(tz => {
            const d = new Date(timestamp+UtilityService.timezoneOffset+UtilityService.timezones[tz]);
            const formatted = `${("0"+d.getDate()).slice(-2)}.${("0"+(d.getMonth()+1)).slice(-2)}.${d.getFullYear()} ${("0" + d.getHours()).slice(-2)}:${("0" + d.getMinutes()).slice(-2)}`;
            return appendTimezone ? `${formatted} ${tz}` : formatted;
        }));
    }
}