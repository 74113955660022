import linkifyStr from 'linkify-string';

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'linkify'})
export class LinkifyPipe implements PipeTransform {

    transform(value: any, options: any) {
        return value ? linkifyStr(value, options) : value;
    }
}