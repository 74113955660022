import { Directive, Input, OnInit, OnDestroy, ElementRef } from '@angular/core';

@Directive({
    selector: '[addNativeElement]'
})
export class AddNativeElementDirective implements OnInit, OnDestroy {
    @Input('addNativeElement') obj: any;

    constructor(private el: ElementRef) {}

    ngOnInit() {
        this.obj.nativeElement = this.el.nativeElement;
    }

    ngOnDestroy() {
        this.obj.nativeElement = null;
    }
}