import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  onSidebarTogglerClicked: Observable<void>;
  private onSidebarTogglerClickedSource: Subject<void> = new Subject<void>();

  sidebarTogglerStatus: Observable<boolean>;
  private sidebarTogglerStatusSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.onSidebarTogglerClicked = this.onSidebarTogglerClickedSource.asObservable();
    this.sidebarTogglerStatus = this.sidebarTogglerStatusSource.asObservable();
  }

  sidebarTogglerClicked() {
    this.onSidebarTogglerClickedSource.next();
  }

  setSidebarTogglerStatus(show: boolean) {
    this.sidebarTogglerStatusSource.next(show);
  }
}
