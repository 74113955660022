import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@services/core/auth.service';
import { DbService } from '@services/core/db.service';
import { LoaderService } from '@services/support/loader.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss']
})
export class SsoLoginComponent implements OnInit {

  loading: boolean = true;
  authSubscription: Subscription = null;

  constructor(
    private dbService: DbService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.authenticate();
  }

  async authenticate() {
    const params = await this.route.queryParams.pipe(first()).toPromise();
    if (!(params && params.id)) {
      this.loading = false;
      return;
    }

    // Navigate main if user already authenticated
    this.authSubscription = this.dbService.authState.subscribe(auth => {
      if (auth) {
        this.router.navigate((params.userlist ? ['/contacts'] : ['/']));
      }
    });

    this.loaderService.show();
    console.log(params.id);
    this.authService.getCustomTokenFromSsoLinkId(params.id)
    .then(r => this.authService.login(r.token))
    .catch(error => { this.loading = false; })
    .finally(() => this.loaderService.hide());
  }
}
