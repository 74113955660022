<div class="tutorial-wrapper" *ngIf="tutorialOpen">
    <div class="skip" *ngIf="!initialTutorialModalOpen">
        <button class="btn btn-danger end-btn p-0" (click)="endTutorial()">
            <span class="skip-text">DONT SHOW AGAIN</span>
            <!--<button class="skip-btn btn bg-danger"><i class="mi mi-close"></i></button>-->
        </button>
        <button class="btn btn-dark skip-btn p-0" (click)="skipTutorial()">
            <span class="skip-text">CLOSE TUTORIAL</span>
            <!--<button class="skip-btn btn bg-danger"><i class="mi mi-close"></i></button>-->
        </button>
    </div>
    
    <div #initialTutorialModal="tutorial" tutorial="initialTutorial" [class.d-none]="!initialTutorialModal.isActive()" class="modal-content rounded-0">
        <div class="modal-body px-3 py-0">
            <p class="title mt-3">{{ 'APP.TUTORIALS.USER_TUTORIAL' | translate }}</p>
            <p class="body mt-4">{{ 'APP.TUTORIALS.GUIDE' | translate }}</p>
            <div class="custom-control custom-checkbox d-flex align-items-center mt-4">
                <input type="checkbox" class="custom-control-input" id="dontshow" name="dontshow" [(ngModel)]="isDontShowChecked">
                <label class="custom-control-label" for="dontshow">{{ 'APP.TUTORIALS.DONT_SHOW' | translate }}</label>
            </div>
        </div>
        <div class="modal-footer d-flex justify-content-between pt-0 pb-2">
            <button class="not-now-button btn btn-sm btn-danger px-4 rounded-0" (click)="isDontShowChecked ? endTutorial() : skipTutorial()">{{ 'APP.TUTORIALS.SKIP_TUTORIAL' | translate }}</button>
            <button class="continue-button btn btn-sm btn-secondary px-4 rounded-0" (click)="continueTutorial()">{{ 'APP.TUTORIALS.NEXT' | translate }}</button>
        </div>
    </div>
</div>

<ng-template #skipTutTemplate let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.TUTORIALS.SKIP_TUTORIAL' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p class="mx-3">{{ 'APP.TUTORIALS.ASK_AGAIN' | translate }}</p>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.no()">{{ 'APP.TUTORIALS.CANCEL' | translate }}</button>
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.yes()">{{ 'APP.TUTORIALS.SKIP' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>